

<template>
  <div>
    <div id="chengkaopersonal" class="chengkaopersonal-w"></div>
    <!-- 该标签用来触发 updated 函数 -->
    <div class="loading-end">{{isLoading}}</div>
  </div>
</template>


<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import { performance_chengkaopersonal } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
export default {
  mixins: [part],
  name: "chengkaopersonal",
  created() {
    this.chengkao();
  },

  mounted() {
    this.initEchart();
  },

  data() {
    return {
      schoolsData: [
        {
          name: "上月招生人数",
          type: "bar",
          data: [1000, 900, 800, 700, 600, 500, 400, 300, 200, 100],
        },
        {
          name: "本月招生人数",
          data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
          type: "bar",
        },
      ], //所有教学点不同时间报名人数 总数据

      students: [
        "学生1（总部1）",
        "\n学生2（总部2）",
        "学生3（总部3）",
        "\n学生4（总部4）",
        "学生5（总部5）",
        "\n学生6（总部6）",
        "学生7（总部7）",
        "\n学生8（总部8）",
        "学生9（总部9）",
        "\n学生10（总部10）",
      ], //x轴前10名学生

      isLoading:true, //是否结束加载

    };
  },

  methods: {
    ...mapActions("global", ["onExtendClick"]),
    initEchart() {
      // echart
      const dom = document.getElementById("chengkaopersonal");
      const myChart = this.$echarts.init(dom);

      let option = {
        title: {
          subtext: "成考招生精英",
        },
        tooltip: {},
        legend: {
          left: "right",
          data: ["上月招生人数", "本月招生人数"],
        },
        grid: {
          width: "1000px",

          top: 80,
          bottom: 50,
          left: 60,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                formatter: function (params) {
                  return params.value.replace("\n", "");
                },
              },
            },
          },
        },
        xAxis: [
          {
            type: "category",
            name: "排名（从左往右依次排名）",
            axisLabel: {
              interval: 0,
              formatter: function (value, i) {
                if (value.length > 2 && i % 2 !== 0) {
                  return value.substring(0, 4) + "...";
                } else if (value.length > 2) {
                  return value.substring(0, 3) + "...";
                }
              },
            },
            data: this.students,
            axisTick: {
              alignWithLabel: true,
            },
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "招生人数",
            min: "0",
            max: "2000",
          },
        ],
        series: this.schoolsData,
      };
      myChart.setOption(option);
    },
    //api
    chengkao() {
      myRequest({
        url: performance_chengkaopersonal,
      }).then((res) => {
        if (res.data.code === "200") {
          //   this.performancelist = res.data.data.performancelist;
          // this.whileSchoolData(this.performancelist);
          this.isLoading = false
        }
      });
    },
    //遍历所有教学点不同时间报名人数
    whileSchoolData(alldata) {
      for (let c in this.valFlag) {
        for (let i in alldata) {
          this.schoolsData[c].data.push(alldata[i][this.valFlag[c]]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chengkaopersonal-w {
  width: 100%;
  height: 400px;
}

.loading-end{
    display: none;
  }
</style>