<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="题库名称:">
          <el-input
						v-model.trim="searchForm.questionname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
				<el-form-item label="题库类型:">
					<el-select
						v-model="searchForm.questiontype"
						@visible-change="isintegrity"
						@keyup.enter.native="seach"
						ref="isintegrityref"
						clearable
					>
						<el-option value="1" label="学生"></el-option>
						<el-option value="2" label="员工" :disabled="!$store.state.login.isCourseAuth"></el-option>
					</el-select>
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column
        label="流水号"
        prop="id"
        align="center"
      />
      <el-table-column
        label="题库名称"
        prop="questionname"
        align="center"
        width="200"
      />
      <el-table-column label="题库类型" align="center" prop="questiontype" width="200">
				<template slot-scope="scope">
			    {{ isCourse[scope.row.questiontype] }}
			  </template>
      </el-table-column>
      <el-table-column
        label="排序"
        prop="sort"
        align="center"
        width="80"
      />
			<el-table-column
        label="操作"
        align="center"
      >
			<template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">
							详情
					</el-button>
        </template>
			</el-table-column>

    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
    <!--添加，修改-->
    <el-dialog
			class="btn-2b5a95"
			width="650px"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
				:model="dynamicValidateForm"
				ref="dynamicValidateForm"
				class="demo-dynamic form-item-w-100"
				status-icon
				:rules="rules"
				label-width="100px"
				>
				<el-form-item label="题库类型:" prop="questiontype">
					<el-select
						v-model="dynamicValidateForm.questiontype"
            clearable
					>
						<el-option :value="1" label="学生"></el-option>
						<el-option :value="2" label="员工" :disabled="!$store.state.login.isCourseAuth"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="题库名称:" prop="questionname">
          <el-input
						v-model.trim="dynamicValidateForm.questionname" />
        </el-form-item>
				<el-form-item label="排序:" prop="sort">
          <el-input
						v-model.trim="dynamicValidateForm.sort" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 详情 -->
    <el-dialog
      class="btn-2b5a95"
      width="76%"
			v-dialogDrag
      title="详情："
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
          <el-form-item label="题目类型:" prop="ttype">
          <el-select
						v-model="selectSearchForm.ttype"
            @keyup.enter.native="selectSeach"
						clearable >
            <el-option
							v-for="question in questiontypeList"
							:key="question.key"
							:label="question.val"
							:value="question.key">
						</el-option>
          </el-select>
        </el-form-item>
            <el-form-item label="标题:" prop="title">
              <el-input
                v-model.trim="selectSearchForm.title"
            		@keyup.enter.native="selectSeach"
                clearable
              />
            </el-form-item>
            <el-form-item label="录入人:" prop="insertusername">
              <el-input
                v-model.trim="selectSearchForm.insertusername"
            		@keyup.enter.native="selectSeach"
                clearable
              />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="selectSeach"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <!-- 操作按钮 -->
      <div class="select-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="btnAddasDetial">新增</el-button>
        <el-button type="primary" @click="btnEditDetial">修改 </el-button>
        <el-button type="primary" @click="btnDelDetial">删除 </el-button>
				<el-button type="primary" @click="btnImportDetial">导入 </el-button>
        <el-button type="primary" @click="btnReloadDetial">刷新 </el-button>
      </div>
      <el-table
        :data="showTable.list"
        style="width: 100%"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="题目类型"
          prop="ttype"
          align="center"
        >
        <template slot-scope="scope">
			    {{ istype[scope.row.ttype] }}
			  </template>
      </el-table-column>
        <el-table-column
          label="标题"
          prop="title"
          align="center"
        ></el-table-column>
				<el-table-column
          label="内容"
          prop="content"
          align="center"
        ></el-table-column>
				<el-table-column
          label="选项abcd"
          prop="questionoption"
          align="center"
        ></el-table-column>
				<el-table-column
          label="答案"
          prop="answer"
          align="center"
        ></el-table-column>
        <el-table-column
          label="解析"
          prop="reference"
          align="center"
        ></el-table-column>
        <el-table-column
          label="排序"
          prop="sort"
          align="center"
        ></el-table-column>
        <el-table-column
          label="录入时间"
          prop="inserttime"
          align="center"
        >
        <template slot-scope="scope">
            {{ scope.row.inserttime | formatDate("YYYY-MM-DD") }}
          </template>
        </el-table-column>
				<el-table-column
          label="录入人"
          prop="insertusername"
          align="center"
        ></el-table-column>
      </el-table>

      <el-pagination
        background
        @current-change="handleDetailTableChange"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="showTable.totalcount"
      ></el-pagination>
    </el-dialog>
		<el-dialog
			class="btn-2b5a95"
			width="45%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="videocoursedetialModal"
      v-if="videocoursedetialModal"
      append-to-body
    >
      <el-form
				:model="videoForm"
				ref="videoForm"
				class="demo-dynamic form-item-w-100"
				status-icon
				:rules="rules"
				label-width="100px"
				>
        <el-form-item label="题目类型:" prop="ttype">
          <el-select
						v-model="videoForm.ttype"
						clearable >
            <el-option
							v-for="question in questiontypeList"
							:key="question.key"
							:label="question.val"
							:value="question.key">
						</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题:" prop="title">
          <el-input v-model="videoForm.title" clearable />
        </el-form-item>
        <div v-if="videoForm.ttype == '1' || videoForm.ttype == '2' " class="display--flex w-e-toolbar">
          <div v-for="(item, i) in videoForm.moreItem1" :key="i" class="content_videoForm">
            <el-form-item label="选项:"
              :prop="'moreItem1.' + i + '.contents'"
              :rules="{
						  required: true,
						  message: '必填字段',
						  trigger: 'blur',
						}"
              style="width: 60%">
			    		<el-input v-model.trim="item.contents" clearable ></el-input>
			    	</el-form-item>
            <el-button @click="report_onRemoveMoreForm(i, 'moreItem1')" style="margin: 0 10px; height: 40px">删除
			    	</el-button>
          </div>
          <el-button @click="report_addFormItem1" style="margin: 0 10px; height: 40px">添加一行</el-button>
        </div>
        <el-form-item label="答案:" prop="answer">
          <el-input v-model="videoForm.answer"/>
        </el-form-item>
        <el-form-item label="解析:" prop="reference">
          <el-input v-model="videoForm.reference"/>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input v-model="videoForm.sort"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitvideoForm('videoForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 导入 -->
    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="showList"
      :downloadUrl="downloadUrl"
    ></Import>
  </div>
</template>


<script>
import { _coursetype, _ttype } from "@/assets/js/filedValueFlag";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  question_list,
	question_save,
  question_input,
  question_delete,
  questiondetailed_save,
  questiondetailed_input,
  questiondetailed_delete,
  questiondetailed_list,
  questiondetailed_importsave,
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "management",
	components: {
    Import,
  },
  props: {
    questiondetailed_importsave: {
      default: () => questiondetailed_importsave,
    },
    questiondetailed_list: {
      default: () => questiondetailed_list
    }
  },
  data() {
    return {
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
			fds: new FormData(),
			map:"",
      fileList: [], //上传的文件列表，仅作展示
      file: new FormData(), //上传的文件表单
			rules: {
				questiontype: [{ trigger: "blur", message: "必填字段", required: true }],
				questionname: [{ trigger: "blur", message: "必填字段", required: true }],
				sort: [{ trigger: "blur", message: "必填字段", required: true }],
        ttype: [{ trigger: "blur", message: "必填字段", required: true }],
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        contents: [{ trigger: "blur", message: "必填字段", required: true }],
        answer: [{ trigger: "blur", message: "必填字段", required: true }],
        reference: [{ trigger: "blur", message: "必填字段", required: true }],
        sort: [{ trigger: "blur", message: "必填字段", required: true }],
			},
			multipleSelection: [], //选中的表格项
    	showAddModal: false, //添加
    	dynamicValidateForm: {
	  	}, //表单
    	dialogTitle: "", //对话框标题
			isCourse: _coursetype,
			showDetailModal: false, //详情
			showTable: {},
			videoForm: {},
			videocoursedetialModal: false,
			importSaveUrl: "",
      downloadUrl: "",
			importDialog: false, //导入框
      questiontypeList: [],
      videoForm: {
        moreItem1: [
				    {
							contents: ""
				    },
				  ],

			},
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页
      selectTableUrl: "",
      istype: _ttype,
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      list: "questiontypeList",
      typecode: "questiontype",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		isintegrity(e) {
			if(!e) {
				this.$refs.isintegrityref.blur();
			}
		},
		reset() {
			this.searchForm = {
				questionname: null,
				questiontype: null,
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: question_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    selectQuery() {
			this.selectSearchForm = {
				title: null,
        insertusername: null,
        ttype: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(questiondetailed_list, null,{
        questionid: this.itemRow.id,
		    });
		},
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 搜索
    selectSeach() {
			this.selectPageInfo.pageindex = 1;
      this.showList();
    },
		//查看详情 click
		btnShowDetail(row) {
		 this.itemRow = row;
		 this.showList();
		},
	 //切换流水账表格页
    handleDetailTableChange(val) {
      this.selectPageInfo.pageindex = val;
      this.showList();
			this.onCloseSelectadd();
    },
    handleChange() {
      if(!this.$store.state.login.isCourseAuth) {
          this.isChecked=true// 禁用与启用
				}else {
					this.isChecked=false// 禁用与启用
				}
    },
    onCloseSelectadd() {
      this.selectPageInfo.pageindex = 1;
    },
		   //查看详情 api
		showList() {
      this.selectSearchForm.questionid = this.itemRow.id,
      myRequest({
        url: questiondetailed_list,
        data: Object.assign(this.selectSearchForm, this.selectPageInfo)
      }).then((res) => {
        if (res.data.code === "200") {
          this.showTable = res.data.data;
					this.showDetailModal = true;
        }
      });
    },
    	//添加一行 - （本人简历） click
		report_addFormItem1() {
			this.videoForm.moreItem1.push({
        contents: ""
			});
		},
    //删除一行 click
		report_onRemoveMoreForm(index, prop) {
			this.videoForm[prop].splice(index, 1);
		},
		  //关闭导出框
		onCloseExport(bool) {
      this.transferDialog = false;
    },
		//关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
		// 导入
		btnImportDetial() {
            console.log('课程流水号---------：',this.itemRow.id)
			this.importSaveUrl = questiondetailed_importsave + '?questionid=' + this.itemRow.id;
      this.downloadUrl = "download/教学管理/学历/题目导入模板.xls";
      this.importDialog = true;
		},
		 //查看详情的刷新 click
		btnReloadDetial() {
      this.showList()
    },
    btnAddasDetial() {
      this.dialogTitle = "添加";
			this.videocoursedetialModal = true;
			this.videoForm = {
        moreItem1: [
				    {
							contents: ""
				    },
				  ],

			}


		},

		//查看详情的修改 click
    btnEditDetial() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
				this.editVideoForm(this.multipleSelection[0].id);
      });
    },
		editVideoForm(id) {
      myRequest({
        url: questiondetailed_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
					this.videoForm = Object.assign(res.data.data.vquestiondetailed);
          this.initDetail(res.data.data);
				}
      });
    },
    //修改数据
		initDetail(resData) {
			this.$set(this.videoForm, "moreItem1", []);
			for(let i in resData.contentlist) {
				this.videoForm.moreItem1.push(resData.contentlist[i]);
			}
			this.videocoursedetialModal = true;
		},
		//添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
      };
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
		 //删除click
		 btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
		//删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: question_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
		 //查看详情的删除 click
		 btnDelDetial() {
      selectCheck(this.multipleSelection, "删除", true, () => {
				confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTable(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

		delTable(idArr) {
      myRequest(
        {
          method: "post",
          url: questiondetailed_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          )
        }).then((res) => {
          if(res.data.code == "200") {
            this.showList();
          }
        })
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: question_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
					this.showAddModal = true;
					this.dynamicValidateForm = res.data.data.vquestion;

				}
      });
    },
    filterParams(obj){
      let _newPar = {};
      for (let key in obj) {
          if ((obj[key] === 0 || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
              _newPar[key] = obj[key];
          }
      }
      return _newPar;
    },
		submitvideoForm(formName) {
      const moreDataKey = ["moreItem1"];
		  const moreItemKey = [
        "contents",
		  ];
		  moreItemKey.map((i) => (this.videoForm[i] = []));
			for (let moreKey in moreDataKey){
			  for(let j in moreItemKey){
			    for(let i in this.videoForm[moreDataKey[moreKey]]){
			      for (let key in this.videoForm[moreDataKey[moreKey]][i]){
			        if (this.videoForm[moreDataKey[moreKey]][i][key] && key === moreItemKey[j]) {
			          //有值才push，否则是空字符串
			          this.videoForm[key].push(this.videoForm[moreDataKey[moreKey]][i][key]);
			        }
			      }
			    }
			  }
			}
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.videoForm.questionid = this.itemRow.id,
          delete this.videoForm.inserttime
					myRequest({
          method: "post",
					url: questiondetailed_save,
          data: this.$qs.stringify(
            this.filterParams(this.videoForm),
						{
              arrayFormat: "repeat",
            }),
						},
						{
			  		isRefTableList: true,
			  		that: this,

			  		modal: "videocoursedetialModal",
					}).then((res) => {
            if(res.data.code == "200") {
              this.onSelectData(questiondetailed_list, null, {
                questionid: this.itemRow.id,
               });
            }
        })
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
					this.submitForm();
        }
      });
    },

		//提交-（添加，修改） api
		submitForm() {
			myRequest({
				method: "post",
				url: question_save,
				data: this.$qs.stringify(this.dynamicValidateForm, {
					arrayFormat: 'repeat'
			}),
			},{
			  isRefTableList: true,
			  that: this,

			  modal: "showAddModal",
			})
		},
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style lang="scss">

</style>
