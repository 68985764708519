<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					@submit.native.prevent
        >
					<el-form-item label="摘要:">
					  <el-input 
							v-model.trim="searchForm.zhaiyao" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="金额:">
					  <el-input 
							v-model.trim="searchForm.amount" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="审核状态:">
					  <el-select
							v-model="searchForm.approveflag" 
							@keyup.enter.native="seach"
							@visible-change="visibleApproveflag"
							ref="approveflagref"
							clearable>
					    <el-option value="-2" label="未提请审批" />
					    <el-option value="-1" label="审核中" />
					    <el-option value="0" label="审核不通过" />
					    <el-option value="1" label="通过" />
					  </el-select>
					</el-form-item>
					<el-form-item label="是否已支出:">
					  <el-select 
							v-model="searchForm.moneyisoutflag" 
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
							clearable>
					    <el-option value="0" label="否" />
					    <el-option value="1" label="是" />
					  </el-select>
					</el-form-item>
					<el-form-item label="冲账标识:">
					  <el-select 
							v-model="searchForm.chongzhanflag" 
							@keyup.enter.native="seach"
							@visible-change="visiblechongzhanflag"
							ref="chongzhanflag"
							clearable>
					    <el-option value="0" label="未冲账" />
					    <el-option value="1" label="已冲账" />
					    <el-option value="2" label="被冲账" />
					  </el-select>
					</el-form-item>
          <el-form-item label="操作人:">
            <el-input 
							v-model.trim="searchForm.opusername" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      border
			stripe
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column
			  label="摘要"
			  prop="zhaiyao"
			  align="center"
				:show-overflow-tooltip="true" 
				width="200"
			/>
      <el-table-column label="金额" prop="amount" align="center" width="100">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.amount
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="现金/银行卡号"
        prop="accountname"
        align="center"
				width="200"
      />
      <el-table-column label="审核状态" align="center" width="100">
        <template slot-scope="scope">
          {{ approveflag[scope.row.approveflag] }}
        </template>
      </el-table-column>
      <el-table-column label="是否已支出" align="center" width="100">
        <template slot-scope="scope">
          {{ scope.row.moneyisoutflag == 1 ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="报销人" prop="outusername" align="center" />
      <el-table-column label="报销时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.outtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
				width="140"
      />
      <el-table-column label="冲账标识" align="center">
        <template slot-scope="scope">
          {{ chongzhanflagObj[scope.row.chongzhanflag] }}
        </template>
      </el-table-column>
      <el-table-column
        label="被冲流水号"
        prop="chongzhanpayid"
        align="center"
				width="120"
      />
      <el-table-column label="操作人" prop="opusername" align="center" width="120"/>
			<!-- <el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-button
					  type="primary"
					  size="small"
					  @click="downloadFile(0, scope.row)"
					  >查看附件</el-button
					>
				</template>
			</el-table-column> -->
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--快速登记-->
    <el-dialog
      class="btn-2b5a95"
      width="83%"
			v-dialogDrag
      title="快速登记："
      :visible.sync="showAddModal"
      v-if="showAddModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="150px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司：">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点：">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报销人名称：" prop="outusername" >
          <el-input v-model="dynamicValidateForm.outusername" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(user_dialogresult, 'showUserModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="报销时间：" prop="outtime" >
          <el-date-picker
            v-model="dynamicValidateForm.outtime"
            type="datetime"
            value-format="yyyy-MM-dd hh:mm:ss"
            placeholder="请选择"
          />
        </el-form-item>
				<el-form-item label="现金/银行:" prop="bankorcash">
				  <el-input
				    v-model="bankorcash[dynamicValidateForm.bankorcash]"
				    disabled
				  />
				  <el-button
				    type="primary"
				    class="select-btn"
				    @click="
				      onSelectData(account_dialogresult, 'showAccountModal', {
				        schoolorgid: dynamicValidateForm.schoolorgid,
				      })
				    "
				    >选择账户</el-button
				  >
				</el-form-item>
				<el-form-item label="现金账户/卡号:" prop="accountname">
				  <el-input v-model.trim="dynamicValidateForm.accountname" disabled />
				</el-form-item>
        <el-form-item
          label="是否本部支出："
          prop="notselfflag"
          
        >
          <el-select v-model="dynamicValidateForm.notselfflag">
            <el-option value="1" label="本部支出"></el-option>
            <el-option value="0" label="非本部支出"></el-option>
          </el-select>
        </el-form-item>
        <p style="margin: 0 0 10px 0">支出详情：</p>
				
        <div
          v-for="(item, i) in dynamicValidateForm.moreItems"
          :key="i"
          class="more-form-item-w"
        >
					<el-form-item
					  label="摘要："
					  :prop="'moreItems.' + i + '.zhaiyao'"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input 
							v-model.trim="item.zhaiyao" 
							clearable
							/>
					</el-form-item>
					<el-form-item
					  label="金额："
					  :prop="'moreItems.' + i + '.amount'"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model.trim="item.amount" clearable/>
					</el-form-item>
					<el-form-item
					  label="支出类别："
					  :prop="'moreItems.' + i + '.typename'"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model.trim="item.typename" disabled></el-input>
					  <el-button
					    type="primary"
					    class="select-btn"
					    @click="
					      onSelectData(moneyouttype2_dialogresult, 'showMoneyOut2Modal', {
					        index: i,
					      })
					    "
					    >选择</el-button
					  >
					</el-form-item>
					<el-form-item
					  label="支出一级类别："
					  :prop="'moreItems.' + i + '.type1name'"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model="item.type1name" disabled></el-input>
					</el-form-item>
					<el-form-item
					  label="支出二级类别："
					  :prop="'moreItems.' + i + '.type2name'"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model="item.type2name" disabled></el-input>
					</el-form-item>
					
					
          <el-form-item
            label="支出明细："
            :prop="'moreItems.' + i + '.name'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model="item.name" disabled></el-input>
            <el-button
              type="primary"
              class="select-btn"
              @click="
                onSelectData(moneyoutitem_dialogresult, 'showMoneyOutModal', {
                  index: i,
                })
              "
              >选择</el-button
            >
          </el-form-item>
          <el-button
            v-if="i !== 0"
            @click="onRemoveMoreForm(i)"
            style="margin: 0 10px; height: 40px"
            >删除</el-button
          >
        </div>
        <el-form-item>
          <el-button @click="addFormItem">添加一行</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 修改快速登记 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="60%"
			title="修改"
			:close-on-click-modal='false'
		  :visible.sync="showinputModal"
		  v-if="showinputModal"
			v-dialogDrag
		  append-to-body
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="150px"
		    class="demo-dynamic form-item-w-50"
		    status-icon
		    :rules="rules"
		  >
		    <el-form-item prop="companyorgname" label="所属分公司：">
		      <el-select
		        v-model="dynamicValidateForm.companyorgname"
		        placeholder="请选择"
		        :disabled="!$store.state.login.isMaxAuth"
		        clearable
		        @change="onChangeCompany($event, 'dynamicValidateForm')"
						disabled
		      >
		        <el-option
		          v-for="item in $store.state.selectData.companyList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item prop="schoolorgname" label="所属教学点：">
		      <el-select
		        v-model="dynamicValidateForm.schoolorgname"
		        placeholder="请选择"
		        clearable
		        @change="onChangeSchool($event, 'dynamicValidateForm')"
						disabled
		      >
		        <el-option
		          v-for="item in $store.state.selectData.schoolList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="报销人名称：" prop="outusername" >
		      <el-input v-model="dynamicValidateForm.outusername" disabled />
		      <el-button
		        type="primary"
		        class="select-btn"
		        @click="onSelectData(user_dialogresult, 'showUserModal')"
		        >选择</el-button
		      >
		    </el-form-item>
		    <el-form-item label="报销时间：" prop="outtime" >
		      <el-date-picker
		        v-model="dynamicValidateForm.outtime"
		        type="datetime"
		        value-format="yyyy-MM-dd hh:mm:ss"
		        placeholder="请选择"
		      />
		    </el-form-item>
		    <p style="margin: 0 0 10px 0">支出详情：</p>
		    <div
		      class="more-form-item-w"
		    >
					<el-form-item
					  label="摘要："
					  prop="zhaiyao"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input 
							v-model.trim="dynamicValidateForm.zhaiyao" 
							clearable
							/>
					</el-form-item>
					<el-form-item
					  label="金额："
					  prop="amount"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model.trim="dynamicValidateForm.amount" clearable/>
					</el-form-item>
					<el-form-item
					  label="支出类别："
					  prop="typename"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model="dynamicValidateForm.typename" disabled />
					  <el-button
					    type="primary"
					    class="select-btn"
					    @click="
					      onSelectData(moneyouttype2_dialogresult, 'showMoneyOut3Modal')
					    "
					    >选择</el-button
					  >
					</el-form-item>
					<el-form-item
					  label="支出一级类别："
					  prop="type1name"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model="dynamicValidateForm.type1name" disabled></el-input>
					</el-form-item>
					<el-form-item
					  label="支出二级类别："
					  prop="type2name"
					  :rules="{
					    required: true,
					    message: '必填字段',
					    trigger: 'blur',
					  }"
					>
					  <el-input v-model="dynamicValidateForm.type2name" disabled></el-input>
					</el-form-item>
		      <el-form-item
		        label="支出明细："
		        prop="itemname"
		        :rules="{
		          required: true,
		          message: '必填字段',
		          trigger: 'blur',
		        }"
		      >
		        <el-input v-model="dynamicValidateForm.itemname" disabled></el-input>
		        <el-button
		          type="primary"
		          class="select-btn"
		          @click="
		            onSelectData(moneyoutitem_dialogresult, 'showgitModal')
		          "
		          >选择</el-button
		        >
		      </el-form-item>
		    </div>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button
		      type="primary"
		      @click="submitInputForm('dynamicValidateForm')"
		      :loading="!$store.state.global.isEndRequest"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>
		<!-- 是否支出 -->
		<el-dialog
			class="btn-2b5a95"
			width="50%"
			title="是否支出："
			v-dialogDrag
			:close-on-click-modal='false'
			:visible.sync="showMoneypayModal"
			v-if="showMoneypayModal"
			append-to-body
		>
			<el-form
			  :model="dynamicValidateForm"
			  ref="dynamicValidateForm"
			  label-width="150px"
			  class="demo-dynamic form-item-w-50"
			  status-icon
			  :rules="rules"
			>
				<el-form-item label="现金/银行:" prop="bankorcash">
				  <el-input
				    v-model="bankorcash[dynamicValidateForm.bankorcash]"
				    disabled
				  />
				  <el-button
				    type="primary"
				    class="select-btn"
				    @click="
				      onSelectData(account_dialogresult, 'showAccountModal', {
				        schoolorgid: dynamicValidateForm.schoolorgid,
				      })
				    "
				    >选择账户</el-button
				  >
				</el-form-item>
				<el-form-item label="现金账户/卡号:" prop="accountname">
				  <el-input v-model="dynamicValidateForm.accountname" disabled />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
			  <el-button type="primary" @click="moneypaypay('dynamicValidateForm')"
			    >提交
			  </el-button>
			</span>
		</el-dialog>
    <!-- 选择账户 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择账户："
      :visible.sync="showAccountModal"
      v-if="showAccountModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAccount">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
          >
            <el-form-item label="开户名称:" prop="accountname">
              <el-input v-model="selectSearchForm.accountname" clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
        <el-table-column
          label="余额"
          prop="curmoney"
          align="center"
          width="200px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({schoolorgid:dynamicValidateForm.schoolorgid}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择报销人 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择报销人："
			:close-on-click-modal='false'
      :visible.sync="showUserModal"
      v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="150" />
        <el-table-column
          label="真实姓名"
          prop="realname"
          align="center"
          width="150"
        />
        <el-table-column
          label="岗位"
          prop="positionname"
          align="center"
          width="150"
        />
        <el-table-column
          label="所属教学点名称"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val,Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
    <!-- 选择支出明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="68%"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择支出明细"
      :visible.sync="showMoneyOutModal"
      v-if="showMoneyOutModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyOut">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="费用类型:" prop="moneyoutitemtypeval">
              <el-input
                v-model.trim="selectSearchForm.moneyoutitemtypeval"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({index: dynamicValidateForm.index}, selectSearchForm)
              )"
                clearable
              />
            </el-form-item>
            <el-form-item label="支出费用项目:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({index: dynamicValidateForm.index}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({index: dynamicValidateForm.index}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column
          label="费用类型名称"
          prop="moneyoutitemtypeval"
          align="center"
        ></el-table-column>
        <el-table-column
          label="支出费用项目名称"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column
          label="警戒比例"
          prop="warnpercent"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({index: dynamicValidateForm.index}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
		<!-- 选择支出明细 // 修改-->
		<el-dialog
		  class="btn-2b5a95"
		  width="78%"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择支出明细"
		  :visible.sync="showgitModal"
		  v-if="showgitModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onAdd">确定</el-button>
		
		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="费用类型:" prop="moneyoutitemtypeval">
		          <el-input
		            v-model.trim="selectSearchForm.moneyoutitemtypeval"
								@keyup.enter.native="onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign({}, selectSearchForm)
		          )"
		            clearable
		          />
		        </el-form-item>
		        <el-form-item label="支出费用项目:" prop="name">
		          <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="
		          onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign({}, selectSearchForm)
		          )
		        "
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				:key="key"	
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" align="center" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column
		      label="费用类型名称"
		      prop="moneyoutitemtypeval"
		      align="center"
		    ></el-table-column>
		    <el-table-column
		      label="支出费用项目名称"
		      prop="name"
		      align="center"
		    ></el-table-column>
		    <el-table-column
		      label="警戒比例"
		      prop="warnpercent"
		      align="center"
		    ></el-table-column>
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		      (val, data) =>
		        handleSelectChange(val, Object.assign({}, selectSearchForm))
		    "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>
    <!-- 选择支出类别 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择支出类别："
      :visible.sync="showMoneyOut2Modal"
      v-if="showMoneyOut2Modal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyOut2">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="支出类型:" prop="typename">
              <el-input 
								v-model.trim="selectSearchForm.typename" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({index: dynamicValidateForm.index}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="支出一级:" prop="type1name">
              <el-input 
								v-model.trim="selectSearchForm.type1name"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({index: dynamicValidateForm.index}, selectSearchForm)
								)"
								 clearable />
            </el-form-item>
            <el-form-item label="支出二级:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({index: dynamicValidateForm.index}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({index: dynamicValidateForm.index}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				:key="key"	
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column
          label="所属支出类型名称"
          prop="typename"
          align="center"
        />
        <el-table-column
          label="所属支出一级项目名称"
          prop="type1name"
          align="center"
        />
        <el-table-column label="支出二级项目名称" prop="name" align="center" />
        <el-table-column label="关联的院校" prop="collegename" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({index: dynamicValidateForm.index}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
		
		<!-- 选择支出类别 -- 修改 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="80%"
			v-dialogDrag
		  @close="onCloseSelect"
			:close-on-click-modal='false'
		  title="选择支出类别："
		  :visible.sync="showMoneyOut3Modal"
		  v-if="showMoneyOut3Modal"
		  append-to-body
		>
		  <el-button type="primary" @click="onTitle">确定</el-button>
		
		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="支出类型:" prop="typename">
		          <el-input 
								v-model.trim="selectSearchForm.typename" 
								@keyup.enter.native="onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign({}, selectSearchForm)
		          )"
								clearable />
		        </el-form-item>
		        <el-form-item label="支出一级:" prop="type1name">
		          <el-input 
								v-model.trim="selectSearchForm.type1name"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								 clearable />
		        </el-form-item>
		        <el-form-item label="支出二级:" prop="name">
		          <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="
		          onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign({}, selectSearchForm)
		          )
		        "
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				:key="key"	
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column
		      label="所属支出类型名称"
		      prop="typename"
		      align="center"
		    />
		    <el-table-column
		      label="所属支出一级项目名称"
		      prop="type1name"
		      align="center"
		    />
		    <el-table-column label="支出二级项目名称" prop="name" align="center" />
		    <el-table-column label="关联的院校" prop="collegename" align="center" />
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		      (val, data) =>
		        handleSelectChange(val, Object.assign({}, selectSearchForm))
		    "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>

    <!-- 查看明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      title="查看明细"
			:close-on-click-modal='false'
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
    >
      <div class="Btn-w eaf4ff-btn">
        <div class="btn-l">
          <el-button type="primary" @click="onDetailAdd">新增</el-button>
          <el-button type="primary" @click="onDetailEdit">修改</el-button>
        </div>
      </div>
      <el-table
        :data="detailTableRes.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="100px"/>
        <el-table-column label="金额" prop="amount" align="center" />
        <el-table-column label="摘要" prop="zhaiyao" align="center" :show-overflow-tooltip="true" width="120px"/>
        <el-table-column label="支出类型名称" prop="typename" align="center" width="130px"/>
        <el-table-column
          label="支出一级项目名称"
          prop="type1name"
          align="center"
					width="260px"
        />
        <el-table-column
          label="支出二级项目名称"
          prop="type2name"
          align="center"
					width="260px"
        />
        <el-table-column label="支出明细名称" prop="itemname" align="center" width="120px"/>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleDetailChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="detailPageInfo.pageindex"
        :page-size="detailPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="detailTableRes.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 查看明细 - 新增，修改 -->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showDetailAdd"
      v-if="showDetailAdd"
      append-to-body
    >
      <el-form
        :model="detailForm"
        ref="detailForm"
        label-width="140px"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="支出明细名称:" prop="itemname">
          <el-input
            v-model="detailForm.itemname"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(moneyoutitem_dialogresult, 'showMoneyOutModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="支出类型名称:" prop="typename">
          <el-input
            v-model="detailForm.typename"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(moneyouttype2_dialogresult, 'showMoneyOut2Modal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="支出一级项目名称:" prop="type1name">
          <el-input v-model.trim="detailForm.type1name" disabled />
        </el-form-item>
        <el-form-item label="支出二级项目名称:" prop="type2name">
          <el-input v-model.trim="detailForm.type2name" disabled />
        </el-form-item>
        <el-form-item label="金额:" prop="amount">
          <el-input v-model.trim="detailForm.amount" clearable/>
        </el-form-item>
        <el-form-item label="摘要:" prop="zhaiyao">
          <el-input v-model.trim="detailForm.zhaiyao" clearable/>
        </el-form-item>
      
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitDetailSave('detailForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看审批 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      title="查看审批"
      :visible.sync="showApprovalModal"
      v-if="showApprovalModal"
      append-to-body
    >
      <el-table
        :data="approvalTableRes.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="员工名称" prop="username" align="center" />
        <el-table-column label="审核是否通过标志" align="center">
          <template slot-scope="scope">
            {{ passflag[scope.row.passflag] }}
          </template>
        </el-table-column>
        <el-table-column label="审批意见" prop="approvedesc" align="center" />
        <el-table-column label="审批时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.approvetime | formatDate("YYYY-MM-DD") }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="detailPageInfo.pageindex"
        :page-size="detailPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="approvalTableRes.totalcount"
      ></el-pagination>
    </el-dialog>

    <!--冲账-->
    <el-dialog
      class="btn-2b5a95"
      width="45%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showBalanceModal"
      v-if="showBalanceModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="报销人:">
          {{ dynamicValidateForm.outusername }}
        </el-form-item>
        <el-form-item label="报销时间:">
          {{ dynamicValidateForm.outtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
        <el-form-item label="现金/银行:">
          {{ bankorcashObj[dynamicValidateForm.bankorcash] }}
        </el-form-item>
        <el-form-item label="收入类型:">
          {{ chongzhanflagObj[dynamicValidateForm.notselfflag] }}
        </el-form-item>
        <el-form-item label="现金账户/银行账户:">
          {{ dynamicValidateForm.accountname }}
        </el-form-item>
        <el-form-item label="经手人:">
          {{ dynamicValidateForm.opusername }}
        </el-form-item>
        <el-form-item label="原金额:">
          {{ dynamicValidateForm.amount }}
        </el-form-item>
        <el-form-item label="冲帐金额:">
          {{ -dynamicValidateForm.amount }}
        </el-form-item>
        <el-form-item label="所属分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="所属教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="开票说明:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
        <el-form-item label="主单流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item prop="chongzhanremark" label="冲帐备注:">
          <el-input 
						v-model.trim="dynamicValidateForm.chongzhanremark" 
						type="textarea"
						:rows="5"
						/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="savebalance('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 负责人审批 -->
    <el-dialog
      class="btn-2b5a95"
      title="负责人审批："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showApproval1Modal"
      v-if="showApproval1Modal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
        :rules="rules"
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 0, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审批意见："
          prop="approvedesc"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="50%"
      :visible.sync="showDetail"
      v-if="showDetail"
      append-to-body
			title="详情"
			:close-on-click-modal='false'
    >
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
        <tr>
          <td>账户类型：{{bankorcashObj[dynamicValidateForm.bankorcash]}}</td>
          <td>金额：{{ dynamicValidateForm.amount }}</td>
          <td>报销时间：{{ dynamicValidateForm.outtime | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
        </tr>
        <tr>
          <td v-if="dynamicValidateForm.notselfflag == 0">非本部支出：否</td>
					<td v-if="dynamicValidateForm.notselfflag == 1">非本部支出：是</td>
          <td>操作时间：{{ dynamicValidateForm.optime  | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td>凭证：{{ dynamicValidateForm.pzurl }}</td>
        </tr>
        <tr>
          <td>借款事由：{{ dynamicValidateForm.reason }}</td>
          <td>预计还款日期：{{ dynamicValidateForm.moneyintime  | formatDate("YYYY-MM-DD HH:mm:ss")  }}</td>
          <td v-if="dynamicValidateForm.approveflag == -2">是否已审核标识：未提请审批 </td>
					<td v-if="dynamicValidateForm.approveflag == -1">是否已审核标识：审核中 </td>
					<td v-if="dynamicValidateForm.approveflag == 0">是否已审核标识：未通过 </td>
					<td v-if="dynamicValidateForm.approveflag == 1">是否已审核标识：通过 </td>
        </tr>
        <tr>
          <td v-if="dynamicValidateForm.moneyisoutflag == 0 ">是否已支出：否</td>
					<td v-if="dynamicValidateForm.moneyisoutflag == 1">是否已支出：是</td>
          <td>审核时间：{{ dynamicValidateForm.approvetime | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td>备注：{{ dynamicValidateForm.remark }}</td>
        </tr>
        <tr>
          <td>冲账时间：{{ dynamicValidateForm.chongzhangtime   | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td>冲账金额：{{ dynamicValidateForm.chongzhanamount }}</td>
          <td>冲账备注：{{ dynamicValidateForm.chongzhanremark }}</td>
        </tr>
        <tr>
          <td v-if="dynamicValidateForm.chongzhanflag == 0">冲账标志：未冲账</td>
					<td v-if="dynamicValidateForm.chongzhanflag == 1">冲账标志：已冲账</td>
          <td>支出编号：{{ dynamicValidateForm.moneypaycode }}</td>
          <td>银行账户名称：{{ dynamicValidateForm.accountname }}</td>
        </tr>
        <tr>
          <td>报销人名称：{{ dynamicValidateForm.outusername }}</td>
          <td>报销人编号：{{ dynamicValidateForm.outusercode }}</td>
          <td>
            报销人所属部门名称：{{ dynamicValidateForm.outuserdepartname }}
          </td>
        </tr>
        <tr>
          <td>
            报销人所属部门编号：{{ dynamicValidateForm.outuserdepartcode }}
          </td>
          <td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
          <td>所属教学点编号：{{ dynamicValidateForm.schoolorgcode }}</td>
        </tr>
        <tr>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
          <td>所属分公司编号：{{ dynamicValidateForm.companyorgcode }}</td>
          <td>操作人名称：{{ dynamicValidateForm.opusername }}</td>
        </tr>
        <tr>
          <td>操作人编号：{{ dynamicValidateForm.opusercode }}</td>
          <td>审核人名称：{{ dynamicValidateForm.approveusername }}</td>
          <td>冲账人名称：{{ dynamicValidateForm.chongzhanusername }}</td>
        </tr>
				<tr>
					<td>流水号： {{dynamicValidateForm.id}}</td>
					<td></td>
					<td></td>
				</tr>
      </table>
    </el-dialog>
		<el-dialog
		  class="btn-2b5a95"
		  width="1000px"
			v-dialogDrag
			:close-on-click-modal='false'
		  title="打印报销单："
		  :visible.sync="showPrint"
		  append-to-body
		  v-if="showPrint"
		>
		  <object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer" width="980px" height="540px" style="margin: auto;">
		      <param name="ReportURL" :value="this.$url.upload + '/report/bxd.grf'">
		      <param name="DataURL" value="" >
		      <param name="AutoRun" value=true >
		      <param name="SerialNo" value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
		      <param name="UserName" value="锐浪报表插件本机开发测试注册" >
		  </object>
		</el-dialog>
  </div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";

import {
  moneypay_anyList,
  moneypay_input,
	moneypay_save,
  moneypay_addall,
  moneypay_delete,
  moneypaydetail_save,
  moneypaydetail_input,
  moneypaydetail_list,
  moneypay_requestapprove,
  moneypay_approvecheck,
	moneypay_checkpay,
  moneypay_approvesave,
  moneypay_print,
  moneypay_pay,
  moneypay_checkbalance,
  moneypay_inputbalance,
  moneypay_savebalance,
  moneypay_checkcopy,
  moneypay_copymoneypay,
  moneypay_export,
  moneypay_exportexcel,
  moneypay_exportsave,
  approve_list,
} from "@/request/api/allChildrenProject";
import {
  _moneyoutitem_dialogresult,
  _account_dialogresult,
  _user_dialogresult,
  _moneyouttype2_dialogresult,
	 _fileupload_save 
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _approveflag, _bankorcash} from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "moneypay",
  components: { Export },
  props: {
    exportUrl: {
      default: () => moneypay_export,
    },
    exportexcelUrl: {
      default: () => moneypay_exportexcel,
    },
    exportsaveUrl: {
      default: () => moneypay_exportsave,
    },

    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    user_dialogresult: {
      default: () => _user_dialogresult,
    },
    moneyoutitem_dialogresult: {
      default: () => _moneyoutitem_dialogresult,
    },
    moneyouttype2_dialogresult: {
      default: () => _moneyouttype2_dialogresult,
    },
		bankorcash: {
			default: () => _bankorcash,
		},
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
			fds: new FormData(),
			fileList: [], // 存储图像的数组
			//type:"支出登记",
      passflag: {
        0: "不通过",
        1: "通过",
      },
      itemId: null, //首表格项id
      searchForm: { },
			multipleSelection: [],
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
			showMoneypayModal: false,//支出
      dynamicValidateForm: {
        moreItems: [
          {
            itemid: "",
            amount: "",
            zhaiyao: "",
            type2id: "",
            type1id: "",
            typeid: "",
          },
        ],
      }, //表单
      rules: {
        chongzhanremark: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        unitname: [{ trigger: "blur", message: "必填字段", required: true }],
        outusername: [{ trigger: "blur", message: "必填字段", required: true }],
        outtime: [{ trigger: "blur", message: "必填字段", required: true }],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        intime: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        type2id: [{ trigger: "blur", message: "必填字段", required: true }],
        itemid: [{ trigger: "blur", message: "必填字段", required: true }],
        amount: [{ trigger: "blur", message: "必填字段", required: true }],
        zhaiyao: [{ trigger: "blur", message: "必填字段", required: true }],
        typemoneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        itemmoneyinitemname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        notselfflag: [{ trigger: "blur", message: "必填字段", required: true }],
        inusername: [{ trigger: "blur", message: "必填字段", required: true }],
        shoujutype: [{ trigger: "blur", message: "必填字段", required: true }],
        itemname: [{ trigger: "blur", message: "必填字段", required: true }],
        typename: [{ trigger: "blur", message: "必填字段", required: true }],
        type1name: [{ trigger: "blur", message: "必填字段", required: true }],
        type2name: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      bankorcashObj: {
        1: "现金",
        0: "银行",
      },
      chongzhanflagObj: {
        0: "未冲账",
        1: "已冲账",
        2: "被冲账",
      },
      approveflag: _approveflag,
			typeflag: {
				0: '支出登记',
				1: '费用报销',
			},
      selectTable: {},
      selectTableUrl: null,
      selectIndex: 0,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectSearchForm: {},
			showinputModal: false,//快速新增修改
      selectSchoolorgid: null,
      showAccountModal: false, //账户
      showUserModal: false, //报销人
      showMoneyOutModal: false, // 支出明细
      showMoneyOut2Modal: false, //支出类别
      showDetailModal: false, //查看明细
      showDetailAdd:false,//
      showApprovalModal: false, //查看审批
      showApproval1Modal: false, //负责人审批
      showBalanceModal: false, //冲账
      showDetail: false,
			showgitModal: false,//查看
      detailTableRes:{},//查看明细数据
      detailForm:{},
			showBankorcashModal: false, // 选择账户
      detailPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
			key: '',
      //查看审批
      approvalTableRes:{},
			showMoneyOut3Modal: false,//支出类别-- 快速新增修改
      showPrint: false, //打印报销单
      voucherData: {}, //报销单数据
			list:[],//列表打印收据
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup",this.enterSearch);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
		enterSearch(e){
		   if(e.keyCode === 13) {
		     this.getDataList();
		   }
		 },
		 // 是否显示下拉框
		 visibleType(e){
		   if(!e) {
		     this.$refs.selectref.blur();
		   }
		 },
		 visibleApproveflag(e) {
			 if(!e) {
				 this.$refs.approveflagref.blur();
			 }
		 },
		 visiblechongzhanflag(e) {
			 if(!e) {
				 this.$refs.chongzhanflag.blur();
			 }
		 },
		selectQuery() {
			this.selectSearchForm = {
				realname: null,
				typename: null,
				type1name: null,
				name: null,
				moneyoutitemtypeval: null,
				
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
		reset() {
			this.searchForm = {
				zhaiyao: null,
				amount: null,
				approveflag: null,
				moneyisoutflag: null,
				chongzhanflag: null,
				opusername: null,
				schoolorgname: null,
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //导出 click api
		btnExport() {
		  confirmCallBack({
		    title: "提示",
		    content: "根据搜索条件进行导出。",
		    success: () => {
		      window.location.href =
		        this.$url.Freemarker +
		        moneypay_export +
		        `?${createObjParams(this.searchForm)}`;
		    },
		    fail: () => {
		      messageTip({
		        message: "取消操作",
		        type: "info",
		      });
		    },
		  });
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		//下载文件
		downloadFile(index, row) {
			if (row.url != undefined) {
			  location.href =
			    this.$url.Freemarker +
			    "/filedownload/save.do?fileurl=" +
			    row.url;
			}
		},
		//上传文件前 before
		beforeUpload(file) {
			this.fds = new FormData();
			this.fds.append("upfile", file); // 传文件
		},
		// 上传文件 upload
		uploadFile(name) {
		  myRequest({
		    method: "post",
		    url: _fileupload_save,
		    data: this.fds,
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.dynamicValidateForm[name] = res.data.url;
		    }
		  });
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url:  moneypay_anyList,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //打印报销单 click
    btnPrint() {
			if(this.multipleSelection.length >= 6) {
				this.$message('最多只能选五条,请重新选择')
				return 
			}
			selectCheck(this.multipleSelection, "操作", true, () => {
			  this.print();
				this.showPrint = true;
			});
      
    },
    //打印报销单 api
		print(idArr) {
			myRequest({
				method: "post",
			    url: moneypay_print,
			    data: this.$qs.stringify(
					{
						ids:  this.multipleSelection.map((i) => i.id),
					},
					{
						arrayFormat: "repeat",
					}
				),
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.list	= res.data.data.list;
								this.list[0].outtime = formatDare(
								  this.list[0].outtime,
								  "YYYY-MM-DD HH:mm:ss"
								);
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
		
			          //获得参数
								var pname	= ReportViewer.Report.ParameterByName("name");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								
								//填充参数
								pname.AsString = this.list[0].outusername;	
								pinserttime.AsString	=	this.list[0].outtime;
								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fprice = ReportViewer.Report.FieldByName("price");
								//填充字段
								
								Report.PrepareRecordset(); 
									for (var i = 0; i < this.list.length; i++){
									Recordset.Append();
									fcollege.AsString =	this.list[i].zhaiyao;
									fprice.AsFloat =	this.list[i].amount;
									Recordset.Post(); 
								}
								
			          ReportViewer.Start();
			       }
			  }); 
		},

    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetail = true;
    },
    //快速登记 click
    btnAddall() {
      this.dynamicValidateForm = {
				type:"费用报销",
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        moreItems: [
          {
            itemid: "",
            amount: "",
            zhaiyao: "",
            type2id: "",
            type1id: "",
            typeid: "",
          },
        ],
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //查看明细 click
    btnFindpaydetail() {
      selectCheck(this.multipleSelection, "查看明细", false, () => {
        this.itemId = this.multipleSelection[0].id
        this.onDetailData();
      });
    },
    //查看明细 - 列表 api
    onDetailData() {
      myRequest({
        url: moneypaydetail_list,
        data: Object.assign( {moneypayid:this.itemId}, this.detailPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.detailTableRes = res.data.data;
          this.showDetailModal= true;
        }
      });
    },
    //查看明细 - 新增 click
    onDetailAdd() {
      this.dialogTitle = '新增：'
      this.detailForm = {
        moneypayid:this.itemId
      }
      this.showDetailAdd = true
    },
    //查看明细 - 修改 click
    onDetailEdit() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.dialogTitle = '修改：'
        this.detail_input(this.multipleSelection[0].id);
      });
    },
    //查看明细 - 修改路由 api
    detail_input(id){
      myRequest({
        url:moneypaydetail_input,
        data:{
          id:id
        }
      }).then(res=>{
        if(res.data.code==='200'){
          this.detailForm = res.data.data.vmoneypaydetail
          this.showDetailAdd = true
        }
      })
    },
    //查看明细 - 新增，修改 - 提交 click
    onSubmitDetailSave(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitDetailSave();
        }
      });
    },
    //查看明细 - 新增，修改 - 提交 api
    submitDetailSave(){
      myRequest({
        url:moneypaydetail_save,
        data:this.detailForm
      }).then(res=>{
        if(res.data.code==='200'){
          this.showDetailAdd = false
          this.onDetailData()
        }
      })
    },

    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "提请审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "是否提请审批？",
          success: () => {
            this.requestapprove(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //查看审批 click
    btnViewApprove() {
      selectCheck(this.multipleSelection, "查看审批", false, () => {
        this.itemId = this.multipleSelection[0].id
        this.viewApprove();
      });
    },
    //查看审批 api
    viewApprove(){
      myRequest({
        url:approve_list,
        data:{
          objectname: "T_MONEYPAY",
          objectid: this.itemId,
        }
      }).then(res=>{
        if(res.data.code==='200'){
          this.approvalTableRes = res.data.data
          this.showApprovalModal = true
        }
      })
    },

    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },
    //负责人审批 click
    btnVeryfy1() {
      selectCheck(this.multipleSelection, "负责人审批", true, () => {
        this.approvecheck();
      });
    },
		//确认银行账户 click
		onConfirmBankorcash() {
		  selectCheck(this.multipleSelection, "选择账户", false, () => {
		    this.$set(
		      this.dynamicValidateForm,
		      "accountname",
		      this.multipleSelection[0].accountname
		    );
		    this.$set(
		      this.dynamicValidateForm,
		      "bankorcash",
		      this.multipleSelection[0].bankorcash
		    );
		    this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
		    this.showBankorcashModal = false;
		  });
		},
    //支出 click
    btnMoneyout() {
      selectCheck(this.multipleSelection, "支出", true, () => {
				this.moneypayCheckpay();
				this.ids = this.multipleSelection.map((i) => i.id);
      });
    },
    //冲账 click
    btnBalance() {
      selectCheck(this.multipleSelection, "冲账", false, () => {
        this.dialogTitle = "冲账";
        this.checkbalance();
      });
    },
    //复制 click
    btnCopy() {
      selectCheck(this.multipleSelection, "复制", false, () => {
        this.checkcopy();
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //确认账户 click
    onConfirmAccount() {
      selectCheck(this.multipleSelection, "确认账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showAccountModal = false;
      });
    },
    //确认报销人 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认报销人", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "outusername",
          this.multipleSelection[0].realname
        );
        this.dynamicValidateForm.outuserid = this.multipleSelection[0].id;
        this.showUserModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认支出明细 click
    onConfirmMoneyOut() {
      selectCheck(this.multipleSelection, "确认支出明细", false, () => {
        this.$set(
          this.detailForm,
          "itemname",
          this.multipleSelection[0].name
        );
        this.detailForm.itemid = this.multipleSelection[0].id

        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "name",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.moreItems[this.selectIndex].itemid = this.multipleSelection[0].id
        this.showMoneyOutModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
		//确认支出明细--修改快速新增 click
		onAdd() {
		  selectCheck(this.multipleSelection, "确认支出明细", false, () => {
				this.$set(
				  this.dynamicValidateForm,
				  "itemname",
				  this.multipleSelection[0].name
				);
				this.dynamicValidateForm.itemid = this.multipleSelection[0].id
		    this.showgitModal = false;
				this.selectQuery();
				this.multipleSelection = [];
		  });
		},
    //确认支出类别 click
    onConfirmMoneyOut2() {
      selectCheck(this.multipleSelection, "确认支出类别", false, () => {
        //以下支出快速登记
        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "type2name",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "type1name",
          this.multipleSelection[0].type1name
        );
        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "typename",
          this.multipleSelection[0].typename
        );
        this.dynamicValidateForm.moreItems[this.selectIndex].type2id = this.multipleSelection[0].id
        this.dynamicValidateForm.moreItems[this.selectIndex].type1id = this.multipleSelection[0].type1id
        this.dynamicValidateForm.moreItems[this.selectIndex].typeid = this.multipleSelection[0].typeid
        this.showMoneyOut2Modal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
		//确认支出类别 click
		onTitle() {
		  selectCheck(this.multipleSelection, "确认支出类别", false, () => {
		    //以下查看明细 - 修改
				this.$set(
				  this.dynamicValidateForm,
				  "type2name",
				  this.multipleSelection[0].name
				);
				this.$set(
				  this.dynamicValidateForm,
				  "type1name",
				  this.multipleSelection[0].type1name
				);
				this.$set(
				  this.dynamicValidateForm,
				  "typename",
				  this.multipleSelection[0].typename
				);
		    this.dynamicValidateForm.type2id = this.multipleSelection[0].id
		    this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id
		    this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid
		    this.showMoneyOut3Modal = false;
				this.selectQuery();
				this.multipleSelection = [];
		  });
		},
    //删除快速新增项 click
    onRemoveMoreForm(index) {
      this.dynamicValidateForm.moreItems.splice(index, 1);
    },
    //添加一行 click
    addFormItem() {
      this.dynamicValidateForm.moreItems.push({
        typeid: "",
        type1id: "",
        type2id: "",
        itemid: "",
        amount: "",
        zhaiyao: "",
      });
    },

    //提交-快速登记 api
    submitAddOrEditForm(formName) {
      const initMorefield = [
        "typeid",
        "typename",

        "type1id",
        "type1name",

        "type2id",
        "type2name",

        "itemid",
        "name",

        "amount",
        "zhaiyao",
      ];
      initMorefield.map((i) => (this.dynamicValidateForm[i] = []));
      for (let i in this.dynamicValidateForm.moreItems) {
        for (let key in this.dynamicValidateForm.moreItems[i]) {
          if (this.dynamicValidateForm.moreItems[i][key]) {
            //有值才push，否则是空字符串
            this.dynamicValidateForm[key].push(
              this.dynamicValidateForm.moreItems[i][key]
            );
          }
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveall();
        }
      });
    },
    //快速新增 api
    saveall() {
      myRequest(
        {
          method: "post",
          url: moneypay_addall,
          data: this.$qs.stringify(this.dynamicValidateForm,{arrayFormat:'repeat'})  ,
        },
        {
          that: this,
          modal: "showAddModal",
          isRefTableList: true,
        }
				)
    },
		//修改快速新增 click
		btnEdit() {
			selectCheck(this.multipleSelection, "操作", false, () => {
				this.input_Edit(this.multipleSelection[0].id);
			});
		},
		//修改 api
		input_Edit(id) {
			myRequest({
				url: moneypay_input,
				data: {
					id: id
				}
			}).then(res => {
				if(res.data.code === '200') {
					this.showinputModal = true;
					this.dynamicValidateForm = res.data.data.vmoneypay;
					this.dynamicValidateForm.outtime = formatDare(
					  this.dynamicValidateForm.outtime,
					  "YYYY-MM-DD HH:mm:ss"
					);
					delete this.dynamicValidateForm.optime; //optime 该字段不用传
					delete this.dynamicValidateForm.approvetime; //approvetime 该字段不用传
				}
			})
		},
		//提交--修改快速新增 api
		submitInputForm(formName) {
			this.$refs[formName].validate((valid) => {
			  if (valid) {
			   myRequest(
			     {
			      method: "post",
			      url: moneypay_save,
			      data: this.$qs.stringify(this.dynamicValidateForm,{arrayFormat:'repeat'}),
			     },
			     {
			       that: this,
			       modal: "showinputModal",
			       isRefTableList: true,
			     }
			   	)
			  }
			});
		},
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: moneypay_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //提请审批 api
    requestapprove(ids) {
      myRequest(
        {
          method: "post",
          url: moneypay_requestapprove,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            { arrayFormat: "repeat" }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //检查是否可以审批 api
    approvecheck() {
      myRequest({
        method: "post",
        url: moneypay_approvecheck,
        data: this.$qs.stringify(
          {
            ids: this.multipleSelection.map((i) => i.id),
            leadertype: "03",
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = {};
          this.showApproval1Modal = true;
        }
      });
    },
		//检查是否可以支出 api
		moneypayCheckpay() {
			myRequest({
			  method: "post",
			  url: moneypay_checkpay,
			  data: this.$qs.stringify(
			    {
			      ids: this.multipleSelection.map((i) => i.id),
			    },
			    {
			      arrayFormat: "repeat",
			    }
			  ),
			}).then((res) => {
			  if (res.data.code === "200") {
			    this.dynamicValidateForm = {};
			    this.showMoneypayModal = true;
					this.ids = this.multipleSelection.map((i) => i.id);
			  }
			});
		},
		//支出 api
		moneypaypay(ids) {
		  myRequest(
		    {
					method: "post",
		      url: moneypay_pay,
		      data: this.$qs.stringify(
						{
						  ids: this.ids,
							accountid: this.dynamicValidateForm.accountid
						},
						{
							arrayFormat: "repeat",
						}
			  ),
			
		    },
		    {
		      isRefTableList: true,
		      that: this,
					
					modal: "showMoneypayModal",
		    }
		  );
		},
    //负责人审批 api 
    headApproval(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: moneypay_approvesave,
              data: this.$qs.stringify(
                {
                  ids: this.multipleSelection.map((i) => i.id),
                  passflag: this.dynamicValidateForm.passflag,
                  approvedesc: this.dynamicValidateForm.approvedesc,
                  leadertype: "03",
                },
                {
                  arrayFormat: "repeat",
                }
              ),
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showApproval1Modal",
            }
          );
        }
      });
    },
    //冲账校验 api
    checkbalance() {
      myRequest(
        {
          url: moneypay_checkbalance,
          data: {
            id: this.multipleSelection[0].id,
          },
        },
        {
          that: this,
          methodName: "inputbalance",
        }
      );
    },
    //冲账路由 api
    inputbalance() {
      myRequest({
        url: moneypay_inputbalance,
        data: {
          id: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.tmoneypay;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.optime; //optime 该字段不用传
          delete this.dynamicValidateForm.approvetime; //approvetime 该字段不用传
          this.dynamicValidateForm.outtime = formatDare(
            this.dynamicValidateForm.outtime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.showBalanceModal = true;
        }
      });
    },
    //保存冲账 api
    savebalance(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: moneypay_savebalance,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showBalanceModal",
            }
          );
        }
      });
    },
    //复制校验 api
    checkcopy() {
      myRequest({
        url: moneypay_checkcopy,
        data: {
          id: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          confirmCallBack({
            title: "提示",
            content: "确定要复制吗？",
            success: () => {
              this.copymoneypay();
            },
            fail: () => {
              messageTip({
                message: "取消操作",
                type: "info",
              });
            },
          });
        }
      });
    },
    //复制 api
    copymoneypay() {
      myRequest(
        {
          url: moneypay_copymoneypay,
          data: {
            id: this.multipleSelection[0].id,
          },
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换查看审批表格页
    handleAppvalChange(val, data) {
      this.detailPageInfo.pageindex = val;
      this.viewApprove();
    },
    //切换明细表格页
    handleDetailChange(val, data) {
      this.detailPageInfo.pageindex = val;
      this.onDetailData();
    },
    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },

    //选项表格 api()
    onSelectData(url, modal, data) {
      this.selectIndex = data?.index || 0;
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
					this.dynamicValidateForm.index = res.data.data.index;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
// 打印报销单
.voucher-w {
  .header-w {
    margin: 0 0 5px 0;
  }
  .top {
    display: flex;
    justify-content: center;
    font-size: 28px;
    letter-spacing: 30px;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  table {
    width: 100%;
    tr {
      height: 40px;
    }
  }

  .table-c-w {
    td {
      width: 33.33%;
    }
  }
  .table-detail-w {
    border-top: none;
    .key_field {
      width: 110px;
    }
  }

  .footer-w {
    display: flex;
    padding: 10px 0 0 0;
    & > div {
      width: 20%;
    }
  }

  .normal-size {
    font-size: 16px;
  }
}
// 添加一行
.more-form-item-w {
  flex-wrap: wrap;
  & > div {
    //width: 25% !important;
    & > label {
      //width: 150px !important;
    }
  }
}
</style>