<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="学生姓名:">
          <el-input
						v-model.trim="searchForm.studentname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="年级:">
          <el-input
						v-model.trim="searchForm.grade"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="项目类型:">
          <el-select
						v-model="searchForm.projecttype"
						@keyup.enter.native="seach"
						@visible-change="levelvalvisible"
						ref="selectref"
						clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试类型:">
          <el-select
						v-model="searchForm.examtype"
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable >
            <el-option
							v-for="item in examtypeL"
							:key="item.key"
							:label="item.val"
							:value="item.val">
						</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属教学点:">
          <el-input
						v-model.trim="searchForm.schoolorgname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >

    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生姓名" align="center" width="120px">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row)">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="身份证号"
        prop="idcard"
        align="center"
        width="180"
      />
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column label="院校" prop="collegename" align="center" width="160px"/>
      <el-table-column label="专业" prop="majorname" align="center" />
      <el-table-column label="层次" prop="majorlevelval" align="center" />
      <el-table-column label="考试类型" prop="examtype" align="center" />
      <el-table-column label="考试科目" prop="coursename" align="center" />
      <el-table-column label="考试成绩" prop="score1" align="center" />
      <el-table-column label="平时成绩" prop="score2" align="center" />
      <el-table-column label="总评成绩" prop="score3" align="center" />
        <!--
      <el-table-column label="是否补考" align="center">
        <template slot-scope="scope">
          {{ available[scope.row.status] }}
        </template>
      </el-table-column>
      -->
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
        width="150"
      />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
			v-dialogDrag
      width="60%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学生:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="身份证号:">
          {{ dynamicValidateForm.idcard }}
        </el-form-item>
        <el-form-item label="学号:">
          {{ dynamicValidateForm.code }}
        </el-form-item>
        <el-form-item label="考试类型:">
          {{ dynamicValidateForm.examtype }}
        </el-form-item>
        <el-form-item label="考试科目:">
          {{ dynamicValidateForm.coursename }}
        </el-form-item>
        <el-form-item label="考试成绩:">
          {{ dynamicValidateForm.score1 }}
        </el-form-item>
        <el-form-item label="平时成绩:">
          {{ dynamicValidateForm.score2 }}
        </el-form-item>
        <el-form-item label="总评成绩:">
          {{ dynamicValidateForm.score3 }}
        </el-form-item>
          <!--
        <el-form-item label="是否补考:">
					<template v-if="dynamicValidateForm.status == 0">
						否
					</template>
					<template v-if="dynamicValidateForm.status == 1">
						是
					</template>
        </el-form-item>
        -->
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.majorlevelval }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";

import {
  educationexamdetail_list2,
  educationexamdetail_exportscore2,
  educationexamdetail_input,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _projectTypes, _available } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "educationexamdetail",
  components: {},
  props: {},
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        attendanceurl: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
      }, //表单规则
      dialogTitle: "", //对话框标题

      projectTypes: _projectTypes,
      grade: [],
      examtypeL:[],//考试类型
      available: _available,
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      list: "grade",
      typecode: "grade",
      that: this,
    });
    this.dic_combox({
      list: "examtypeL",
      typecode: "examtype",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		levelvalvisible(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				grade: null,
				projecttype: null,
				examtype: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: educationexamdetail_list2,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换德立学生表格页
    handleDeliChange(val) {
      this.pageinfo.pageindex = val;
      this.onSelectDeli();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: educationexamdetail_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.veducationexamdetail;
        }
      });
    },

    //导出 click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            educationexamdetail_exportscore2 +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>
