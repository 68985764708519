<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="会议主题:">
            <el-input 
							v-model.trim="searchForm.title" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="会议简介:">
            <el-input 
							v-model.trim="searchForm.introduction" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="getDataList"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >展开</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :data="tableRes.list"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      stripe
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="会议主题" prop="title" align="center"/>
      <el-table-column label="会议时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.meetingtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        label="会议简介"
        prop="introduction"
        align="center"
      />
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
      <el-table-column label="操作" align="center" class-name="btn-2b5a95" fixed="right" width="300">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="downloadFile(0, scope.row)"
            >下载会议纪要</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="downloadFile(1, scope.row)"
            >下载签到列表</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 添加, 修改 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="60%"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        label-width="120px"
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        :rules="rules"
        class="form-item-w-100"
      >
        <el-form-item label="标题:" prop="title">
          <el-input v-model.trim="dynamicValidateForm.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="会议时间" prop="meetingtime">
          <el-date-picker
            v-model="dynamicValidateForm.meetingtime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="会议简介:" prop="introduction">
          <el-input v-model.trim="dynamicValidateForm.introduction" type="textarea" clearable></el-input>
        </el-form-item>

        <el-form-item label="会议纪要:" prop="summary">
          <el-input v-model="dynamicValidateForm.summary" disabled></el-input>
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :file-list="fileList"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="
              (summary) => {
                uploadFile('summary');
              }
            "
          >
            <el-button size="small" type="primary" class="select-btn"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>

        <el-form-item label="签到表:" prop="signin">
          <el-input v-model="dynamicValidateForm.signin" disabled></el-input>
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :file-list="fileList"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :http-request="
              (signin) => {
                uploadFile('signin');
              }
            "
          >
            <el-button size="small" type="primary" class="select-btn"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";

import {
  meeting_list,
  meeting_delete,
  meeting_input,
  meeting_save,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {} from "@/assets/js/initTableData";
import { _fileupload_save } from "@/request/api/public";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {} from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "purchase",
  components: {},
  props: {},
  data() {
    return {
      searchForm: {}, //搜索表单
      tableRes: {}, //列表接口
			dialogTitle: "", //对话框标题
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      fds: new FormData(),
      fileList: [], // 存储图像的数组
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        meetingtime: [{ trigger: "blur", message: "必填字段", required: true }],
        introduction: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        summary: [{ trigger: "blur", message: "必填字段", required: true }],
        signin: [{ trigger: "blur", message: "必填字段", required: true }],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
		reset() {
			this.searchForm = {
				title: null,
				introduction: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: meeting_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 添加
    btnAdd() {
      this.showAddModal = true;
			this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        title: null,
        meetingtime: null,
        introduction: null,
        summary: null,
        signin: null,
      };
    },

    //上传文件前 before
    beforeUpload(file) {
      this.fds = new FormData();
      this.fds.append("upfile", file); // 传文件
    },
    // 上传文件 upload
    uploadFile(name) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm[name] = res.data.url;
        }
      });
    },

    // 修改 click
    btnEdit() {
			this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoute(this.multipleSelection[0].id);
      });
    },

    // 修改 api
    editRoute(id) {
      myRequest({
        url: meeting_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vmeeting;
          this.dynamicValidateForm.meetingtime = formatDare(
            res.data.data.vmeeting.meetingtime,
            "YYYY-MM-DD HH:mm:ss"
          );
          delete this.dynamicValidateForm.inserttime; // inserttime不用传
          this.showAddModal = true;
        }
      });
    },

    // 添加确定 click api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: meeting_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },

    //删除表格项 click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //删除表格项 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: meeting_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    // 下载文件
    downloadFile(index, row) {
      if (index === 0) {
        if (row.summary != undefined) {
          location.href =
            this.$url.Freemarker +
            "/filedownload/save.do?fileurl=" +
            row.summary;
        }
      } else {
        if (row.signin != undefined) {
          location.href =
            this.$url.Freemarker +
            "/filedownload/save.do?fileurl=" +
            row.signin;
        }
      }
    },
    //刷新 click
    btnRefresh() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>