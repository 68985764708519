<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          ref="searchForm"
          :rules="searchRules"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
          :model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="所属分公司" prop="companyorgname">
            <el-select
              v-model="searchForm.companyorgname"
              placeholder="请选择"
              :disabled="!$store.state.login.isMaxAuth"
							@keyup.enter.native="seach"
							@visible-change="compileVisible"
							ref="selectref"
              clearable
              @change="onChangeCompany($event, 'searchForm')"
            >
              <el-option
                v-for="item in $store.state.selectData.companyList"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属教学点" prop="schoolorgname">
            <el-select
              v-model="searchForm.schoolorgname"
              placeholder="请选择"
              clearable
              @change="onChangeSchool($event, 'searchForm')"
							@keyup.enter.native="seach"
							@visible-change="nameVisible"
							ref="nameref"
            >
              <el-option
                v-for="item in $store.state.selectData.schoolList"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="招生年份:" prop="year">
            <el-date-picker
              v-model="searchForm.year"
              type="year"
              placeholder="选择年份"
              value-format="yyyy"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="招生员:">
            <el-input 
							v-model.trim="searchForm.followusername" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach('searchForm')"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
      v-show="tableRes.list && tableRes.list.length"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 成考表格 -->
    <h4>成考表格：</h4>
    <el-table 
			:data="tableRes.listc" 
			style="width: 100%" 
			border
			stripe
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
			>
      <el-table-column
        label="招生员"
        prop="followusername"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生年份"
        prop="year"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="年级"
        prop="grade"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="项目类型"
        prop="projecttype"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="优惠"
        prop="preferential"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生人数"
        prop="num"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="第一学年奖励"
        prop="reward1"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="第二学年奖励"
        prop="reward2"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="第三学年奖励"
        prop="reward3"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="生源来源类别"
        prop="studentfromtypeval"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      ></el-table-column>
    </el-table>
    <!-- 网考表格 -->
    <h4>网考表格：</h4>
    <el-table 
			:data="tableRes.listw" 
			style="width: 100%"
			border
			stripe
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
			>
      <el-table-column
        label="招生员"
        prop="followusername"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生年份"
        prop="year"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="年级"
        prop="grade"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="项目类型"
        prop="projecttype"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="优惠"
        prop="preferential"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生人数"
        prop="num"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="第一学年奖励"
        prop="reward1"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="第二学年奖励"
        prop="reward2"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="第三学年奖励"
        prop="reward3"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="生源来源类别"
        prop="studentfromtypeval"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      ></el-table-column>
    </el-table>
    <!-- 自考表格 -->
    <h4>自考表格：</h4>
    <el-table 
			:data="tableRes.listz" 
			style="width: 100%"
			border
			stripe
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
			>
      <el-table-column
        label="招生员"
        prop="followusername"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生年份"
        prop="year"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="年级"
        prop="grade"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="项目类型"
        prop="projecttype"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="优惠"
        prop="preferential"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生人数"
        prop="num"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="第一学年奖励"
        prop="reward1"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="第二学年奖励"
        prop="reward2"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="第三学年奖励"
        prop="reward3"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="生源来源类别"
        prop="studentfromtypeval"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import { educationreward_list } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _org_combox, _allSchool } from "@/request/api/public";

export default {
  mixins: [part],
  name: "testtarget",
  components: {},
  props: {},
  data() {
    return {
      itemId: null, //首表格项id
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      searchForm: {},
      searchRules: {
        year: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
      },
    };
  },
  created() {
    this.org_combox();
    this.org_comboxchild(this.$store.state.login.userinfo.companyorgid);
    this.initSearchForm();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),

    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		compileVisible(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		nameVisible(e) {
			if(!e) {
				this.$refs.nameref.blur();
			}
		},
		reset() {
			this.searchForm = {
				companyorgname: null,
				schoolorgname: null,
				year: null,
				followusername: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //初始化搜索数据
    initSearchForm() {
      this.searchForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
      };

      // console.log(this.searchForm)
    },

    //搜索 click
    seach(searchForm) {
      // console.log(
      //   this.searchForm,
      //   Object.assign(this.searchForm, this.pageinfo)
      // );
      this.$refs[searchForm].validate((valid) => {
        if (valid) {
          this.getDataList();
        }
      });
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: educationreward_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
  },
};
</script>

<style  lang="scss">
</style>