<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
				<el-form-item label="流水号:">
					<el-input 
						v-model.trim="searchForm.id" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
        <el-form-item label="收据年份:">
          <el-input 
						v-model.trim="searchForm.iyear" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="收据编号:">
          <el-input 
						v-model.trim="searchForm.code"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="是否使用:">
          <el-select 
						v-model="searchForm.usedflag" 
						@keyup.enter.native="seach"
						@visible-change="visibleusedflag"
						ref="selectref"
						clearable>
            <el-option value="0" label="未使用" />
            <el-option value="1" label="已使用" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
				<el-form-item label="打印次数:">
				  <el-input 
						v-model.trim="searchForm.printcount" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button
				type="primary"
				@click="resetQuery"
			>
				重置
			</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      border
			stripe
			:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="收据编号" prop="code" align="center" />
			<el-table-column label="打印次数" prop="printcount" align="center" />
      <el-table-column label="收据产生时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="收据年份" prop="iyear" align="center" />
      <el-table-column label="基表流水号" prop="obid" align="center"/>
      <el-table-column label="基表名" prop="obname" align="center" width="200px"/>
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
      />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--发放凭证-->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			:close-on-click-modal='false'
      :title="dialogTitle"
			v-dialogDrag
      :visible.sync="showAddModal"
      v-if="showAddModal"
			v-dialogDrag
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="iyear" label="年份">
          <el-date-picker
            v-model="dynamicValidateForm.iyear"
            type="year"
            format="yyyy"
            value-format="yyyy"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item prop="quality" label="发放数量">
          <el-input v-model.trim="dynamicValidateForm.quality" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <Export
      :exportTitle="'收据管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>



<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import { _available } from "@/assets/js/filedValueFlag";

import {
  shouju_list,
  shouju_input,
  shouju_save,
  shouju_delete,
  shouju_export,
  shouju_exportexcel,
  shouju_exportsave,
} from "@/request/api/allChildrenProject";
import { _moneyintype_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "referral",
  components: { Export },
  props: {
    exportUrl: {
      default: () => shouju_export,
    },
    exportexcelUrl: {
      default: () => shouju_exportexcel,
    },
    exportsaveUrl: {
      default: () => shouju_exportsave,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        iyear: [{ trigger: "blur", message: "必填字段", required: true }],
        quality: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      available: _available,
      showDetailModal: false,

    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup",this.enterSearch)
  },
  methods: {
    ...mapActions("global", ["onExtendClick","isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].schoolorgid = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val.key;
      this[form].schoolorgcode = val.code;
      this[form].schoolorgname = val.val;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
		enterSearch(e){
		   if(e.keyCode === 13) {
		     this.getDataList()
		   }
		 },
		 // 是否显示下拉框
		 visibleusedflag(e){
		   if(!e) {
		     this.$refs.selectref.blur();
		   }
		 },
		rest() {
			this.searchForm = {
				id: null,
				iyear: null,
				code: null,
				usedflag: null,
				schoolorgname: null,
			}
		},
		//重置
		resetQuery() {
			this.rest();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: shouju_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //发放凭证 click
    btnAdd() {
      this.dialogTitle = "发放凭证";
      this.dynamicValidateForm = {
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        schoolorgcode: this.$store.state.login.userinfo.schoolorgcode,
      };
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.showAddModal = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: shouju_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
	  .base_table{
	  margin: auto;
	 }
</style>