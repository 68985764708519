<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="学生:">
          <el-input
						v-model.trim="searchForm.studentname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="身份证号:">
          <el-input
						v-model="searchForm.idcard"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="院校:">
          <el-input
						v-model="searchForm.collegename"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="专业:">
          <el-input
						v-model="searchForm.majorname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="层次:">
          <el-select
						v-model="searchForm.majorlevelval"
						@keyup.enter.native="seach"
						@visible-change="levelvalvisible"
						ref="selectref"
						clearable>
            <el-option
              v-for="item in leveltype"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级:">
          <el-input
						v-model.trim="searchForm.grade"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="项目类型:">
          <el-select
						v-model="searchForm.projecttype"
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属教学点:">
          <el-input
						v-model.trim="searchForm.schoolorgname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="录取状态">
          <el-select
            v-model="searchForm.registerflag"
						@visible-change="registType"
						@keyup.enter.native="seach"
						ref="registperef"
						clearable>
            <el-option label="已录取" value="1"></el-option>
            <el-option label="未录取" value="0"></el-option>
          </el-select>
        </el-form-item>

          <el-form-item label="合作名单">
              <el-select v-model="searchForm.iscooperation"
                         @visible-change="registType"
                         @keyup.enter.native="seach"
                         ref="registperef"
                         clearable>
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
              </el-select>
          </el-form-item>

      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生" align="center" width="120px">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row)">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="身份证号"
        prop="idcard"
        align="center"
        width="200"
      />
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column label="院校" prop="collegename" align="center" width="160px" />
      <el-table-column label="专业" prop="majorname" align="center" />
      <el-table-column label="层次" prop="majorlevelval" align="center" />
			<el-table-column label="录取状态" align="center">
				<template slot-scope="scope">
					    {{ scope.row.registerflag == 1 ? "已录取" : "未录取" }}
					</template>
			</el-table-column>
      <el-table-column
        label="总分"
        prop="total"
        align="center"
      />
        <el-table-column label="跟进人" prop="followusername" align="center" />
        <el-table-column label="合作名单" align="center">
            <template slot-scope="scope">
                {{ scope.row.iscooperation == 1 ? "是" : "否" }}
            </template>
        </el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="150"
      />
			<el-table-column label="操作" align="center" width="120px">
			  <template slot-scope="scope">
			    <el-button type="text" @click="btnShow(scope.row)"
			      >查看详情</el-button
			    >
			  </template>
			</el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      width="60%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学生:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="学号:">
          {{ dynamicValidateForm.code }}
        </el-form-item>
        <el-form-item label="身份证号:">
          {{ dynamicValidateForm.idcard }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.majorlevelval }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 成绩详情 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
      title="成绩详情："
      :visible.sync="showModal"
			:close-on-click-modal='false'
			v-if="showModal"
      append-to-body
    >
    <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
						<el-form-item label="考试类型:">
						  <el-input
								v-model.trim="selectSearchForm.examtype"
								@keyup.enter.native="selectSeach"
								clearable />
						</el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="selectSeach"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleChangeQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <div class="select-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="btnDetailEdit">修改</el-button>
        <el-button type="primary" @click="btnDetailDel">删除</el-button>
				<el-button type="primary" @click="btnDetailReload">刷新</el-button>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="学生" prop="studentname" align="center" />
        <el-table-column label="项目类型" prop="projecttype" align="center" />
        <el-table-column label="考试类型" prop="examtype" align="center" />
        <el-table-column label="考试科目" prop="coursename" align="center" />
        <el-table-column label="考试成绩" prop="score1" align="center" />
        <el-table-column label="平时成绩" prop="score2" align="center" />
        <el-table-column label="总评成绩" prop="score3" align="center" />
          <!--
        <el-table-column label="是否补考" prop="status" align="center">
          <template slot-scope="scope">
            {{ isflag[scope.row.status] }}
          </template>
        </el-table-column>
        -->
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
			<el-pagination
			  background
				@current-change="
				  (val, data) => handleSelectChange(val, Object.assign({educationexamid: this.itemId}))
				"
			  :current-page="selectPageInfo.pageindex"
			  :page-size="selectPageInfo.pagesize"
			  :page-sizes="[10, 20, 30, 40, 50]"
			  layout="total, prev, pager, next, jumper"
				:total="selectTable.totalcount"
			></el-pagination>
    </el-dialog>

    <!--成绩详情的添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showDetailEditModal"
			v-if="showDetailEditModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"

        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="姓名">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
        </el-form-item>
        <el-form-item label="科目名称" prop="coursename">
          <el-input v-model="dynamicValidateForm.coursename"></el-input>
        </el-form-item>
        <el-form-item label="分数" prop="score">
          <el-input v-model="dynamicValidateForm.score"></el-input>
        </el-form-item>
          <!--
        <el-form-item label="是否补考" prop="status">
          <el-select v-model="dynamicValidateForm.status">
            <el-option value="1" label="是"></el-option>
            <el-option value="0" label="否"></el-option>
          </el-select>
        </el-form-item>
        -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveDetail('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 导入成绩 -->
    <el-dialog
      class="btn-2b5a95"
      title="导入文件数据"
			v-dialogDrag
      :visible.sync="importDialog"
			v-if="importDialog"
			:close-on-click-modal='false'
      width="30%"
      center
      append-to-body
    >
      <el-form
        ref="dynamicValidateForm"
        :model="dynamicValidateForm"
        :rules="rules"
      >
        <el-form-item label="项目类型:" prop="projecttype">
          <el-select v-model="dynamicValidateForm.projecttype" clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试类型:" prop="examtype">
          <el-select v-model="dynamicValidateForm.examtype" clearable>
            <el-option
              v-for="item in examtypes"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-upload
        class="upload-demo"
        name="file"
        ref="upload"
        action=""
        accept=".xls, .xlsx"
        :on-change="handleChange"
        :fileList="fileList"
				:on-remove="onScorefileRemove"
      >
        <el-button slot="trigger" type="primary">选择文件</el-button>
        <el-button class="download" type="success" @click="download"
          >下载模板</el-button
        >
        <div slot="tip" class="el-upload__tip" v-if="!resTipC">
          只能上传以.xls为后缀的文件
        </div>
        <!-- 响应提示 -->
        <div class="res-tip" v-else>
          <h2>提示：</h2>
          <div v-html="resTipC" class="resTipc-w"></div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCloseImport">取 消</el-button>
        <el-button
          type="primary"
          @click="onImportSave('dynamicValidateForm', educationexam_importsave, 'importDialog')"
          :loading="!$store.state.global.isEndRequest"
          >确 定</el-button
        >
      </div>
    </el-dialog>
		<!--添加，修改-->
		<el-dialog
		  class="btn-2b5a95"
		  width="1100px"
			v-dialogDrag
			:close-on-click-modal='false'
		  title="录取"
		  :visible.sync="showAddModal"
		  v-if="showAddModal"
		  append-to-body
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="170px"
		    class="demo-dynamic form-item-w-50"
		    status-icon
		    :rules="rules"
		  >
		    <el-form-item prop="companyorgname" label="所属分公司">
		      <el-select
		        v-model="dynamicValidateForm.companyorgname"
		        placeholder="请选择"
		        disabled
		        clearable
		        @change="onChangeCompany($event, 'dynamicValidateForm')"
		      >
		        <el-option
		          v-for="item in $store.state.selectData.companyList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item prop="schoolorgname" label="所属教学点">
		      <el-select
		        v-model="dynamicValidateForm.schoolorgname"
		        placeholder="请选择"
						disabled
		        clearable
		        @change="onChangeSchool($event, 'dynamicValidateForm')"
		      >
		        <el-option
		          v-for="item in $store.state.selectData.schoolList"
		          :key="item.key"
		          :label="item.val"
		          :value="item.key"
							clearable
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="姓名:" prop="studentname">
		      <el-input v-model="dynamicValidateForm.studentname" disabled />
		    </el-form-item>
				<el-form-item label="身份证:" prop="idcard">
				  <el-input v-model="dynamicValidateForm.idcard"/>
				</el-form-item>
		    <el-form-item label="入学年级:" prop="grade">
		      <el-select v-model="dynamicValidateForm.grade" clearable>
		        <el-option
		          v-for="item in grade"
		          :key="item.id"
		          :value="item.key"
		          :label="item.val"
		        ></el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="层次:" prop="levelkey">
		      <el-select v-model="dynamicValidateForm.levelkey" clearable>
		        <el-option
		          v-for="item in leveltype"
		          :key="item.key"
		          :value="item.key"
		          :label="item.val"
		        ></el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="学习形式:" prop="studytypekey">
		      <el-select v-model="dynamicValidateForm.studytypekey" clearable>
		        <el-option
		          v-for="item in studytypeval"
		          :key="item.key"
		          :value="item.key"
		          :label="item.val"
		        ></el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="院校:" prop="collegename">
		      <el-input v-model="dynamicValidateForm.collegename" disabled />
		    </el-form-item>
		    <el-form-item label="专业:" prop="majorname">
		      <el-input v-model="dynamicValidateForm.majorname" disabled />
		      <el-button
		        type="primary"
		        class="select-btn"
		        @click="
		          onSelectData(collegemajor_dialogresult1, 'showCollegeModal')
		        "
		        >选择</el-button
		      >
		    </el-form-item>
		    <el-form-item label="学制:" prop="xuezhikey">
		      <el-select v-model="dynamicValidateForm.xuezhikey" clearable>
		        <el-option
		          v-for="item in xuezhi"
		          :key="item.key"
		          :value="item.key"
		          :label="item.val"
		        ></el-option>
		      </el-select>
		    </el-form-item>
		   <el-form-item label="函授站:" prop="guakaocompanyorgname">
		      <el-select
		        v-model="dynamicValidateForm.guakaocompanyorgname"
		        clearable
		        @change="onGuakaoschoolorChange($event, 'dynamicValidateForm')"
		      >
					<el-option
					  v-for="(value, key) in map"
						:key="value"
					  :value="key"
					  :label="value"
						clearable
					></el-option>
		      </el-select>
		    </el-form-item>
		   <el-form-item label="上课地点:（开班点）" prop="guakaoschoolorgname">
		      <el-select
		        v-model="dynamicValidateForm.guakaoschoolorgname"
		        clearable
		        @change="onClassaddressChange($event, 'dynamicValidateForm')"
		      >
		        <el-option
		          v-for="item in collegecoporgList"
		          :key="item.classid"
		          :value="item.classid"
		          :label="item.classname"
		        ></el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item label="项目类型:" prop="projecttype">
		      <el-select v-model="dynamicValidateForm.projecttype" clearable>
		        <el-option
		          v-for="item in educationList"
		          :key="item.key"
		          :value="item.val"
		          :label="item.val"
		        ></el-option>
		      </el-select>
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button
		      type="primary"
		      @click="submitAddOrEditForm('dynamicValidateForm')"
		      :loading="!$store.state.global.isEndRequest"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>
		<!-- 选择专业-->
		<el-dialog
		  class="btn-2b5a95"
		  width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择专业："
		  :visible.sync="showCollegeModal"
		  v-if="showCollegeModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onConfirmCollegemajor">确定</el-button>

		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="院校:" prop="collegename">
		          <el-input
								v-model.trim="selectSearchForm.collegename"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
		        </el-form-item>
		        <el-form-item label="专业:" prop="name">
		          <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
		        </el-form-item>
		        <el-form-item label="专业层次:" prop="majorlevelval">
		          <el-input
								v-model.trim="selectSearchForm.majorlevelval"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>

		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				:key="key"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="院校" prop="collegename" align="center" />
		    <el-table-column label="专业" prop="name" align="center" />
		    <el-table-column label="层次" prop="majorlevelval" align="center" />
		    <el-table-column label="类别" prop="majortypeval" align="center" />
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		      (val, data) => handleSelectChange(val, selectSearchForm)
		    "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>
		<Import
		  :importDialog="importDialogregister"
		  :importSaveUrl="importSaveUrl"
		  @onCloseImport="onCloseImportregister"
		  @getDataList="getDataList"
		  :downloadUrl="downloadUrl"
		></Import>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Import from "@/components/Import/index.vue";
import {
  educationexam_list,
  educationexam_input,
	educationexam_show,
  educationexam_delete,
  educationexamdetail_list,
  educationexamdetail_input,
  educationexamdetail_save,
  educationexamdetail_delete,
  educationexam_importsave,
  educationexamdetail_exportscore,
	collegemajor_getcollegemajor,
	studentregister_getclassname,
	educationexam_register,
	collegemajor_dialogresult1,
	educationexam_importsave2,
    educationexam_export2,
} from "@/request/api/allChildrenProject";
import { _filedownload_save } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _projectTypes } from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "educationexam",
  components: { Import },
 props: {
  collegemajor_dialogresult1: {
    default: () => collegemajor_dialogresult1,
  },
	educationexam_importsave: {
		default: () => educationexam_importsave
	},
	studentregister_getclassname: {
		default: () => studentregister_getclassname
	}
 },
  data() {
    return {
			studytypeval: [], // 学习形式
			showCollegeModal: false,
      importDialog: false, //导入框
			importDialogregister: false, //录取框
      itemId: null, //首表格项id（可替代）
			importSaveUrl: "",
			downloadUrl: "",
      rowItem: null, //首表格项
      searchForm: {},
      tableRes: {}, //列表接口
			key: '',
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        studentname: [{ trigger: "blur", message: "必填字段", required: true }],
        examtype: [{ trigger: "blur", message: "必填字段", required: true }],
        coursename: [{ trigger: "blur", message: "必填字段", required: true }],
        score: [{ trigger: "blur", message: "必填字段", required: true }],
        status: [{ trigger: "blur", message: "必填字段", required: true }],
        projecttype: [{ trigger: "blur", message: "必填字段", required: true }],
        examtype: [{ trigger: "blur", message: "必填字段", required: true }],
				projecttype: [{ trigger: "blur", message: "必填字段", required: true }],
				majorname: [{ trigger: "blur", message: "必填字段", required: true }],
				grade: [{ trigger: "blur", message: "必填字段", required: true }],
				levelkey: [{ trigger: "blur", message: "必填字段", required: true }],
				studytypekey: [{ trigger: "blur", message: "必填字段", required: true }],
				collegename: [{ trigger: "blur", message: "必填字段", required: true }],
				guakaocompanyorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				guakaoschoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				xuezhikey: [{ trigger: "blur", message: "必填字段", required: true }],
				idcard: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),
      isflag: {
        0: "否",
        1: "是",
      },
      deliTable: {},
      showDeliModal: false, //德立学生

      isDisable: false,

      showModal: false,
      showTable: {},
      showDetailEditModal: false,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      projectTypes: _projectTypes,
      grade: [],
			selectSearchForm: {},
			map:"",
			collegecoporgList:[],//上课地点
      leveltype: [],
			xuezhi: [],
      examtypes: [],
      fileList: [], //上传的文件列表，仅作展示
      file: new FormData(), //上传的文件表单
      resTipC: "", //响应提示 object
      msgType: "", //提示信息类型
			educationList: [],
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      list: "grade",
      typecode: "grade",
      that: this,
    });
    this.dic_combox({
      list: "leveltype",
      typecode: "leveltype",
      that: this,
    });
    this.dic_combox({
      list: "examtypes",
      typecode: "examtype",
      that: this,
    });
		this.dic_combox({
		  list: "xuezhi",
		  typecode: "xuezhi",
		  that: this,
		});
		this.dic_combox({
		  list: "studytypeval",
		  typecode: "studytype",
		  that: this,
		});
		this.dic_combox({
		  list: "educationList",
		  typecode: "education",
		  that: this,
		});
		window.addEventListener("keyup", this.enterSelect);
		this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox","org_combox", "org_comboxchild"]),
		//改变分公司 change
		onChangeCompany(val, form) {
		  this[form].schoolorgname = "";
		  this[form].companyorgid = val;
		  this.org_comboxchild(val);
		},
		onChangeSchool(val, form) {
		  this[form].schoolorgid = val;
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		levelvalvisible(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
    registType(e) {
      if(!e) {
        this.$refs.registperef.blur();
      }
    },
		reset() {
			this.searchForm = {
				studentname: null,
				idcard: null,
				collegename: null,
				majorname: null,
				majorlevelval: null,
				grade: null,
				projecttype: null,
				schoolorgname: null,
        registerflag: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				qianzainame: null,
				mobile: null,
				schoolorgname: null,
				collegename: null,
				name: null,
				majorlevelval: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},

    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: educationexam_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
     // 搜索
    selectSeach() {
			this.pageinfo.pageindex = 1;
			this.onSelectData(educationexamdetail_list,
                "showModal",
                Object.assign({educationexamid: this.itemId,
                                   examtype:this.selectSearchForm.examtype,
			     },

                )
            );
    },
    handleChangeQuery() {
      this.selectSearchForm = {
        examtype: null
      }
      this.onSelectData(educationexamdetail_list, null, {
        educationexamid: this.itemId,
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换德立学生表格页
    handleDeliChange(val) {
      this.selectPageInfo.pageindex = val;
      this.onSelectDeli();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: educationexam_show,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.veducationexam;
        }
      });
    },
		//改变函授站 change
		onGuakaoschoolorChange(val, form) {
			this[form].guakaoschoolorgid = val;
			this.dynamicValidateForm.guakaoschoolorgid = this[form].guakaoschoolorgid;
			this.collegecoporglist();
			this.dynamicValidateForm.guakaoschoolorgname = "";
		},
		//改变上课开班地点 change
		onClassaddressChange(val, form) {
			this[form].classaddressid = val;
			this.dynamicValidateForm.classaddressid = this[form].classaddressid;
		},
		//加载上课地点 api
		collegecoporglist() {
		  myRequest({
		    url: studentregister_getclassname,
		    data: {
					collegeid: this.dynamicValidateForm.collegeid,
					schoolorgid: this.dynamicValidateForm.guakaoschoolorgid,
		      pagesize:20
		    },
		  }).then(res=>{
		    if(res.data.code==='200'){
		     this.collegecoporgList = res.data.data.list
		    }
		  })
		},
		//提交-（添加，修改） api
		submitAddOrEditForm(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		      myRequest(
		        {
		          url: educationexam_register,
		          data: {
								id: this.id,
								idcard: this.dynamicValidateForm.idcard,
								projecttype: this.dynamicValidateForm.projecttype,
								grade: this.dynamicValidateForm.grade,
								collegeid: this.dynamicValidateForm.collegeid,
								majorid: this.dynamicValidateForm.majorid,
								levelkey: this.dynamicValidateForm.levelkey,
								studytypekey: this.dynamicValidateForm.studytypekey,
								studentname: this.dynamicValidateForm.studentname,
								xuezhikey: this.dynamicValidateForm.xuezhikey,
								guakaoschoolorgid: this.dynamicValidateForm.guakaoschoolorgid,
								classaddressid: this.dynamicValidateForm.classaddressid,
							}
		        },
		        {
		          isRefTableList: true,
		          that: this,

		          modal: "showAddModal",
		        }
		      );
		    }
		  });
		},
		// 文件上传删除
		onScorefileRemove(file, fileList) {
		  this.dynamicValidateForm.scorefile = "";
		  this.file.delete("scorefile");
		},
    //查看详情 click
    btnShow(row) {
      this.itemId = row.id;
			this.onSelectData(educationexamdetail_list, "showModal", {
			 educationexamid: this.itemId,
			});
    },
		//批量录取
		batchAdminssion() {
			this.importSaveUrl = educationexam_importsave2;
			this.downloadUrl = "/download/教学管理/学历/批量录取模板.xls";
			this.importDialogregister = true;
		},
		//录取
		btnRegister() {
			selectCheck(this.multipleSelection, "录取", false, () => {
				this.register(this.multipleSelection[0].id);
			})
		},
		register(id) {
		  myRequest({
		    url: educationexam_input,
		    data: {
		      id: id,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
					this.showAddModal = true;
					this.id	=	res.data.data.veducationexam.id;
					this.dynamicValidateForm = Object.assign(
						res.data.data.veducationexam,
						res.data.data.vstudent,
					);
					this.dynamicValidateForm.guakaoschoolorgname = "";
					this.dynamicValidateForm.guakaocompanyorgname = "";
					this.org_comboxchild(this.dynamicValidateForm.companyorgid);
					this.collegemajor_getcollegemajor();
					delete this.dynamicValidateForm.inserttime
					delete res.data.data.vstudent.id
		    }
		  });
		},
    //查看详情的修改 click
    btnDetailEdit() {
      this.dialogTitle = "修改";
      this.dynamicValidateForm = {};
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editDetailRoutePage(this.multipleSelection[0].id);
      });
    },
    //查看详情的删除 click
    btnDetailDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delDetail(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
		btnDetailReload() {
			this.onSelectData(educationexamdetail_list, null, {
			 educationexamid: this.itemId,
			});
		},
    //详情的保存 api
    saveDetail(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: educationexamdetail_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              modal: "showDetailEditModal",
            }).then((res) => {
							if (res.data.code === "200") {
								this.showDetailEditModal = false;
								this.onSelectData(educationexamdetail_list, null, {
								 educationexamid: this.itemId,
								});
					}
				});

        }
      });
    },
    //详情的修改路由页 api
    editDetailRoutePage(id) {
      myRequest({
        url: educationexamdetail_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.multipleSelection = [];
          this.showDetailEditModal = true;
          this.dynamicValidateForm = res.data.data.veducationexamdetail;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    //查看详情的删除 api
    delDetail(idArr) {
      myRequest(
        {
          method: "post",
          url: educationexamdetail_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          modal: "showDetailEditModal",
          that: this,
        }
      );
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
		//确认专业 click
		onConfirmCollegemajor() {
		  selectCheck(this.multipleSelection, "选择", false, () => {
		    this.$set(
		      this.dynamicValidateForm,
		      "majorname",
		      this.multipleSelection[0].name
		    );
		    this.$set(
		      this.dynamicValidateForm,
		      "collegename",
		      this.multipleSelection[0].collegename
		    );
		    this.$set(
		      this.dynamicValidateForm,
		      "keleival",
		      this.multipleSelection[0].majortypeval
		    );
				this.dynamicValidateForm.collegeid =
				  this.multipleSelection[0].collegeid;
		    this.dynamicValidateForm.majorid = this.multipleSelection[0].id;
		    this.dynamicValidateForm.keleikey =
		    this.multipleSelection[0].majortypekey;
				this.collegemajor_getcollegemajor();
		    this.dynamicValidateForm.guakaocompanyorgname = "";
				this.dynamicValidateForm.guakaoschoolorgname = "";
		    this.showCollegeModal = false;
				this.selectQuery();
				this.multipleSelection = [];
		  });
		},
		//函授站 api
		 collegemajor_getcollegemajor() {
		  myRequest({
				url:collegemajor_getcollegemajor,
		    data:{
						id: this.dynamicValidateForm.collegeid
		     }
		   }).then((res) => {
		     if (res.data.code === "200") {
							this.map = res.data.data.map
							for(const key in this.map){
								// console.log(key,'key')
								// console.log('value',this.map[key])
							}
		     }

		   });
		 },
    //导出成绩
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            educationexamdetail_exportscore +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

      //导出名单
      btnExport2() {
          confirmCallBack({
              title: "提示",
              content: "根据搜索条件进行导出22。",
              success: () => {
                  window.location.href =
                      this.$url.Freemarker +
                      educationexam_export2 +
                      `?${createObjParams(this.searchForm)}`;
              },
              fail: () => {
                  messageTip({
                      message: "取消操作",
                      type: "info",
                  });
              },
          });
      },

    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: educationexam_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //上传文件改变时
    handleChange(file, fileList) {
      this.file = new FormData();
      this.file.append("scorefile", file.raw);
			this.fileList = [{ name: file.name, url: file.name }];
			this.dynamicValidateForm.scorefile = file.raw.name;
    },
    //确定导入 click api
    onImportSave(dynamicValidateForm, url, modal) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          this.file.append("examtype", this.dynamicValidateForm.examtype);
          this.file.append("projecttype", this.dynamicValidateForm.projecttype);
          if (!this.file.get("scorefile")) {
            return messageTip({
              message: "请选择需要导入的文件",
              type: "warning",
            });
          }
          myRequest(
            {
              method: "post",
              url: url,
              data: this.file,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
            {
              disableTip: true,
            }
          ).then((res) => {
            if (res.data.code === "200" && res.data.object === null) {
              //上传成功
              this.msgType = "success";
              this.resTipC = res.data.msg;
              this.onCloseImport();
              this.getDataList();
							this[modal] = false;
            } else {
              //上传失败
              this.msgType = "warning";
            }
            if (res.data.object !== null) {
              this.resTipC = res.data.object;
            }
            messageTip({
              message: res.data.msg,
              type: this.msgType,
            });
          });
        }
      });
    },

    // 下载模板 click
    download() {
      myRequest({
        url: _filedownload_save,
        data: {
          fileurl: "download/教学管理/学历/成绩导入模板.xls",
        },
      }).then((res) => {
        if (res.status === 200) {
          location.href =
            this.$url.Freemarker +
            _filedownload_save +
            "?fileurl=" +
            "download/教学管理/学历/成绩导入模板.xls";
        }
      });
    },

    //导入（成绩）click
    btnImport2() {
      this.importDialog = true;
      this.resTipC = "";
      this.fileList = [];
      this.file = new FormData();
    },
		onCloseImportregister() {
			this.importDialogregister = false;
		},
    //关闭导入框
    onCloseImport() {
      this.resTipC = "";
      this.fileList = [];
      this.file = new FormData();
      this.importDialog = false;
    },
		//切换选项表格页
		handleSelectChange(val, data) {
		  this.selectPageInfo.pageindex = val;
		  this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
		},
		//选项表格 api（专业，德立学生,院校）
		onSelectData(url, modal, data) {
		  this.selectTableUrl = url;
		  myRequest({
		    url: this.selectTableUrl,
		    data: Object.assign(data || {}, this.selectPageInfo),
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
		      this.selectTable = res.data.data;
					this.key = Math.random();
		      this[modal] = true;
		    }
		  });
		},
		//选项表格 - 搜索 click
		onSelectSearchClick(url, modal, data) {
		  this.selectPageInfo.pageindex = 1;
		  this.onSelectData(url, modal, data);
		},
		//关闭选项框 click
		onCloseSelect() {
		  this.selectPageInfo.pageindex = 1;
		},
  },
};
</script>


<style scoped lang="scss">
.resTipc-w {
  color: red;
}

.download {
  margin-left: 13%;
}
</style>
