<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
          :rules="searchRules"
          :model="searchForm"
          ref="searchForm"
        >
          <el-form-item prop="companyorgname" label="所属分公司">
            <el-select
              v-model="searchForm.companyorgname"
              placeholder="请选择"
              :disabled="!$store.state.login.isMaxAuth"
              clearable
              @change="onChangeCompany($event, 'searchForm')"
							@visible-change="visibleRef"
							ref="selectref"
							@keyup.enter.native="seach"
            >
              <el-option
                v-for="item in $store.state.selectData.companyList"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="schoolorgname" label="所属教学点">
            <el-select
              v-model="searchForm.schoolorgname"
              placeholder="请选择"
              clearable
              @change="onChangeSchool($event, 'searchForm')"
							@visible-change="schoolVisble"
							@keyup.enter.native="seach"
							ref="nameref"
            >
              <el-option
                v-for="item in $store.state.selectData.schoolList"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="报读月份:" prop="regdate">
            <el-date-picker
              v-model="searchForm.regdate"
              type="month"
              placeholder="选择月份"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="招生员:" prop="followusername">
            <el-input 
							v-model.trim="searchForm.followusername"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach('searchForm')"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <div>
      <!-- 操作按钮 -->
      <div class="select-btn" v-if="tableRes.list && tableRes.list.length">
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <!-- 表格 -->
      <el-table
        :data="tableRes.list"
				border
				stripe
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="跟进人"
          prop="followusername"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="招生人数"
          prop="num"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="实收总金额"
          prop="recvmoney"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="生源来源类别"
          prop="studentfromtypeval"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="奖励百分比"
          prop="percentage"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="总提成"
          prop="reward"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="tableRes.totalcount"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";

import { educationreward_listzhiye } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

import { _org_combox, _allSchool } from "@/request/api/public";
export default {
  mixins: [part],
  name: "educationreward",
  components: {},
  props: {},
  data() {
    return {
      itemId: null, //首表格项id
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      searchForm: {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
      },
      searchRules: {
        regdate: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],



      },
      companyList: [],
      schoolList: [],
    };
  },
  created() {
    this.org_combox();
    this.org_comboxchild(this.searchForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //搜索 click
    seach(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getDataList();
        }
      });
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		schoolVisble(e) {
			if(!e) {
				this.$refs.nameref.blur();
			}
		
		},
		reset() {
			this.searchForm = {
				companyorgname: null,
				schoolorgname: null,
				regdate: null,
				followusername: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: educationreward_listzhiye,
        data: {
          pageindex: this.pageinfo.pageindex,
          pagesize: this.pageinfo.pagesize,
          regdate: this.searchForm.regdate,
          followusername: this.searchForm.followusername,
          schoolorgid: this.searchForm.schoolorgid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>