<template>
  <div class="important-page-c display--flex fd--column">
    <div v-if="!goPrint">
      <!-- 搜索 -->
      <div class="search-form btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_1
              ? ''
              : $store.state.global.searchFormHeight_1 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
            "
						:model="searchForm"
						@submit.native.prevent
          >
						<el-form-item label="流水号:">
						  <el-input 
								v-model.trim="searchForm.id" 
								@keyup.enter.native="seach"
								clearable />
						</el-form-item>
            <el-form-item label="标题:">
              <el-input 
								v-model.trim="searchForm.title" 
								@keyup.enter.native="seach"
								clearable />
            </el-form-item>
            <el-form-item label="审核状态:">
              <el-select
                v-model="searchForm.passflag"
								@keyup.enter.native="seach"
								@visible-change="visibleType"
								ref="selectref"
                placeholder="请选择"
                clearable
              >
                  <el-option
                  v-for="item in approveflags"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option
                >
              </el-select>
            </el-form-item>

            <el-form-item label="所属教学点名称:">
              <el-input 
								v-model.trim="searchForm.schoolorgname" 
								@keyup.enter.native="seach"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="seach"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            >搜索</el-button
          >
					<el-button type="primary" @click="resetQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_1
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_1"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_1')"
            >{{ $store.state.global.searchExtendText_1 }}</el-button
          >
        </div>
      </div>

      <!-- 按钮操作 -->
      <div
        class="Btn-w eaf4ff-btn"
        :style="{
          height: $store.state.global.isBtnExtend_1
            ? ''
            : $store.state.global.BtnHeight_1 + 10 + 'px',
        }"
      >
        <div
          class="btn-l"
          v-if="tableRes.buttonlist"
          :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
        >
          <el-button
            v-for="(item, index) in tableRes.buttonlist"
            :key="index"
            type="primary"
            @click="onClickBtn(item.buttonname)"
            >{{ item.name }}
          </el-button>
        </div>
        <div class="Btn-extend">
          <el-button
            type="primary"
            v-if="$store.state.global.btnExtend_1"
            :icon="
              $store.state.global.isBtnExtend_1
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            @click="onExtendClick('isBtnExtend_1')"
            >展开</el-button
          >
        </div>
      </div>

      <!-- 表格 -->
      <el-table
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        :data="tableRes.list"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
      >
        <el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="流水号" prop="id"></el-table-column>
        <el-table-column label="标题" prop="title" align="center">
          <template slot-scope="scope">
            <el-button @click="btnShowDetail(scope.row)" type="text">{{
              scope.row.title
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="passflag" align="center">
          <template slot-scope="scope">
            {{ approveflag[scope.row.passflag] }}
          </template>
        </el-table-column>
        <el-table-column label="审核时间" prop="approvetime" align="center">
          <template slot-scope="scope">
            {{ scope.row.approvetime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="审核人"
          prop="approveusername"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属教学点名称"
          prop="schoolorgname"
          width="140"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属分公司名称"
          prop="companyorgname"
          width="140"
          align="center"
        ></el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="tableRes.totalcount"
      ></el-pagination>
    </div>

    <!-- 打印页 -->
    <PublicPrint
      v-else
      @switchPrint="switchPrint"
      :data="printData"
    ></PublicPrint>

    <!--快速新增-->
    <el-dialog
      class="btn-2b5a95"
      title="快速新增："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showSave2Modal"
      v-if="showSave2Modal"
      append-to-body
      width="65%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-auto"
        status-icon
      >
        <el-form-item
          prop="title"
          label="标题："
          :rules="{
            required: true,
            message: '必填字段',
            trigger: 'blur',
          }"
        >
          <el-input v-model.trim="dynamicValidateForm.title" clearable></el-input>
        </el-form-item>

        <div
          v-for="(item, i) in dynamicValidateForm.moreItems"
          :key="i"
          class="more-form-item-w"
        >
          <el-form-item
            label="类型"
            :prop="'moreItems.' + i + '.type'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-select v-model="item.type" placeholder="请选择" clearable>
              <el-option
                v-for="item in typeSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="名称"
            :prop="'moreItems.' + i + '.name'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.name" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="单位"
            :prop="'moreItems.' + i + '.unit'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.unit" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="数量"
            :prop="'moreItems.' + i + '.num'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.num" clearable></el-input>
          </el-form-item>
          <el-button
            v-if="i !== 0"
            @click="onRemoveMoreForm(i)"
            style="margin: 0 10px; height: 40px"
            >删除</el-button
          >
        </div>

        <el-form-item>
          <el-button @click="addFormItem">添加一行</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitSave2('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 负责人审批 -->
    <el-dialog
      class="btn-2b5a95"
      title="负责人审批："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showApprovalModal"
      v-if="showApprovalModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="headApprovalForm"
        ref="headApprovalForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="headApprovalForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 2, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审批意见："
          prop="approvedesc"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model.trim="headApprovalForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval">提交 </el-button>
      </span>
    </el-dialog>

    <!-- 审批列表 -->
    <el-dialog
      class="btn-2b5a95"
      title="审批记录："
			v-dialogDrag
			width="70%"
			:close-on-click-modal='false'
      :visible.sync="showApprovalListModal"
      v-if="showApprovalListModal"
      append-to-body
    >
      <el-table 
				:data="approvalTableList" 
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
				style="width: 100%">
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column label="对象名称" prop="objectname" align="center">
        </el-table-column>
        <el-table-column label="审批时间" align="center" width="160">
          <template slot-scope="scope">
            {{ scope.row.approvetime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="审批意见"
          prop="approvedesc"
          align="center"
        ></el-table-column>

        <el-table-column
          label="审批类型"
          prop="approvetype"
          align="center"
        ></el-table-column>
        <el-table-column
          label="审核是否通过标志"
          prop="passflag"
          width="140"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.passflag === 1 ? "通过" : "不通过" }}
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <!-- 表格项详情 -->
    <el-dialog
      title="详情："
			v-dialogDrag
			width="60%"
			:close-on-click-modal='false'
      :visible.sync="showTableItemModal"
      v-if="showTableItemModal"
      append-to-body
    >
      <!-- 操作按钮 -->
      <div class="eaf4ff-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="btnEditDetial">修改 </el-button>
        <el-button type="primary" @click="btnDelDetial">删除 </el-button>
      </div>

      <el-table
        :data="tableItemDetail.list"
        style="width: 100%"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column label="类型" prop="type" align="center">
        </el-table-column>
        <el-table-column
          label="物品名称"
          prop="name"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          label="单位"
          prop="unit"
          align="center"
        ></el-table-column>
        <el-table-column
          label="采购数量"
          prop="num"
          align="center"
        ></el-table-column>
      </el-table>

      <el-pagination
        background
        @current-change="handleDetailListChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="tableItemDetail.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 详情里的修改 -->
    <el-dialog
      class="btn-2b5a95"
      title="修改："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showDetailEditModal"
      v-if="showDetailEditModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
        :rules="detailRules"
      >
        <el-form-item prop="type" label="类型：">
          <el-select
            v-model="dynamicValidateForm.type"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in typeSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name" label="物品名称：">
          <el-input v-model.trim="dynamicValidateForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item prop="unit" label="单位：">
          <el-input v-model.trim="dynamicValidateForm.unit" clearable></el-input>
        </el-form-item>
        <el-form-item
          prop="num"
          label="采购数量："
          :rules="[{ validator: numberfield, required: true }]"
        >
          <el-input v-model.trim="dynamicValidateForm.num" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAddOrEditForm">提交 </el-button>
      </span>
    </el-dialog>

    <!-- 查看详情 -->
    <el-dialog
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table border="1" cellpadding="10" cellspacing="0" class="base_table" >
        <tr>
          <td>标题：{{ dynamicValidateForm.title }}</td>
          <td v-if="dynamicValidateForm.passflag === -2">审核状态：未提请审批</td>
					<td v-if="dynamicValidateForm.passflag === -1">审核状态：审核中</td>
					<td v-if="dynamicValidateForm.passflag === 0">审核状态：未通过</td>
					<td v-if="dynamicValidateForm.passflag === 1">审核状态：通过</td>
          <td>审核时间：{{ dynamicValidateForm.approvetime | formatDate('YYYY-MM-DD') }}</td>
        </tr>
        <tr>
          <td>录入时间：{{ dynamicValidateForm.inserttime | formatDate('YYYY-MM-DD')}}</td>
          <td>审核人：{{ dynamicValidateForm.approveusername }}</td>
          <td>录入人：{{ dynamicValidateForm.insertusername }}</td>
        </tr>
        <tr>
          <td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
					<td>流水号: {{dynamicValidateForm.id}}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";
import PublicPrint from "@/components/public/publicPrint.vue";

import {
  purchase_list,
  purchase_input,
  purchase_save2,
  purchase_delete,
  purchase_requestapprove,
  purchase_approvesave,
  approve_list,
  purchase_print,
  purchasedetail_exportdetail,
  purchasedetail_list,
  purchasedetail_input,
  purchasedetail_save,
  purchasedetail_delete,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import { _initStockmanage } from "@/assets/js/initTableData";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
} from "@/utils/util";
import { _approveflag, _approveflags } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "purchase",
  components: {
    Export,
    Import,
    PublicPrint,
  },
  props: {
    printUrl: {
      default: () => purchase_print,
    },
  },
  data() {
    return {
      numberfield: (rule, value, callback) => {
        if (!(value + "")) {
          return callback(new Error("必填字段"));
        } else {
          if (isNaN(Number(value))) {
            callback(new Error("请输入数字值"));
          } else {
            callback();
          }
        }
      },
      itemId: null, //每一项id
      searchForm: {
        id: "",
        schoolorgname: "",
        title: "",
      }, //搜索表单
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      goPrint: false, //前往打印页
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        type: [{ trigger: "blur", message: "必填字段", required: true }],
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        unit: [{ trigger: "blur", message: "必填字段", required: true }],
        num: [{ trigger: "blur", message: "必填字段", required: true }],
      },

      typeSelect: [
        {
          value: "耐用品",
          label: "耐用品",
        },
        {
          value: "易耗品",
          label: "易耗品",
        },
      ], //类型选择
      headApprovalForm: {
        approvedesc: "",
        passflag: null, //是否通过审批
      }, //负责人审批表单
      showApprovalModal: false,
      approvalTableList: [], //审批记录列表
      showApprovalListModal: false,
      showTableItemModal: false,
      tableItemDetail: [],
      showDetailEditModal: false,
      detailRules: {
        type: [{ trigger: "blur", message: "必填字段", required: true }],
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        unit: [{ trigger: "blur", message: "必填字段", required: true }],
      },
      approveflag: _approveflag,
      approveflags: _approveflags,

      //快速新增表单
      dynamicValidateForm: {
        title: "",
        moreItems: [
          {
            type: "",
            name: "",
            unit: "",
            num: "",
          },
        ],
      },
      showSave2Modal: false, //快速新增框
      showRequest: false, //提前审批
      printData: null, //打印的数据
      showDetailModal: false,
    };
  },
  created() {
    this.searchForm.id = this.$route.params.id;
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    //添加一行 click
    addFormItem() {
      this.dynamicValidateForm.moreItems.push({
        type: "",
        name: "",
        unit: "",
        num: "",
      });
    },
    //切换到打印页
    switchPrint(type) {
      this.goPrint = type;
      this.multipleSelection = [];
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				id: null,
				title: null,
				passflag: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: purchase_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换详情当前页
    handleDetailListChange(val) {
      this.pageinfo.pageindex = val;
      this.lookDetail();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //打印 click
    btnPrint() {
      selectCheck(this.multipleSelection, "打印", false, () => {
        this.print(this.multipleSelection[0].id);
      });
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //快速新增 click
    btnSave2() {
      this.dynamicValidateForm = {
        title: "",
        moreItems: [
          {
            type: "",
            name: "",
            unit: "",
            num: "",
          },
        ],
      };
      this.showSave2Modal = true;
    },
    //删除快速新增项 click
    onRemoveMoreForm(index) {
      this.dynamicValidateForm.moreItems.splice(index, 1);
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //负责人审批 click
    btnHeadApproval() {
      selectCheck(this.multipleSelection, "负责人审批", true, () => {
        this.showApprovalModal = true;
      });
    },
    //查看审批 click
    btnLookApproval() {
      selectCheck(this.multipleSelection, "查看审批", false, () => {
        this.lookApproval(this.multipleSelection[0].id);
      });
    },
    //导出详情 click
    btnExportDetail() {
      selectCheck(this.multipleSelection, "导出详情", false, () => {
        window.location.href =
          this.$url.Freemarker +
          purchasedetail_exportdetail +
          `?id=${this.multipleSelection[0].id}`;
      });
    },
    //查看详情 click
    btnLookDetail() {
      selectCheck(this.multipleSelection, "查看详情", false, () => {
        this.showTableItemModal = true;
        this.lookDetail();
      });
    },

    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "提请审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "选中的条目将提请审批！",
          success: () => {
            this.reqApproval(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //查看详情的修改 click
    btnEditDetial() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editDetailInput(this.multipleSelection[0].id);
      });
    },
    //查看详情的删除 click
    btnDelDetial() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delDetial(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //详情的修改提交 api
    submitAddOrEditForm() {
      myRequest({
        url: purchasedetail_save,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailEditModal = false;
          this.lookDetail();
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //删除表格项 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: purchase_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //快速新增提交 click
    onSubmitSave2(formName) {
      this.dynamicValidateForm.type = [];
      this.dynamicValidateForm.name = [];
      this.dynamicValidateForm.unit = [];
      this.dynamicValidateForm.num = [];
      for (let i in this.dynamicValidateForm.moreItems) {
        for (let key in this.dynamicValidateForm.moreItems[i]) {
          if (this.dynamicValidateForm.moreItems[i][key]) {
            //有值才push，否则是空字符串
            this.dynamicValidateForm[key].push(this.dynamicValidateForm.moreItems[i][key]);
          }
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitSave2();
        }
      });
    },
    //快速新增 api
    submitSave2() {
      myRequest(
        {
          url: purchase_save2,
          method: "post",
          data: this.$qs.stringify(
            {
              title: this.dynamicValidateForm.title,
              type: this.dynamicValidateForm.type,
              name: this.dynamicValidateForm.name,
              unit: this.dynamicValidateForm.unit,
              num: this.dynamicValidateForm.num,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.showSave2Modal = false;
        }
      });
    },
    //打印 api
    print(id) {
      myRequest({
        url: purchase_print,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.printData = res.data.data;
          this.goPrint = true;
        }
      });
    },

    //提请审批 api
    reqApproval(ids) {
      myRequest(
        {
          method: "post",
          url: purchase_requestapprove,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //负责人审批 api
    headApproval() {
      myRequest(
        {
          method: "post",
          url: purchase_approvesave,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
              passflag: this.headApprovalForm.passflag,
              approvedesc: this.headApprovalForm.approvedesc,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.showApprovalModal = false;
        }
      });
    },
    //查看审批 api
    lookApproval(id) {
      myRequest({
        url: approve_list,
        data: {
          objectname: "T_PURCHASE",
          objectid: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.approvalTableList = res.data.data.list;
          this.showApprovalListModal = true;
        }
      });
    },
    //查看详情 api
    lookDetail() {
      myRequest({
        url: purchasedetail_list,
        data: {
          pageindex: this.pageinfo.pageindex,
          pagesize: this.pageinfo.pagesize,
          purchaseid: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.multipleSelection = [];
          this.tableItemDetail = res.data.data;
        }
      });
    },
    //查看详情的修改路由页 api
    editDetailInput(id) {
      myRequest({
        url: purchasedetail_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailEditModal = true;
          this.dynamicValidateForm = res.data.data.vpurchasedetail;
        }
      });
    },
    //查看详情的删除 api
    delDetial(ids) {
      myRequest({
        method: "post",
        url: purchasedetail_delete,
        data: this.$qs.stringify(
          {
            ids: ids,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.lookDetail();
        }
      });
    },
  },
};
</script>


<style  lang="scss">
	 .base_table{
	  margin: auto;
	 }
</style>