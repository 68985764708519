<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="学生姓名:">
            <el-input 
							v-model.trim="searchForm.studentname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input 
							v-model.trim="searchForm.mobile"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
					<el-form-item label="年级:">
					  <el-input 
							v-model.trim="searchForm.grade"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="审核状态:">
            <el-select
              v-model.trim="searchForm.approveflag1"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="appro"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in approveflags"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="退费标识:">
            <el-select
              v-model="searchForm.feebackflag"
							@keyup.enter.native="seach"
							@visible-change="visibleFee"
							ref="feeback"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in feebackflags"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属教学点名称:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
					<el-form-item label="申请人:">
					  <el-input 
							v-model.trim="searchForm.insertusername" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="退费时间起:">
					  <el-date-picker
					    v-model="searchForm.t1"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd 00:00:00"
					  >
					  </el-date-picker>
					</el-form-item>
					<el-form-item label="退费时间止:">
					  <el-date-picker
					    v-model="searchForm.t2"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd 00:00:00"
					  >
					  </el-date-picker>
					</el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      border
			stripe
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="姓名"
        prop="studentname"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="手机"
        prop="mobile"
        align="center"
        width="130"
      ></el-table-column>
			<el-table-column
			  label="年级"
			  prop="grade"
			  align="center"
			  width="130"
			></el-table-column>
      <el-table-column
        label="金额"
        prop="backmoney"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column label="申请时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.applytime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        prop="feebackflag"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          {{ feebackflag[scope.row.feebackflag] }}
        </template>
      </el-table-column>
      <el-table-column
        label="费用类型"
        prop="feetypeval"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column
        label="收据"
        prop="shoujucode"
        align="center"
        width="200"
      ></el-table-column>
      <el-table-column
        label="申请人"
        prop="insertusername"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="退费原因"
        prop="reasonsreason"
        align="center"
        width="300"
				:show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="审核结果" align="center" width="140">
        <template slot-scope="scope" >
         <p> {{scope.row.approveusername1}}{{ approveflag[scope.row.approveflag1]}}</p>
         <p>
					{{scope.row.approveusername2 ? scope.row.approveusername2 : '' }}{{scope.row.schoolorgname == '海珠教学点' ? approveflag[scope.row.approveflag2] : ""}}
					</p>
				</template>
      </el-table-column>
      <el-table-column
        label="所属教学点"
        prop="schoolorgname"
        align="center"
        width="140"
      >
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 审批 -->
    <el-dialog
      class="btn-2b5a95"
      title="审批："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showApprovalModal"
      v-if="showApprovalModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
        :rules="rules"
      >
        <el-form-item label="审核是否通过：" prop="passflag">
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 0, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批意见：" prop="approvedesc">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onApprovalClick('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 申请退费 -->
    <el-dialog
      class="btn-2b5a95"
      title="退费："
			width="60%"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showFeebackModal"
      v-if="showFeebackModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-50"
        status-icon
        label-width="150px"
        :rules="rules"
      >
        <el-form-item label="学生姓名：" prop="studentname">
          <el-input
            v-model="dynamicValidateForm.studentname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="退费金额：" prop="backmoney">
          <el-input v-model="dynamicValidateForm.backmoney" disabled></el-input>
        </el-form-item>
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收据编号：" prop="shoujucode">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="费用类型名称：" prop="feetypeval">
          <el-input
            v-model="dynamicValidateForm.feetypeval"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="银行/现金：" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          ></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(account_dialogresult, 'showAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            class="select-btn"
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="账户名称：" prop="accountname">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号：" prop="cardno">
          <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
        </el-form-item>
        <el-form-item label="支出明细名称：" prop="itemname">
          <el-input v-model="dynamicValidateForm.itemname" disabled></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(moneyoutitem_dialogresult, 'showMoneyinitemModal')
            "
            class="select-btn"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="支出二级项目名称：" prop="type2name">
          <el-input v-model="dynamicValidateForm.type2name" disabled></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(moneyouttype2_dialogresult, 'showMoneyinitemModal2')
            "
            class="select-btn"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="支出一级项目名称：" prop="type1name">
          <el-input v-model="dynamicValidateForm.type1name" disabled></el-input>
        </el-form-item>
        <el-form-item label="支出类型名称：" prop="typename">
          <el-input v-model="dynamicValidateForm.typename" disabled></el-input>
        </el-form-item>
        <el-form-item label="摘要：" prop="zhaiyao">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.zhaiyao"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onFeebackClick('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择账户 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="1300px"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择账户："
			:close-on-click-modal='false'
      :visible.sync="showAccountModal"
      v-if="showAccountModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAccount">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="账户:" prop="accountname">
              <el-input 
								v-model.trim="selectSearchForm.accountname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
            <el-form-item label="银行:" prop="bankname">
              <el-input 
								v-model.trim="selectSearchForm.bankname" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
            <el-form-item label="卡号:" prop="cardno">
              <el-input 
								v-model.trim="selectSearchForm.cardno" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
         <el-table-column
          label="余额"
          prop="curmoney"
          align="center"
          width="200px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>


      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择支出明细 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择支出明细："
      :visible.sync="showMoneyinitemModal"
      v-if="showMoneyinitemModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem">确定</el-button>
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择支出二级明细 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择支出二级明细："
      :visible.sync="showMoneyinitemModal2"
      v-if="showMoneyinitemModal2"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem2">确定</el-button>
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="支出类型:" prop="typename">
              <el-input 
								v-model.trim="selectSearchForm.typename" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="支出一级项目:" prop="type1name">
              <el-input 
								v-model.trim="selectSearchForm.type1name"
								 @keyup.enter.native="onSelectSearchClick(
								   selectTableUrl,
								   null,
								   Object.assign({}, selectSearchForm)
								 )"
								clearable />
            </el-form-item>
            <el-form-item label="支出二级项目:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button @click="handleQuery">重置</el-button>
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				:key="key"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
           <el-table-column
          label="所属支出类型名称"
          prop="typename"
          align="center"
        ></el-table-column>
           <el-table-column
          label="所属一级项目名称"
          prop="type1name"
          align="center"
        ></el-table-column>
        <el-table-column
          label="二级项目名称"
          prop="name"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 退费记录表格 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="90%"
			:close-on-click-modal='false'
      :visible.sync="showMoneybackModal"
      v-if="showMoneybackModal"
      append-to-body
			title="退费记录："
    >
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="账户名称"
          prop="accountname"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="学生姓名"
          prop="studentname"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="退费金额"
          prop="backmoney"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="费用类型名称"
          prop="feetypeval"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column label="退款时间" align="center" width="100">
          <template slot-scope="scope">
            {{ scope.row.backtime | formatDate("YYYY-MM-DD hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="银行/现金"
          prop="bankorcash"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="收据编号"
          prop="shoujucode"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column label="申请退费时间" align="center" width="100">
          <template slot-scope="scope">
            {{ scope.row.applytime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="退费人姓名"
          prop="backusername"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="所属分公司"
          prop="companyorgname"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="60%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:close-on-click-modal='false'
			title="详情"
    >
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
        <tr>
          <td>申请退费金额：{{ dynamicValidateForm.backmoney }}</td>
          <td>凭证路径：{{ dynamicValidateForm.pzurl }}</td>
          <td v-if=" dynamicValidateForm.approveflag1 == -2">教学点审核结果：未提请审批</td>
					<td v-if=" dynamicValidateForm.approveflag1 == -1">教学点审核结果：审核中</td>
					<td v-if=" dynamicValidateForm.approveflag1 == 0">教学点审核结果：未通过</td>
					<td v-if=" dynamicValidateForm.approveflag1 == 1">教学点审核结果：通过</td>
        </tr>
        <tr>
          <td>教学点审核意见：{{ dynamicValidateForm.approvedesc1 }}</td>
          <td>教学点审核时间：{{ dynamicValidateForm.approvetime1 | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td v-if="dynamicValidateForm.approveflag2 == -2">分公司审核标志：未提请审批</td>
					<td v-if="dynamicValidateForm.approveflag2 == -1">分公司审核标志：审核中</td>
					<td v-if="dynamicValidateForm.approveflag2 == 0">分公司审核标志：未通过</td>
					<td v-if="dynamicValidateForm.approveflag2 == 1">分公司审核标志：通过</td>
        </tr>
        <tr>
          <td>分公司审核意见：{{ dynamicValidateForm.approvedesc2 }}</td>
          <td>分公司审核时间：{{ dynamicValidateForm.approvetime2 | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td v-if="dynamicValidateForm.approveflag3 == -2">总部财务审核结果：未提请审批</td>
					<td v-if="dynamicValidateForm.approveflag3 == -1">总部财务审核结果：审核中</td>
					<td v-if="dynamicValidateForm.approveflag3 == 0">总部财务审核结果：未通过</td>
					<td v-if="dynamicValidateForm.approveflag3 == 1">总部财务审核结果：通过</td>
        </tr>
        <tr>
          <td>总部财务审核意见：{{ dynamicValidateForm.approvedesc3 }}</td>
          <td>总部财务审核时间：{{ dynamicValidateForm.approvetime3 | formatDate("YYYY-MM-DD HH:mm:ss")  }}</td>
          <td>费用类型编号：{{ dynamicValidateForm.feetypekey }}</td>
        </tr>
        <tr>
          <td>费用类型名称：{{ dynamicValidateForm.feetypeval }}</td>
          <td>基表流水号：{{ dynamicValidateForm.objectid }}</td>
          <td>备注：{{ dynamicValidateForm.remark }}</td>
        </tr>
        <tr>
          <td>退费原因：{{ dynamicValidateForm.reasonsreason }}</td>
          <td>申请退费时间：{{ dynamicValidateForm.applytime | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td v-if="dynamicValidateForm.feebackflag == 0">退费标识：未退费</td>
					<td v-if="dynamicValidateForm.feebackflag == 1">退费标识：已退费</td>
        </tr>
        <tr>
          <td>学生姓名：{{ dynamicValidateForm.studentname }}</td>
          <td>手机号码：{{ dynamicValidateForm.mobile }}</td>
          <td>申请人姓名：{{ dynamicValidateForm.insertusername }}</td>
        </tr>
        <tr>
          <td>教学点审核人：{{ dynamicValidateForm.approveusername1 }}</td>
          <td>分公司审核人：{{ dynamicValidateForm.approveusername2 }}</td>
          <td>总部财务审核人：{{ dynamicValidateForm.approveusername3 }}</td>
        </tr>
        <tr>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
          <td>所属分公司编号：{{ dynamicValidateForm.companyorgcode }}</td>
          <td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
        </tr>
        <tr>
          <td>所属教学点编号：{{ dynamicValidateForm.schoolorgcode }}</td>
          <td>收据编号：{{ dynamicValidateForm.shoujucode }}</td>
					<td>流水号： {{dynamicValidateForm.id}}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>


<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";

import {
  studentfeeback_list,
  studentfeeback_approvecheck,
  studentfeeback_approvesave,
  studentfeeback_export,
  studentfeeback_exportexcel,
  studentfeeback_exportsave,
  studentmoneybackrecv_canbatchmoneybackrecv,
  studentmoneybackrecv_input,
  studentmoneybackrecv_save,
  studentmoneybackrecv_inputbatch,
  studentmoneybackrecv_savebatch,
  studentmoneybackrecv_list,
  studentfeeback_delete,
	studentfeeback_exportfeeback,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _feebackflag,
  _feebackflags,
  _bankorcash,
  _approveflags,
  _approveflag,
} from "@/assets/js/filedValueFlag";

import {
  _account_dialogresult,
  _moneyoutitem_dialogresult,
  _moneyouttype2_dialogresult,
} from "@/request/api/public";

export default {
  mixins: [part],
  name: "studentfeeback",
  components: { Export },
  props: {
    exportUrl: {
      default: () => studentfeeback_export,
    },
    exportexcelUrl: {
      default: () => studentfeeback_exportexcel,
    },
    exportsaveUrl: {
      default: () => studentfeeback_exportsave,
    },

    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    moneyoutitem_dialogresult: {
      default: () => _moneyoutitem_dialogresult,
    },
    moneyouttype2_dialogresult: {
      default: () => _moneyouttype2_dialogresult,
    },

  },
  inject: ["reload"],
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框
			key: '',
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        studentname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        backmoney: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        companyorgname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        schoolorgname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        shoujucode: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        feetypeval: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        bankorcash: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        accountname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        itemname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        type2name: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        zhaiyao: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        passflag: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        approvedesc: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
      },
      showAddModal: false,

      showApprovalModal: false,
      feebackflag: _feebackflag,
      feebackflags: _feebackflags,

      showFeebackModal: false, //退费框

      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTable: {},
      selectTableUrl: "",
      selectSearchForm: {},
      showAccountModal: false,
      showMoneyinitemModal: false, //支出明细框
      bankorcash: _bankorcash,

      showMoneyinitemModal2: false, //支出二级明细框
      showMoneybackModal: false, //查看退费记录

      approveflags: _approveflags,
      approveflag: _approveflag,
      showDetailModal: false,
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.appro.blur();
			}
		},
		visibleFee(e) {
			if(!e) {
				this.$refs.feeback.blur();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				mobile: null,
				approveflag1: null,
				feebackflag: null,
				schoolorgname:null,
				grade: null
			}
		},
		selectQuery() {
			this.selectSearchForm = {
				accountname: null,
				bankname: null,
				cardno: null,
				typename: null,
				type1name: null,
				name: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //导出 click
    btnExport() {
			confirmCallBack({
			  title: "提示",
			  content: "根据搜索条件进行导出。",
			  success: () => {
			    window.location.href =
			      this.$url.Freemarker +
			      studentfeeback_exportfeeback +
			      `?${createObjParams(this.searchForm)}`;
			  },
			  fail: () => {
			    messageTip({
			      message: "取消操作",
			      type: "info",
			    });
			  },
			});
    },
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentfeeback_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
				console.log(res,'这个是什么东西-----')
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },

    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //审批 click
    btnApproval() {
      selectCheck(this.multipleSelection, "审批", true, () => {
        this.approvecheck(this.multipleSelection.map((i) => i.id));
      });
    },
    //退费 click
    btnFeeBack() {
      selectCheck(this.multipleSelection, "退费", false, () => {
        this.checkFeeback();
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //删除 click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //提交退费 click
    onFeebackClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.feeBack();
        }
      });
    },
    //确认账户 click
    onConfirmAccount() {
      selectCheck(this.multipleSelection, "确认账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "cardno",
          this.multipleSelection[0].cardno
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showAccountModal = false;
				this.multipleSelection = [];
				this.selectQuery();
      });
    },
    //确认支出明细 click
    onConfirmMoneyinitem() {
      selectCheck(this.multipleSelection, "确认支出明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "itemname",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.itemid = this.multipleSelection[0].id;
        this.showMoneyinitemModal = false;
				this.multipleSelection = [];
				this.selectQuery();
      });
    },
    //确认支出二级明细 click
    onConfirmMoneyinitem2() {
      selectCheck(this.multipleSelection, "确认支出二级明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "type2name",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "typename",
          this.multipleSelection[0].typename
        );
        this.$set(
          this.dynamicValidateForm,
          "type1name",
          this.multipleSelection[0].type1name
        );
        this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
        this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
        this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
        this.showMoneyinitemModal2 = false;
				this.multipleSelection = [];
				this.selectQuery();
      });
    },
    //查看退费记录 click
    btnShow() {
      selectCheck(this.multipleSelection, "查看退费记录", false, () => {
        this.moneybackrecvList();
      });
    },

    //审批检查 api
    approvecheck(ids) {
      myRequest({
        method: "post",
        url: studentfeeback_approvecheck,
        data: this.$qs.stringify(
          {
            ids: ids,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.showApprovalModal = true;
          this.dynamicValidateForm = {};
        }
      });
    },
    //提交审批 click api
    onApprovalClick(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: studentfeeback_approvesave,
              data: this.$qs.stringify(
                {
                  leadertype: "03",
                  ids: this.multipleSelection.map((i) => i.id),
                  passflag: this.dynamicValidateForm.passflag,
                  approvedesc: this.dynamicValidateForm.approvedesc,
                },
                {
                  arrayFormat: "repeat",
                }
              ),
            },
            {
              modal: "showApprovalModal",
              isRefTableList: true,
              that: this,
            }
          );
        }
      });
    },
    //退费校验 api
    checkFeeback() {
      myRequest(
        {
          method: "post",
          url: studentmoneybackrecv_canbatchmoneybackrecv,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          that: this,
          methodName: "feeBackInput",
        }
      );
    },
    //退费路由页 api
    feeBackInput() {
      this.itemId = this.multipleSelection[0].id;
      myRequest({
        url: studentmoneybackrecv_input,
        data: {
          id: this.itemId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = Object.assign(
            res.data.data.tstudentmoneybackrecv,
            res.data.data.tmoneyout
          );
          this.dynamicValidateForm.schoolorgid =
            this.multipleSelection[0].schoolorgid;
          this.dynamicValidateForm.schoolorgname =
            this.multipleSelection[0].schoolorgname;
          this.dynamicValidateForm.companyorgid =
            this.multipleSelection[0].companyorgid;
          this.dynamicValidateForm.companyorgname =
            this.multipleSelection[0].companyorgname;
          delete this.dynamicValidateForm.deleteflag;
          this.showFeebackModal = true;
        }
      });
    },
    //保存退费 api
    feeBack() {
      myRequest(
        {
          url: studentmoneybackrecv_save,
          data: this.dynamicValidateForm,
        },
        {
          modal: "showFeebackModal",
          that: this,
          isRefTableList: true,
        }
      );
    },
    //查看退费记录 api
    moneybackrecvList() {
      myRequest({
        url: studentmoneybackrecv_list,
        data: {
          feebackid: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.selectTable = res.data.data;
          this.showMoneybackModal = true;
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: studentfeeback_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //切换选项表格页
    handleSelectChange(val, data, prop, page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选项表格 api（）
    onSelectData(url, modal, data, row, prop, page) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
	.base_table{
		 margin:auto;
	 }
</style>