<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="学生:">
            <el-input 
							v-model.trim="searchForm.qianzainame" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
					<el-form-item label="手机:">
					  <el-input 
							v-model.trim="searchForm.mobile" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="院校:">
					  <el-input 
							v-model.trim="searchForm.collegename1" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="年级:">
					  <el-input 
							v-model.trim="searchForm.grade" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="确认:">
            <el-select
              v-model="searchForm.isokflag"
							@keyup.enter.native="seach"
							@visible-change="visibleisok"
							ref="isok"
              placeholder="请选择"
              clearable
            >
							<el-option value="1" label="是"></el-option>
							<el-option value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="层次:">
            <el-select
              v-model="searchForm.levelval"
							@keyup.enter.native="seach"
							@visible-change="visibleLevelval"
							ref="levelref"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in leveltypeList"
                :key="item.key"
                :label="item.val"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
					<el-form-item label="项目类型:">
					  <el-select
					    v-model="searchForm.projecttype"
							@keyup.enter.native="seach"
							@visible-change="projectVisible"
							ref="projectref"
					    placeholder="请选择"
					    clearable
					  >
					    <el-option
					      v-for="item in educationList"
					      :key="item.key"
					      :label="item.val"
					      :value="item.val"
					    >
					    </el-option>
					  </el-select>
					</el-form-item>
					
					<el-form-item label="合作人:">
					  <el-input 
							v-model.trim="searchForm.followusername" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="专业:">
					  <el-input 
							v-model.trim="searchForm.majorname1" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="教学点:">
					  <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="录入日期起:">
						 <el-date-picker
								v-model="searchForm.t1"
								type="date"
								placeholder="选择日期"
								value-format="yyyy-MM-dd"
								>
						  </el-date-picker>
					</el-form-item>
					<el-form-item label="录入日期止:">
						<el-date-picker
							v-model="searchForm.t2"
							type="date"
							placeholder="选择日期"
							value-format="yyyy-MM-dd"
							>
						</el-date-picker>
					</el-form-item>
					<el-form-item label="招生年份:">
					  <el-select
					  	v-model="searchForm.year"
					  	@keyup.enter.native="seach"
					  	@visible-change="yearVisible"
					  	ref="yearRef"
					  	clearable
					  	>
								<el-option
								  v-for="item in yearList"
								  :key="item.key"
								  :label="item.label"
								  :value="item.label"
								/>
					  </el-select>
					</el-form-item>
        </el-form>
      </div>

      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :data="tableRes.list"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="姓名" align="center" width="120">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row.qianzaiid)" type="text">
						{{
							scope.row.qianzainame
						}}
					</el-button>
        </template>
      </el-table-column>
      <el-table-column label="手机号" align="center" width="150">
				<template slot-scope="scope" v-if="scope.row.mobile !== null">
					  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
					</template>
				</el-table-column>
      <el-table-column
        label="年级"
        prop="grade"
        align="center"
      ></el-table-column>
      <el-table-column
        label="院校"
        prop="collegename1"
        align="center"
				width="200px"
      ></el-table-column>
      <el-table-column
        label="专业"
        prop="majorname1"
        align="center"
				width="200px"
      ></el-table-column>
      <el-table-column
        label="层次"
        prop="levelval"
        align="center"
      ></el-table-column>
      <el-table-column label="确认" prop="isokflag" align="center" width="80">
        <template slot-scope="scope">{{
          available[scope.row.isokflag]
        }}</template>
      </el-table-column>
      <el-table-column
        label="合作人"
        prop="followusername"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="项目类型"
        prop="projecttype"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      >
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
		
		<div id="RightOffside" class="RightOffside" v-show="showDetailModal">
			<!-- 按钮组 -->
			<div class="Offside_btnBox">
				<div class="Offside_Btn Offside_CloseBtn" @click="handleShow"></div>
				<div class="Offside_Btn"
					v-for="(item,index) in tabList"
					:key="index"
					:class="{active:currentClass === index}"
					@click="toggleTab(index)"
				>
					{{item}}
				</div>
			</div>
			<div class="OrderStatusTips">
				<span class="pull-left text-blue">人员信息</span>
			</div>
			<!-- 切换栏 -->
			<div>
				<div class="OrderContentBox" v-show="currentTab === 0">
					<div class="order-card x-basic abase-card" style="width:800px;background-color: #D1EEEE; margin-bottom: 30px;">
						<div class="top_el">
							基本信息
						</div>
						<el-form>
							<table  class="tlist_el" style="width: 100%;" bgcolor="#D1EEEE" cellpadding="5px">
								<tbody>
									<tr>
										<td class="ltd_el">流水号：</td>
										<td class="ltd_tag">{{ vstudentpotential.id}}</td>
										<td class="ltd_el">姓名：</td>
										<td class="ltd_tag">{{ vstudentpotential.name }}</td>
										<td class="ltd_el">性别：</td>
										<td class="ltd_tag">{{ vstudentpotential.sexval }}</td>
										<td class="ltd_el"> 手机：</td>
										<td class="ltd_tag"> {{ vstudentpotential.mobile | phoneFilter()}} </td>
									</tr>
									<tr>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">户口所在地：</td>
										<td class="ltd_tag">{{ vstudentpotential.hukouaddr }}</td>
										<td class="ltd_el">政治面貌：</td>
										<td class="ltd_tag">{{ vstudentpotential.polival }}</td>
										<td class="ltd_el">民族：</td>
										<td class="ltd_tag">{{ vstudentpotential.nationval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">出生日期：</td>
										<td class="ltd_tag">{{ vstudentpotential.birthday | formatDate("YYYY-MM-DD")}}</td>
										<td class="ltd_el"> 层次：</td>
										<td class="ltd_tag"> {{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">证件号码：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcard }}</td>	
										<td class="ltd_el"> 正式学生：</td>
										<td class="ltd_tag"> {{ available[vstudentpotential.studentflag] }} </td>
									</tr>
									<tr>
										<td class="ltd_el">操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.opusername }}</td>
										<td class="ltd_el"> 录入时间：</td>
										<td class="ltd_tag">
										{{
										  vstudentpotential.inserttime
										    | formatDate("YYYY-MM-DD HH:mm:ss")
										}}
										</td>
										<td class="ltd_el">最后操作时间：  </td>
										<td class="ltd_tag">{{
										    vstudentpotential.lastinserttime
										      | formatDate("YYYY-MM-DD HH:mm:ss")
										  }} </td>
										<td class="ltd_el">意向专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.wantcollegemajor }}</td>
									</tr>
									<tr>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">毕业时间：</td>
										<td class="ltd_tag">
											{{
									      vstudentpotential.graduatetime
									        | formatDate("YYYY-MM-DD")
									    }}</td>
										<td class="ltd_el">所属分公司：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyorgname }}</td>
										<td class="ltd_el">所属教学点：</td>
										<td class="ltd_tag">{{ vstudentpotential.schoolorgname }}</td>
									</tr>
									<tr>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">学历：</td>
										<td class="ltd_tag">{{ vstudentpotential.eduval }}</td>
										<td class="ltd_el">专科毕业证书号：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankeno }}</td>
									</tr>
									<tr>
										<td class="ltd_el">专科毕业专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankemajor }}</td>
										<td class="ltd_el">毕业时间</td>
										<td class="ltd_tag"> 
											{{
											vstudentpotential.graduatetime | formatDate("YYYY-MM-DD")
											}}
										</td>
										<td class="ltd_el"></td>
										<td class="ltd_tag"></td>
										<td class="ltd_el"></td>
										<td class="ltd_tag"> 
										</td>
									</tr>
								</tbody>
							</table>
						</el-form>
					</div>
				</div>
			</div>
		</div>	
		<el-dialog
		  class="btn-2b5a95"
		  :title="dialogTitle"
			v-dialogDrag
			:close-on-click-modal='false'
		  :visible.sync="addshowDetailList"
		  v-if="addshowDetailList"
		  append-to-body
		  width="80%"
		>
			<div class="abase">
				<div class="top">
						基本信息
				</div>
				<el-form
					:label-position="labelPosition"
					label-width="110px"
					:model="dynamicValidateForm"
					ref="dynamicValidateForm"
					:rules="rules">
					<table class="tlist" style="width: 100%;" cellpadding="5px;">
						<tr>
							<td colspan="2">
								<div class="rig heigsuperrig">
									<div>
										<el-form-item prop="studentcode" label="考生号:">
											<el-input v-model.trim="dynamicValidateForm.studentcode" clearable/>
										</el-form-item>
									</div>
									<div>
									</div>
								</div>
							</td>
							<td colspan="2">
								<div class="rig heigsuper flex-xl">
									<div>
										<el-form-item prop="name" label="姓名:">
											<el-input v-model.trim="dynamicValidateForm.name" clearable :disabled="adddisable"/>
										</el-form-item>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="rig heigsuper">
									<el-form-item prop="sexkey" label="性别:" class="sex">
										<el-radio-group v-model="dynamicValidateForm.sexkey">
										  <el-radio label="1">男</el-radio>
										  <el-radio label="2">女</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
							</td>
							<td colspan="2">
								<div class="heigsuperrig">
									<el-form-item label="民族:">
										<el-select 
										v-model="dynamicValidateForm.nationkey" 
										clearable
										>
										  <el-option
										    v-for="(item, index) in nationList"
										    :key="index"
										    :label="item.val"
										    :value="item.key"
												clearable
										  />
										</el-select>
									</el-form-item>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<div class="rig flex-xl heigsuper brithday--ww">
									<div>
										<el-form-item label="出生日期:" style="width: 250px;">
										  <el-date-picker
										    type="date"
										    format="yyyy-MM-dd"
										    value-format="yyyy-MM-dd 04:00:00"
										    v-model="dynamicValidateForm.birthday"
										    placeholder="请选择"
												clearable
										  />
										</el-form-item>
									</div>
									<div class="title-font">
										(格式:1990-01-01)
									</div>
								</div>
							</td>
							<td colspan="4">
								<div class="heigsuper" style="margin-top: -26px;">
								<el-form-item label="政治面貌:" >
									<el-select
									v-model="dynamicValidateForm.polikey" 
									clearable
									>
									  <el-option
									    v-for="item in policyList"
									    :key="item.key"
									    :value="item.key"
									    :label="item.val"
											clearable
									  />
									</el-select>
								</el-form-item>
								</div>
							</td>
						</tr>
						<tr>
							<td width="300">
								<div class="rig heigsuperrig">
									<el-form-item prop="levelval" label="考生类别：">
									    <el-select 
									        v-model="dynamicValidateForm.levelval" 
									        clearable
									        @change="isSelect"
									        >
									        <el-option
									            v-for="(item, index) in arr_province"
									            :key="index"
									            :label="item"
									            :value="item"
									            clearable
									        />
									    </el-select>
									</el-form-item>
								</div>
							</td>
							<td class="kelei_pro" colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="registeredSubjects" label="报考科类：">
											<el-select 
												v-model="dynamicValidateForm.registeredSubjects" 
												clearable
											>
												<el-option
													v-for="(obj, index) in baokaokeleiList"
													:key="index"
													:label="obj.val"
													:value="obj.val"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div class="title-font">
											<font color="red">(报考专升本的外语类专业请选择"文史类")</font>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td style="height: 38px;">
								<div class="rig ex-col">
									<div>
										<el-form-item label=" 考试县区：">
											<el-select
												v-model="dynamicValidateForm.examination" 
												clearable
											 >
												<el-option
													v-for="(areaflag, index) in areaflags"
													:key="index"
													:label="areaflag.label"
													:value="areaflag.label"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>	
								</div>
							</td>
							<td style="height: 38px;" colspan="3">
								<div class="rig ex-col">
									<el-form-item prop="course" label="考试科目组：">
									 <el-select 
										v-model="dynamicValidateForm.course" 
										@change="handleCourse"
										clearable>
									 	<el-option
									 		v-for="(item, index) in typeOptions"
									 		:key="index"
									 		:label="item"
									 		:value="item"
									 		clearable
									 	/>
									 </el-select>
									</el-form-item>
									</div>
								</td>
							</td>
						</tr>
						<tr>
							<td style="height: 38px;">
								<div class="rig ex-col">
									<div>
										<el-form-item  label="考前学历:">
											<el-select 
												v-model="dynamicValidateForm.eduval" 
												@change="changeedual"
												clearable
											>
												<el-option
													v-for="(edualForm, index) in preExaminationList"
													:key="index"
													:label="edualForm.val"
													:value="edualForm"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>
							
								</div>
							</td>
							<td colspan="3">
								<div class="rig ex-col">
									<div>
										<el-form-item  label=" 户口所在地：">
											<el-select 
												v-model="dynamicValidateForm.hukouaddr"
												@change="changeHukouaddr"
												clearable
											 >
												<el-option
													v-for="(hukou, index) in registeredResidenceList"
													:key="index"
													:label="hukou.val"
													:value="hukou"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
									<div>
									</div>
								
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="heigsuperrig">
									<el-form-item prop="idcardtypeval" label=" 证件类型：">
										<el-select 
										v-model="dynamicValidateForm.idcardtypeval" 
										@change="changeIdcardtypeval"
										clearable
										>
											<el-option
												v-for="(idcardtype, index) in idcardtypeList"
												:key="index"
												:label="idcardtype.val"
												:value="idcardtype"
												clearable
											/>
										</el-select>
									</el-form-item>
								</div>
							</td>
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="idcard" label=" 证  件  号：">
											<el-input 
												v-model.trim="dynamicValidateForm.idcard" 
												@blur="getIdcard(dynamicValidateForm.idcard)"
												clearable
												maxlength="18"
												/>
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">证件号码一经注册不能修改</font>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td  class="ex-col">
								<div class="heigsuperrig">
										<el-form-item prop="graduateschool" label="毕业学校：">
											<el-input v-model.trim="dynamicValidateForm.graduateschool" clearable/>
										</el-form-item>
								</div>
							</td>
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="graduatetime" label=" 毕业年月日：">
										  <el-date-picker
										    type="date"
										    v-model="dynamicValidateForm.graduatetime"
										    placeholder="请选择"
												clearable
										  />
										</el-form-item>
									</div>
									<div class="title-font">
										(格式:2010-09)
									</div>
								</div>
							</td>
						</tr>
						<tr class="zkdisplay">
							<td colspan="3" class="ex-col">
								<div class="rig heigsuperrig">
									<el-form-item prop="zhuankemajor" label=" 毕业专业：">
										<el-input v-model.trim="dynamicValidateForm.zhuankemajor" clearable />
									</el-form-item>
								</div>
							</td>
						</tr>
						<tr class="zkdisplay">
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="zhuankeno" label=" 毕业证书号：">
										  <el-input v-model.trim="dynamicValidateForm.zhuankeno" clearable />
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">(未取得专科毕业证的专科考生请输“待定”)</font>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<div class="rig flex-xl heigsuper">
									<div>
										<el-form-item prop="mobile" label="移动电话：">
										  <el-input v-model.trim="dynamicValidateForm.mobile" maxlength="11" clearable :disabled="adddisable"/>
										</el-form-item>
									</div>
									<div class="title-font">
										<font color="red">(此手机号码用于绑定您的网上报名号)</font>
									</div>
								</div>
							</td>
						</tr>
				</table>
				</el-form>
				</div>
				<div class="abase" style="margin-top: 30px; width: 800px;">
					<div class="top">报考志愿</div>
					<el-form 
						:model="dynamicValidateForm"
						ref="dynamicValidateForm"
						:rules="rules">
					<table class="tlist" style="width: 100%; text-align: center; background-color: #87ceeb;">
						<thead>
							<tr>
								<th style="font-size: 14px;">
									报考院校
								</th>
								<th colspan="8" style="font-size: 14px;">
									报考专业
								</th>
							</tr>
							<tr>
								<th>
									<div class="ex-col_el">
										<el-form-item prop="grade" label="年级:">
											<el-select
												v-model="dynamicValidateForm.grade"
												@change="onChangeGrade($event, 'dynamicValidateForm')"
												clearable
											 >
												<el-option
													v-for="(item, index) in gradeList"
													:key="index"
													:label="item.val"
													:value="item.val"
													clearable
												/>
											</el-select>
										</el-form-item>
									</div>
								</th>
								<th>
									<div class="ex-col_el">
										<el-form-item prop="projecttype" label="项目类型：">
										  <el-select 
												v-model="dynamicValidateForm.projecttype"
												style="width: 100px;"
												@change="proChange"
												clearable
											 >
										  	<el-option
										  		v-for="(project, index) in educationList"
										  		:key="index"
										  		:label="project.val"
										  		:value="project"
										  		clearable
										  	/>
										  </el-select>
										</el-form-item>
									</div>
								</th>
								<th>
									<div class="ex-col_el">
										<el-form-item
										  prop="studytypeval"
											label="学习形式:"
										>
										  <el-select 
												v-model="dynamicValidateForm.studytypeval"
												clearable
												@change="studeyChange"
												clearable
												>
										    <el-option
										      v-for="(study,index) in studyTypeList"
										      :key="index"
										      :label="study.val"
										      :value="study"
										    ></el-option>
										  </el-select>
										</el-form-item>
									</div>
								</th>
							</tr>
						</thead>
						<tbody id="zsb" style="background-color: rgb(209, 238, 238);">
								<tr id="zsb">
									<td>
										<div class="ex-col_el">
											<el-form-item label="招生年份:" prop="year">
											  <el-date-picker
											    type="year"
											    value-format="yyyy"
											    format="yyyy"
											    v-model="dynamicValidateForm.year"
											    placeholder="请选择年份"
											  />
											</el-form-item>
										</div>
									</td>
									<td>
										<div class="ex-col_el">
											<el-form-item prop="collegename1"label="报考院校1:" >
											  <el-input disabled v-model="dynamicValidateForm.collegename1" />
											</el-form-item>
										</div>
									</td>
									<td>
										<div class="ex-col_el">
											<el-form-item prop="collegenamecode1" label="院校代码1：">
												<el-input v-model.trim="dynamicValidateForm.collegenamecode1" clearable />
											</el-form-item>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="ex-col_el">
											<el-form-item prop="majorname1" label="报考专业1:">
											  <el-input disabled v-model="dynamicValidateForm.majorname1" style="width: 180px;" />
											  <el-button
											    type="primary"
											    class="select-btn"
											    @click="
													reportSelectBtn(
													  collegefeestandard_dialogresult,
													  'showCollegemajorModal',
													  {
													    schoolorgid: dynamicValidateForm.schoolorgid,
													    grade: dynamicValidateForm.grade,
													    majorlevelval: dynamicValidateForm.levelval,
													    projectname: dynamicValidateForm.projecttype,
															studytypeval: dynamicValidateForm.studytypeval,
													  }
													)
											    "
											    >选择</el-button
											  >
											</el-form-item>
										</div>
									</td>
									<td>
										<div class="ex-col_el">
											<el-form-item prop="majornamecode1" label="专业代码1：">
												<el-input v-model.trim="dynamicValidateForm.majornamecode1" clearable style="width: 130px;" />
											</el-form-item>
										</div>
									</td>
									<td>
										<div class="ex-col_el">
											<el-form-item prop="collegenamecode2" label="第二志愿院校代码:">
												<el-input v-model.trim="dynamicValidateForm.collegenamecode2" clearable/>
											</el-form-item>
										</div>
									</td>
								</tr>
								<tr id="zsb">
									<td>
										<div class="ex-col_el">
											<el-form-item prop="majornamecode2" label="第二志愿专业代码:">
												<el-input v-model.trim="dynamicValidateForm.majornamecode2" clearable />
											</el-form-item>
										</div>
									</td>
									<td>
										<div class="ex-col_el">
											<el-form-item prop="majorname2" label="第二志愿专业:">
											  <el-input disabled v-model="dynamicValidateForm.majorname2" style="width: 180px;" />
											  <el-button
											    type="primary"
											    class="select-btn"
											    @click="
													reportSelectBtn(
													  collegefeestandard_dialogresult,
													  'showMajor2',
													  {
													    schoolorgid: dynamicValidateForm.schoolorgid,
													    grade: dynamicValidateForm.grade,
													    majorlevelval: dynamicValidateForm.levelval,
													    projectname: dynamicValidateForm.projecttype,
															studytypeval: dynamicValidateForm.studytypeval,
													  }
													)
											    "
											    >选择</el-button
											  >
											</el-form-item>
										</div>
									</td>
									<td>
										<div class="ex-col_el">
											<el-form-item prop="collegename2" label="第二志愿专业院校:">
											  <el-input disabled v-model="dynamicValidateForm.collegename2" />
											</el-form-item>
										</div>
									</td>
								</tr>
						</tbody>
					</table>
				</el-form>
			</div>
			<center style="padding-top: 30px;">
				<el-button type="primary"
				 @click="submitConent('dynamicValidateForm')">保存资料</el-button>
			</center>
		</el-dialog>
    <!--选择层次-->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择院校专业："
      :visible.sync="showSelectCollegeModal"
      v-if="showSelectCollegeModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCollegeMajor">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
          >
            <el-form-item label="院校名称:" prop="collegename">
              <el-input v-model="selectSearchForm.collegename" clearable />
            </el-form-item>
            <el-form-item label="专业名称:" prop="collegemajorname">
              <el-input v-model="selectSearchForm.collegemajorname" clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        ref="moneyTable"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="招生年级"
          prop="enrollyearval"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          label="院校"
          prop="collegename"
          align="center"
          width="350"
        >
        </el-table-column>
        <el-table-column
          label="专业"
          prop="collegemajorname"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          label="层次"
          prop="majorlevelval"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="150"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
      ></el-pagination>
    </el-dialog>
    <!--选择院校专业 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择院校专业"
      :visible.sync="showCollegemajorModal"
      v-if="showCollegemajorModal"
      width="1200px"
      append-to-body
    >
      <el-button type="primary" class="confirm" @click="reportDataComfirm"
        >确 定</el-button
      >
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								     schoolorgid: dynamicValidateForm.schoolorgid,
								     grade: dynamicValidateForm.grade,
								     majorlevelval: dynamicValidateForm.levelval,
								     projectname: dynamicValidateForm.projecttype,
								     studytypeval: dynamicValidateForm.studytypeval,
								    },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="collegemajorname">
              <el-input 
								v-model.trim="selectSearchForm.collegemajorname" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      grade: dynamicValidateForm.grade,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								      studytypeval: dynamicValidateForm.studytypeval,
								    },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
            <el-form-item label="专业层次:" prop="majorlevelval">
              <el-input 
								v-model.trim="selectSearchForm.majorlevelval" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                   schoolorgid: dynamicValidateForm.schoolorgid,
                   grade: dynamicValidateForm.grade,
                   majorlevelval: dynamicValidateForm.levelval,
                   projectname: dynamicValidateForm.projecttype,
                   studytypeval: dynamicValidateForm.studytypeval,
                  },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    grade: dynamicValidateForm.grade,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                    studytypeval: dynamicValidateForm.studytypeval,
                  },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="hanleSearchForm">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @select="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="年级" prop="grade" align="center" />
        <el-table-column
          label="院校"
          prop="collegename"
          align="center"
          width="180"
        />
        <el-table-column label="专业" prop="collegemajorname" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        :current-page="selectPageInfo.pageindex"
        :current-size="selectPageInfo.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  grade: dynamicValidateForm.grade,
                  majorlevelval: dynamicValidateForm.levelval,
                  projectname: dynamicValidateForm.projecttype,
                  studytypeval: dynamicValidateForm.studytypeval,
                },
                selectSearchForm
              )
            )
        "
      />
    </el-dialog>
    <!--选择校外教学点 -->
    <el-dialog
      class="btn-2b5a95"
      @close="onCloseSelect"
			v-dialogDrag
			:close-on-click-modal='false'
      title="选择校外教学点"
      :visible.sync="showCollegecoporg"
      v-if="showCollegecoporg"
      width="1200px"
      append-to-body
    >
      <el-button type="primary" class="confirm" @click="onComfirmCollegecoporg"
        >确 定</el-button
      >
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校名称:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    enrollyearval: dynamicValidateForm.year,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
            <el-form-item label="函授站名称:" prop="schoolorgname">
              <el-input 
								v-model.trim="selectSearchForm.schoolorgname"
								 @keyup.enter.native="onSelectSearchClick(
								   selectTableUrl,
								   null,
								   Object.assign(
								     {
								       schoolorgid: dynamicValidateForm.schoolorgid,
								       enrollyearval: dynamicValidateForm.year,
								       majorlevelval: dynamicValidateForm.levelval,
								       projectname: dynamicValidateForm.projecttype,
								     },
								     selectSearchForm
								   )
								 )"
								clearable />
            </el-form-item>
            <el-form-item label="上课地点:" prop="classname">
              <el-input 
								v-model.trim="selectSearchForm.classname"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      enrollyearval: dynamicValidateForm.year,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								    },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    enrollyearval: dynamicValidateForm.year,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="hanleSearchForm">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        ref="multipleTable"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="院校名称" prop="collegename" align="center" />
        <el-table-column label="函授站名称" prop="schoolorgname" align="center" />
        <el-table-column label="上课点名称" prop="classname" align="center" />
      </el-table>


      <el-pagination
        background
        :current-page="selectPageInfo.pageindex"
        :current-size="selectPageInfo.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.totalcount
        "
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  collegename: dynamicValidateForm.collegename1,
                },
                selectSearchForm
              )
            )
        "
      />
    </el-dialog>
		<!--选择院校专业2报考2 -->
		<el-dialog
		  class="btn-2b5a95"
			v-dialogDrag
		  @close="onCloseSelect"
			:close-on-click-modal='false'
		  title="第二志愿院校专业选择"
		  :visible.sync="showMajor2"
		  v-if="showMajor2"
		  width="1200px"
		  append-to-body
		>
		  <el-button type="primary" class="confirm" @click="onComfirmShow"
		    >确 定</el-button
		  >
		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="院校:" prop="collegename">
		          <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								     schoolorgid: dynamicValidateForm.schoolorgid,
								     grade: dynamicValidateForm.grade,
								     majorlevelval: dynamicValidateForm.levelval,
								     projectname: dynamicValidateForm.projecttype,
								     studytypeval: dynamicValidateForm.studytypeval,
								    },
								    selectSearchForm
								  )
								)"
								clearable />
		        </el-form-item>
		        <el-form-item label="专业:" prop="collegemajorname">
		          <el-input 
								v-model.trim="selectSearchForm.collegemajorname" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      grade: dynamicValidateForm.grade,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								      studytypeval: dynamicValidateForm.studytypeval,
								    },
								    selectSearchForm
								  )
								)"
								clearable />
		        </el-form-item>
		        <el-form-item label="专业层次:" prop="majorlevelval">
		          <el-input 
								v-model.trim="selectSearchForm.majorlevelval" 
								@keyup.enter.native="onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign(
		              {
		               schoolorgid: dynamicValidateForm.schoolorgid,
		               grade: dynamicValidateForm.grade,
		               majorlevelval: dynamicValidateForm.levelval,
		               projectname: dynamicValidateForm.projecttype,
		               studytypeval: dynamicValidateForm.studytypeval,
		              },
		              selectSearchForm
		            )
		          )"
								clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="
		          onSelectSearchClick(
		            selectTableUrl,
		            null,
		            Object.assign(
		              {
		               schoolorgid: dynamicValidateForm.schoolorgid,
		               grade: dynamicValidateForm.grade,
		               majorlevelval: dynamicValidateForm.levelval,
		               projectname: dynamicValidateForm.projecttype,
		               studytypeval: dynamicValidateForm.studytypeval,
		              },
		              selectSearchForm
		            )
		          )
		        "
		        >搜索</el-button
		      >
					<el-button type="primary" @click="hanleSearchForm">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @select="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="年级" prop="grade" align="center" />
		    <el-table-column
		      label="院校"
		      prop="collegename"
		      align="center"
		      width="180"
		    />
		    <el-table-column label="专业" prop="collegemajorname" align="center" />
		    <el-table-column label="层次" prop="majorlevelval" align="center" />
		    <el-table-column
		      label="所属教学点"
		      prop="schoolorgname"
		      align="center"
		    />
		  </el-table>
		  <el-pagination
		    background
		    :current-page="selectPageInfo.pageindex"
		    :current-size="selectPageInfo.pagesize"
		    layout="total, prev, pager, next, jumper"
		    :total="
		      selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
		    "
		    @current-change="
		      (val, data) =>
		        handleSelectChange(
		          val,
		          Object.assign(
		            {
		             schoolorgid: dynamicValidateForm.schoolorgid,
		             grade: dynamicValidateForm.grade,
		             majorlevelval: dynamicValidateForm.levelval,
		             projectname: dynamicValidateForm.projecttype,
		             studytypeval: dynamicValidateForm.studytypeval,
		            },
		            selectSearchForm
		          )
		        )
		    "
		  />
		</el-dialog>
		<Export
		  :exportTitle="'学历继续教育'"
		  :transferDialog="transferDialog"
		  :exportUrl="exportUrl"
		  :exportexcelUrl="exportexcelUrl"
		  :exportsaveUrl="exportsaveUrl"
		  :searchForm="searchForm"
		  @onCloseExport="onCloseExport"
		></Export>
		<Import
		  :importDialog="importDialog"
		  :importSaveUrl="importSaveUrl"
		  @onCloseImport="onCloseImport"
		  @getDataList="getDataList"
		  :downloadUrl="downloadUrl"
		></Import>
  </div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
	student_delete2,
	student_importsave2,
	student_exportstudet2,
	student_importsave,
  student_list2,
  student_input2,
  student_save2,
  student_delete,
  student_checkincollect,
  student_incollect,
  student_savecollect,
  student_checkapplyrefund,
  student_applyrefund,
  studentfeeback_savestudent,
  student_changeflag,
  studentpotential_show,
  student_checkprintshouju,
  student_printshouju,
	student_printshoujuPaper,
	discount_save,
	collegesrceivable_checkprintshouju,
	student_export,
	student_exportexcel,
	student_exportsave,
} from "@/request/api/allChildrenProject";
import {
  dic_combox,
  _allCompany,
  _allSchool,
  _moneyinitem_dialogresult,
  _moneyintype2_dialogresult,
  _account_dialogresult,
  _shouju_getshoujuinfo,
  _enrollplandetail_dialogresult,
  _collegefeestandard_dialogresult,
  _collegecoporg_dialogresult,
	 _fileupload_save, 
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _formalStudent,
  _formalStudents,
  _bankorcash,
  _chongzhanflag,
  _available,
  _paidTypes,
  _projectTypes,
	_discount,
	_approveflags,
	_passflags,
	_discountYear,
	areaflag,
	_isAppselect, 
} from "@/assets/js/filedValueFlag";
import { _refundForm } from "@/assets/js/initTableData";
import	Export from '@/components/Export/index.vue';
import Import from "@/components/Import/index.vue";
export default {
  mixins: [part],
  name: "student",
  components: { Export, Import },
  props: {
    moneyinitem_dialogresult: {
      default: () => _moneyinitem_dialogresult,
    },
    moneyintype2_dialogresult: {
      default: () => _moneyintype2_dialogresult,
    },
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    enrollplandetail_dialogresult: {
      default: () => _enrollplandetail_dialogresult,
    },
    paidTypes: {
      default: () => _paidTypes,
    },
    collegefeestandard_dialogresult: {
      default: () => _collegefeestandard_dialogresult,
    },
    collegecoporg_dialogresult: {
      default: () => _collegecoporg_dialogresult,
    },
		exportUrl: {
		  default: () => student_export,
		},
		exportexcelUrl: {
		  default: () =>  student_exportexcel,
		},
		exportsaveUrl: {
		  default: () => student_exportsave,
		},
  },
  data() {
    return {
      checkfield: (rule, value, callback) => {
        if (!(value + "")) {
          return callback(new Error("必填字段"));
        } else {
          if (isNaN(Number(value))) {
            callback(new Error("请输入数字值"));
          } else {
            callback();
          }
        }
      },
			followuserid: null,
			srcList: [],
			adddisable: false,//禁用
			studentcode: null,
			dialogTitle: "",
			showMajor2: false,
			importSaveUrl: "",
			typeOptions:[],
			downloadUrl: "",
			importDialog: false, //导入框
			educationList: [],
			showCollegecoporg2: false,
			discountYear: _discountYear,
			discounttypeList: _discount,
			labelPosition: 'left',//右对齐
			currentTab: 0,
			currentClass: 0, 
			transferDialog: false,//导出框
			typeOptions: [],
			areaflags: areaflag,
			languageTypeList:[],//语言种类
			arr_province: ['中专','高升专','高升本','专升本','研究生'],
			brandObj: _isAppselect,
			tabList:['个人档案信息'],
      bankorcash: _bankorcash, // 银行现金
      itemId: null, //首表格项id
      rowItem: null, //表格项
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      available: _available,
      chongzhanflag: _chongzhanflag,
      showAddModal: false, //显示表单框
      showSchoolTable: false, //显示学院表格
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      showSelectCollegeModal: false, // 院校专业弹框
      rules: {
				registeredSubjects: [{ trigger: "blur", message: "必填字段", required: true }],
				studentcode: [{ trigger: "blur", message: "必填字段", required: true }],
        studytypeval: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				registrationpointval: [{ trigger: "blur", message: "必填字段", required: true }],
				discounttype:[
          { trigger: "blur", message: "必填字段", required: true },
        ],
				iyear:[
          { trigger: "blur", message: "必填字段", required: true },
        ],
				guakaoschoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				course: [{ trigger: "blur", message: "必填字段", required: true }],
				graduatetime:[{ trigger: "blur", message: "必填字段", required: true }],
				zhuankeno:[{ trigger: "blur", message: "必填字段", required: true }],
				zhuankemajor:[{ trigger: "blur", message: "必填字段", required: true }],
				graduateschool:[{ trigger: "blur", message: "必填字段", required: true }],
				eduval:[{ trigger: "blur", message: "必填字段", required: true }],
				hukouaddr:[{ trigger: "blur", message: "必填字段", required: true }],
				birthday: [{ trigger: "blur", message: "必填字段", required: true }],
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        sexkey: [{ trigger: "blur", message: "必填字段", required: true }],
        nationkey: [{ trigger: "blur", message: "必填字段", required: true }],
        polikey: [{ trigger: "blur", message: "必填字段", required: true }],
        homeaddr: [{ trigger: "blur", message: "必填字段", required: true }],
        homezipcode: [{ trigger: "blur", message: "必填字段", required: true }],
        job: [{ trigger: "blur", message: "必填字段", required: true }],
        idcard: [
									{ trigger: "blur", message: "必填字段", required: true },
								],
        year: [{ trigger: "blur", message: "必填字段", required: true }],
        grade: [{ trigger: "blur", message: "必填字段", required: true }],
        collegename1: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        majorname1: [{ trigger: "blur", message: "必填字段", required: true }],
        levelval: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        qianzainame: [{ trigger: "blur", message: "必填字段", required: true }],
        shoujucode: [{ trigger: "blur", message: "必填字段", required: true }],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        shouldrecvmoney: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        realrecvmoney: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        typem2oneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        itemmoneyinitemname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				discountamount: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				classesname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				mobile: [
					{
					  required: true,
					  trigger: "blur",
					},
					{
						pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[123456789]|17[01235678]|18[0-9]|19[0123456789])\d{8}$/,
						 message: "请输入正确的手机号码"
					}
					],
				projecttype:[{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      projecttypeList: _projectTypes, // 项目类型数组
      studyTypeList: [], // 学习形式
      nationList: [], // 民族
      policyList: [], //政治面貌
      enrollyearList: [], //招生年份
      leveltypeList: [], //层次类型
      companyList: [], //所有分公司
      schoolList: [], //所有教学点
      policyList: [],
      studentfromtypeList: [],
      intentionList: [],
      enrollyearList: [],
      gradeList: [],
			vstudent:{},
			vmoneyinlist:[],
      showRefundModal: false, //退费框

      //收费表单
      showEnrolModal: false, //收费框
      selectType: null,
      selectTableUrl: null,
      selectTableType: null,
      selectTable: {},
      showSelectItemModal: false, // 明细弹框
      showSelectTypeModal: false, // 二级弹框
      showSelectAccountModal: false, // 银行弹框
      showCollegemajorModal: false, // 选择院校专业
      showCollegecoporg:false,//校外教学点
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页

      selectSchoolorgid: null,

      formalStudent: _formalStudent,
      formalStudents: _formalStudents,

      studyTypes: [], //学习形式

      formalStudent: _formalStudent,
      formalStudents: _formalStudents,

      tabIData: null, //不同tab栏数据类型可能不同
      detailData: {},
			baokaokeleiList: [],
      mapbean: {}, // 存储搜索值

      showPrintReceipt: false, //打印收据
      // 查看详情
      showDetailModal: false,
      vstudentpotential: {},
      showDetailList: {},
			addshowDetailList:false,//报读学历
      activeName: "vstudentpotential",
      tabId: null,
			showDeliModal: false,
      fds: new FormData(),
			fromtypeList: [],
			key: '',
			studentfromtypeList: [],
			registrationPointList: [],
			idphoto: "",
			yearList: [
				{ value: '0', label: '2019'},
				{ value: '1', label: '2020'},
				{ value: '2', label: '2021'},
				{ value: '3', label: '2022'},
				{ value: '4', label: '2023'},
				{ value: '5', label: '2024'},
				{ value: '6', label: '2025'}
			],
    };
  },
	watch: {
		 '$route' : "getDataList" 
	},
  created() {
    this.getDataList();
    this.nation();
    this.leveltype();
    this.studyType();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		this.dic_combox({
		  that: this,
		  typecode: "idcardtype",
		  list: "idcardtypeList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "registrationPoint",
		  list: "registrationPointList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "language",
		  list: "languageTypeList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "nation",
		  list: "nationList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "occupation",
		  list: "occupationList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "baokaokelei",
		  list: "baokaokeleiList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "studytypeval",
		  list: "studytypevalList",
		});
    this.dic_combox({
      that: this,
      typecode: "policy",
      list: "policyList",
    });
    this.dic_combox({
      that: this,
      typecode: "studentfromtype",
      list: "studentfromtypeList",
    });

    this.dic_combox({
      that: this,
      typecode: "intention",
      list: "intentionList",
    });
    this.dic_combox({
      that: this,
      typecode: "enrollyear",
      list: "enrollyearList",
    });
    this.dic_combox({
      that: this,
      typecode: "grade",
      list: "gradeList",
    });
		this.dic_combox({
		  that: this,
		  typecode: "projecttype",
		  list: "projecttypeList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "leveltype",
		  list: "leveltypeList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "preExamination",
		  list: "preExaminationList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "registeredResidence",
		  list: "registeredResidenceList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "fromtype",
		  list: "fromtypeList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "education",
		  list: "educationList",
		});
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild",'dic_combox']),
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(prop) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.fds = new FormData();
          this.dynamicValidateForm[prop] = res.data.url;
        }
      });
    },
		reset() {
			this.searchForm = {
				qianzainame:null,
				grade: null,
				isokflag: null,
				levelval: null,
				projecttype: null,
				mobile: null,
				followusername: null,
				collegename1: null,
				majorname1: null,
				schoolorgname: null,
				t1: null,
				t2: null,
				fromtypeval: null,
				studentfromtypeval: null,
				year: null,
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleisok(e) {
			if(!e) {
				this.$refs.isok.blur();
			}
		},
		visibleLevelval(e) {
			if(!e) {
				this.$refs.levelref.blur();
			}
		},
		projectVisible(e) {
			if(!e) {
				this.$refs.projectref.blur();
			}
		},
		fromtyVisible(e) {
			if(!e) {
				this.$refs.fromtyref.blur();
			}
		},
		yearVisible(e) {
			if(!e) {
				this.$refs.yearRef.blur();
			}
		},
		selectQuery() {
			this.selectSearchForm = {
				accountname: null,
				bankname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, {schoolorgid: this.dynamicValidateForm.schoolorgid});
		},
		handleselect() {
			this.selectSearchForm = {
				name: null,
				type1name: null,
				typename: null
			}
		},
		changeRegistra(registraval){
			this.dynamicValidateForm.registrationpointval = registraval.val;
		},
		changeedual(edualForm){
			this.dynamicValidateForm.eduval = edualForm.val;
		},
		changeHukouaddr(hukou){
			this.dynamicValidateForm.hukouaddr = hukou.val;
		},
		changeIdcardtypeval(idcardtype){
			this.dynamicValidateForm.idcardtypeval = idcardtype.val;
		},
		proChange(project){
			this.dynamicValidateForm.projecttype = project.val;
		},
		studeyChange (study){
			this.dynamicValidateForm.studytypeval = study.val;
			console.log(this.dynamicValidateForm.studytypeval, '这个是studytypeval')
			this.dynamicValidateForm.studytypekey = study.key;
			console.log(this.dynamicValidateForm.studytypekey, '这个是studytypekey')
		},
		changeIdcardtypeval(idcardtype){
			this.dynamicValidateForm.idcardtypeval = idcardtype.val;
		},
		selectHanle() {
			this.handleselect();
			this.onSelectData(this.selectTableUrl, null, null);
		},
		handleCourse() {
			this.$forceUpdate();
		},
		handleForm() {
			this.selectSearchForm = {
				collegename: null,
				collegemajorname: null,
				majorlevelval: null,
				schoolorgname: null,
				classname: null
			}
		},
		hanleSearchForm() {
			this.handleForm();
			this.onSelectData(this.selectTableUrl, null,Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  grade: dynamicValidateForm.grade,
                  majorlevelval: dynamicValidateForm.levelval,
                  projectname: dynamicValidateForm.projecttype,
                  studytypeval: dynamicValidateForm.studytypeval,
                }
              ));
		},
		//隐藏和显示
		handleShow(){
			this.showDetailModal = false;
		},
		//关闭导出框
		onCloseExport(bool) {
			this.transferDialog = false;
		},
		//导出
		btnInduce() {
			this.transferDialog = true;
		},
		//改变切换栏
		toggleTab(current) {
			this.currentTab = current;
			this.currentClass = current;
		},
		isSelect() {
			for(const k in this.arr_province) {
				if(this.dynamicValidateForm.levelval === this.arr_province[k]) {
					this.typeOptions = this.brandObj[this.dynamicValidateForm.levelval]
				} else {
					this.dynamicValidateForm.course = "";
				}
			}
			this.dynamicValidateForm.collegename1 = '';
			this.dynamicValidateForm.majorname1 = '';
		},
		changeCourse() {
			this.typeOptions = this.brandObj[this.dynamicValidateForm.levelval]
		},
		// 改变年级
		onChangeGrade(val, form) {
			this[form].grade = val;
			this[form].collegename1 = '';
			this[form].majorname1 = '';
		},
    //改变选择框 change
    onChangeSelect(val, prop) {
      this.dynamicValidateForm[prop] = val;
    },
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].schoolorgid = "";
      this[form].companyorgid = val;
      this[form].shoujucode = "";
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: student_list2,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
		//删除
		btnDel() {
			selectCheck(this.multipleSelection, "删除", true, ()=> {
				this.headApproval(this.multipleSelection.map((i) => i.id));
			});
		},
		//删除 api
		headApproval(idArr) {
			myRequest(
			{
				method: "post",
				url: student_delete2,
				data: this.$qs.stringify(
					{
						ids: idArr,
					},
					{
						arrayFormat: "repeat"
					}
				),
			},
			{
				isRefTableList: true,
				that: this,
			}
			)
		},
		//导出
		btnImport() {
			this.importSaveUrl = student_importsave2;
			this.downloadUrl = "/download/业务管理/合作名单导入模板.xls";
			this.importDialog = true;
		},
		//关闭导入框
		onCloseImport(bool) {
		  this.importDialog = bool;
		},
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
		btnExport() {
			confirmCallBack({
			  title: "提示",
			  content: "根据搜索条件进行导出。",
			  success: () => {
			    window.location.href =
			      this.$url.Freemarker +
			      student_exportstudet2 +
			      `?${createObjParams(this.searchForm)}`;
			  },
			  fail: () => {
			    messageTip({
			      message: "取消操作",
			      type: "info",
			    });
			  },
			});
		},
		//报读学历
		btnAdd(){
				this.dialogTitle = "新增:";
				this.adddisable = false;
				this.dynamicValidateForm = {
					schoolorgid: this.$store.state.login.userinfo.schoolorgid,
					companyorgid: this.$store.state.login.userinfo.companyorgid,
				};
				this.addshowDetailList = true;
				this.getIdcard();
				this.$set(
				  this.dynamicValidateForm,
				  "companyorgname",
				  this.$store.state.login.userinfo.companyorgname
				);
				this.$set(
				  this.dynamicValidateForm,
				  "schoolorgname",
				  this.$store.state.login.userinfo.schoolorgname
				);
				 this.org_comboxchild(this.dynamicValidateForm.companyorgid);
			},
		// 保存资料student_save
		submitConent(dynamicValidateForm){
			 this.$refs[dynamicValidateForm].validate((valid) => {
				if(valid) {
					myRequest(
					  {
							url: student_save2,
							data:{
								id: this.dynamicValidateForm.id,
								studytypekey:this.dynamicValidateForm.studytypekey,
								studytypeval: this.dynamicValidateForm.studytypeval,
								collegenamecode1: this.dynamicValidateForm.collegenamecode1,
								majornamecode1: this.dynamicValidateForm.majornamecode1,
								collegenamecode2: this.dynamicValidateForm.collegenamecode2,
								majornamecode2: this.dynamicValidateForm.majornamecode2,
								hukouaddr: this.dynamicValidateForm.hukouaddr,
								sexkey: this.dynamicValidateForm.sexkey,
								sexval: this.dynamicValidateForm.sexval,
								majorid1: this.dynamicValidateForm.majorid1,
								majorid2: this.dynamicValidateForm.majorid2,
								studentcode: this.dynamicValidateForm.studentcode,
								name:this.dynamicValidateForm.name,
								nationkey: this.dynamicValidateForm.nationkey,
								birthday: this.dynamicValidateForm.birthday, //出生日期
								polikey: this.dynamicValidateForm.polikey,
								examinationType: this.dynamicValidateForm.examinationType,
								points:this.dynamicValidateForm.points,
								levelval:this.dynamicValidateForm.levelval,
								levelkey:this.dynamicValidateForm.levelkey,
								registeredsubjects:this.dynamicValidateForm.registeredsubjects,
								course: this.dynamicValidateForm.course,
								examination:this.dynamicValidateForm.examination,
								registrationpointval: this.dynamicValidateForm.registrationpointval,
								eduval: this.dynamicValidateForm.eduval,
								idcardtypeval: this.dynamicValidateForm.idcardtypeval,
								idcard:this.dynamicValidateForm.idcard, //证件号码
								graduateschool:this.dynamicValidateForm.graduateschool, //毕业院校
								graduatetime : this.$set(
									this.dynamicValidateForm,
									"graduatetime",
									formatDare(
										this.dynamicValidateForm.graduatetime,
										"YYYY-MM-DD HH:mm:ss"
									)
								),
								zhuankemajor: this.dynamicValidateForm.zhuankemajor,
								zhuankeno : this.dynamicValidateForm.zhuankeno,
								
								homezipcode:this.dynamicValidateForm.homezipcode,
								mobile: this.dynamicValidateForm.mobile, //移动电话
								collegename1: this.dynamicValidateForm.collegename1, //院校1
								majorname1:this.dynamicValidateForm.majorname1,
								majorname2:this.dynamicValidateForm.majorname2,
								collegename2:this.dynamicValidateForm.collegename2,
								year: this.dynamicValidateForm.year,
								grade:this.dynamicValidateForm.grade,
								projecttype: this.dynamicValidateForm.projecttype,
								studentinfoid: this.dynamicValidateForm.studentinfoid,
								}
					  },
						{
							isRefTableList:true,
							that: this,
							modal: "addshowDetailList"
						}
						).then((res) =>{
						if (res.data.code === "200") {
							// this.id = res.data.data.id;
							// this.education(this.id);
						}
					})
				}
			})
		},
    //切换招生计划页时 change
    handleEnrollplanChange(val) {
      this.pageinfo.pageindex = val;
      this.enrollplan();
    },

    //  查看详情 tab切换 change
    handleClick() {
      this.btnShowDetail(this.tabId);
    },

    // 查看详情 clik
    btnShowDetail(qianzaiid) {
      this.tabId = qianzaiid;
      myRequest({
        url: studentpotential_show,
        data: {
          id: qianzaiid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailList = res.data.data;
          this.vstudentpotential = this.showDetailList.vstudentpotential;
          this.showDetailModal = true;
        }
      });
    },
		getIdcard(idCard) {
			// console.log(idCard,'这个是身份证号码---');
			var birthday = "";
			var idCard = this.dynamicValidateForm.idcard;
			if (idCard != null && idCard != "") {
			  if (idCard.length == 15) {
			    birthday = "19" + idCard.substr(6, 6);
					// console.log(birthday,'这个数局是几位啊---');
			  } else if (idCard.length == 18) {
			    birthday = idCard.substr(6, 8);
					// console.log(birthday,'18位--');
			  }
			  this.dynamicValidateForm.birthday = birthday.replace(/(.{4})(.{2})(.{2})/, "$1-$2-$3 04:00:00");
				// console.log(this.dynamicValidateForm.birthday,'这个是出生年月日----')
			}
			return birthday;
		},
    //修改 click
    btnEdit() {
			this.dialogTitle = "修改"; 
			this.adddisable = true;
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage();
      });
    },
    //修改路由页 api
    editRoutePage() {
      myRequest({
        url: student_input2,
        data: {
          id: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.policy();
          this.enrollyear();
          this.dynamicValidateForm = Object.assign(
            res.data.data.vstudentpotential || {},
            res.data.data.vstudent,
						res.data.data.vstudentinfo
          );
					this.changeCourse();
					this.dynamicValidateForm.id = res.data.data.vstudent.id;
					this.dynamicValidateForm.studytypekey = res.data.data.vstudent.studytypekey;
					this.dynamicValidateForm.studytypeval = res.data.data.vstudent.studytypeval;
					this.dynamicValidateForm.graduatetime = formatDare(
					  this.dynamicValidateForm.graduatetime,
					  "YYYY-MM-DD HH:mm:ss"
					);
					this.dynamicValidateForm.birthday = formatDare(
					  this.dynamicValidateForm.birthday,
					  "YYYY-MM-DD HH:mm:ss"
					);
					this.dynamicValidateForm.joinuptime = formatDare(
					  this.dynamicValidateForm.joinuptime,
					  "YYYY-MM-DD HH:mm:ss"
					);
					this.dynamicValidateForm.jobtime = formatDare(
					  this.dynamicValidateForm.jobtime,
					  "YYYY-MM-DD HH:mm:ss"
					);
					this.dynamicValidateForm.joinouttime = formatDare(
					  this.dynamicValidateForm.joinouttime,
					  "YYYY-MM-DD HH:mm:ss"
					);
					this.dynamicValidateForm.lastfollowtime = formatDare(
					  this.dynamicValidateForm.lastfollowtime,
					  "YYYY-MM-DD HH:mm:ss"
					);
					
					delete this.dynamicValidateForm.studentinfoid;
					delete this.dynamicValidateForm.feebacktime;
					delete this.dynamicValidateForm.inserttime;
					delete this.dynamicValidateForm.edukey; //shoukuantime 该字段不用传
					delete this.dynamicValidateForm.lastinserttime;
					this.getIdcard();
					this.addshowDetailList = true;
        }
      });
    },
    // 选择报读学历跟进人 click
    reportSelectBtn(url, modal, data) {
      const requireField = [
				{
				  prop: "studentcode",
				  message: "请先填写考生号",
				},
				{
				  prop: "name",
				  message: "请先填写姓名",
				},
				{
				  prop: "sexkey",
				  message: "请先选择性别",
				},
				{
				  prop: "levelval",
				  message: "请先选择考生类别",
				},
				{
				  prop: "registeredSubjects",
				  message: "请先选择报考科类",
				},
				{
				  prop: "course",
				  message: "请先选择考试科目组",
				},
				{
				  prop: "year",
				  message: "请先选择年份",
				},
        {
          prop: "projecttype",
          message: "请先选择项目类型",
        },
				{
				  prop: "studytypeval",
				  message: "请先选择学习形式",
				},
				{
				  prop: "mobile",
				  message: "请先填写电话号码",
				},
      ];
      for (let i in requireField) {
        if (!this.dynamicValidateForm[requireField[i].prop]) {
          return messageTip({
            type: "warning",
            message: requireField[i].message,
          });
        }
      }
      this.onSelectData(url, modal, data);
    },
    // 选择报读学历跟进人确定
    reportDataComfirm() {
      selectCheck(this.multipleSelection, "操作", false, () => {
			this.dynamicValidateForm.majorname1 =
       this.multipleSelection[0].collegemajorname; // 报考专业1
      this.dynamicValidateForm.collegename1 =
        this.multipleSelection[0].collegename; //院校1
      this.dynamicValidateForm.grade = this.multipleSelection[0].grade; //招生年级
			this.dynamicValidateForm.collegeid1 = this.multipleSelection[0].id;
			this.dynamicValidateForm.majorid1 = this.multipleSelection[0].collegemajorid;
			this.dynamicValidateForm.schoolorgid = this.multipleSelection[0].schoolorgid;
      this.showCollegemajorModal = false;
			this.handleForm();
			this.multipleSelection = [];
      });
    },
		onComfirmShow() {
			selectCheck(this.multipleSelection, "操作", false, () => {
			this.dynamicValidateForm.majorname2 =
			 this.multipleSelection[0].collegemajorname; // 报考专业1
			this.dynamicValidateForm.collegename2 =
			  this.multipleSelection[0].collegename; //院校1
			this.dynamicValidateForm.grade = this.multipleSelection[0].grade; //招生年级
			this.dynamicValidateForm.ccollegeid2 = this.multipleSelection[0].id;
			this.dynamicValidateForm.majorid2 = this.multipleSelection[0].collegemajorid;
			this.dynamicValidateForm.schoolorgid = this.multipleSelection[0].schoolorgid;
			this.showMajor2 = false;
			this.handleForm();
			this.multipleSelection = [];
			});
		},
    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    // 确定 - 院校专业 click
    onConfirmCollegeMajor() {
      selectCheck(this.multipleSelection, "院校专业", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "grade",
          this.multipleSelection[0].enrollyearval
        );
        this.$set(
          this.dynamicValidateForm,
          "collegename1",
          this.multipleSelection[0].collegename
        );
        this.$set(
          this.dynamicValidateForm,
          "majorname1",
          this.multipleSelection[0].collegemajorname
        );
        this.$set(
          this.dynamicValidateForm,
          "leveltype1",
          this.multipleSelection[0].majorlevelval
        );
        this.dynamicValidateForm.collegeid1 =
          this.multipleSelection[0].id;
        this.dynamicValidateForm.majorid1 = this.multipleSelection[0].collegemajorid;
        this.showSelectCollegeModal = false;
      });
    },
    //院校专业列表 api
    enrollplan() {
      myRequest({
        url: enrollplandetail_dialogresult,
        data: {
          pageindex: this.pageinfo.pageindex,
          pagesize: this.pageinfo.pagesize,
          year: this.dynamicValidateForm.year,
          schoolorgname: this.dynamicValidateForm.schoolorgname,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.enrollplandetail = res.data.data;
          this.showSchoolTable = true;
        }
      });
    },
		//名族 api
		nation() {
		  myRequest({
		    url: dic_combox,
		    data: {
		      typecode: "nation",
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.nationList = res.data.data.list;
		    }
		  });
		},
    //政治面貌 api
    policy() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "policy",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.policyList = res.data.data.list;
        }
      });
    },
    //招生年份 api
    enrollyear() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "enrollyear",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.enrollyearList = res.data.data.list;
        }
      });
    },
    //层次类型 api
    leveltype() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "leveltype",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.leveltypeList = res.data.data.list;
        }
      });
    },
		//项目类型 api
		leveltype() {
		  myRequest({
		    url: dic_combox,
		    data: {
		      typecode: "projecttype",
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.projecttypeList = res.data.data.list;
		    }
		  });
		},
    //学习形式 api
    studyType() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "studytype",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.studyTypeList = res.data.data.list;
        }
      });
    },
  },
};
</script>

<style  lang="scss">
@import "../../../assets/css/pulid.scss";
</style>