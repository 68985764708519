<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				@submit.native.prevent
      >
        <el-form-item label="招生年份:">
          <el-date-picker
            type="year"
            value-format="yyyy"
            v-model="searchForm.year"
            clearable
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input
						v-model.trim="searchForm.schoolorgname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"

      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="招生年份" prop="year" align="center" >
<template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.year
          }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="成考目标" prop="cktarget" align="center" />
      <el-table-column label="自考目标" prop="zktarget" align="center" />
      <el-table-column label="国开、广开" prop="gkgk" align="center" />
      <el-table-column label="全日制专升本" prop="fulltimezsb" align="center" />
      <el-table-column label="职称评审" prop="title" align="center" />
      <el-table-column label="香港务工" prop="hongkong" align="center" />
      <el-table-column label="全日制中专、高职自招、现代学徒" prop="fulltimezz" align="center" />
      <el-table-column label="其他资格和职业技能" prop="skill" align="center" />
      <el-table-column label="成人中专" prop="adultzz" align="center" />
      <el-table-column label="档案托管" prop="files" align="center" />
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:close-on-click-modal='false'
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"

        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="用户流水号:">
          {{ dynamicValidateForm.userid }}
        </el-form-item>
        <el-form-item label="招生年份:">
          {{ dynamicValidateForm.year }}
        </el-form-item>
        <el-form-item label="成考目标:">
          {{ dynamicValidateForm.cktarget }}
        </el-form-item>
        <el-form-item label="自考目标:">
          {{ dynamicValidateForm.zktarget }}
        </el-form-item>
        <el-form-item label="国开、广开:">
          {{ dynamicValidateForm.gkgk }}
        </el-form-item>
        <el-form-item label="全日制专升本:">
          {{ dynamicValidateForm.fulltimezsb }}
        </el-form-item>
        <el-form-item label="职称评审:">
          {{ dynamicValidateForm.title }}
        </el-form-item>
        <el-form-item label="香港务工:">
          {{ dynamicValidateForm.hongkong }}
        </el-form-item>
        <el-form-item label="全日制中专、现代学徒:">
          {{ dynamicValidateForm.fulltimezz }}
        </el-form-item>
        <el-form-item label="其他资格和职业技能:">
          {{ dynamicValidateForm.skill }}
        </el-form-item>
        <el-form-item label="成人中专:">
          {{ dynamicValidateForm.adultzz }}
        </el-form-item>
        <el-form-item label="档案托管:">
          {{ dynamicValidateForm.files }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="45%"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="45%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份:" prop="year">
          <el-date-picker
            type="year"
            v-model="dynamicValidateForm.year"
            value-format="yyyy"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="成考目标:" prop="cktarget">
          <el-input
            v-model.trim="dynamicValidateForm.cktarget"
						clearable
          />
        </el-form-item>
        <el-form-item label="自考目标:" prop="zktarget">
          <el-input
                  v-model.trim="dynamicValidateForm.zktarget"
                  clearable
          />
        </el-form-item>

        <el-form-item label="国开、广开:" prop="gkgk">
          <el-input
                  v-model.trim="dynamicValidateForm.gkgk"
                  clearable
          />
        </el-form-item>
        <el-form-item label="全日制专升本:" prop="fulltimezsb">
          <el-input
                  v-model.trim="dynamicValidateForm.fulltimezsb"
                  clearable
          />
        </el-form-item>
        <el-form-item label="职称评审:" prop="title">
          <el-input
                  v-model.trim="dynamicValidateForm.title"
                  clearable
          />
        </el-form-item>
        <el-form-item label="香港务工:" prop="hongkong">
          <el-input
                  v-model.trim="dynamicValidateForm.hongkong"
                  clearable

          />
        </el-form-item>
        <el-form-item label="全日制中专、现代学徒:" prop="fulltimezz">
          <el-input
                  v-model.trim="dynamicValidateForm.fulltimezz"
                  clearable
          />
        </el-form-item>
        <el-form-item label="其他资格和职业技能:" prop="skill">
          <el-input
                  v-model.trim="dynamicValidateForm.skill"
                  clearable
          />
        </el-form-item>
        <el-form-item label="成人中专:" prop="adultzz">
          <el-input
                  v-model.trim="dynamicValidateForm.adultzz"
                  clearable
          />
        </el-form-item>
        <el-form-item label="档案托管:" prop="files">
          <el-input
                  v-model.trim="dynamicValidateForm.files"
                  clearable
          />
        </el-form-item>





      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择跟进人 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择跟进人："
			:close-on-click-modal='false'
      :visible.sync="showUserModal"
			v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column
          label="真实姓名"
          prop="realname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="员工编号"
          prop="code"
          align="center"
        ></el-table-column>
        <el-table-column
          label="登录账号"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属部门"
          prop="departorgname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属分公司"
          prop="companyorgname"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="(val,data,prop,page)=>handleSelectChange(val,{
                  schoolorgid:dynamicValidateForm.schoolorgid,
                  leaveflag:1
                },'selectTable','selectPageInfo')"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
      ></el-pagination>
    </el-dialog>

    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      downloadUrl="download/业务管理/教学点年度招生计划.xls"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
    ></Import>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import Import from "@/components/Import/index.vue";
import { mapActions } from "vuex";

import {
  schoolplan_list,
  schoolplan_input,
  schoolplan_save,
  schoolplan_delete,
  schoolplan_importsave,
} from "@/request/api/allChildrenProject";
import { _user_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {_initschoolplan} from '@/assets/js/initTableData'

export default {
  mixins: [part],
  name: "schoolplan",
  components: { Import },
  props: {
    importSaveUrl: {
      default: () => schoolplan_importsave,
    },
    user_dialogresult: {
      default: () => _user_dialogresult,
    },
  },
  data() {
    return {
      importDialog: false, //导入框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: _initschoolplan, //表单
      rules: {
        companyorgname: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
        year: [{ trigger: "blur", message: "必填字段", required: true }],
        cktarget: [{ trigger: "blur", message: "必填字段", required: true }],
        zktarget: [{ trigger: "blur", message: "必填字段", required: true }],
        gkgk: [{ trigger: "blur", message: "必填字段", required: true }],
        fulltimezsb: [{ trigger: "blur", message: "必填字段", required: true }],
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        hongkong: [{ trigger: "blur", message: "必填字段", required: true }],
        fulltimezz: [{ trigger: "blur", message: "必填字段", required: true }],
        skill: [{ trigger: "blur", message: "必填字段", required: true }],
        adultzz: [{ trigger: "blur", message: "必填字段", required: true }],
        files: [{ trigger: "blur", message: "必填字段", required: true }]
      }, //表单规则
      dialogTitle: "", //对话框标题

      selectTable: {},
      selectTableUrl: "",
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
			key: '',
      showUserModal: false,
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid)
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		reset() {
			this.searchForm = {
				year: null,
				schoolorgname: null,
			}
		},
		resetQuery() {
			this.reset();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
    //导入 click
    btnImport() {
      this.importDialog = true;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: schoolplan_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换选项表格页
    handleSelectChange(val,data,prop,page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,
        data,
        this.itemRow,
        prop,
        page);
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: schoolplan_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vschoolplan;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = _initschoolplan
      this.dynamicValidateForm.schoolorgid = this.$store.state.login.userinfo.schoolorgid
      this.dynamicValidateForm.companyorgid = this.$store.state.login.userinfo.companyorgid
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //确认跟进人 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认跟进人", false, () => {
        this.dynamicValidateForm.userid = this.multipleSelection[0].id;
        this.$set(this.dynamicValidateForm,'username',this.multipleSelection[0].realname)
        this.showUserModal = false;
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: schoolplan_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vschoolplan;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: schoolplan_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: schoolplan_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选项表格 api（跟进人）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>
