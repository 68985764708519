<template>
<section>
	<div class="cooperation_bg" v-show="$store.state.login.cooperation"  v-loading="loading">
		<img src="../../assets/image/banner.jpg" class="cooperation__pic"/>
	</div>
		<div class="index-page-w" v-show="!$store.state.login.cooperation" v-if="endRequest"  v-loading="loading">
    <!-- 1 -->
    <div class="performance-w report-card">
      <div class="head item-head-card">个人业绩</div>
      <div class="body">
        <div class="item-w">
          <div class="name">今日学历</div>
          <div class="data">
						<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.todayedu" :duration='duration'></countTo>
          </div>
          <div class="other">
            昨天学历：
						<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.yesterdayedu" :duration='duration'></countTo>
          </div>
        </div>

        <div class="item-w">
          <div class="name">今日技能</div>
          <div class="data">
						<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.todaytech" :duration='duration'></countTo>
          </div>
          <div class="other">
            昨天技能：
						<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.yesterdaytech" :duration='duration'></countTo>
          </div>
        </div>

        <div class="item-w">
          <div class="name">今日名片</div>
          <div class="data">
						<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.todaystudent" :duration='duration'></countTo>
					</div>
          <div class="other">
						昨日名片：
							<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.yesterdaystudent" :duration='duration'></countTo>
					</div>
        </div>

        <div class="item-w">
          <div class="name">今日跟进</div>
          <div class="data">
						<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.todayfollow" :duration='duration'></countTo>
					</div>
          <div class="other">
						昨日跟进：
						<countTo :startVal='startVal' :endVal="prestatisticsRes.tchengkaopersonalperformance.yesterdayfollow" :duration='duration'></countTo>
					</div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="performance-w report-card">
      <div class="head item-head-card">目标完成情况</div>
      <div class="body">
        <div class="item-w">
            <div class="type-i">
              <p class="data"><countTo :startVal='startVal' :endVal="completionRes.xuelicount" :duration='duration'></countTo></p>
              <p class="name">年度学历目标</p>
            </div>
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.xuelicount2" :duration='duration'></countTo></p>
            <p class="name">年度学历完成</p>
          </div>
        </div>

        <div class="item-w">
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tuserplan.cktarget" :duration='duration'></countTo></p>
            <p class="name">本月成考目标</p>
          </div>
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tchengkaopersonalperformance.cktarget" :duration='duration'></countTo></p>
            <p class="name">本月成考完成</p>
          </div>
        </div>

        <div class="item-w">
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tuserplan.gkgk" :duration='duration'></countTo></p>
            <p class="name">本月国开、广开目标</p>
          </div>
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tchengkaopersonalperformance.gkgk" :duration='duration'></countTo></p>
            <p class="name">本月国开、广开完成</p>
          </div>
        </div>

          <div class="item-w">
            <div class="type-i">
              <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tuserplan.zktarget" :duration='duration'></countTo></p>
              <p class="name">本月自考目标</p>
            </div>
            <div class="type-i">
              <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tchengkaopersonalperformance.zktarget" :duration='duration'></countTo></p>
              <p class="name">本月自考完成</p>
            </div>
          </div>

        <div class="item-w">
            <div class="type-i">
              <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tuserplan.fulltimezsb" :duration='duration'></countTo></p>
              <p class="name">本月全日制专插本目标</p>
            </div>
            <div class="type-i">
              <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tchengkaopersonalperformance.fulltimezsb" :duration='duration'></countTo></p>
              <p class="name">本月全日制专插本完成</p>
            </div>
        </div>


        <div class="item-w">
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.jinengcount" :duration='duration'></countTo></p>
            <p class="name">年度非学历目标</p>
          </div>
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.jinengcount2" :duration='duration'></countTo></p>
            <p class="name">年度非学历完成</p>
          </div>
        </div>


        <div class="item-w">
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tuserplan.hongkong" :duration='duration'></countTo></p>
            <p class="name">本月香港务工目标</p>
          </div>
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tchengkaopersonalperformance.hongkong" :duration='duration'></countTo></p>
            <p class="name">本月香港务工完成</p>
          </div>
        </div>

        <div class="item-w">
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tuserplan.title" :duration='duration'></countTo></p>
            <p class="name">本月职称评审目标</p>
          </div>
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tchengkaopersonalperformance.title" :duration='duration'></countTo></p>
            <p class="name">本月职称评审完成</p>
          </div>
        </div>

        <div class="item-w">
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tuserplan.skill" :duration='duration'></countTo></p>
            <p class="name">本月其他资格和技能目标</p>
          </div>
          <div class="type-i">
            <p class="data"><countTo :startVal='startVal' :endVal="completionRes.tchengkaopersonalperformance.skill" :duration='duration'></countTo></p>
            <p class="name">本月其他资格和技能完成</p>
          </div>
        </div>



        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="notice-w report-card">
      <div class="head item-head-card">通知公告</div>
      <div class="body">
        <el-table :data="bulletinRes.list" style="width: 100%">
          <el-table-column label="主题" prop="title" align="center">
          </el-table-column>
          <el-table-column
            label="发布人"
            prop="pubusername"
            align="center"
          ></el-table-column>
          <el-table-column
            label="类别"
            prop="type"
            align="center"
          ></el-table-column>
          <el-table-column label="发布时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column
            label="所属教学点"
            prop="schoolorgname"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 4 -->
    <div class="rank-w report-card">
      <div class="head item-head-card">学历英雄榜</div>
      <div class="body">
        <el-table :data="edupersonalRes.performancelist">
          <el-table-column label="排名" width="180">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="followusername" label="姓名" width="180">
          </el-table-column>
          <el-table-column prop="month1" label="1月"> </el-table-column>
          <el-table-column prop="month2" label="2月"> </el-table-column>
          <el-table-column prop="month3" label="3月"> </el-table-column>
          <el-table-column prop="month4" label="4月"> </el-table-column>
          <el-table-column prop="month5" label="5月"> </el-table-column>
          <el-table-column prop="month6" label="6月"> </el-table-column>
          <el-table-column prop="month7" label="7月"> </el-table-column>
          <el-table-column prop="month8" label="8月"> </el-table-column>
          <el-table-column prop="month9" label="9月"> </el-table-column>
          <el-table-column prop="month10" label="10月"> </el-table-column>
          <el-table-column prop="month11" label="11月"> </el-table-column>
          <el-table-column prop="month12" label="12月"> </el-table-column>
          <el-table-column prop="yeartotal" label="合计"> </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 5 -->
    <div class="admissions-w report-card">
      <div class="head item-head-card">教学点招生汇总</div>
      <div class="body">
        <el-table :data="chengkaoRes.performancelist" style="width: 100%">
          <el-table-column label="排名" width="180">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="schoolorgname" label="教学点" width="180">
          </el-table-column>
          <el-table-column prop="c1" label="年度德立学生报名">
          </el-table-column>
          <el-table-column prop="c2" label="上月德立学生报名">
          </el-table-column>
          <el-table-column prop="c3" label="本月德立学生报名">
          </el-table-column>

          <el-table-column prop="c5" label="本月辅导班报名"> </el-table-column>
          <el-table-column prop="c6" label="昨日德立学生报名">
          </el-table-column>
          <el-table-column prop="c7" label="年度合作报名"> </el-table-column>

        </el-table>
      </div>
    </div>
    <!-- 6 -->
    <div class="excellent-w report-card">
      <div class="head item-head-card">五星员工</div>
      <div class="body">
        <el-table :data="fivestarsRes.list" style="width: 100%">
          <el-table-column prop="username" label="姓名" width="180">
          </el-table-column>
          <el-table-column prop="month" label="月份" width="180">
          </el-table-column>
          <el-table-column prop="schoolorgname" label="教学点">
          </el-table-column>
        </el-table>
      </div>
    </div>

</section>
</template>

<script>
import countTo from 'vue-count-to';
import { mapActions } from "vuex";
import { myRequest } from "@/request/index";
import {
  performance_prestatistics,
  bulletin_list,
  performance_edupersonal,
  performance_chengkao,
  fivestars_list,
  performance_completion,
} from "@/request/api/allChildrenProject";

export default {
  name: "welcome",
	inject: ["reload"],
	components: { countTo },
  created() {
		this.initData();
  },
  mounted() {

	},
  data() {
    return {
			loading: false, //加载
      prestatisticsRes: {}, //个人业绩
      bulletinRes: {}, //通知公共
      edupersonalRes: {}, //学历英雄榜
      chengkaoRes: {}, //教学点招生汇总
      fivestarsRes: {}, //五星员工
      completionRes: {}, //目标完成情况
      endRequest: false,
			// 需要滚动的时间
			duration: 5000,
			// 初始值
			startVal: 700,
			// 最终值
			endVal: 2018,
    };
  },

  methods: {
    async initData() {
      await this.prestatistics();
      await this.completion();
      await this.bulletin();
      await this.edupersonal();
      await this.chengkao();
      await this.fivestars();
    },
    //个人业绩 api
    prestatistics() {
      myRequest({
        url: performance_prestatistics,
        data: {
          userid: this.$store.state.login.userinfo.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.prestatisticsRes = res.data.data;
        }
      });
    },

    //目标完成情况 api
    completion() {
      myRequest({
        url: performance_completion,
        data: {
          userid: this.$store.state.login.userinfo.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.completionRes = res.data.data;
        }
      });
    },

    //通知公共 api
    bulletin() {
      myRequest({
        url: bulletin_list,
      }).then((res) => {
        if (res.data.code === "200") {
          this.bulletinRes = res.data.data;
        }
      });
    },

    //学历英雄榜 api
    edupersonal() {
      myRequest({
        url: performance_edupersonal,
      }).then((res) => {
        if (res.data.code === "200") {
          this.edupersonalRes = res.data.data;
        }
      });
    },

    //教学点招生汇总 api
    chengkao() {
      myRequest({
        url: performance_chengkao,
      }).then((res) => {
        if (res.data.code === "200") {
          this.chengkaoRes = res.data.data;
        }
      });
    },

    //五星员工 api
    fivestars() {
      myRequest({
        url: fivestars_list,
      }).then((res) => {
        if (res.data.code === "200") {
          this.fivestarsRes = res.data.data;
          this.endRequest = true;
        }
      });
    },
  },
	beforeRouteEnter(to, from, next) {
		// 写在当前组件
		to.meta.keepAlive = false;
		next()
	},
};
</script>

<style lang="scss" >
.color-00384be6 {
  color: #00384be6;
}
.report-card {
  position: relative;
  padding: 10px 0 20px 0;
  box-shadow: #ccc 0 0 10px;
  margin: 0 20px 20px 20px;
  border-radius: 3px;
  color: #00384be6;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    width: 4px;
    height: 30px;
    background-color: #2b5a95;
  }
}
.item-head-card {
  padding: 0 0 0 20px;
}

.index-page-w {
  margin: 20px 0;
}
.performance-w {
  .head {
    margin: 0 0 20px 0;
    color: #00384b;
  }
  .body {
    display: flex;
  }

  .item-w {
    width: 25%;
    padding: 0 20px;
    .name {
      font-size: 12px;
    }
    .data {
      margin: 10px 0 10px 0;
      font-size: 30px;
    }
    .other {
      font-size: 14px;
    }
  }
}

.target-w {
  .head {
    margin: 0 0 20px 0;
  }
  .body {
    display: flex;
    // flex-wrap: wrap;
  }
  .item-w {
    width: 50%;
    padding: 0 100px 0 20px;
    margin: 0 0 20px 0;
    .content-w {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      margin: 20px 0;

      .type-i {
        margin: 0 0 20px 0;
        & > p {
          text-align: center;
        }
        & > p:first-child {
          font-size: 30px;
        }
        & > p:last-child {
          font-size: 12px;
        }
      }
    }
    .layout-1{
      .type-i {
        width: 25%;
      }
    }
.layout-2{
      .type-i {
        width: 33.33%;
      }
    }


  }
}
</style>
