<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
				<el-form-item label="流水号:">
					<el-input 
						v-model.trim="searchForm.id" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
        <el-form-item label="账户名称:">
          <el-input 
						v-model.trim="searchForm.accountname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="收据编号:">
          <el-input 
						v-model.trim="searchForm.shoujucode" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="登帐标识:">
          <el-select 
						v-model="searchForm.registerflag" 
						@keyup.enter.native="seach"
						@visible-change="visibleType"
						ref="selectref"
						clearable>
            <el-option value="0" label="未登帐" />
            <el-option value="1" label="已登帐" />
          </el-select>
        </el-form-item>
        <el-form-item label="摘要:">
          <el-input 
						v-model.trim="searchForm.zhaiyao" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="卡号:">
          <el-input 
						v-model.trim="searchForm.cardno"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="对方单位名称:">
          <el-input 
						v-model.trim="searchForm.unitname"
						@keyup.enter.native="seach"
					 clearable />
        </el-form-item>
        <el-form-item label="账户所属教学:">
          <el-input 
						v-model.trim="searchForm.accschoolorgname"
						@keyup.enter.native="seach"
					 clearable />
        </el-form-item>
        <el-form-item label="收入金额:">
          <el-input 
						v-model.trim="searchForm.moneyinamount" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="所属教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname"
						@keyup.enter.native="seach"
					 clearable />
        </el-form-item>
        <el-form-item label="操作人姓名:">
          <el-input 
						v-model.trim="searchForm.opusername" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="操作日期起:">
          <el-date-picker
            v-model="searchForm.t1"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="选择结束合作时间"
          />
        </el-form-item>
        <el-form-item label="操作日期止:">
          <el-date-picker
            v-model="searchForm.t2"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="选择结束合作时间"
          />
        </el-form-item>
        <el-form-item label="基表名称:">
          <el-input 
						v-model.trim="searchForm.objectname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="基表流水号:">
          <el-input 
						v-model.trim="searchForm.objectid" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
				<el-form-item label="分页大小:">
          <el-input-number 
              class="inputNumber"
              v-model="pageinfo.pagesize" 
              :min="10" 
              :max="100000" 
              label="描述文字"
              @keyup.enter.native="seach"
            >
          </el-input-number>
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button 
				type="primary" 
				@click="resetQuery"
				>
				重置
				</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      border
			stripe
			:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
			:height="tableHeight"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column label="流水号" prop="id" align="center" />
      <el-table-column   align="center" width="260px">
        <template slot="header" slot-scope="scope">
          <p>账户名称</p>
          <p style="border-top: 1px solid #dfe6ec">卡号</p>
        </template>
        <template slot-scope="scope">

          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.accountname
          }}</el-button>


          <p>{{ scope.row.cardno }}</p>
        </template>
      </el-table-column>

      <el-table-column label="金额" align="center">
        <el-table-column label="收入" prop="moneyinamount" align="center" />
        <el-table-column label="支出" prop="moneyoutamount" align="center" />
      </el-table-column>

      <el-table-column label="当前余额" prop="amount" align="center" width="100px"/>
      <el-table-column
				label="摘要" 
				prop="zhaiyao" 
				width="120"
				:show-overflow-tooltip="true"
				align="center" 
				/>
      <el-table-column  align="center" width="140">
        <template slot="header" slot-scope="scope">
          <p>对方单位</p>
          <p style="border-top: 1px solid #dfe6ec">登帐标识/月份</p>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.unitname }}</p>
          <p>{{ _registerflag[scope.row.registerflag] }} / {{scope.row.registermonth}} </p>
        </template>
      </el-table-column>

      <el-table-column  align="center" width="200px">
        <template slot="header" slot-scope="scope">
          <p>操作日期</p>
          <p style="border-top: 1px solid #dfe6ec">账户所属教学点</p>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.opdate | formatDate("YYYY-MM-DD HH:mm:ss") }}</p>
          <p>{{ scope.row.accschoolorgname }}</p>
        </template>
      </el-table-column>

      <el-table-column  align="center" width="160px">
        <template slot="header" slot-scope="scope">
          <p>收据编号</p>
          <p style="border-top: 1px solid #dfe6ec">报销人</p>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.shoujucode }}</p>
          <p>{{ scope.row.outusername }}</p>
        </template>
      </el-table-column>

      <el-table-column  align="center" width="180px">
        <template slot="header" slot-scope="scope">
          <p>操作人</p>
          <p style="border-top: 1px solid #dfe6ec">教学点</p>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.opusername }}</p>
          <p>{{ scope.row.schoolorgname }}</p>
        </template>
      </el-table-column>

      <el-table-column  align="center" width="250px">
        <template slot="header" slot-scope="scope">
          <p>基表名称</p>
          <p style="border-top: 1px solid #dfe6ec">基表流水号</p>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.objectname }}<br />{{ scope.row.objectname2 }}</p>
          <p>{{ scope.row.objectid }}</p>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <Export
      :exportTitle="'收入日记账'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="80%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:close-on-click-modal='false'
			title="详情"
    >
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
	
        <tr>
          <td>操作日期：{{ dynamicValidateForm.opdate | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td>收入金额：{{ dynamicValidateForm.moneyinamount }}</td>
          <td>支出金额：{{ dynamicValidateForm.moneyoutamount }}</td>
        </tr>
        <tr>
          <td>当前余额：{{ dynamicValidateForm.amount }}</td>
          <td>摘要：{{ dynamicValidateForm.zhaiyao }}</td>
          <td>对方单位名称：{{ dynamicValidateForm.unitname }}</td>
        </tr>
        <tr>
          <td>基表流水号：{{ dynamicValidateForm.objectid }}</td>
          <td>基表名称：{{ dynamicValidateForm.objectname }}</td>
          <td v-if="dynamicValidateForm.jiaoyitype == 0">交易类型：银行</td>
					<td v-if="dynamicValidateForm.jiaoyitype == 1">交易类型：现金</td>
        </tr>
        <tr>
          <td>登帐标识：{{ _registerflag[dynamicValidateForm.registerflag] }}</td>
          <td>收入二级项目名称：{{ dynamicValidateForm.moneyintype2name }}</td>
          <td>登帐月份：{{ dynamicValidateForm.registermonth }}</td>
        </tr>
        <tr>
          <td>账户名称：{{ dynamicValidateForm.accountname }}</td>
          <td>卡号：{{ dynamicValidateForm.cardno }}</td>
          <td>账户所属教学点：{{ dynamicValidateForm.accschoolorgname }}</td>
        </tr>
        <tr>
          <td>账户所属分公司：{{ dynamicValidateForm.acccompanyorgname }}</td>
          <td v-if="dynamicValidateForm.isposflag == 0">是否是POS机：否</td>
					<td v-if="dynamicValidateForm.isposflag == 1">是否是POS机：是</td>
          <td>操作人所属教学点：{{ dynamicValidateForm.schoolorgname }}</td>
        </tr>
        <tr>
          <td>操作人所属分公司：{{ dynamicValidateForm.companyorgname }}</td>
          <td>操作人姓名：{{ dynamicValidateForm.opusername }}</td>
          <td>报销人姓名：{{ dynamicValidateForm.outusername }}</td>
        </tr>
        <tr>
          <td>收据编号：{{ dynamicValidateForm.shoujucode }}</td>
          <td>财务凭证号：{{ dynamicValidateForm.code }}</td>
					<td>编号： {{dynamicValidateForm.id}}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  journalaccount_list2,
  journalaccount_show,
  journalaccount_export,
  journalaccount_exportexcel,
  journalaccount_exportsave,
} from "@/request/api/allChildrenProject";
import { _moneyintype_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {registerflag} from '@/assets/js/filedValueFlag'
export default {
  mixins: [part],
  name: "journalaccount",
  components: { Export },
  props: {
    exportUrl: {
      default: () => journalaccount_export,
    },
    exportexcelUrl: {
      default: () => journalaccount_exportexcel,
    },
    exportsaveUrl: {
      default: () => journalaccount_exportsave,
    },
    _registerflag: {
      default: () => registerflag,
    },
    
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      showDetailModal: false,
			tableHeight: 500,
    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSeach)
  },
	mounted () {
	  //固定表头
	     this.$nextTick(function() {
	       this.tableHeight =
	         window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 50;
	 
	       // 监听窗口大小变化
	       let self = this;
	       window.onresize = function() {
	         self.tableHeight =
	           window.innerHeight - self.$refs.multipleTable.$el.offsetTop - 50;
	       };
	     });
	},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
		enterSeach(e) {
			if(e.keyCode === 13) {
				this.getDataList()
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		rest() {
			this.searchForm = {
				id: null,
				accountname: null,
				shoujucode: null,
				registerflag: null,
				zhaiyao: null,
				cardno: null,
				unitname: null,
				accschoolorgname: null,
				moneyinamount: null,
				schoolorgname: null,
				opusername: null,
				t1: null,
				t2: null,
				objectname: null,
				objectid: null,
			}
		},
		//重置
		resetQuery() {
			this.rest();
			this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: journalaccount_list2,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
  },
};
</script>


<style  lang="scss">
	  .base_table{
	  margin: auto;
	 }
</style>