<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="年级:">
            <el-input 
							v-model.trim="searchForm.grade" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
					<el-form-item label="院校:">
					  <el-input
							v-model.trim="searchForm.collegename" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="学年:">
						<el-select
							v-model="searchForm.iyear"
							clearable 
							@visible-change="visibleIyear"
							ref="iyear"
							placeholder="请选择">
							<el-option value="1" label="1"></el-option>
							<el-option value="2" label="2"></el-option>
							<el-option value="3" label="3"></el-option>
							<el-option value="4" label="4"></el-option>
							<el-option value="5" label="5"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="教学点:">
					  <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="层次:">
            <el-select
              v-model.trim="searchForm.levelval"
              placeholder="请选择"
							@visible-change="visibleLeveval"
							ref="levelval"
              clearable
            >
              <el-option
                v-for="(item, i) in leveltypeList"
                :key="i"
                :label="item.val"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      border
			stripe
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="序号" align="center">
				<template slot-scope="scope">
					{{scope.$index+1}}
				</template>
			</el-table-column>
      <el-table-column label="年级" prop="grade"  align="center"/>
      <el-table-column label="院校" align="center" prop="collegename"/>
			<el-table-column label="缴费学年" align="center" prop="iyear"/>
      <el-table-column
        label="应收人数"
        prop="shouldrecvmun"
        align="center"
      />
			<el-table-column label="应收总额" align="center" prop="shouldrecvtotal"/>
			<el-table-column label="已收人数" align="center" prop="recvmun"/>
			<el-table-column label="已收总额" align="center" prop="recvtotal"/>
			<el-table-column label="优惠总额" align="center" prop="discounttotal"/>
			<el-table-column label="欠费人数" align="center" prop="arrearsmun"/>
			<el-table-column label="欠费总额" align="center" prop="arrearstotal"/>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
  </div>
</template>
<script>
import E from "wangeditor";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";

import {
	registerfeetotal_list
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  components: {},
  props: {},
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {
				flag: 0,
			},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      multipleSelection: [], //选中的表格项
			leveltypeList:[]
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      typecode: "leveltype",
      list: "leveltypeList",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e){
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleIyear(e) {
			if(!e) {
				this.$refs.iyear.blur();
			}
		},
		visibleLeveval(e) {
			if(!e) {
				this.$refs.levelval.blur();
			}
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
			this.search();
			this.searchForm.flag = 1;
    },
		search() {
			if(this.searchForm !== null) {
				this.getDataList();
			}
		},
		reset() {
			this.searchForm = {
				grade: null,
				collegename: null,
				iyear: null,
				schoolorgname: null,
				levelval: null,
			},
			this.searchForm.flag = 0;
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: registerfeetotal_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }else if (res.data.code === "0") {
				  this.tableRes = res.data.data;
				}
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
			this.searchForm.flag = 0;
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">

</style>