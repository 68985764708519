<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="学生姓名:">
            <el-input
							v-model.trim="searchForm.studentname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input
                    v-model.trim="searchForm.mobile"
                    @keyup.enter.native="seach"
                    clearable />
          </el-form-item>
          <el-form-item label="班别:">
            <el-input
                    v-model.trim="searchForm.fudaoclasstypename"
                    @keyup.enter.native="seach"
                    clearable />
          </el-form-item>
          <el-form-item label="年级:">
            <el-input
                    v-model.trim="searchForm.grade"
                    @keyup.enter.native="seach"
                    clearable />
          </el-form-item>
          <el-form-item label="是否收款:">
            <el-select
              v-model="searchForm.isokflag"
							@keyup.enter.native="seach"
							@visible-change="isokVisible"
							ref="selectref"
              placeholder="请选择"
              clearable
            >
              <el-option label="已收款" value="1" />
              <el-option label="未收款" value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="层次:">
            <el-select
              v-model="searchForm.levelval"
							@keyup.enter.native="seach"
							@visible-change="visibleLevel"
							ref="levevalref"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in leveltypeList"
                :key="item.key"
                :label="item.val"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收据号:">
            <el-input
							v-model.trim="searchForm.shoujucode"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="报读时间:">
            <el-date-picker
              v-model="searchForm.regdate"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
					<el-form-item label="跟进人:">
					  <el-input
							v-model.trim="searchForm.followusername"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="学生姓名" align="center" width="150">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row.qianzaiid)" type="text">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="mobile" align="center" width="150">
				<template slot-scope="scope" v-if="scope.row.mobile !== null">
				  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
				</template>
      </el-table-column>
      <el-table-column
        label="年级"
        prop="grade"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column label="报读时间" align="center" width="160">
        <template slot-scope="scope"
          >{{ scope.row.regdate | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        label="辅导班班别"
        prop="fudaoclasstypename"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column label="层次" prop="levelval" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="辅导费"
        prop="fudaofee"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="教材费" prop="bookfee" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="总金额"
        prop="totalmoney"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="收款标识" align="center" width="100">
        <template slot-scope="scope"
          >{{ scope.row.isokflag === 1 ? "已收款" : "未收款" }}
        </template>
      </el-table-column>
      <el-table-column
        label="实收辅导费"
        prop="recvfudaofee"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="实收教材费"
        prop="recvbookfee"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="实收总金额"
        prop="recvmoney"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="开班班次" prop="" align="center" width="100">
      </el-table-column>
      <el-table-column
        label="优惠金额"
        prop="preferentialmoney"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="额外减免金额"
        prop="extrasubmoney"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column label="是否已退费" align="center" width="100">
        <template slot-scope="scope">
          {{ available[scope.row.feebackflag] }}
        </template>
      </el-table-column>
      <el-table-column
        label="退费金额"
        prop="feebackmoney"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="收据编号"
        prop="shoujucode"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column
        label="跟进人"
        prop="followusername"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column label="总部给予" align="center" width="100">
        <template slot-scope="scope">
          {{ available[scope.row.hqsend] }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--修改-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
      title="修改："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"

        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <h3 class="title">基本信息</h3>
        <el-form-item prop="name" label="姓名:">
          <el-input v-model="dynamicValidateForm.name" disabled />
        </el-form-item>
        <el-form-item prop="sexkey" label="性别:">
          <el-select v-model="dynamicValidateForm.sexkey" clearable>
            <el-option value="1" label="男"></el-option>
            <el-option value="2" label="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="nationkey" label="民族:">
          <el-select v-model="dynamicValidateForm.nationkey" clearable>
            <el-option
              v-for="(item, index) in nationList"
              :key="index"
              :label="item.val"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="polikey" label="政治面貌:">
          <el-select v-model="dynamicValidateForm.polikey" clearable>
            <el-option
              v-for="item in policyList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="homeaddr" label="家庭住址:">
          <el-input v-model.trim="dynamicValidateForm.homeaddr" clearable/>
        </el-form-item>
        <el-form-item prop="homezipcode" label="家庭邮编:">
          <el-input v-model.trim="dynamicValidateForm.homezipcode" clearable/>
        </el-form-item>
        <el-form-item prop="job" label="职业:">
          <el-input v-model.trim="dynamicValidateForm.job" clearable/>
        </el-form-item>
        <el-form-item prop="idcard" label="证号:">
          <el-input v-model.trim="dynamicValidateForm.idcard" clearable/>
        </el-form-item>
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <h3 class="title">报读辅导班</h3>
        <el-form-item prop="fudaoclasstypename" label="辅导班班别:">
          <el-input disabled v-model="dynamicValidateForm.fudaoclasstypename" clearable/>
          <el-button
            class="select-btn"
            type="primary"
            @click="
              onSelectData(
                stufudaofeestandard_dialogresult,
                'showStufudaofeesModal',
                { schoolorgid: dynamicValidateForm.schoolorgid }
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="fudaotypeval" label="辅导班类型:">
          <el-input disabled v-model="dynamicValidateForm.fudaotypeval" clearable/>
        </el-form-item>
        <el-form-item prop="fudaofee" label="辅导费:">
          <el-input disabled v-model="dynamicValidateForm.fudaofee" clearable/>
        </el-form-item>
        <el-form-item prop="bookfee" label="教材费:">
          <el-input disabled v-model="dynamicValidateForm.bookfee" clearable/>
        </el-form-item>
        <el-form-item prop="levelkey" label="层次:">
          <el-select
            placeholder="请选择层次"
            v-model="dynamicValidateForm.levelkey"
						clearable
          >
            <el-option
              v-for="item in leveltypeList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="regdate" label="报读时间:">
          <el-date-picker
            placeholder="请选择报读时间"
            v-model="dynamicValidateForm.regdate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
          />
        </el-form-item>
        <el-form-item prop="grade" label="年级:">
          <el-select
            placeholder="请选择年级"
            v-model="dynamicValidateForm.grade"
						clearable
          >
            <el-option
              v-for="item in gradeList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitAddOrEditForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!--选择辅导班别-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择辅导班别："
      :visible.sync="showStufudaofeesModal"
      v-if="showStufudaofeesModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmStufudaofees">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="辅导班名称:" prop="fudaoclasstypename">
              <el-input
                v-model.trim="selectSearchForm.fudaoclasstypename"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )"
                clearable
              />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button @click="handleQuery" type="primary">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.stufudaofeestandardlist"
        style="width: 100%"
        ref="fudaofeesTable"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          label="辅导班班别名称"
          prop="fudaoclasstypename"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          label="辅导班类型"
          prop="fudaotypeval"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="项目类型"
          prop="projecttypeval"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          label="辅导费"
          prop="fudaofee"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          label="教材费"
          prop="bookfee"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column label="审核标识" align="center" width="200">
          <template slot-scope="scope">
            {{ scope.row.approveflag == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
      ></el-pagination>
    </el-dialog>

    <!-- 退款 -->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
      title="申请退款："
			:close-on-click-modal='false'
      :visible.sync="showRefundModal"
      v-if="showRefundModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"

        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="学生姓名:">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
        </el-form-item>
        <el-form-item label="手机:">
          <el-input v-model="dynamicValidateForm.mobile" disabled />
        </el-form-item>
        <el-form-item
          prop="recvfudaofee"
          label="申请退费金额:"
          :rules="[{ required: true, validator: checkfield }]"
        >
          <el-input v-model.trim="dynamicValidateForm.recvfudaofee" clearable/>
        </el-form-item>
        <el-form-item
          prop="reason"
          label="申请退费理由:"
          :rules="[{ required: true, message: '必填字段' }]"
        >
          <el-input v-model.trim="dynamicValidateForm.reason" clearable />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitRefundForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="60%"
      title="收款："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showEnrolModal"
      v-if="showEnrolModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            disabled
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            clearable
            disabled
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="shoujucode" label="收据编号:">
          <el-input v-model="dynamicValidateForm.shoujucode" disabled />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item prop="bankorcash" label="银行/现金:">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showSelectAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="accountname" label="账户名:">
          <el-input v-model="dynamicValidateForm.accountname" disabled />
        </el-form-item>
        <el-form-item prop="cardno" label="卡号:">
          <el-input v-model="dynamicValidateForm.cardno" disabled />
        </el-form-item>
        <el-form-item prop="totalmoney" label="应收总金额:">
          <el-input v-model="dynamicValidateForm.totalmoney" disabled />
        </el-form-item>
        <el-form-item prop="extrasubmoney" label="额外减免金额:">
          <el-input v-model="dynamicValidateForm.extrasubmoney" disabled />
        </el-form-item>
        <el-form-item prop="preferentialmoney" label="优惠金额:">
          <el-input v-model="dynamicValidateForm.preferentialmoney" disabled />
        </el-form-item>
        <el-form-item prop="fudaofee" label="应收辅导费:">
          <el-input v-model.trim="dynamicValidateForm.fudaofee" clearable/>
        </el-form-item>
        <el-form-item prop="recvfudaofee" label="实收辅导费:">
          <el-input v-model.trim="dynamicValidateForm.recvfudaofee" clearable/>
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname1" label="辅导费收入明细:">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname1"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItem1Modal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="bookfee" label="应收教材费:">
          <el-input v-model.trim="dynamicValidateForm.bookfee" clearable/>
        </el-form-item>
        <el-form-item prop="recvbookfee" label="实收教材费:">
          <el-input v-model.trim="dynamicValidateForm.recvbookfee" clearable/>
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname" label="教材费收入明细:">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItemModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="typem2oneyintypename" label="收入二级类别:">
          <el-input
            v-model="dynamicValidateForm.typem2oneyintypename"
            disabled
          />
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyintype2_dialogresult, 'showSelectTypeModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="typem1oneyintypename" label="收入一级类别:">
          <el-input
            v-model="dynamicValidateForm.typem1oneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="typemoneyintypename" label="收入类别:">
          <el-input
            v-model="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item prop="shoukuanremark" label="开票说明:">
          <el-input v-model.trim="dynamicValidateForm.shoukuanremark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitEnrolForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择账户 -->
    <el-dialog
      class="btn-2b5a95"
      width="1300px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择账户："
      :visible.sync="showSelectAccountModal"
      v-if="showSelectAccountModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAccount">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="开户名称:" prop="accountname">
              <el-input
								v-model.trim="selectSearchForm.accountname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
            <el-form-item label="银行名称:" prop="bankname">
              <el-input
								v-model.trim="selectSearchForm.bankname"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
         <el-table-column
          label="余额"
          prop="curmoney"
          align="center"
          width="200px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择收入二级类别 -->
    <el-dialog
      class="btn-2b5a95"
      width="78%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入二级类别："
      :visible.sync="showSelectTypeModal"
      v-if="showSelectTypeModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmType">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="收入二级项目:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="收入一级项目:" prop="type1name">
              <el-input
								v-model.trim="selectSearchForm.type1name"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
            <el-form-item label="收入类别:" prop="typename">
              <el-input
								v-model.trim="selectSearchForm.typename"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleForm">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
        ref="moneyTable"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="收入类别"
          prop="typename"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="收入一级项目"
          prop="type1name"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="收入二级项目"
          prop="name"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          label="关联院校"
          prop="collegename"
          align="center"
          width="150"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择收入明细 - 辅导费 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择辅导收入明细："
      :visible.sync="showSelectItem1Modal"
      v-if="showSelectItem1Modal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem1">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleForm">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择收入明细 - 教材费 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入明细："
      :visible.sync="showSelectItemModal"
      v-if="showSelectItemModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleForm">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
		<!-- 打印收据 -->
		<el-dialog
			class="btn-2b5a95"
			width="1000px"
			v-dialogDrag
			@close="onCloseSelect"
			title="打印收据:"
			:close-on-click-modal='false'
			:visible.sync="shoujuPaper"
			v-if="shoujuPaper"
			append-to-body
		>
			<object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer" width="900px" height="500px" style="margin: auto;">
			    <param name="ReportURL" :value="this.$url.upload + '/report/shouju.grf'">
			    <param name="DataURL" value="" >
			    <param name="AutoRun" value=true >
			    <param name="SerialNo" value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
			    <param name="UserName" value="锐浪报表插件本机开发测试注册" >
			</object>
		</el-dialog>
		<div id="RightOffside" class="RightOffside" v-show="showDetailModal">
			<!-- 按钮组 -->
			<div class="Offside_btnBox">
				<div class="Offside_Btn Offside_CloseBtn" @click="handleShow"></div>
				<div class="Offside_Btn"
					v-for="(item,index) in tabList"
					:key="index"
					:class="{active:currentClass === index}"
					@click="toggleTab(index)"
				>
					{{item}}
				</div>
			</div>
			<div class="OrderStatusTips">
				<span class="pull-left text-blue">人员信息</span>
			</div>
			<!-- 切换栏 -->
			<div>
				<div class="OrderContentBox" v-show="currentTab === 0">
					<div class="order-card x-basic abase-card" style="width:800px;background-color: #D1EEEE; margin-bottom: 30px;">
						<div class="top_el">
							基本信息
						</div>
						<el-form>
							<table  class="tlist_el" style="width: 100%;" bgcolor="#D1EEEE" cellpadding="5px">
								<tbody>
									<tr>
										<td class="ltd_el">流水号：</td>
										<td class="ltd_tag">{{ vstudentpotential.id }}</td>
										<td class="ltd_el">姓名：</td>
										<td class="ltd_tag">{{ vstudentpotential.name }}</td>
										<td class="ltd_el">性别：</td>
										<td class="ltd_tag">{{ vstudentpotential.sexval }}</td>
										<td class="ltd_el">婚否：</td>
										<td class="ltd_tag">{{ vstudentpotential.marryval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">手机：</td>
										<td class="ltd_tag">{{ vstudentpotential.mobile | phoneFilter()}}</td>
										<td class="ltd_el">手机2：</td>
										<td class="ltd_tag">{{ vstudentpotential.mobile2 }}</td>
										<td class="ltd_el"> 微信：</td>
										<td class="ltd_tag">
												{{ vstudentpotential.weixin }}
										</td>
										<td class="ltd_el">QQ：</td>
										<td class="ltd_tag">{{ vstudentpotential.qq }}</td>
									</tr>
									<tr>
										<td class="ltd_el">电子邮箱：</td>
										<td class="ltd_tag">{{ vstudentpotential.email }}</td>
										<td class="ltd_el">户口所在地：</td>
										<td class="ltd_tag">{{ vstudentpotential.hukouaddr }}</td>
										<td class="ltd_el">政治面貌：</td>
										<td class="ltd_tag">{{ vstudentpotential.polival }}</td>
										<td class="ltd_el">民族：</td>
										<td class="ltd_tag">{{ vstudentpotential.nationval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">出生日期：</td>
										<td class="ltd_tag">{{ vstudentpotential.birthday  | formatDate("YYYY-MM-DD") }}</td>
										<td class="ltd_el">证件类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcardtypeval }}</td>
										<td class="ltd_el">证件号码：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcard }}</td>
										<td class="ltd_el">总部给予</td>
										<td v-if="vstudentpotential.hqsend == 1" class="ltd_tag">是</td>
										<td v-if="vstudentpotential.hqsend == 0" class="ltd_tag">否</td>
									</tr>
									<tr>
										<td class="ltd_el">固定电话：</td>
										<td class="ltd_tag">{{ vstudentpotential.phone }}</td>
										<td class="ltd_el">籍贯</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">意向程度：</td>
										<td class="ltd_tag">{{ vstudentpotential.intentionval }}</td>
										<td class="ltd_el">意向院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.wantcollegename }}</td>
									</tr>
									<tr>
										<td class="ltd_el">意向专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">意向专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">购买辅导教材：</td>
										<td class="ltd_tag" v-if="vstudentpotential.bookflag == 0">否</td>
										<td class="ltd_tag" v-if="vstudentpotential.bookflag == 1">是</td>
									</tr>
									<tr>
										<td class="ltd_el">辅导班：</td>
										<td class="ltd_tag"  v-if="vstudentpotential.yubaomingflag == 0">否</td>
										<td class="ltd_tag"  v-if="vstudentpotential.yubaomingflag == 1">是</td>
										<td class="ltd_el">预报名：</td>
										<td class="ltd_tag" v-if="vstudentpotential.yubaomingflag == 0">否</td>
										<td class="ltd_tag" v-if="vstudentpotential.yubaomingflag == 1">是</td>
										<td class="ltd_el">正式学生：</td>
										<td class="ltd_tag" v-if="vstudentpotential.studentflag == 0">否</td>
										<td class="ltd_tag" v-if="vstudentpotential.studentflag == 1">是</td>
										<td class="ltd_el">是否可转移：</td>
										<td class="ltd_tag" v-if=" vstudentpotential.moveflag == 0">否</td>
										<td class="ltd_tag" v-if=" vstudentpotential.moveflag == 1">是</td>
									</tr>
									<tr>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">信息来源：</td>
										<td class="ltd_tag">{{ vstudentpotential.messagefromval }}</td>
										<td class="ltd_el">住址：</td>
										<td class="ltd_tag">{{ vstudentpotential.homeaddr }}</td>
										<td class="ltd_el">邮编：</td>
										<td class="ltd_tag">{{ vstudentpotential.homezipcode }}</td>
									</tr>
									<tr>
										<td class="ltd_el">户口所在地：</td>
										<td class="ltd_tag">{{ vstudentpotential.hukouaddr }}</td>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">毕业时间：</td>
										<td class="ltd_tag">
											{{
										    vstudentpotential.graduatetime
										      | formatDate("YYYY-MM-DD HH:mm:ss")
										  }}</td>
										<td class="ltd_el">学历：</td>
										<td class="ltd_tag">{{ vstudentpotential.eduval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进次数：</td>
										<td class="ltd_tag">{{ vstudentpotential.followcount }}</td>
										<td class="ltd_el">工作类型：</td>
											<td class="ltd_tag">
												{{ vstudentpotential.jobtype }}
										</td>
										<td class="ltd_el">专科毕业专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankemajor }}</td>
										<td class="ltd_el">	参加工作时间：</td>
										<td class="ltd-tag">
											{{
												vstudentpotential.jobtime | formatDate("YYYY-MM-DD HH:mm:ss")
											}}
										</td>
									</tr>
									<tr>
										<td class="ltd_el">工作单位：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyname }}</td>
										<td class="ltd_el">操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.opusername }}</td>
										<td class="ltd_el"> 最后操作时间：</td>
										<td class="ltd_tag">
											{{
										    vstudentpotential.lastinserttime
										      | formatDate("YYYY-MM-DD HH:mm:ss")
										  }}</td>
											<td class="ltd_el">
												所属区域
											</td>
											<td class="ltd_tag">
												{{ vstudentpotential.regionname }}
											</td>
									</tr>
									<tr>
										<td class="ltd_el">城市：</td>
										<td class="ltd_tag">{{ vstudentpotential.cityval }}</td>
										<td class="ltd_el">放弃跟进原因：</td>
										<td class="ltd_tag">{{ vstudentpotential.followstatename }}</td>
										<td class="ltd_el">咨询情况：</td>
										<td class="ltd_tag">{{ vstudentpotential.question }}</td>
										<td class="ltd_el">合作企业人：</td>
										<td class="ltd_tag">{{ vstudentpotential.enterprisename }}</td>
									</tr>
									<tr>
										<td class="ltd_el">所属分公司：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyorgname }}</td>
										<td class="ltd_el">所属教学点：</td>
										<td class="ltd_tag">{{ vstudentpotential.schoolorgname }}</td>
										<td class="ltd_el">转介绍人：</td>
										<td class="ltd_tag">{{ vstudentpotential.linkman }}</td>
											<td class="ltd_el">客服：</td>
											<td class="ltd_tag">{{ vstudentpotential.kefuusername }}</td
											>
									</tr>
									<tr>
										<td class="ltd_el">专科毕业证书号：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankeno }}</td>
										<td class="ltd_el">职业：</td>
										<td class="ltd_tag">{{ vstudentpotential.job }}</td>
										<td class="ltd_el">录入时间：</td>
										<td class="ltd_tag">{{
												vstudentpotential.inserttime
													| formatDate("YYYY-MM-DD HH:mm:ss")
											}}</td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</el-form>
					</div>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 1">
					<el-table
						:data="showDetailList.studentlist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					  ></el-table-column>
					  <el-table-column label="姓名" prop="qianzainame" align="center">
					  </el-table-column>
					  <el-table-column
					    label="年级"
					    prop="grade"
					    align="center"
					  ></el-table-column>
					  <el-table-column
					    label="院校"
					    prop="collegename1"
					    align="center"
					  ></el-table-column>
					  <el-table-column
					    label="专业"
					    prop="majorname1"
					    align="center"
					  ></el-table-column>
					  <el-table-column
					    label="层次"
					    prop="levelval"
					    align="center"
					  ></el-table-column>
					  <el-table-column label="应收" prop="shouldrecvmoney" align="center">
					  </el-table-column>
					  <el-table-column label="实收" prop="realrecvmoney" align="center">
					  </el-table-column>
					  <el-table-column label="退费" prop="feebackmoney" align="center">
					  </el-table-column>
					  <el-table-column label="收据编号" prop="shoujucode" align="center">
					  </el-table-column>
					  <el-table-column label="项目类型" prop="projecttype" align="center">
					  </el-table-column>
					  <el-table-column label="教学点" prop="schoolorgname" align="center">
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 2">
					<el-table
						:data="showDetailList.studentmoneyrecvlist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						>
					  <el-table-column label="流水号" prop="id" align="center" />
					  <el-table-column label="姓名" prop="studentname" align="center" />
					  <el-table-column label="身份证" prop="idcard" align="center" />
					  <el-table-column label="金额" prop="recvmoney" align="center" />
					  <el-table-column label="缴费时间" align="center" width="160">
					    <template slot-scope="scope">
					      {{ scope.row.recvtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					  />
					  <el-table-column
					    label="经手人"
					    prop="insertusername"
					    align="center"
					  />
					  <el-table-column label="冲账" align="center">
					    <template slot-scope="scope">
					      {{ chongzhanflag[scope.row.invertflag] }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="教学点"
					    prop="schoolorgname"
					    align="center"
					  />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 3">
					<el-table
						:data="showDetailList.techfudaolist"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					  ></el-table-column>
					  <el-table-column label="姓名" prop="studentname" align="center">
					  </el-table-column>
					  <el-table-column
					    label="班别"
					    prop="fudaoclasstypename"
					    align="center"
					  ></el-table-column>

					  <el-table-column label="报读时间" align="center" width="160">
					    <template slot-scope="scope">
					      {{ scope.row.regdate | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>

					  <el-table-column label="应收" prop="totalmoney" align="center">
					  </el-table-column>
					  <el-table-column
					    label="减免金额"
					    prop="extrasubmoney"
					    align="center"
					  >
					  </el-table-column>

					  <el-table-column label="实收" prop="recvmoney" align="center">
					  </el-table-column>
					  <el-table-column
					    label="退费金额"
					    prop="feebackmoney"
					    align="center"
					  >
					  </el-table-column>
					  <el-table-column label="收据编号" prop="shoujucode" align="center">
					  </el-table-column>
					  <el-table-column label="教学点" prop="schoolorgname" align="center">
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 4">
					<el-table
						:data="showDetailList.studentregisterlist"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					  ></el-table-column>
					  <el-table-column label="姓名" prop="studentname" align="center">
					  </el-table-column>
					  <el-table-column label="年级" prop="grade" align="center">
					  </el-table-column>
					  <el-table-column label="院校" prop="collegename" align="center">
					  </el-table-column>
					  <el-table-column label="专业" prop="majorname" align="center">
					  </el-table-column>
					  <el-table-column label="层次" prop="levelval" align="center">
					  </el-table-column>
					  <el-table-column label="学年" prop="iyear" align="center">
					  </el-table-column>
					  <el-table-column label="项目类型" prop="projecttype" align="center">
					  </el-table-column>
					  <el-table-column label="教学点" prop="schoolorgname" align="center">
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 5">
					<el-table
						:data="showDetailList.studentgenzonglist"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						>
					  <el-table-column label="流水号" prop="id" align="center" />
					  <el-table-column label="学生" prop="qianzainame" align="center" />
					  <el-table-column
					    label="跟进内容"
					    prop="followcontent"
					    align="center"
					  />
					  <el-table-column label="跟进时间" align="center" width="160">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column label="跟进人" prop="username" align="center" />
					  <el-table-column
					    label="教学点"
					    prop="schoolorgname"
					    align="center"
					  />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 6">
					<el-table
						:data="showDetailList.studentfudaolist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					  ></el-table-column>
					  <el-table-column label="姓名" prop="studentname" align="center">
					  </el-table-column>
					  <el-table-column
					    label="年级"
					    prop="grade"
					    align="center"
					  ></el-table-column>

					  <el-table-column
					    label="辅导班"
					    prop="fudaoclasstypename"
					    align="center"
					  >
					  </el-table-column>

					  <el-table-column label="应收" prop="totalmoney" align="center">
					  </el-table-column>

					  <el-table-column label="实收" prop="recvmoney" align="center">
					  </el-table-column>

					  <el-table-column
					    label="优惠"
					    prop="preferentialmoney"
					    align="center"
					  >
					  </el-table-column>
					  <el-table-column
					    label="额外减免"
					    prop="extrasubmoney"
					    align="center"
					  >
					  </el-table-column>

					  <el-table-column label="退费" prop="feebackmoney" align="center">
					  </el-table-column>
					  <el-table-column label="收据编号" prop="shoujucode" align="center">
					  </el-table-column>
					  <el-table-column label="教学点" prop="schoolorgname" align="center">
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 7">
					<el-table
						:data="showDetailList.studentyubaominglist"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
						>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					  ></el-table-column>
					  <el-table-column label="姓名" prop="qianzainame" align="center">
					  </el-table-column>
					  <el-table-column
					    label="年级"
					    prop="year"
					    align="center"
					  ></el-table-column>
					  <el-table-column label="应收" prop="totalmoney" align="center">
					  </el-table-column>
					  <el-table-column label="实收" prop="recvmoney" align="center">
					  </el-table-column>
					  <el-table-column label="退费" prop="feebackmoney" align="center">
					  </el-table-column>
					  <el-table-column label="收据号" prop="shoujucode" align="center">
					  </el-table-column>
					  <el-table-column label="教学点" prop="schoolorgname" align="center">
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 8">
					<div class="demo-image__preview demo-image__error">
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证正面</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : ''"
									@click="vbs(vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证反面</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : ''"
									@click="vbs(vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">照片</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.photo ? $url.upload + vstudentpotential.photo : ''"
									@click="vbs(vstudentpotential.photo ? $url.upload + vstudentpotential.photo : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本首页</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : ''"
									@click="vbs(vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本人页</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : ''"
									@click="vbs(vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">毕业证</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src="vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : ''"
									@click="vbs(vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">注册备案表</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : ''"
									@click="vbs(vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">居住证明</p>
							<div class="image_pic_el">
								<el-image
									class="adaptation"
									:src=" vstudentpotential.residence ? $url.upload + vstudentpotential.residence : ''"
									@click="vbs(vstudentpotential.residence ? $url.upload + vstudentpotential.residence : '')"
									:preview-src-list="srcList"
									:z-index="99999"
								>
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import {
  _available,
  _bankorcash,
  _chongzhanflag,
} from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  studentfudao_list,
  studentfudao_delete,
  studentfudao_input,
  studentfudao_save,
  studentpotential_show,
  studentfudao_canfeeback,
  studentfudao_feeback,
  studentfeeback_savestufudaofeeback,
  studentfudao_canenrol,
  studentfudao_enrol,
  studentfudao_saveenrol,
  studentfudao_checkprint,
  studentfudao_print,
  studentfudao_printshoujuPaper,
  studentfudao_export1,
} from "@/request/api/allChildrenProject";
import {
  dic_combox,
  _allCompany,
  _allSchool,
  _stufudaofeestandard_dialogresult,
  _enrollplandetail_dialogresult,
  _shouju_getshoujuinfo,
  _account_dialogresult,
  _moneyinitem_dialogresult,
  _moneyintype2_dialogresult,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "studentfudao",
  components: {},
  props: {
    stufudaofeestandard_dialogresult: {
      default: () => _stufudaofeestandard_dialogresult,
    },
    moneyinitem_dialogresult: {
      default: () => _moneyinitem_dialogresult,
    },
    moneyintype2_dialogresult: {
      default: () => _moneyintype2_dialogresult,
    },
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      checkfield: (rule, value, callback) => {
        if (!(value + "")) {
          return callback(new Error("必填字段"));
        } else {
          if (isNaN(Number(value))) {
            callback(new Error("请输入数字值"));
          } else {
            callback();
          }
        }
      },
			currentTab: 0,
			currentClass: 0,
			tabList:['档案','学历报名','收费','培训','学籍','跟进','成考辅导班','代预报名','证件信息'],
      bankorcash: _bankorcash,
      itemId: null, //首表格项id
      searchForm: {
        regdate: "",
      },
			srcList: [],
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      chongzhanflag: _chongzhanflag,
      showAddModal: false, //显示表单框
      showTableModal: false, //显示项表格框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
			tshouju:{},
			vmoneyinlist:[],
			vstudentfudao:{},
			shoujuPaper:false,//打印收据
      rules: {
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        sexkey: [{ trigger: "blur", message: "必填字段", required: true }],
        nationkey: [{ trigger: "blur", message: "必填字段", required: true }],
        polikey: [{ trigger: "blur", message: "必填字段", required: true }],
        homeaddr: [{ trigger: "blur", message: "必填字段", required: true }],
        homezipcode: [{ trigger: "blur", message: "必填字段", required: true }],
        job: [{ trigger: "blur", message: "必填字段", required: true }],
        idcard: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        grade: [{ trigger: "blur", message: "必填字段", required: true }],

        fudaoclasstypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        fudaotypeval: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        fudaofee: [{ trigger: "blur", message: "必填字段", required: true }],
        bookfee: [{ trigger: "blur", message: "必填字段", required: true }],
        levelkey: [{ trigger: "blur", message: "必填字段", required: true }],
        regdate: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        fudaofee: [{ trigger: "blur", message: "必填字段", required: true }],
        recvfudaofee: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        bookfee: [{ trigger: "blur", message: "必填字段", required: true }],
        recvbookfee: [{ trigger: "blur", message: "必填字段", required: true }],
        totalmoney: [{ trigger: "blur", message: "必填字段", required: true }],
        itemmoneyinitemname1: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        shoujucode: [{ trigger: "blur", message: "必填字段", required: true }],
        itemmoneyinitemname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        typem1oneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        accountname: [{ trigger: "blur", message: "必填字段", required: true }],
        typem2oneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        typemoneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				companyorgname: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则

      // 查看详情
      showDetailModal: false,
      vstudentpotential: {},
      showDetailList: {},
      activeName: "vstudentpotential",
      tabId: null,
			key: '',
      //收费表单
      showEnrolModal: false, //收费框
      selectType: null,
      selectTableUrl: null,
      selectTableType: null,
      selectTable: {},
      showSelectItem1Modal: false, // 辅导费收入明细框
      showSelectItemModal: false, // 教材费收入明细框
      showSelectTypeModal: false, // 二级弹框
      showSelectAccountModal: false, // 银行弹框
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页

      selectSchoolorgid: null,

      nationList: [], //名族
      policyList: [], //政治面貌
      enrollyearList: [], //招生年份
      leveltypeList: [], //层次类型
      companyList: [], //所有分公司
      schoolList: [], //所有教学点

      gradeList: [], //年级

      showRefundModal: false, //退费框

      showStufudaofeesModal: false, //辅导费用框

      // 是否对象
      available: _available,
      isBookflag: {
        1: "是",
        0: "否",
      },
      isMoveflag: {
        1: "是",
        0: "否",
      },
      isStudentflag: {
        1: "是",
        0: "否",
      },
    };
  },
	watch: {
		 '$route' : "getDataList"
	},
  created() {
    this.getDataList();
    this.nation();
    this.leveltype();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].schoolorgid = "";
      this[form].companyorgid = val;
      this[form].shoujucode = "";
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		//隐藏和显示
		handleShow(){
			this.showDetailModal = false;
		},
		//改变切换栏
		toggleTab(current) {
			this.currentTab = current;
			this.currentClass = current;
		},
		vbs(val) {
			this.srcList = []
			this.srcList.push(val)
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		isokVisible(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		visibleLevel(e) {
			if(!e) {
				this.$refs.levevalref.blur();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				isokflag: null,
				schoolorgname: null,
				levelval: null,
				shoujucode: null,
				regdate: null,
				followusername: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				fudaoclasstypename: null,
				accountname: null,
				bankname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, { schoolorgid: this.dynamicValidateForm.schoolorgid });
		},
		handleselectForm() {
			this.selectSearchForm = {
				name: null,
				type1name: null,
				typename: null
			}
		},
		handleForm() {
			this.handleselectForm();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentfudao_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },

    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		//纸质版本--按钮
		btnPrintReceipt2() {
			selectCheck(this.multipleSelection, "操作", false, ()=> {
				this.selectShouju(this.multipleSelection[0].id)
			})
		},
		//检查可否打印收据 api 纸质版本
		selectShouju() {
			myRequest({
				url: studentfudao_checkprint,
				data: {
					id: this.multipleSelection[0].id,
				}
			}).then((res) => {
				if(res.data.code === "200") {
					this.shoujuPaper = true;
					this.printshoujuPaper();
				}
			})
		},
		printshoujuPaper() {
			myRequest({
			       url: studentfudao_printshoujuPaper,
			       data:{
			         id: this.multipleSelection[0].id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vstudentfudao = res.data.data.vstudentfudao;
								this.vmoneyinlist = res.data.data.vmoneyinlist;
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
			          //获得参数
								var ppzh = ReportViewer.Report.ParameterByName("pzh");
								var pname = ReportViewer.Report.ParameterByName("name");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pmajor= ReportViewer.Report.ParameterByName("major");

								var premark= ReportViewer.Report.ParameterByName("remark");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");

									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudentfudao.studentname;
									pinserttime.AsString =  formatDare(
									  		this.vstudentfudao.inserttime,
									  "YYYY-MM-DD HH:mm:ss"
									);
									plevel.AsString = this.vstudentfudao.levelval;
									//pmajor.AsString="成人高考";
                                    pmajor.AsString="";

									premark.AsString = this.vstudentfudao.shoukuanremark === null ? '' + '* 德立教育集团投诉与建议: 020-89226488' : this.vstudentfudao.shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488';
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;

								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");
								var maohao = ReportViewer.Report.FieldByName("maohao");
							 //填充字段
								Report.PrepareRecordset();
									Recordset.Append();
									fcollege.AsString = this.vstudentfudao.fudaoclasstypename;
									fitem0.AsString =this.vmoneyinlist[1].itemmoneyinitemname;//应收金额明细项名称（辅导费等）
									maohao0.AsString ="：" ;
									fprice0.AsFloat =this.vstudentfudao.fudaofee;

									if(this.vmoneyinlist[1]!=null) {

										fitem.AsString = this.vmoneyinlist[1].itemmoneyinitemname;
										maohao.AsString ="：" ;
										fprice.AsFloat = this.vmoneyinlist[1].amount;
										yuan.AsString ="元" ;
										pshoukuairen.AsString= this.vmoneyinlist[1].inusername;
										pkaipiaoren.AsString=this.vmoneyinlist[1].inusername;

									}

                                     Recordset.Post();
                                     if(this.vmoneyinlist[0].amount > 0) {//如果教材费的实收大于0
                                       Recordset.Append();
                                       fitem0.AsString = "教材费";
                                       maohao0.AsString = "：";
                                       fprice0.AsFloat = this.vstudentfudao.bookfee;//应收
                                       yuan0.AsString = "元";
                                       //Recordset.Post();
                                     }
                                    if(this.vmoneyinlist[0].amount > 0){//如果教材费的实收大于0
                                       //Recordset.Append();
                                       fitem.AsString =this.vmoneyinlist[0].itemmoneyinitemname;
                                       maohao.AsString ="：" ;
                                       fprice.AsFloat =this.vmoneyinlist[0].amount;//实收
                                       yuan.AsString ="元" ;
                                       Recordset.Post();
                                     }

								if(0-this.vstudentfudao.preferentialmoney <0){
												Recordset.Append();
												fitem0.AsString ="优惠" ;
												maohao0.AsString ="：" ;
												fprice0.AsFloat = 0-this.vstudentfudao.preferentialmoney;
												yuan0.AsString ="元" ;
												Recordset.Post();
                                }
                               if(0-this.vstudentfudao.extrasubmoney <0){
												Recordset.Append();
												fitem0.AsString ="额外减免金额";
												maohao0.AsString ="：" ;
												fprice0.AsFloat =0-this.vstudentfudao.extrasubmoney;
												yuan0.AsString ="元" ;

												Recordset.Post();
                               }


			          ReportViewer.Start();
			       }
			  });
		},

    //打印收据 click
    btnPrintReceipt() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.checkprintshouju(this.multipleSelection[0]);
      });
    },
    //检查可否打印收据 api
    checkprintshouju(row) {
      myRequest({
        url: studentfudao_checkprint,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.printReceipt(row);
        }
      });
    },
    //打印收据 api
    printReceipt(row) {
      myRequest({
        url: studentfudao_print,
        data: {
          id: row.id,
          shoujuid: row.shoujuid,
          shoujucode: row.shoujucode,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          window.open(
            this.$url.upload +
              "/shouju/" +
              row.id +
              res.data.data.tshouju.code +
              ".pdf"
          );
        }
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //修改click
    btnEdit() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(
          this.multipleSelection[0].id,
          this.multipleSelection[0].qianzaiid
        );
      });
    },
    //修改路由页 api
    editRoutePage(id, qianzaiid) {
      myRequest({
        url: studentfudao_input,
        data: {
          id: id,
          qianzaiid: qianzaiid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.policy();
          this.enrollyear();
          this.grade();
          this.dynamicValidateForm = Object.assign(
            res.data.data.tstudentpotential,
            res.data.data.tstudentfudao
          );
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.dynamicValidateForm.qianzaiid = qianzaiid;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          delete this.dynamicValidateForm.finishdate; //finishdate 该字段不用传
          delete this.dynamicValidateForm.joinuptime; //joinuptime 该字段不用传
          delete this.dynamicValidateForm.joinouttime; //joinouttime 该字段不用传
          delete this.dynamicValidateForm.lastinserttime; //lastinserttime 该字段不用传
          delete this.dynamicValidateForm.lastfollowtime; //lastfollowtime 该字段不用传
          this.dynamicValidateForm.regdate = formatDare(
            this.dynamicValidateForm.regdate,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.showAddModal = true;
          this.itemId = id;
        }
      });
    },

    //提交-（添加，修改）click
    onSubmitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitAddOrEditForm();
        }
      });
    },

    //提交-（添加，修改） api
    submitAddOrEditForm() {
      this.dynamicValidateForm.id = this.itemId;
      myRequest(
        {
          url: studentfudao_save,
          data: this.dynamicValidateForm,
        },
        {
          that: this,
          modal: "showAddModal",
          isRefTableList: true,
        }
      );
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: studentfudao_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //  查看详情 tab切换 change
    handleClick() {
      this.btnShowDetail(this.tabId);
    },

    // 查看详情 clik
    btnShowDetail(qianzaiid) {
      this.tabId = qianzaiid;
      myRequest({
        url: studentpotential_show,
        data: {
          id: qianzaiid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailList = [];
          this.showDetailList = res.data.data;
          this.vstudentpotential = {};
          this.vstudentpotential = this.showDetailList.vstudentpotential;
          this.showDetailModal = true;
        }
      });
    },

    //申请退费click
    btnApplyRefund() {
      selectCheck(this.multipleSelection, "申请退费", false, () => {
        this.checkapplyrefund(this.multipleSelection[0].id);
      });
    },
    //检查是否可以申请退费 api
    checkapplyrefund(id) {
      myRequest(
        {
          url: studentfudao_canfeeback,
          data: {
            ids: id,
          },
        },
        {
          that: this,
          methodName: "applyRefund",
        }
      );
    },
    //申请退费 api
    applyRefund() {
      myRequest({
        url: studentfudao_feeback,
        data: {
          ids: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.tstudentfudaolist[0];
          this.showRefundModal = true;
        }
      });
    },
    //保存申请退费 api
    onSubmitRefundForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentfeeback_savestufudaofeeback,
              data: {
                id: this.dynamicValidateForm.id,
                qianzaiid: this.dynamicValidateForm.qianzaiid,
                shoujuid: this.dynamicValidateForm.shoujuid,
                companyorgid: this.dynamicValidateForm.companyorgid,
                schoolorgid: this.dynamicValidateForm.schoolorgid,
                feebackmoney: this.dynamicValidateForm.recvfudaofee,
                reason: this.dynamicValidateForm.reason,
              },
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showRefundModal",
            }
          );
        }
      });
    },

    //收费 click
    btnCharge() {
      selectCheck(this.multipleSelection, "收费", false, () => {
        this.checkincollect(this.multipleSelection[0].id);
      });
    },
    //检查是否可以收费 api
    checkincollect(id) {
      myRequest({
        url: studentfudao_canenrol,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.collect(id);
        }
      });
    },
    //收费 api
    collect(id) {
      myRequest({
        url: studentfudao_enrol,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = Object.assign(
            res.data.data.tmoneyin,
            res.data.data.tstudentfudao
          );
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.dynamicValidateForm.recvmoney = this.dynamicValidateForm.totalmoney;
          this.dynamicValidateForm.recvfudaofee = this.dynamicValidateForm.fudaofee;
          this.dynamicValidateForm.recvbookfee = this.dynamicValidateForm.bookfee;
          this.dynamicValidateForm.itemmoneyinitemname1 = res.data.data.itemname1;
          this.dynamicValidateForm.itemid1 = 778728;//初始值，辅导费流水号778728
          delete this.dynamicValidateForm.regdate;
          delete this.dynamicValidateForm.inserttime;
          delete this.dynamicValidateForm.finishdate;
          this.showEnrolModal = true;
        }
      });
    },
    //获取收据编号  api
    getshoujuinfo() {
      myRequest({
        url: _shouju_getshoujuinfo,
        data: {
          schoolorgid: this.dynamicValidateForm.schoolorgid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.shoujuid = res.data.data.tshouju.id;
          this.dynamicValidateForm.shoujucode = res.data.data.tshouju.code;

        }
      });
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
    },

    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //选项表格（银行, 二级, 明细） api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //确定 - 辅导 click
    onConfirmStufudaofees() {
      selectCheck(this.multipleSelection, "辅导费用", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "fudaoclasstypename",
          this.multipleSelection[0].fudaoclasstypename
        );
        this.$set(
          this.dynamicValidateForm,
          "fudaotypeval",
          this.multipleSelection[0].fudaotypeval
        );
        this.$set(
          this.dynamicValidateForm,
          "fudaofee",
          this.multipleSelection[0].fudaofee
        );
        this.$set(
          this.dynamicValidateForm,
          "bookfee",
          this.multipleSelection[0].bookfee
        );
        this.dynamicValidateForm.classtypeid =
          this.multipleSelection[0].fudaoclasstypeid;
        this.dynamicValidateForm.fudaotypekey =
          this.multipleSelection[0].fudaotypekey;
        this.showStufudaofeesModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    // 确定 - 辅导费收入明细 click
    onConfirmMoneyinitem1() {
      selectCheck(this.multipleSelection, "辅导费收入明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "itemmoneyinitemname1",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.itemid1 = this.multipleSelection[0].id;//辅导班要传，已用上。
        this.showSelectItem1Modal = false;
				this.handleselectForm();
				this.multipleSelection = [];
      });
    },

    // 确定 - 教材费收入明细 click
    onConfirmMoneyinitem() {
      selectCheck(this.multipleSelection, "教材费收入明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "itemmoneyinitemname",
          this.multipleSelection[0].name
        );
        this.showSelectItemModal = false;
				this.handleselectForm();
				this.multipleSelection = [];
      });
    },

    // 确定 - 收入二级类别 click
    onConfirmType() {
      selectCheck(this.multipleSelection, "二级类别", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "typem2oneyintypename",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "typem1oneyintypename",
          this.multipleSelection[0].type1name
        );
        this.$set(
          this.dynamicValidateForm,
          "typemoneyintypename",
          this.multipleSelection[0].typename
        );
        this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
        this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
        this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
        this.showSelectTypeModal = false;
				this.handleselectForm();
				this.multipleSelection = [];
      });
    },

    // 确定 - 银行账户 click
    onConfirmAccount() {
      selectCheck(this.multipleSelection, "银行账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "cardno",
          this.multipleSelection[0].cardno
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showSelectAccountModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    // 提交收款 click
    onSubmitEnrolForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentfudao_saveenrol,
              data: {
                id: this.dynamicValidateForm.id, // id
                qianzaiid: this.dynamicValidateForm.qianzaiid, // 潜在id
                typeid: this.dynamicValidateForm.typeid, // 类型id
                itemid: this.dynamicValidateForm.itemid, // 明细id
                itemid1: this.dynamicValidateForm.itemid1,//明细id，已用上。
                type2id: this.dynamicValidateForm.type2id, // 二级id
                type1id: this.dynamicValidateForm.type1id, // 一级id
                shoujuid: this.dynamicValidateForm.shoujuid, // 收据id
                accountid: this.dynamicValidateForm.accountid, // 银行id
                bankorcash: this.dynamicValidateForm.bankorcash, // 现金/银行
                classtypeid: this.dynamicValidateForm.classtypeid, // 辅导班id
                schoolorgid: this.dynamicValidateForm.schoolorgid, // 教学点id
                companyorgid: this.dynamicValidateForm.companyorgid, // 分公司id
                qianzainame: this.dynamicValidateForm.qianzainame, // 学生姓名
                totalmoney: this.dynamicValidateForm.totalmoney, // 应收总金额
                recvmoney: this.dynamicValidateForm.recvmoney, // 实收总金额
                extrasubmoney: this.dynamicValidateForm.extrasubmoney, // 额外减免金额
                preferentialmoney: this.dynamicValidateForm.preferentialmoney, // 优惠金额
                fudaofee: this.dynamicValidateForm.fudaofee, // 应收辅导费
                recvfudaofee: this.dynamicValidateForm.recvfudaofee, // 实收辅导费
                bookfee: this.dynamicValidateForm.bookfee, // 应收教材费
                recvbookfee: this.dynamicValidateForm.recvbookfee, // 实收教材费
                shoukuanremark: this.dynamicValidateForm.shoukuanremark, // 开票说明
              },
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showEnrolModal",
            }
          );
        }
      });
    },

    // 民族 api
    nation() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "nation",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.nationList = res.data.data.list;
        }
      });
    },
    //政治面貌 api
    policy() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "policy",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.policyList = res.data.data.list;
        }
      });
    },
    //政治面貌 api
    enrollyear() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "enrollyear",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.enrollyearList = res.data.data.list;
        }
      });
    },
    //层次类型 api
    leveltype() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "leveltype",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.leveltypeList = res.data.data.list;
        }
      });
    },
    //年级 api
    grade() {
      myRequest({
        url: dic_combox,
        data: {
          typecode: "grade",
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.gradeList = res.data.data.list;
        }
      });
    },

    //导出 click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "请根据搜索条件进行导出。",
        success: () => {
          window.location.href =
                  this.$url.Freemarker +
                  studentfudao_export1 +
                  `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

  },
};
</script>

<style  lang="scss">
	@import "../../../assets/css/pulid.scss";
</style>
