<template>
  <div class="important-page-c display--flex fd--column">
    <el-form label-position="right">
      <el-form-item>
        <el-date-picker
          v-model="searchForm.year"
          type="year"
          format="yyyy"
          value-format="yyyy"
        />
        <el-button type="primary" @click="download">搜索并下载</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { studentdeli_exportchengkao } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
export default {
  mixins: [part],
  name: "meiyuzhaosheng",
  components: {},
  props: {},
  data() {
    return {
      searchForm: {}, //搜索
    };
  },
  created() {},
  methods: {
    download() {
      myRequest({
        url: studentdeli_exportchengkao,
        data: this.searchForm,
      }).then((res) => {
        if (res.data.code === 200) {
          location.href =
            studentdeli_exportchengkao + "year=" + this.searchForm.year;
        }
      });
    },
  },
};
</script>

<style  lang="scss">
</style>