<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="年级:">
          <el-input 
						v-model.trim="searchForm.grade" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="考试批次:">
          <el-input 
						v-model.trim="searchForm.batch" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="层次:">
          <el-select 
						v-model="searchForm.levelval" 
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in leveltype"
              :value="item.val"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校:">
          <el-input 
						v-model.trim="searchForm.collegename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="专业:">
          <el-input 
						v-model.trim="searchForm.majorname"
						 @keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="院校" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row)">{{
            scope.row.collegename
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="专业"
        prop="majorname"
        align="center"
        width="200"
      />
      <el-table-column label="层次" prop="levelval" align="center" width="80" />
      <el-table-column label="年级" prop="grade" align="center" width="80" />
      <el-table-column
        label="课程名称"
        prop="coursename"
        align="center"
        width="350"
      />
      <el-table-column
        label="数量（份）"
        prop="count"
        align="center"
        width="100"
      />
      <el-table-column
        label="考试批次"
        prop="batch"
        align="center"
        width="100"
      />
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="150"
      />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="课程流水号:">
          {{ dynamicValidateForm.courseid }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="数量（份）:">
          {{ dynamicValidateForm.count }}
        </el-form-item>
        <el-form-item label="用户流水号:">
          {{ dynamicValidateForm.userid }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
        <el-form-item label="考试批次:">
          {{ dynamicValidateForm.batch }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="课程:">
          {{ dynamicValidateForm.coursename }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程名称:" prop="coursename">
          <el-input v-model="dynamicValidateForm.coursename" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                coursemanagement_dialogresult,
                'showcourseModal',
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="层次:" prop="levelval">
          <el-input v-model="dynamicValidateForm.levelval" disabled />
        </el-form-item>
        <el-form-item label="年级:" prop="grade">
          <el-input v-model.trim="dynamicValidateForm.grade" clearable/>
        </el-form-item>
        <el-form-item label="数量（份）:" prop="count">
          <el-input v-model.trim="dynamicValidateForm.count" clearable/>
        </el-form-item>
        <el-form-item label="考试批次:" prop="batch">
          <el-input v-model.trim="dynamicValidateForm.batch" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择课程 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择课程："
      :close-on-click-modal='false'
      :visible.sync="showcourseModal"
			v-if="showcourseModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCourse">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
          <el-form-item label="课程:" prop="coursename">
              <el-input 
								v-model.trim="selectSearchForm.coursename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="院校:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="majorname">
              <el-input 
								v-model.trim="selectSearchForm.majorname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="专业层次:" prop="majorlevelval">
              <el-input 
								v-model.trim="selectSearchForm.majorlevelval" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="课程" prop="coursename" align="center" />
        <el-table-column label="院校" prop="collegename" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column label="专业" prop="majorname" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 导入 -->
    <Import
      :importDialog="importDialog"
      :importSaveUrl="paperprint_importsave"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
      downloadUrl="download/教学管理/学历/试卷统计导入模板.xls"
    ></Import>
  </div>
</template>


<script>
import { _approveflag } from "@/assets/js/filedValueFlag";
import Import from "@/components/Import/index.vue";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  paperprint_list,
  paperprint_input,
  paperprint_save,
  paperprint_delete,
  paperprint_exporttotal2,
  paperprint_importsave,
} from "@/request/api/allChildrenProject";
import {
  _fileupload_save,
  _filedownload_save,
  _coursemanagement_dialogresult,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "paperstock",
  components: {
    Import,
  },
  props: {
    coursemanagement_dialogresult: {
      default: () => _coursemanagement_dialogresult,
    },
    paperprint_importsave: {
      default: () => paperprint_importsave,
    },
  },
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {
        grade: "",
        batch: "",
        levelval: "",
        majorname: "",
        collegename: "",
        schoolorgname: "",
      },
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        courseid: [{ trigger: "blur", message: "必填字段", required: true }],
        coursename: [{ trigger: "blur", message: "必填字段", required: true }],
        levelval: [{ trigger: "blur", message: "必填字段", required: true }],
        grade: [{ trigger: "blur", message: "必填字段", required: true }],
        count: [{ trigger: "blur", message: "必填字段", required: true }],
        batch: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      courseTable: {}, //课程表格
      showcourseModal: false,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      leveltype: [],
      selectSearchForm: {},
      selectTable: {}, // 选择的对象
      selectTableUrl: null, // 选择的路径
      
      selectSchoolorgid: null, // modal框
      approveflag: _approveflag,
      importDialog: false, // 导入
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.dic_combox({
      list: "leveltype",
      typecode: "leveltype",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", [
      "dic_combox",
      "org_combox",
      "org_comboxchild",
    ]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },

    // 导入关闭
    onCloseImport() {
      this.importDialog = false;
    },
    // 导入
    btnImport() {
      this.importDialog = true;
    },

    clearSearchForm() {},

    //导出总表 click api
    btnExport() {
      if (
        this.searchForm.grade !== "" &&
        this.searchForm.batch !== "" &&
        this.searchForm.levelval !== ""
      ) {
        confirmCallBack({
          title: "提示",
          content: "根据搜索条件进行导出。",
          success: () => {
            window.location.href =
              this.$url.Freemarker +
              paperprint_exporttotal2 +
              `?${createObjParams(this.searchForm)}`;
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      } else {
        this.$message({
          type: "warning",
          message: "请填写年级, 层次, 考试批次",
        });
      }
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		reset() {
			this.searchForm = {
				grade: null,
				batch: null,
				levelval: null,
				collegename: null,
				majorname: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				coursename: null,
				collegename: null,
				majorname: null,
				majorlevelval: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData( this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: paperprint_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: paperprint_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vpaperprint;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        companyorgname: null,
        schoolorgname: null,
        courseid: null,
        coursename: null,
        levelval: null,
        grade: null,
        count: null,
        batch: null,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.showAddModal = true;

      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //确认课程 click
    onConfirmCourse() {
      selectCheck(this.multipleSelection, "确认课程", false, () => {
        this.$set(this.dynamicValidateForm,'coursename',this.multipleSelection[0].coursename)
        this.$set(this.dynamicValidateForm,'levelval',this.multipleSelection[0].majorlevelval)
        this.dynamicValidateForm.courseid = this.multipleSelection[0].id;
        this.showcourseModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onSelectData();
    },

    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },


    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: paperprint_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vpaperprint;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: paperprint_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: paperprint_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>