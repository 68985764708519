<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="统计月份:">
            <el-date-picker
              v-model="searchForm.month"
              type="month"
              clearable
              value="yyyy-MM"
              value-format="yyyy-MM"
            />
          </el-form-item>

          <el-form-item label="结账标识:">
            <el-select
              v-model="searchForm.finishflag"
              placeholder="请选择"
							@visible-change="visiblefinis"
							@keyup.enter.native="seach"
							ref="finishflag"
              clearable
            >
              <el-option label="已结账" value="1"></el-option>
              <el-option label="未结账" value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="操作人:">
            <el-input
							v-model.trim="searchForm.opusername"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
			<el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="月份" prop="month" align="center" />
      <el-table-column label="结账标识" align="center" prop="finishflag">
        <template slot-scope="scope">
          {{ scope.row.finishflag === 1 ? "已结账" : "未结账" }}
        </template>
      </el-table-column>
      <el-table-column label="操作人" prop="opusername" align="center"/>

      <el-table-column label="操作时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.opdate | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
				label="备注"
				prop="remark"
				align="center"
				:show-overflow-tooltip="true"
			 />
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
      />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="120px"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="month" label="月份:">
          <el-date-picker
            v-model="dynamicValidateForm.month"
            type="month"
            format="yyyy-MM"
            value-format="yyyy-MM"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item prop="companyorgname" label="所属分公司:">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点:">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 登账明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
      title="登账明细："
			v-dialogDrag
      :visible.sync="showRJModal"
			:close-on-click-modal='false'
      v-if="showRJModal"
			@close="onCloseSelect"
      append-to-body
    >
      <!-- 搜索 -->
      <div class="index_2">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
						:model="selectSearchFormList"
						@submit.native.prevent
          >
            <el-form-item label="摘要:">
              <el-input
								v-model.trim="selectSearchFormList.zhaiyao"
								@keyup.enter.native="selectSeach"
								clearable />
            </el-form-item>
            <el-form-item label="收入金额:">
              <el-input
								v-model.trim="selectSearchFormList.moneyinamount"
								@keyup.enter.native="selectSeach"
								clearable />
            </el-form-item>
            <el-form-item label="支出金额:">
              <el-input
								v-model.trim="selectSearchFormList.moneyoutamount"
								@keyup.enter.native="selectSeach"
								clearable />
            </el-form-item>
            <el-form-item label="对方单位:">
              <el-input
								v-model.trim="selectSearchFormList.unitname"
								@keyup.enter.native="selectSeach"
								clearable />
            </el-form-item>
            <el-form-item label="收据编号:">
              <el-input
								v-model.trim="selectSearchFormList.shoujucode"
								@keyup.enter.native="selectSeach"
								clearable />
            </el-form-item>
            <el-form-item label="支出编号:">
              <el-input
								v-model.trim="selectSearchFormList.moneyoutcode"
								@keyup.enter.native="selectSeach"
								clearable />
            </el-form-item>
            <el-form-item label="交易类型:">
              <el-select
								v-model="selectSearchFormList.jiaoyitype"
								@keyup.enter.native="selectSeach"
								@visible-change="visibleType"
								ref="selectref"
								clearable>
                <el-option value="0" label="收入"></el-option>
                <el-option value="1" label="支出"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="selectSeach"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            >搜索</el-button
          >
					<el-button type="primary" @click="primaryQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <!-- 操作按钮 -->
      <div class="select-btn" style="padding-bottom: 20px;">
        <el-button type="primary" @click="btnAddRJ">增加</el-button>
        <el-button type="primary" @click="btnDelRJ">删除</el-button>
        <el-button type="primary" @click="btnTagmoneoutcodeRJ"
          >标注支出编号</el-button
        >
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
				:height="tableHeight"
				:key="key"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
        show-summary
        :summary-method="RJsummary"
      >
        <el-table-column type="selection" width="40" align="center" />
        <el-table-column label="流水号" prop="id" align="center" width="120" />
        <el-table-column label="摘要" prop="zhaiyao" align="center" width="250" />
        <el-table-column label="报销时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.outtime | formatDate("YYYY-MM-DD") }}
          </template>
        </el-table-column>

        <el-table-column label="收入金额" prop="moneyinamount" align="center" />
        <el-table-column
          label="支出金额"
          prop="moneyoutamount"
          align="center"
        />
				<el-table-column label="账户名称" prop="accountname" align="center" />
				<el-table-column label="卡号" prop="cardno" align="center" />
        <el-table-column label="对方单位" prop="unitname" align="center" />
        <el-table-column label="收据编号" prop="shoujucode" align="center" />
        <el-table-column label="支出编号" prop="moneyoutcode" align="center" width="60" />
        <el-table-column label="报销人" prop="outusername" align="center" width="70"/>
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
			<el-pagination
			  background
				@size-change="handleSizeChange"
			  @current-change="(val, data)=>handleSelectChange(val, Object.assign(
          {
            regjournalid: this.itemRow.id,
          },
          this.selectSearchFormList
        ))"
			  :current-page="selectPageInfo.pageindex"
			  :page-size="selectPageInfo.pagesize"
			  :page-sizes="[10,50,100,200,500,1000,2000]"
			  layout="total, sizes, prev, pager, next, jumper"
			  :total="selectTable.totalcount"
			></el-pagination>
    </el-dialog>
    <!-- 登账明细增加 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
      title="登账明细增加"
			:close-on-click-modal='false'
      :visible.sync="showRJAddModal"
      v-if="showRJAddModal"
			@close="onCloseSelectadd"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmDetailAdd">确定</el-button>
      <!-- 搜索 -->
      <div
        class="index_2"
        :style="{
          height: $store.state.global.isBtnExtend_2
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="$store.state.global.isBtnExtend_2 ? '' : 'overflow--hidden'"
          :model="selectSearchForm"
					@submit.native.prevent
        >
          <el-form-item label="流水号:">
            <el-input
							v-model.trim="selectSearchForm.id"
							@keyup.enter.native="selectSearchadd"
							clearable />
          </el-form-item>
          <el-form-item label="收据编号:">
            <el-input
							v-model.trim="selectSearchForm.shoujucode"
							@keyup.enter.native="selectSearchadd"
							clearable />
          </el-form-item>
          <el-form-item label="操作时间起:">
            <el-date-picker
              type="datetime"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="selectSearchForm.t1"
              clearable
            />
          </el-form-item>
          <el-form-item label="操作时间止:">
            <el-date-picker
              type="datetime"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="selectSearchForm.t2"
              clearable
            />
          </el-form-item>
					<el-form-item label="收据起:">
					  <el-input
							v-model.trim="selectSearchForm.t3"
							@keyup.enter.native="selectSearchadd"
							clearable />
					</el-form-item>
					<el-form-item label="收据止:">
					 <el-input
							v-model.trim="selectSearchForm.t4"
							@keyup.enter.native="selectSearchadd"
							clearable />
					</el-form-item>
          <el-form-item label="对方单位:">
            <el-input
							v-model.trim="selectSearchForm.unitname"
							@keyup.enter.native="selectSearchadd"
							clearable />
          </el-form-item>
          <el-form-item label="摘要:">
            <el-input
							v-model.trim="selectSearchForm.zhaiyao"
							@keyup.enter.native="selectSearchadd"
							clearable />
          </el-form-item>
          <el-form-item label="交易类型:">
              <el-select
								v-model="selectSearchForm.jiaoyitype"
								@keyup.enter.native="selectSearchadd"
								clearable>
                <el-option value="" label="全部"></el-option>
                <el-option value="0" label="收入"></el-option>
                <el-option value="1" label="支出"></el-option>
              </el-select>
            </el-form-item>
          <el-form-item label="分页大小:">
            <el-input-number
              class="inputNumber"
              v-model="rjDetailPage.pagesize"
              :min="10"
              :max="100000"
              label="描述文字"
              @keyup.enter.native="selectSearchadd"
            >
          </el-input-number>
        </el-form-item>
					<el-form-item label="收入金额:">
					  <el-input
							v-model.trim="selectSearchForm.moneyinamount"
							@keyup.enter.native="selectSearchadd"
							clearable />
					</el-form-item>
					<el-form-item label="支出金额:">
					  <el-input
							v-model.trim="selectSearchForm.moneyoutamount"
							@keyup.enter.native="selectSearchadd"
							clearable />
					</el-form-item>
        </el-form>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="selectSearchadd"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="handleQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isBtnExtend_2
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.btnExtend_2"
          class="btn-2b5a95"
          @click="onExtendClick('isBtnExtend_2')"
          >{{ $store.state.global.BtnExtendText_2 }}</el-button
        >
      </div>
      <el-table
        :data="rjDetailTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column
          label="摘要"
          prop="zhaiyao"
          align="center"
          width="150"
        />
        <el-table-column
          label="账户名称"
          prop="accountname"
          align="center"
          width="150"
        />
        <el-table-column label="卡号" prop="cardno" align="center" />
        <el-table-column
          label="操作日期"
          prop="opdate"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.opdate | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="收入金额" prop="moneyinamount" align="center" />
        <el-table-column
          label="支出金额"
          prop="moneyoutamount"
          align="center"
        />
        <el-table-column label="对方单位名称" prop="unitname" align="center" />
        <el-table-column label="收据编号" prop="shoujucode" align="center" />
        <el-table-column label="报销人" prop="outusername" align="center" />
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
      <div></div>
      <el-pagination
        background
				@current-change="handleDetailTableChange"
        :current-page="rjDetailPage.pageindex"
        :page-size="rjDetailPage.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="rjDetailTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 标注支出编号 -->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
      title="标注支出编号："
			:close-on-click-modal='false'
      :visible.sync="showCodeModal"
      v-if="showCodeModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="支出编号:" prop="moneyoutcode1">
          <el-input v-model.trim="dynamicValidateForm.moneyoutcode1" clearable />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="savemoneyoutcode('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import { part } from "@/utils/mixins";
import {
  registerjournal_list,
  registerjournal_input,
  registerjournal_save,
  registerjournal_delete,
  registerjournaldetail_list,
  registerjournal_checksettle,
  registerjournal_settle,
  registerjournal_checkreturnsettle,
  registerjournal_returnsettle,
  registerjournal_exportreport,
  registerjournaldetail_checkoperatedetail,
  journalaccount_dialogresult,
  registerjournaldetail_savedetail,
  registerjournaldetail_delete,
  registerjournaldetail_export,
  registerjournaldetail_exportexcel,
  registerjournaldetail_exportsave,
  registerjournaldetail_checkmoneyoutcode,
  registerjournaldetail_savemoneyoutcode,
} from "@/request/api/allChildrenProject";
import { _pictureupload_save } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "registerjournal",
  components: { Export },
  props: {
    exportUrl: {
      default: () => registerjournaldetail_export,
    },
    exportexcelUrl: {
      default: () => registerjournaldetail_exportexcel,
    },
    exportsaveUrl: {
      default: () => registerjournaldetail_exportsave,
    },
  },
  data() {
    return {
			tableHeight: 600,
      transferDialog: false, //导出框
      itemRow: null, //首表格项
      searchForm: {
        id: "",
        month: "",
        opusername: "",
        schoolorgname: "",
      },
      selectSearchForm: {}, // 详情页搜索
			selectSearchFormList: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        month: [{ trigger: "blur", message: "必填字段", required: true }],
				companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				schoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				moneyoutcode1:[{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      selectTable: {},
      selectTableUrl: "",
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      showRJModal: false, //

      showRJAddModal: false, //明细新增框
      rjDetailTable: {}, //流水账
      rjDetailPage: {
        pageindex: 1,
        pagesize: 10,
      },
			key: '',
      showCodeModal: false,
    };
  },
  created() {
    this.org_combox();
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
	mounted () {
	  //固定表头
	     this.$nextTick(function() {
	       this.tableHeight =
	         window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 50;

	       // 监听窗口大小变化
	       const self = this
	       window.onresize = function() {
	         self.tableHeight =
	           window.innerHeight - self.$refs.multipleTable.$el.offsetTop - 50;
	       };
	     });
	},
  methods: {
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		selectQuery() {
			this.selectSearchForm = {
				zhaiyao: null,
				moneyinamount:null,
				moneyoutamount: null,
				unitname: null,
				shoujucode: null,
				moneyoutcode: null,
				jiaoyitype: null,
				id: null,
				shoujucode: null,
				t1:null,
				t2:null,
				t3: null,
				t4: null,
				pagesize: 10,
			}
		},
		queryForm() {
			this.selectSearchFormList = {
				zhaiyao: null,
				jiaoyitype: null,
				moneyinamount:null,
				moneyoutamount: null,
				unitname: null,
				shoujucode: null,
				moneyoutcode: null,
			}
		},
		primaryQuery () {
			this.queryForm();
			this.onSelectData(this.selectTableUrl, null, {regjournalid: this.itemRow.id});
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, {schoolorgid: this.itemRow.schoolorgid});
		},
		reset() {
			this.searchForm = {
				month: null,
				finishflag: null,
				opusername: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visiblefinis(e) {
			if(!e) {
				this.$refs.finishflag.blur();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: registerjournal_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
		//切换页码
		handleSizeChange(val) {
			this.selectPageInfo.pagesize = val;
			this.onSelectData(registerjournaldetail_list, "showRJModal",
			Object.assign(
          {
            regjournalid: this.itemRow.id,
          },
          this.selectSearchFormList
        ))
		},
    //切换流水账表格页
    handleDetailTableChange(val) {
      this.rjDetailPage.pageindex = val;
			this.journalaccountL();
			this.onCloseSelectadd();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //登账明细 click
    btnRegisterdetail() {
      selectCheck(this.multipleSelection, "登账明细", false, () => {
        this.itemRow = this.multipleSelection[0];
        this.onSelectData(registerjournaldetail_list, "showRJModal", {
          regjournalid: this.itemRow.id,
        });
      });
    },
    //结账 click
    btnSettle() {
      selectCheck(this.multipleSelection, "结账", false, () => {
        this.checksettle();
      });
    },
    //反结账 click
    btnReturnSettle() {
      selectCheck(this.multipleSelection, "反结账", false, () => {
        this.checkreturnsettle();
      });
    },
    //导出报表 click
    exportAllTable() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.exportbyid(this.multipleSelection[0].id);
      });
    },

    //导出报表 api
    exportbyid(id) {
      myRequest({
        url: registerjournal_exportreport,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.status === 200) {
          window.open(
            this.$url.Freemarker + registerjournal_exportreport + "?id=" + id
          );
        }
      });
    },

    //确认流水账 click
    onConfirmDetailAdd() {
      selectCheck(this.multipleSelection, "确认流水账", true, () => {
        this.savedetail();
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //登账明细添加 click
    btnAddRJ() {
      this.selectSearchForm = {

			};
			this.selectSearchForm.schoolorgid = this.itemRow.schoolorgid;
      this.checkoperatedetail();
    },
    //登账明细删除 click
    btnDelRJ() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.detailDel(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //登账明细标注支出编号 click
    btnTagmoneoutcodeRJ() {
      selectCheck(this.multipleSelection, "标注支出编号", true, () => {
        this.dynamicValidateForm = {};
        this.dynamicValidateForm.ids = this.multipleSelection.map((i) => i.id);
        this.checkmoneyoutcode();
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: registerjournal_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vregisterjournal;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          this.dynamicValidateForm.opdate = formatDare(
            this.dynamicValidateForm.opdate,
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: registerjournal_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: registerjournal_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //校验是否可反结账 api
    checkreturnsettle() {
      myRequest({
        url: registerjournal_checkreturnsettle,
        data: {
          id: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          confirmCallBack({
            title: "提示",
            content: "确定反结账吗？",
            success: () => {
              this.returnsettle();
            },
            fail: () => {
              messageTip({
                message: "取消操作",
                type: "info",
              });
            },
          });
        }
      });
    },
    //校验是否可结账 api
    checksettle() {
      myRequest(
        {
          url: registerjournal_checksettle,
          data: {
            id: this.multipleSelection[0].id,
          },
        },
        {
          disableTip: true,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.settle();
        } else {
          confirmCallBack({
            title: "提示",
            content: res.data.msg,
            confirmButtonText: "继续",
            cancelButtonText: "取消",
            success: () => {
              this.settle();
            },
            fail: () => {
              messageTip({
                message: "取消操作",
                type: "info",
              });
            },
          });
        }
      });
    },
    //结账 api
    settle() {
      myRequest(
        {
          url: registerjournal_settle,
          data: {
            id: this.multipleSelection[0].id,
          },
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //反结账 api
    returnsettle() {
      myRequest(
        {
          url: registerjournal_returnsettle,
          data: {
            id: this.multipleSelection[0].id,
          },
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选项表格 api（登账明细）
    onSelectData(url, modal, data, pageKey) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this[pageKey] || this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data, pageKey) {
      this.selectPageInfo.pageindex = 1;
      this[pageKey].pageindex = 1;
      this.onSelectData(url, modal, data, pageKey);
    },
		onCloseSelectadd() {
			this.rjDetailPage.pageindex = 1;
		},
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },
    // 搜索(登账明细)
    selectSeach() {
      this.onSelectData(
        this.selectTableUrl,
        "showRJModal",
        Object.assign(
          {
            regjournalid: this.itemRow.id,
          },
          this.selectSearchFormList
        )
      );
			this.selectPageInfo.pageindex = 1;
    },
    //明细新增校验 api
    checkoperatedetail() {
      myRequest(
        {
          url: registerjournaldetail_checkoperatedetail,
          data: {
            regjournalid: this.itemRow.id,
          },
        },
        {
          that: this,
          methodName: "journalaccountL",
        }
      );
    },

    //登账明细 - 表格合计逻辑 event
    RJsummary(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 1) {
          sums[
            index
          ] = `共${this.selectTable.totalcount}条数据，收入${this.selectTable.totalin}条，支出${this.selectTable.totalout}条`;
          return;
        }

				if(index === 4) {
					sums[index] = `总收入金额 ${this.selectTable.moneyin}`
					return;
				}

				if(index === 5) {
					sums[index] = `总支出金额 ${this.selectTable.moneyout}`
					return;
				}
			/*	if (column.property === "moneyinamount") {
					 sums[index] = data
						.map((i) => i.moneyinamount)
						.reduce((pre, cur) => pre + cur).toFixed(2)//收入金额总计
				} */
       /* if (column.property === "moneyoutamount") {
          sums[index] = data
            .map((i) => i.moneyoutamount)
            .reduce((pre, cur) => pre + cur).toFixed(2)//支出金额总计
        }*/
      });
      return sums;
    },
    //登账明细 - 增加 - 搜索 click
    selectSearchadd() {
			this.rjDetailPage.pageindex = 1;
      this.journalaccountL();
    },
    //登账明细 - 增加 - 列表  api
    journalaccountL() {
			this.selectSearchForm.schoolorgid = this.itemRow.schoolorgid;
      myRequest({
        url: journalaccount_dialogresult,
        data: Object.assign(this.selectSearchForm, this.rjDetailPage),
      }).then((res) => {
        if (res.data.code === "200") {
          this.rjDetailTable = res.data.data;
          this.showRJAddModal = true;
        }
      });
    },

    //保存流水账 api
    savedetail() {
      myRequest({
        method: "post",
        url: registerjournaldetail_savedetail,
        data: this.$qs.stringify(
          {
            regjournalid: this.itemRow.id,
            ids: this.multipleSelection.map((i) => i.id),
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.onSelectData(this.selectTableUrl, null, {
            regjournalid: this.itemRow.id,
          });
          this.showRJAddModal = false;
					this.selectSearchForm.moneyinamount = '';
					this.selectSearchForm.moneyoutamount = '';
        }
      });
    },
    //登账明细删除 api
    detailDel(idArr) {
      myRequest({
        method: "post",
        url: registerjournaldetail_delete,
        data: this.$qs.stringify(
          {
            ids: idArr,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.onSelectData(registerjournaldetail_list, null, {
            regjournalid: this.itemRow.id,
          });
        }
      });
    },
    //校验支出编号 api
    checkmoneyoutcode() {
      myRequest(
        {
          method: "post",
          url: registerjournaldetail_checkmoneyoutcode,
          data: this.$qs.stringify(this.dynamicValidateForm, {
            arrayFormat: "repeat",
          }),
        },
        {
          that: this,
          showModal: "showCodeModal",
        }
      );
    },
    //保存支出编号 api
    savemoneyoutcode() {
      myRequest({
        method: "post",
        url: registerjournaldetail_savemoneyoutcode,
        data: this.$qs.stringify(this.dynamicValidateForm, {
          arrayFormat: "repeat",
        }),
      }).then((res) => {
        if (res.data.code === "200") {
          this.onSelectData(registerjournaldetail_list, null, {
            regjournalid: this.itemRow.id,
          });
          this.showCodeModal = false;
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
.base_table{
	margin: auto;
	}
</style>
