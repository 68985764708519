<template>
  <div>
    <div id="market" class="market-w"></div>
    <!-- 该标签用来触发 updated 函数 -->
    <div class="loading-end">{{isLoading}}</div>
  </div>
</template>



<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import { performance_market } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
export default {
  mixins: [part],
  name: "market",
  created() {
    this.chengkao();
  },

  mounted() {},

  data() {
    return {
      performancelist: [
        {
          name: "地区",
          prop: "add",
          data: ["地区"],
        },
        {
          name: "年度",
          prop: "yearcount",
          data: ["年度"],
        },
        {
          name: "上月",
          prop: "premonthcount",
          data: ["上月"],
        },
        {
          name: "本月",
          prop: "curmonthcount",
          data: ["本月"],
        },
        {
          name: "昨天",
          prop: "predaycount",
          data: ["昨天"],
        },
        {
          name: "今天",
          prop: "curdaycount",
          data: ["今天"],
        },
      ], //后台数据字段，最后生成图表的渲染数据，这种数据结构只适应当前图表
      isLoading:true, //是否结束加载
    };
  },

  methods: {
    ...mapActions("global", ["onExtendClick"]),
    initEchart() {
      // echart
      const dom = document.getElementById("market");
      const myChart = this.$echarts.init(dom);

      let option = {
        title: {
          subtext: "市场部业绩汇总",
        },
        legend: {},
        tooltip: {},
        dataset: {
          source: this.performancelist.map((i) => i.data),
        },
        grid: {
          width: "1100px",
					left: "50px",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                formatter: function (params) {
                  return params.value.replace("\n", "");
                },
              },
            },
          },
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: [
          {
            name: "名单数",
          },
        ],
        series: [
          { type: "bar", barWidth: "15%" },
          { type: "bar", barWidth: "15%" },
        ],
      };
      myChart.setOption(option);
    },
    //api
    chengkao() {
      myRequest({
        url: performance_market,
      }).then((res) => {
        if (res.data.code === "200") {
          this.whileData(res.data.data.performancelist);
          this.initEchart();
					this.isLoading = false
        }
      });
    },

    //赋值数据
    whileData(data) {
      for (let i in this.performancelist) {
        for (let dataI in data) {
          this.performancelist[i].data.push(
            data[dataI][this.performancelist[i].prop]
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.market-w {
  width: 100%;
  height: 400px;
}
.loading-end{
    display: none;
  }
</style>