<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="标题:">
            <el-input 
							v-model.trim="searchForm.title" 
							@keyup.enter.native="seach"
							clearable 
							/>
					</el-form-item>	
					<el-form-item label="内容:">
					  <el-input 
							v-model.trim="searchForm.content" 
							@keyup.enter.native="seach"
							clearable 
							/>
					</el-form-item>	
					<el-form-item label="处理人:">
					  <el-input 
							v-model.trim="searchForm.username" 
							@keyup.enter.native="seach"
							clearable 
							/>
					</el-form-item>	
					<el-form-item label="阅读标志:">
					 <el-select 
							v-model="searchForm.readflag"
							@keyup.enter.native="seach" 
							@visible-change="visibleType"
							ref="selectref"
							clearable>
					 	<el-option
					 		v-for="(item, index) in readflags"
					 		:key="index"
					 		:label="item.val"
					 		:value="item.key"
					 	/>
					 </el-select>
					</el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button
					type="primary"
					@click="resetQuery"
					class="btn-2b5a95"
				>
					重置
				</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="标题" prop="title" align="center" width="250px">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.title
          }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="内容" prop="content" align="center" :show-overflow-tooltip="true"/>
      <el-table-column label="是否已阅读" align="center">
        <template slot-scope="scope">
          {{ scope.row.readflag == 1 ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="阅读时间" align="center" width="160px">
        <template slot-scope="scope">
          {{ scope.row.readtime | formatDate("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="处理人" prop="username" align="center" />
      <el-table-column label="录入时间" align="center" width="160px">
        <template slot-scope="scope">
          {{ scope.row.inserttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="操作" class-name="btn-2b5a95" width="130px" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="btnDispose(scope.row)"
            >去处理</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
        <tr>
          <td>标题：{{ dynamicValidateForm.title }}</td>
          <td>处理网址：{{ dynamicValidateForm.url }}</td>
					<td>录入时间：{{ dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")  }}</td>
        </tr>
        <tr>
          <td>阅读时间：{{ dynamicValidateForm.readtime | formatDate("YYYY-MM-DD HH:mm:ss")  }}</td>
					<td>对象名称：{{ dynamicValidateForm.objectname }}</td>
					<td v-if="dynamicValidateForm.processflag == 0">处理标志：否</td>
					<td v-if="dynamicValidateForm.processflag == 1">处理标志：是</td>
        </tr>
        <tr>
          <td>处理时间：{{ dynamicValidateForm.processtime | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td>用户编号：{{ dynamicValidateForm.usercode }}</td>
          <td>用户名称：{{ dynamicValidateForm.username }}</td>
        </tr>
        <tr>
          <td>部门名称：{{ dynamicValidateForm.departorgname }}</td>
          <td>所属教学点编号：{{ dynamicValidateForm.schoolorgcode }}</td>
					<td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
        </tr>
        <tr>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
					<td>流水号: {{dynamicValidateForm.id}}</td>
					<td></td>
        </tr>
				<tr>
					<td colspan="4">内容：{{ dynamicValidateForm.content }}</td>
				</tr>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import {
  notice_list,
  notice_approve,
  notice_list2,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
  setSession,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "referral",
  data() {
    return {
			readflags: [
				{
					key: 0,
					val: '未读',
				},
				{
					key: 1,
					val: '已读',
				}
			],
      itemRow: null, //表格项
      searchForm: {
        readflag:1
      },
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      deliTable: {},
      showDeliModal: false, //德立学生

      showDetailModal: false,
      isDisable: false,
    };
  },
  created() {
    this.searchForm.readflag = this.$route.params.readflag;
		this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("aside", ["getMenuItem"]),
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				title: null,
				readflag: null,
				content: null,
				username: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //列表查询 api
    getDataList(searchForm) {
      myRequest({
        url: notice_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList(this.searchForm);
    },
    //切换德立学生表格页
    handleDeliChange(val) {
      this.pageinfo.pageindex = val;
      this.onSelectDeli();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    // 去处理 click
    btnDispose(row) {
      this.itemRow = row;
      const { url } = row;
      let regRes = url.match(/.+\//)
      if(!regRes) return
      let name = regRes[0].replace(/\/*/g, "");
      this.getMenuItem({
        id: row.moduleid,
        list: ["thirdMenuId", "secondMenuId", "mainMenuId"],
      }); //每当改变最终菜单激活项都需要调 getMenuItem
      this.$router.push({name: name, params:{id: row.objectid}});
      this.noticeApprove();
    },
    //去处理（表示已读）api
    noticeApprove() {
      myRequest({
        url: notice_approve,
        data: {
          id: this.itemRow.id,
        },
      })
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },

  
};
</script>


<style  lang="scss">
	.base_table{
		margin: auto;
	}
</style>