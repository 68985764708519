<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="项目类型:">
          <el-select 
						v-model="searchForm.projecttype" 
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级:">
          <el-input 
						v-model.trim="searchForm.grade" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="院校:">
          <el-input 
						v-model.trim="searchForm.collegename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column label="院校" align="center" width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row)">{{
            scope.row.collegename
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="专业"
        prop="majorname"
        align="center"
        width="150"
      />
      <el-table-column label="层次" prop="levelval" align="center" />
      <el-table-column label="学习形式" prop="studytypeval" align="center" />
      <el-table-column label="考试科目" prop="coursename" align="center" />
      <el-table-column label="考试时间始" align="center" width="200px">
        <template slot-scope="scope">
          {{ scope.row.starttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="考试时间止" align="center" width="200px">
        <template slot-scope="scope">
          {{ scope.row.endtime | formatDate("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
			<el-table-column label="监考老师" align="center" prop="teacher" width="100px"></el-table-column>
      <el-table-column label="考试地点" prop="address" align="center" />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="80%"
      :visible.sync="showDetailModal"
			:close-on-click-modal='false'
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle "
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="学习形式:">
          {{ dynamicValidateForm.studytypeval }}
        </el-form-item>
        <el-form-item label="考试科目:">
          {{ dynamicValidateForm.coursename }}
        </el-form-item>
        <el-form-item label="考试时间始:">
          {{
            dynamicValidateForm.starttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="考试时间止:">
          {{ dynamicValidateForm.endtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
        <el-form-item label="考试地点:">
          {{ dynamicValidateForm.address }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			width="60%"
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          <el-input v-model="dynamicValidateForm.id" disabled />
        </el-form-item>
        <el-form-item label="项目类型:" prop="projecttype">
          <el-select v-model="dynamicValidateForm.projecttype" disabled>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级:" prop="grade">
          <el-select v-model="dynamicValidateForm.grade" disabled>
            <el-option
              v-for="item in grades"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学习形式:" prop="studytypeval">
          <el-select v-model="dynamicValidateForm.studytypeval" clearable>
            <el-option
              v-for="item in studyTypevals"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校:" prop="collegename">
          <el-input v-model="dynamicValidateForm.collegename" disabled />
          <el-button type="primary" class="select-btn" @click="onSelectDeli" disabled
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="专业" prop="majorname">
          <el-input v-model="dynamicValidateForm.majorname" disabled />
        </el-form-item>
        <el-form-item label="考试科目:" prop="coursename">
          <el-input type="text" v-model.trim="dynamicValidateForm.coursename" clearable/>
        </el-form-item>
        <el-form-item label="考试地点:" prop="address">
          <el-input type="text" v-model.trim="dynamicValidateForm.address" clearable/>
        </el-form-item>
        <el-form-item label="考试时间始:" prop="starttime">
          <el-date-picker
            type="datetime"
            v-model="dynamicValidateForm.starttime"
            value-format="yyyy-MM-dd hh:mm:ss"
            format="yyyy-MM-dd hh:mm:ss"
						clearable
          />
        </el-form-item>
        <el-form-item label="考试时间止:" prop="endtime">
          <el-date-picker
            type="datetime"
            v-model="dynamicValidateForm.endtime"
            value-format="yyyy-MM-dd hh:mm:ss"
            format="yyyy-MM-dd hh:mm:ss"
						clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 选择院校-->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择院校："
      :visible.sync="showDeliModal"
			v-if="showDeliModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-button type="primary" @click="onConfirmDeli">确 定</el-button>
      <el-table
        :data="deliTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="院校名称" prop="collegename" align="center" />
        <el-table-column label="专业名称" prop="name" align="center" />
        <el-table-column label="专业层次" prop="majorlevelval" align="center" />
        <el-table-column label="专业类型" prop="majortypeval" align="center" />
        <el-table-column label="是否招生标志" align="center">
          <template slot-scope="scope">
            {{ scope.row.enableflag == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="handleDeliChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="deliTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
      downloadUrl="download/教学管理/学历/学历考试安排导入模板.xls"
    ></Import>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";

import Import from "@/components/Import/index.vue";
import {
  examplan_list,
  examplan_delete,
  examplan_input,
  examplan_save,
  examplan_importsave,
  examplan_export2,
  collegemajor_dialogresult1,
} from "@/request/api/allChildrenProject";
import { _fileupload_save, dic_combox } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _projectTypes } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "examplan",
  components: { Import },
  props: {
    importSaveUrl: {
      default: () => examplan_importsave,
    },
  },

  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      projectTypes: [], // 项目类型数组
      grades: [], // 年级数组
      studyTypevals: [], // 学习形式数组
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        projecttype: [{ trigger: "blur", message: "必填字段", required: true }],
        grade: [{ trigger: "blur", message: "必填字段", required: true }],
        studytypeval: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        collegename: [{ trigger: "blur", message: "必填字段", required: true }],
        majorname: [{ trigger: "blur", message: "必填字段", required: true }],
        iscompulsory: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        coursetype: [{ trigger: "blur", message: "必填字段", required: true }],
        coursename: [{ trigger: "blur", message: "必填字段", required: true }],
        credit: [{ trigger: "blur", message: "必填字段", required: true }],
        totalhours: [{ trigger: "blur", message: "必填字段", required: true }],
        semester: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      projectTypes: _projectTypes,
      collegeTable: {},
      showCollegeModal: false,

      deliTable: {},
      showDeliModal: false, //德立学生

      importDialog: false,
    };
  },
  created() {
    this.getDataList();
    // 年级
    this.getGradeList("grade", "grades");
    // 学习形式
    this.getGradeList("studytype", "studyTypevals");
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    // 年级
    getGradeList(params, list) {
      myRequest({
        url: dic_combox,
        data: {
          typecode: params,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this[list] = res.data.data.list;
        }
      });
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		reset() {
			this.searchForm = {
				projecttype:null,
				grade: null,
				collegename: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: examplan_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换德立学生表格页
    handleDeliChange(val) {
      this.pageinfo.pageindex = val;
      this.onSelectDeli();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: examplan_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vexamplan;
        }
      });
    },

    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //确认德立学生 click
    onConfirmDeli() {
      selectCheck(this.multipleSelection, "确认德立学生", false, () => {
        this.dynamicValidateForm.collegemajorid = this.multipleSelection
          .map((i) => i.id)
          .join(",");

this.$set(
          this.dynamicValidateForm,
          "collegename",
          this.multipleSelection.map((i) => i.collegename).join(",")
        );
this.$set(
          this.dynamicValidateForm,
          "majorname",
          this.multipleSelection.map((i) => i.name).join(",")
        );
        this.showDeliModal = false;
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: examplan_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm.starttime = formatDare(
            res.data.data.vexamplan.starttime,
            "YYYY-MM-DD HH:mm:ss"
          );

          this.dynamicValidateForm.endtime = formatDare(
            res.data.data.vexamplan.endtime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm = res.data.data.vexamplan;
          this.dynamicValidateForm.starttime = formatDare(
            this.dynamicValidateForm.starttime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.endtime = formatDare(
            this.dynamicValidateForm.endtime,
            "YYYY-MM-DD HH:mm:ss"
          );
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: examplan_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              
              modal: "showAddModal",
            }
          );
        } else {
          this.$message({
            type: "warning",
            message: "红星必填",
          });
        }
      });
    },

    // 开启导入
    btnImport() {
      this.importDialog = true;
    },

    // 关闭导入 click
    onCloseImport() {
      this.importDialog = false;
    },

    //导出总表 click api
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            examplan_export2 +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: examplan_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //德立学生  api
    onSelectDeli() {
      myRequest({
        url: collegemajor_dialogresult1,
        data: this.pageinfo,
      }).then((res) => {
        if (res.data.code === "200") {
          this.multipleSelection = [];
          this.deliTable = res.data.data;
          this.showDeliModal = true;
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>