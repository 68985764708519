<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="院校:">
          <el-input 
						v-model.trim="searchForm.collegename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="年级:">
          <el-input 
						v-model.trim="searchForm.grade" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="项目类型:">
          <el-select 
						v-model="searchForm.projecttype" 
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生姓名" align="center" width="120px">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row)">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="手机号码"
        prop="mobile"
        align="center"
        width="130"
      />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column label="院校" prop="collegename" align="center" width="160px"/>
      <el-table-column label="专业" prop="majorname" align="center" width="160px"/>
      <el-table-column label="层次" prop="levelval" align="center" />
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column label="类别" prop="type" align="center" />
      <el-table-column label="课程" prop="coursename" align="center" />
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="downloadFile(scope.row)" type="primary" size="mini"
            >下载</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 60%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学生姓名:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="手机号码:">
          {{ dynamicValidateForm.mobile }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="所属教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="所属分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="类别:">
          {{ dynamicValidateForm.type }}
        </el-form-item>
        <el-form-item label="课程:">
          {{ dynamicValidateForm.coursename }}
        </el-form-item>
        <el-form-item label="证件路径:">
          {{ dynamicValidateForm.fileurl }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类别" prop="type">
          <el-select v-model="dynamicValidateForm.type" clearable>
            <el-option value="统考"></el-option>
            <el-option value="课程"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="课程"
          prop="coursename"
          v-if="dynamicValidateForm.type == '课程'"
        >
          <el-input v-model="dynamicValidateForm.coursename" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                courseplan_dialogresult,
                'showCoursenameModal',
              )
            "
            >选择课程</el-button
          >
        </el-form-item>
        <el-form-item label="姓名" prop="studentname">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                studentregister_dialogresult4,
                'showDeliModal',
                {schoolorgid:dynamicValidateForm.schoolorgid,}  
              )
            "
            >选择学生</el-button
          >
        </el-form-item>
        <el-form-item prop="fileurl" label="附件">
          <el-input v-model="dynamicValidateForm.fileurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择德立学生-->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择德立学生："
      :visible.sync="showDeliModal"
			v-if="showDeliModal"
			:close-on-click-modal='false'
      append-to-body
      
    >
      <el-button type="primary" @click="onConfirmDeli">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="学生姓名:" prop="studentname">
              <el-input 
								v-model.trim="selectSearchForm.studentname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="手机号码:" prop="mobile">
              <el-input 
								v-model.trim="selectSearchForm.mobile" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
								clearable />
            </el-form-item>
            
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="100px"/>
        <el-table-column label="学生姓名" prop="studentname" align="center" />
        <el-table-column
          label="手机号码"
          prop="mobile"
          align="center"
          width="180"
        />
        <el-table-column label="学号" prop="code" align="center" width="120" />
        <el-table-column
          label="院校"
          prop="collegename"
          align="center"
          width="180"
        />
        <el-table-column label="学年" prop="iyear" align="center" />
        <el-table-column label="省内/省外" prop="areaval" align="center" width="90px"/>
        <el-table-column label="入学年级" prop="grade" align="center" />
        <el-table-column label="类别" prop="keleival" align="center" />
        <el-table-column label="层次" prop="levelval" align="center" />
        <el-table-column
          label="专业"
          prop="majorname"
          align="center"
          width="180"
        />
        <el-table-column label="学习方式" prop="studytypeval" align="center" />
        <el-table-column
          label="学籍状态"
          prop="xuejistatusval"
          align="center"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
					width="180px"
        />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择课程-->
    <el-dialog
      class="btn-2b5a95"
      width="83%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择课程："
      :visible.sync="showCoursenameModal"
			v-if="showCoursenameModal"
			:close-on-click-modal='false'
      append-to-body
      
    >
      <el-button type="primary" @click="onConfirmCoursename">确定</el-button>


<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="majorname">
              <el-input 
								v-model.trim="selectSearchForm.majorname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="课程名称:" prop="coursename">
              <el-input 
								v-model.trim="selectSearchForm.coursename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,selectSearchForm)
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="showQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="院校" prop="collegename" align="center" width="300px"/>
        <el-table-column label="专业" prop="majorname" align="center" />
        <el-table-column label="项目类型" prop="projecttype" align="center" />
        <el-table-column label="年级" prop="grade" align="center" />
        <el-table-column label="层次" prop="levelval" align="center" />
        <el-table-column label="学习形式" prop="studytypeval" align="center" />
        <el-table-column label="课程名称" prop="coursename" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,selectSearchForm)"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  exempt_list,
  exempt_input,
  exempt_save,
  exempt_delete,
  studentregister_dialogresult4,
  courseplan_dialogresult,
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
  _filedownload_save,
  _fileupload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _projectTypes } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "exempt",
  components: {},
  props: {
    studentregister_dialogresult4: {
      default: () => studentregister_dialogresult4,
    },
    courseplan_dialogresult: {
      default: () => courseplan_dialogresult,
    },
  },
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        studentname: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
        type: [{ trigger: "blur", message: "必填字段", required: true }],
        coursename: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      projectTypes: _projectTypes,
      collegeTable: {},
      showCollegeModal: false,

      showDeliModal: false, //德立学生
      showCoursenameModal: false, // 课程
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTableUrl: null,
      selectTable: {},
      
      selectSchoolorgid: null,
      selectSearchForm: {},
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		reset() {
			this.searchForm = {
				collegename: null,
				grade: null,
				projecttype: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				studentname: null,
				mobile: null,
				collegename: null,
				majorname: null,
				coursename: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, {schoolorgid: this.dynamicValidateForm.schoolorgid});
		},
		showQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: exempt_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: exempt_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vexempt;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        fileurl: null,
        coursename: null,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.fileurl = res.data.url;
        }
      });
    },
    //下载 click
    downloadFile(row) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.fileurl;
    },
    //确认德立学生 click
    onConfirmDeli() {
      selectCheck(this.multipleSelection, "确认德立学生", false, () => {
        this.dynamicValidateForm.studentregisterid = this.multipleSelection
          .map((i) => i.id)
          .join(",");
this.$set(
          this.dynamicValidateForm,
          "studentname",
          this.multipleSelection.map((i) => i.studentname).join(",")
        );
        this.showDeliModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认课程 click
    onConfirmCoursename() {
      selectCheck(this.multipleSelection, "确认课程", false, () => {
        this.dynamicValidateForm.courseplanid = this.multipleSelection
          .map((i) => i.id)
          .join(",");
          this.$set(
          this.dynamicValidateForm,
          "coursename",
          this.multipleSelection.map((i) => i.coursename).join(",")
        );
        this.showCoursenameModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: exempt_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vexempt;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      if (this.dynamicValidateForm.type !== "课程") {
        delete this.dynamicValidateForm.coursename;
        delete this.dynamicValidateForm.courseplanid;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: exempt_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: exempt_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
    },

    

    //选项表格 api(德立学生)
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

    
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>