<template>
  <div class="important-page-c display--flex fd--column office-links-w">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="流水号:">
            <el-input 
							v-model.trim="searchForm.id" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="标题:">
            <el-input 
							v-model.trim="searchForm.title" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="类型:">
            <el-select 
							v-model="searchForm.type" 
							@visible-change="visibleType"
							@keyup.enter.native="seach"
							ref="projecttyperef"
							placeholder="请选择" 
							clearable>
              <el-option
                v-for="item in linkAddTypes"
                :key="item.key"
                :label="item.val"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- link main -->
    <div class="link-main-w">
      <h2>学习平台</h2>
      <div v-if="tableRes.study && tableRes.study.length" class="links-w">
        <div class="link-i-w" v-for="(item, i) in tableRes.study" :key="i">
          <div class="link-i">
            <a class="title" @click="onClickLink(item.url)">{{ item.title }}</a>
            <div
              class="link-c-w"
              @mouseenter="onLinkEnter(item.id)"
              @mouseleave="onLinkLeave"
            >
              <a :href="item.url" target="blank" style="display: block"
                ><img :src="$url.upload + item.icon" alt="" />
              </a>
              <div class="link-mask-w" v-if="linkId === item.id">
                <div class="link-btn" @click="btnEdit(item)">
                  <i class="el-icon-edit"></i>
                </div>
                <div class="link-btn" @click="btnDel">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>管理平台</h2>
      <div v-if="tableRes.management && tableRes.management.length" class="links-w">
        <div class="link-i-w" v-for="(item, i) in tableRes.management" :key="i">
          <div class="link-i">
            <a class="title" @click="onClickLink(item.url)">{{ item.title }}</a>
            <div
              class="link-c-w"
              @mouseenter="onLinkEnter(item.id)"
              @mouseleave="onLinkLeave"
            >
              <a :href="item.url" target="blank" style="display: block"
                ><img :src="$url.upload + item.icon" alt="" />
              </a>
              <div class="link-mask-w" v-if="linkId === item.id">
                <div class="link-btn" @click="btnEdit(item)">
                  <i class="el-icon-edit"></i>
                </div>
                <div class="link-btn" @click="btnDel">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag	
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="标题:" prop="title">
          <el-input v-model.trim="dynamicValidateForm.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="链接:" prop="url">
          <el-input v-model.trim="dynamicValidateForm.url" clearable></el-input>
        </el-form-item>
        <el-form-item label="图标:" prop="icon">
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            :file-list="fileList"
            :on-remove="onRemoveIcon"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="类型:" prop="type">
          <el-select
            v-model="dynamicValidateForm.type"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in linkAddTypes"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  links_list,
  links_input,
  links_save,
  links_delete,
} from "@/request/api/allChildrenProject";
import { _fileupload_save, _filedownload_save } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _linkAddTypes, _linkAddType } from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "building",
  components: {},
  props: {
    linkAddTypes: {
      default: () => _linkAddTypes,
    },
    linkAddType: {
      default: () => _linkAddType,
    },
  },
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {
        study:[],
        management:[],
      }, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        url: [{ trigger: "blur", message: "必填字段", required: true }],
        icon: [{ trigger: "blur", message: "必填字段", required: true }],
        type: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      fds: new FormData(),

      linkId: null,
      fileList: [],
    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		reset() {
			this.searchForm = {
				id: null,
				title: null,
				type: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: links_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
          this.tableRes.study = this.tableRes.list.filter(i=>i.type === '学习平台')
          this.tableRes.management = this.tableRes.list.filter(i=>i.type === '管理平台')
        }
      });
    },

    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.fileList = [];
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //修改click
    btnEdit(item) {
      this.dialogTitle = "修改";
      this.editRoutePage(this.itemId);
    },

    //初始化修改 event
    initEdit() {
      this.fileList = [];
      this.fileList.push({
        name: this.dynamicValidateForm.icon.slice(
          this.dynamicValidateForm.icon.lastIndexOf("/") + 1,
          this.dynamicValidateForm.icon.length
        ),
        url: this.dynamicValidateForm.icon,
      });
    },

    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: links_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vlinks;
          this.initEdit();
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          this.showAddModal = true;
        }
      });
    },

    //图标文件删除 remove
    onRemoveIcon(file, fileList) {
      if (!fileList.length) {
        this.dynamicValidateForm.icon = "";
      }
    },

    //删除click
    btnDel() {
      confirmCallBack({
        title: "提示",
        content: "此操作将永久删除选中的数据！",
        success: () => {
          this.delTableItem();
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: links_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem() {
      myRequest(
        {
          method: "post",
          url: links_delete,
          data: this.$qs.stringify(
            {
              ids: [this.itemId],
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.fds = new FormData();
          this.$set(this.dynamicValidateForm, "icon", res.data.url);
        }
      });
    },

    //link移入
    onLinkEnter(id) {
      this.linkId = id;
      this.itemId = id;
    },
    //link移出
    onLinkLeave() {
      this.linkId = null;
    },
    //link-标题 click
    onClickLink(url) {
      window.open(url);
    },
  },
};
</script>

<style  lang="scss" scoped>
.office-links-w {
}
.link-main-w {
  padding: 10px;
}

.links-w {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  .link-i-w {
    position: relative;
  }
  .link-i {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    img {
      width: 150px;
      height: 150px;
    }
    .title {
      cursor: pointer;
      color: blue;
    }
  }
}

.link-mask-w {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.link-c-w {
  position: relative;
}
.link-btn {
  margin: 0 8px;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
}
</style>