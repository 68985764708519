


<template>
  <div>
    <div id="kefu" class="kefu-w"></div>
    <!-- 该标签用来触发 updated 函数 -->
    <div class="loading-end">{{isLoading}}</div>
  </div>
</template>



<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import { performance_kefu } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
export default {
  mixins: [part],
  name: "kefu",
  created() {
    this.kefu();
  },

  mounted() {},

  data() {
    return {
      performancelist: [
        {
          name: "年度",
          type: "bar",
          prop: "yearcount",
          data: [],
          barWidth: "20",
        },
        {
          name: "上月",
          type: "bar",
          prop: "premonthcount",
          data: [],
          barWidth: "20",
        },
        {
          name: "本月",
          type: "bar",
          prop: "curmonthcount",
          data: [],
          barWidth: "20",
        },
        {
          name: "昨天",
          type: "bar",
          prop: "predaycount",
          data: [],
          barWidth: "20",
        },
        {
          name: "今天",
          type: "bar",
          prop: "curdaycount",
          data: [],
          barWidth: "20",
        },
      ], //后台数据字段，最后生成图表的渲染数据，这种数据结构只适应当前图表

      userNames: [], //客服用户名
      isLoading:true, //是否结束加载

    };
  },

  methods: {
    ...mapActions("global", ["onExtendClick"]),
    initEchart() {
      // echart
      const dom = document.getElementById("kefu");
      const myChart = this.$echarts.init(dom);

      let option = {
        title: {
          subtext: "客服业绩汇总",
        },
        tooltip: {},
        legend: {
          left: "right",
          data: ["年度", "上月", "本月", "昨天", "今天"],
        },
        grid: {
          width: "1000px",
          top: 80,
          bottom: 50,
          left: 60,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                formatter: function (params) {
                  return params.value.replace("\n", "");
                },
              },
            },
          },
        },
        xAxis: [
          {
            type: "category",
            name: "排名（从左往右依次排名）",
            axisLabel: {
              interval: 0,
            },
            data: this.userNames,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "招生人数",
          },
        ],
        series: this.performancelist,
      };
      myChart.setOption(option);
    },
    //api
    kefu() {
      myRequest({
        url: performance_kefu,
      }).then((res) => {
        if (res.data.code === "200") {
          this.userNames = res.data.data.performancelist.map(
            (item) => item.kefuname
          );
          this.whileData(res.data.data.performancelist);
          this.initEchart();
          this.isLoading = false
        }
      });
    },
    //数据赋值
    whileData(data) {
      for (let i in this.performancelist) {
        for (let dataI in data) {
          this.performancelist[i].data.push(
            data[dataI][this.performancelist[i].prop]
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kefu-w {
  width: 100%;
  height: 400px;
}
.loading-end{
    display: none;
  }
</style>