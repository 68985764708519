<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="院校:">
            <el-input 
							v-model.trim="searchForm.collegename1" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="专业:">
            <el-input 
							v-model.trim="searchForm.majorname1" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="年级:">
            <el-input 
							v-model.trim="searchForm.grade" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="函授站:">
            <el-input 
							v-model.trim="searchForm.guakaoschoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="项目类型:">
            <el-select
              v-model="searchForm.projecttype"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, i) in projectTypes"
                :key="i"
                :label="item.val"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :data="tableRes.list"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        label="姓名"
        prop="qianzainame"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        label="年级"
        prop="grade"
        align="center"
      ></el-table-column>
      <el-table-column
        label="院校"
        prop="collegename1"
        align="center"
      ></el-table-column>
      <el-table-column
        label="专业"
        prop="majorname1"
        align="center"
      ></el-table-column>
      <el-table-column
        label="层次"
        prop="levelval"
        align="center"
      ></el-table-column>
      <el-table-column label="确认" prop="isokflag" align="center" width="80">
        <template slot-scope="scope">{{
          available[scope.row.isokflag]
        }}</template>
      </el-table-column>
      <el-table-column
        label="项目类型"
        prop="projecttype"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="函授站"
        prop="guakaoschoolorgname"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      >
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";

import { student_guakaoschoollist } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _pictureupload_save } from "@/request/api/public";
import { _available,_projectTypes } from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "guakaoschool",
  components: {},
  props: {
    available: {
      default: () => _available,
    },
    projectTypes: {
      default: () => _projectTypes,
    },
  },
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        code: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		//重置
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				collegename1: null,
				majorname1: null,
				grade: null,
				guakaoschoolorgname: null,
				projecttype: null,
				schoolorgname: null
				
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: student_guakaoschoollist,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
   btnRefresh() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>