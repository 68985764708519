<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
					<el-form-item label="流水号:">
					  <el-input 
							v-model.trim="searchForm.id" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="学生姓名:">
            <el-input 
							v-model.trim="searchForm.studentname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="缴费金额:">
            <el-input 
							v-model.trim="searchForm.recvmoney" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="收据编号:">
            <el-input 
							v-model.trim="searchForm.shoujucode" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="手机号码:">
            <el-input 
							v-model.trim="searchForm.mobile" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="经手人姓名:">
            <el-input 
							v-model.trim="searchForm.insertusername" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="费用类型:">
            <el-input 
							v-model.trim="searchForm.feetypeval"
							 @keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="所属教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="基表流水号:">
            <el-input 
							v-model.trim="searchForm.objectid"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="所属分公司:">
            <el-input 
							v-model.trim="searchForm.companyorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="是否冲账:">
            <el-select
              v-model="searchForm.invertflag"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in availables"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
					<el-form-item label="录入时间起:">
					  <el-date-picker
					    v-model="searchForm.t1"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd 00:00:00"
					  >
					  </el-date-picker>
					</el-form-item>
					<el-form-item label="结束时间止:">
					  <el-date-picker
					    v-model="searchForm.t2"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd 00:00:00"
					  >
					  </el-date-picker>
					</el-form-item>
					<el-form-item label="审核标识:">
					  <el-select 
							v-model="searchForm.approveflag" 
							@keyup.enter.native="seach"
							@visible-change="visibleApproveflag"
							ref="visibleRef"
							clearable>
					    <el-option value="-2" label="未提请审批" />
					    <el-option value="-1" label="审核中" />
					    <el-option value="0" label="审核不通过" />
					    <el-option value="1" label="通过" />
					  </el-select>
					</el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="学生" prop="studentname" align="center" />
      <el-table-column label="手机号" prop="mobile" align="center" width="120">
				<template slot-scope="scope" v-if="scope.row.mobile !== null">
					  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
				</template>
			</el-table-column>
      <el-table-column label="身份证" prop="idcard" align="center" width="125">
				<template slot-scope="scope">
					{{scope.row.idcard | onlyIDcard()}}
				</template>
      </el-table-column>
      <el-table-column label="费用类型" prop="feetypeval" align="center" />
      <el-table-column
        label="现金/银行"
        prop="accountname"
        align="center"
				width="205"
      >
				<template slot-scope="scope">
					{{scope.row.accountname | bankCard()}}
				</template>
      </el-table-column>
      <el-table-column label="金额" prop="recvmoney" align="center" />
      <el-table-column label="缴费时间" prop="recvtime" align="center" width="120">
        <template slot-scope="scope">
          {{ scope.row.recvtime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column label="收据编号" prop="shoujucode" align="center" width="120"/>
      <el-table-column label="跟进人" prop="followusername" align="center" />
      <el-table-column label="经手人" prop="insertusername" align="center" />
			<el-table-column label="打印次数" prop="printcount" align="center" />
      <el-table-column label="教学点" prop="schoolorgname" align="center" width="120"/>
      <el-table-column label="录入时间" prop="inserttime" align="center">
        <template slot-scope="scope">
          {{ scope.row.inserttime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
			<el-table-column
			  label="审核标识"
			  prop="approveflag"
			  align="center"
			  width="120"
			>
			  <template slot-scope="scope">
			    {{ approveflag[scope.row.approveflag] }}
			  </template>
			</el-table-column>
			
      <el-table-column label="冲账标志" prop="invertflag" align="center">
        <template slot-scope="scope">
          {{ available[scope.row.invertflag] }}
        </template>
      </el-table-column>

      <el-table-column label="冲账时间" prop="inverttime" align="center">
        <template slot-scope="scope">
          {{ scope.row.inverttime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column label="被冲流水号" prop="invertid" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--冲账-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="600px"
      title="冲账："
			:close-on-click-modal='false'
      :visible.sync="showBalanceModal"
      v-if="showBalanceModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="150px"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="studentname" label="被冲学生姓名：">
          <el-input v-model.trim="dynamicValidateForm.studentname" clearable/>
        </el-form-item>
        <el-form-item prop="shoujucode" label="被冲收据编号：">
          <el-input v-model.trim="dynamicValidateForm.shoujucode" clearable/>
        </el-form-item>
        <el-form-item prop="recvmoney" label="冲账金额：">
          <el-input v-model.trim="dynamicValidateForm.recvmoney" clearable/>
        </el-form-item>
        <el-form-item prop="newshoujucode" label="收据编号：">
          <el-input v-model.trim="dynamicValidateForm.newshoujucode" clearable/>
        </el-form-item>
        <el-form-item prop="zhiyao" label="摘要：">
          <el-input v-model.trim="dynamicValidateForm.zhiyao" clearable/>
        </el-form-item>
        <el-form-item prop="invertreason" label="冲账原因：">
          <el-input v-model.trim="dynamicValidateForm.invertreason" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="savebalance('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 打印收据 -->
		<el-dialog
			class="btn-2b5a95"
			width="1000px"
			v-dialogDrag
			:close-on-click-modal='false'
			@close="onCloseShouju"
			title="打印收据:"
			:visible.sync="shoujuPaper"
			v-if="shoujuPaper"
			append-to-body
		>
			<object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer" width="900px" height="500px" style="margin: auto;">
			    <param name="ReportURL" :value="this.$url.upload + '/report/registshouju.grf'">
			    <param name="DataURL" value="" >
			    <param name="AutoRun" value=true >
			    <param name="SerialNo" value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
			    <param name="UserName" value="锐浪报表插件本机开发测试注册" >
			</object>
		</el-dialog>
		<!-- 冲账审批 -->
		<el-dialog
		  class="btn-2b5a95"
		  title="冲账审批："
			v-dialogDrag
			:close-on-click-modal='false'
		  :visible.sync="showApproval1Modal"
		  v-if="showApproval1Modal"
		  append-to-body
		  width="35%"
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    class="demo-dynamic form-item-w-100"
		    status-icon
		    
		    :rules="rules"
		  >
		    <el-form-item
		      label="审核是否通过："
		      prop="passflag"
		      :rules="[
		        {
		          required: true,
		          message: '必填字段',
		          trigger: 'blur',
		        },
		      ]"
		    >
		      <el-select
		        v-model="dynamicValidateForm.passflag"
		        placeholder="请选择"
		        clearable
		      >
		        <el-option
		          v-for="item in [
		            { value: 1, label: '通过' },
		            { value: 0, label: '不通过' },
		          ]"
		          :key="item.value"
		          :label="item.label"
		          :value="item.value"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		    <el-form-item
		      label="审批意见："
		      prop="approvedesc"
		      :rules="[
		        {
		          required: true,
		          message: '必填字段',
		          trigger: 'blur',
		        },
		      ]"
		    >
		      <el-input
		        type="textarea"
		        v-model.trim="dynamicValidateForm.approvedesc"
		      ></el-input>
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="headApproval('dynamicValidateForm')"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>
  </div>
</template>

<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  studentmoneyrecv_list,
  studentmoneyrecv_printshouju,
  studentmoneyrecv_invert,
  studentmoneyrecv_invertsave,
	studentmoneyrecv_printshoujuPaper,
	studentmoneyrecv_exportstudentmoneyrecv,
	studentmoneyrecv_requestapprove,
	studentmoneyrecv_approvecheck,
	studentmoneyrecv_approvesave,
} from "@/request/api/allChildrenProject";
import {} from "@/request/api/public";

import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _projectTypes,
  _availables,
  _available,
	 _approveflag,
} from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "academic",
  components: {},
  props: {
    availables: {
      default: () => _availables,
    },
    available: {
      default: () => _available,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
			shoujuPaper:false,//收据打印
      searchForm: {id: null},
			showApproval1Modal: false, //负责人审批
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        studentname: [{ trigger: "blur", message: "必填字段", required: true }],
        shoujucode: [{ trigger: "blur", message: "必填字段", required: true }],
        recvmoney: [{ trigger: "blur", message: "必填字段", required: true }],
        newshoujucode: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        zhiyao: [{ trigger: "blur", message: "必填字段", required: true }],
        invertreason: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
      }, //表单规则
      dialogTitle: "", //对话框标题
      showBalanceModal: false, //冲账
			approveflag: _approveflag,
    };
  },
  created() {
		this.searchForm.id = this.$route.params.id;
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		reset() {
			this.searchForm = {
				studentname: null,
				recvmoney: null,
				shoujucode: null,
				mobile: null,
				insertusername: null,
				feetypeval: null,
				schoolorgname: null,
				objectid: null,
				companyorgname: null,
				invertflag: null,
				approveflag: null,
				id: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		visibleApproveflag(e) {
			if(!e) {
				this.$refs.visibleRef.blur();
			}
		},
    //列表查询 api
    getDataList() {
			if(this.searchForm.id == undefined) {
				delete this.searchForm.id
			} else {
				this.searchForm.id
			}
      myRequest({
        url: studentmoneyrecv_list,
        data: Object.assign(this.searchForm,this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
		btnImport() {
			confirmCallBack({
			  title: "提示",
			  content: "根据搜索条件进行导出。",
			  success: () => {
			    window.location.href =
			      this.$url.Freemarker +
			      studentmoneyrecv_exportstudentmoneyrecv +
			      `?${createObjParams(this.searchForm)}`;
			  },
			  fail: () => {
			    messageTip({
			      message: "取消操作",
			      type: "info",
			    });
			  },
			});
		},
		//提请审批 click
		btnRequestApprove() {
		  selectCheck(this.multipleSelection, "提请审批", true, () => {
		    confirmCallBack({
		      title: "提示",
		      content: "是否提请审批？",
		      success: () => {
		        this.requestapprove(this.multipleSelection.map((i) => i.id));
		      },
		      fail: () => {
		        messageTip({
		          message: "取消操作",
		          type: "info",
		        });
		      },
		    });
		  });
		},
		//提请审批 api
		requestapprove(ids) {
		  myRequest(
		    {
		      method: "post",
		      url: studentmoneyrecv_requestapprove,
		      data: this.$qs.stringify(
		        {
		          ids: ids,
		        },
		        { arrayFormat: "repeat" }
		      ),
		    },
		    {
		      isRefTableList: true,
		      that: this,
		    }
		  );
		},
		//负责人审批 click
		approvalWite() {
		  selectCheck(this.multipleSelection, "负责人审批", true, () => {
		    this.approvecheck();
		  });
		},
		//检查是否可以审批 api
		approvecheck() {
		  myRequest({
		    method: "post",
		    url: studentmoneyrecv_approvecheck,
		    data: this.$qs.stringify(
		      {
		        ids: this.multipleSelection.map((i) => i.id),
		      },
		      {
		        arrayFormat: "repeat",
		      }
		    ),
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.dynamicValidateForm = {};
		      this.showApproval1Modal = true;
		    }
		  });
		},
		//负责人审批 api
		headApproval(dynamicValidateForm) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
		      myRequest(
		        {
		          method: "post",
		          url: studentmoneyrecv_approvesave,
		          data: this.$qs.stringify(
		            {
		              ids: this.multipleSelection.map((i) => i.id),
		              passflag: this.dynamicValidateForm.passflag,
		              approvedesc: this.dynamicValidateForm.approvedesc,
		            },
		            {
		              arrayFormat: "repeat",
		            }
		          ),
		        },
		        {
		          isRefTableList: true,
		          that: this,
		
		          modal: "showApproval1Modal",
		        }
		      );
		    }
		  });
		},
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		//关闭收据弹框
		onCloseShouju() {
			this.shoujuPaper = false;
		},
		//打印收据纸质
		btnPrint2() {
			selectCheck(this.multipleSelection, "操作", false, ()=> {
				this.shoujuPaper = true;
				this.printshoujuPaper(this.multipleSelection[0])
			})
			
		},
		printshoujuPaper() {
			myRequest({
			       url: studentmoneyrecv_printshoujuPaper,
			       data:{
			         id: this.multipleSelection[0].id,
							shoujuid: this.multipleSelection[0].shoujuid
			         	}
			     }).then((res) => {
							//console.log(res,'这个是什么东西---')
			       if (res.data.code === "200") {
									this.sessionuser = res.data.data.sessionuser;
									this.vmoneyin = res.data.data.vmoneyin;
									this.vtechfudao = res.data.data.vtechfudao;
									this.tshouju = res.data.data.tshouju;
									this.vmoneyinlist = res.data.data.vmoneyinlist;
									this.vstudentmoneyrecv = res.data.data.vstudentmoneyrecv;
									this.vstudentregisterfeedetail = res.data.data.vstudentregisterfeedetail;
									this.vstudentfudao = res.data.data.vstudentfudao;
									this.vstudentyubaoming = res.data.data.vstudentyubaoming;
									this.vstudentbook = res.data.data.vstudentbook;
									this.vstudentpotential = res.data.data.vstudentpotential;
									this.studentbookdetaillist = res.data.data.studentbookdetaillist;
									this.vgeneralmoneyiecvdetail = res.data.data.vgeneralmoneyiecvdetail;
									this.vtechkjbaokao=res.data.data.vtechkjbaokao;
									this.vstudent = res.data.data.vstudent;
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;
		
			          //获得参数
								var ppzh= ReportViewer.Report.ParameterByName("pzh");
								var pname= ReportViewer.Report.ParameterByName("name");
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var pgrade= ReportViewer.Report.ParameterByName("grade");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pmajor= ReportViewer.Report.ParameterByName("major");
								var premark= ReportViewer.Report.ParameterByName("remark");
								var pshenheren= ReportViewer.Report.ParameterByName("shenheren");
								var pkuaiji= ReportViewer.Report.ParameterByName("kuaiji");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");	
								var psfzhm= ReportViewer.Report.ParameterByName("sfzhm");	
								var bankorcash= ReportViewer.Report.ParameterByName("bankorcash");	
								//填充参数
								if(this.vstudentmoneyrecv.feetypekey == '01' ||  this.vstudentmoneyrecv.feetypekey == '06'){
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudentfudao.studentname;	
									pinserttime.AsString = formatDare(
									  	this.vstudentmoneyrecv.recvtime,
									  "YYYY-MM-DD HH:mm:ss"
									);
									plevel.AsString = this.vstudentfudao.levelval;
									pmajor.AsString="成人高考";			
																
									premark.AsString = this.vstudentfudao.shoukuanremark+ '* 德立教育集团投诉与建议: 020-89226488';
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;
									//辅导班--辅导
								}else if(this.vstudentmoneyrecv.feetypekey == '17'){
								 //报其他--通用收费
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudentmoneyrecv.studentname;	
									pinserttime.AsString= formatDare(
														this.vstudentmoneyrecv.recvtime,	
											"YYYY-MM-DD HH:mm:ss"
										);
									premark.AsString=this.vstudentmoneyrecv.shoukuanremark === null ? ''+ '* 德立教育集团投诉与建议: 020-89226488' : this.vstudentmoneyrecv.shoukuanremark+ '* 德立教育集团投诉与建议: 020-89226488';		
									pshoukuairen.AsString=this.vstudentmoneyrecv.insertusername;
									pkaipiaoren.AsString=this.vstudentmoneyrecv.insertusername;	
								 
								 }else if(this.vstudentmoneyrecv.feetypekey == '02' || this.vstudentmoneyrecv.feetypekey == '07'){
									//代预报名 -- 预报名
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudentyubaoming.qianzainame;	
									pinserttime.AsString = formatDare(
									  	this.vstudentmoneyrecv.recvtime,
									  "YYYY-MM-DD HH:mm:ss"
									);
									pgrade.AsString = this.vstudentyubaoming.year;	
									plevel.AsString = this.vstudentyubaoming.wantlevelval === null ? '' : this.vstudentyubaoming.wantlevelval;
									pmajor.AsString = "成人高考";	
																
									premark.AsString = this.vmoneyinlist[0].shoukuanremark === null ? '' + '* 德立教育集团投诉与建议: 020-89226488' :this.vmoneyinlist[0].shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488' ;
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;
									
									
								} else if(this.vstudentmoneyrecv.feetypekey == '03' || this.vstudentmoneyrecv.feetypekey == '08'){
									//购买教材 -- 
									ppzh.AsString	= this.tshouju.code;
									pname.AsString = this.vstudentbook.studentname;			
									plevel.AsString = this.vstudentpotential.levelval === null ? '' : this.vstudentpotential.levelval;
									pmajor.AsString = "成人高考";
									premark.AsString = this.vstudentbook.shoukuanremark+ '* 德立教育集团投诉与建议: 020-89226488';	
									pinserttime.AsString = formatDare(
									  	this.vstudentbook.shoukuantime,
									  "YYYY-MM-DD HH:mm:ss"
									);
									pshoukuairen.AsString = this.vstudentbook.shoukuanusername;	
									pkaipiaoren.AsString = this.vstudentbook.shoukuanusername;
								} else if(this.vstudentmoneyrecv.feetypekey == '04' || this.vstudentmoneyrecv.feetypekey == '11' ){ 
									//入学注册
									ppzh.AsString= this.tshouju.code;
									pname.AsString=	this.vstudentregisterfeedetail.studentname;	
									pinserttime.AsString = formatDare(
									  	this.vstudentmoneyrecv.recvtime,
									  "YYYY-MM-DD HH:mm:ss"
									);	//收款时间
									pgrade.AsString=	this.vstudentregisterfeedetail.grade;//年级
									plevel.AsString=	this.vstudentregisterfeedetail.levelval;		
									premark.AsString=	this.vstudentregisterfeedetail.shoukuanremark+ '* 德立教育集团投诉与建议: 020-89226488';		
									pmajor.AsString=	this.vstudentregisterfeedetail.majorname;					
									pshoukuairen.AsString= this.vmoneyin.inusername;
									pkaipiaoren.AsString=this.sessionuser.code;
									psfzhm.AsString=	this.vstudentregisterfeedetail.studentidcard== null ? '' :	this.vstudentregisterfeedetail.studentidcard;
									
									if(this.vmoneyin.bankorcash == 1){
										bankorcash.AsString="（现金）";
									}else if(this.vmoneyin.bankorcash ==0){
										bankorcash.AsString="（转账）";
										}
									
								}else if(this.vstudentmoneyrecv.feetypekey == '09'){ 
									//入学注册
									ppzh.AsString= this.tshouju.code;
									pname.AsString=	this.vstudentregisterfeedetail.studentname;	
									pinserttime.AsString = formatDare(
									  	this.vstudentmoneyrecv.recvtime,
									  "YYYY-MM-DD HH:mm:ss"
									);	//收款时间
									pgrade.AsString=	this.vstudentregisterfeedetail.grade;//年级
									plevel.AsString=	this.vstudentregisterfeedetail.levelval;		
									premark.AsString=	this.vstudentregisterfeedetail.shoukuanremark+ '* 德立教育集团投诉与建议: 020-89226488';		
									pmajor.AsString=	this.vstudentregisterfeedetail.majorname;					
									pshoukuairen.AsString= this.vmoneyin.inusername;
									pkaipiaoren.AsString=this.sessionuser.code;
									psfzhm.AsString=	this.vstudentregisterfeedetail.studentidcard== null ? '' :	this.vstudentregisterfeedetail.studentidcard;
									
									if(this.vmoneyin.bankorcash == 1){
										bankorcash.AsString="（现金）";
									}else if(this.vmoneyin.bankorcash ==0){
										bankorcash.AsString="（转账）";
										}
									
								}else if(this.vstudentmoneyrecv.feetypekey == '05'){
									//学历继续教育 
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudent.qianzainame;	
									psfzhm.AsString = this.vstudent.studentidcard === null ? '': this.vstudent.studentidcard;
									pinserttime.AsString = formatDare(
									  	this.vstudentmoneyrecv.recvtime,
									  "YYYY-MM-DD HH:mm:ss"
									);	//收款时间
									pgrade.AsString = this.vstudent.grade;	
									plevel.AsString=this.vstudent.levelval;
									pmajor.AsString=this.vstudent.majorname1;
								
									premark.AsString = this.vstudent.shoukuanrenark === null ? ''+ '* 德立教育集团投诉与建议: 020-89226488': this.vstudent.shoukuanrenark+ '* 德立教育集团投诉与建议: 020-89226488';
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;
									
								}else if(this.vstudentmoneyrecv.feetypekey == '20'){
									//学历继续教育(成考辅导费)
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudent.qianzainame;	
									psfzhm.AsString = this.vstudent.studentidcard === null ? '': this.vstudent.studentidcard;
									pinserttime.AsString = formatDare(
									  	this.vstudentmoneyrecv.recvtime,
									  "YYYY-MM-DD HH:mm:ss"
									);	//收款时间
									pgrade.AsString = this.vstudent.grade;	
									plevel.AsString=this.vstudent.levelval;
									pmajor.AsString=this.vstudent.majorname1;
								
									premark.AsString = this.vstudent.shoukuanrenark === null ? ''+ '* 德立教育集团投诉与建议: 020-89226488': this.vstudent.shoukuanrenark+ '* 德立教育集团投诉与建议: 020-89226488';
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;
									
								}else if( this.vstudentmoneyrecv.feetypekey == '10'){
									//学历继续教育 
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vstudent.qianzainame;	
									psfzhm.AsString = this.vstudent.studentidcard === null ? '': this.vstudent.studentidcard;
									pinserttime.AsString = formatDare(
									  	this.vstudentmoneyrecv.recvtime,
									  "YYYY-MM-DD HH:mm:ss"
									);	//收款时间
									pgrade.AsString = this.vstudent.grade;	
									plevel.AsString=this.vstudent.levelval;
									pmajor.AsString=this.vstudent.majorname1;
								
									premark.AsString = this.vstudent.shoukuanrenark === null ? ''+ '* 德立教育集团投诉与建议: 020-89226488': this.vstudent.shoukuanrenark+ '* 德立教育集团投诉与建议: 020-89226488';
									pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
									pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;
									
								}else if(this.vstudentmoneyrecv.feetypekey == '13' || this.vstudentmoneyrecv.feetypekey == '14' ){
									//技能培训
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vtechfudao.studentname;	
									pinserttime.AsString= formatDare(
											  	this.vstudentmoneyrecv.recvtime,
											  "YYYY-MM-DD HH:mm:ss"
											);	//收款时间	
									plevel.AsString=this.vtechfudao.fudaoclasstypename;
									premark.AsString=this.vtechfudao.shoukuanremark === null ? ''+ '* 德立教育集团投诉与建议: 020-89226488' : this.vtechfudao.shoukuanremark+ '* 德立教育集团投诉与建议: 020-89226488';		
									pmajor.AsString="技能培训";
									pshoukuairen.AsString=this.vtechfudao.insertusername;
									pkaipiaoren.AsString=this.vtechfudao.insertusername;	
								}else if( this.vstudentmoneyrecv.feetypekey == '15'|| this.vstudentmoneyrecv.feetypekey == '16'){
									//技能培训
									ppzh.AsString = this.tshouju.code;
									pname.AsString = this.vtechkjbaokao.studentname;	
									pinserttime.AsString= formatDare(
											  	this.vstudentmoneyrecv.recvtime,
											  "YYYY-MM-DD HH:mm:ss"
											);	//收款时间	
									premark.AsString=this.vtechkjbaokao.shoukuanrenark === null ? ''+ '* 德立教育集团投诉与建议: 020-89226488' : this.vtechkjbaokao.shoukuanrenark+ '* 德立教育集团投诉与建议: 020-89226488';		
									pmajor.AsString="技能培训";
									pshoukuairen.AsString=this.vtechkjbaokao.insertusername;
									pkaipiaoren.AsString=this.vtechkjbaokao.insertusername;	
								}
									
								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");
								
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var maohao = ReportViewer.Report.FieldByName("maohao");
								
								//填充字段
								
								Report.PrepareRecordset(); 
								Recordset.Append(); 
								if(this.vstudentmoneyrecv.feetypekey == '01' ||  this.vstudentmoneyrecv.feetypekey == '06'){
									//辅导班
									fcollege.AsString = this.vstudentfudao.fudaoclasstypename;
										fitem0.AsString ="辅导费";
										maohao0.AsString ="：" ;
										fprice0.AsFloat =this.vstudentfudao.fudaofee;
										if(this.vmoneyinlist[1]!=null) {
											fitem.AsString = this.vmoneyinlist[1].itemmoneyinitemname;
											maohao.AsString ="：" ;
											fprice.AsFloat = this.vmoneyinlist[1].amount;
											yuan.AsString ="元" ;
											pshoukuairen.AsString= this.vmoneyinlist[1].inusername;
											pkaipiaoren.AsString=this.vmoneyinlist[1].inusername;	
										}
											
											Recordset.Post();
											
											
											Recordset.Append();
											fitem0.AsString ="教材费" ;
											maohao0.AsString ="：" ;
											fprice0.AsFloat = this.vstudentfudao.bookfee;//应收
											yuan0.AsString ="元" ;
											if(this.vmoneyinlist[0] !== '') {
												fitem.AsString =this.vmoneyinlist[0].itemmoneyinitemname;
												maohao.AsString ="：" ;
												fprice.AsFloat =this.vmoneyinlist[0].amount;
												yuan.AsString ="元" ;
											}
											
											
										  Recordset.Post();
									if(0-this.vstudentfudao.preferentialmoney <0){
													Recordset.Append();
													fitem0.AsString ="优惠" ;
													maohao0.AsString ="：" ;
													fprice0.AsFloat = 0-this.vstudentfudao.preferentialmoney;	
													yuan0.AsString ="元" ;		    
													Recordset.Post();
												}
												if(0-this.vstudentfudao.extrasubmoney <0){
													Recordset.Append();
													fitem0.AsString ="额外减免金额";
													maohao0.AsString ="：" ;
													fprice0.AsFloat =0-this.vstudentfudao.extrasubmoney;
													yuan0.AsString ="元" ;
													
													Recordset.Post();
												}	
																
								}else if(this.vstudentmoneyrecv.feetypekey == '17'){ 
									for (var index = 0; index <	this.vmoneyinlist.length; index++){
										Recordset.Append();
										fcollege.AsString = this.vmoneyinlist[index].typem2oneyintypename;
										fitem.AsString = this.vmoneyinlist[index].itemmoneyinitemname; //实收
										maohao.AsString ="：" ;
										fprice.AsFloat = this.vmoneyinlist[index].amount;
										yuan.AsString ="元" ;
										
										
										Recordset.Post();
									}
									
								}else if(this.vstudentmoneyrecv.feetypekey == '02' || this.vstudentmoneyrecv.feetypekey == '07'){
									//代预报名
									for (var index = 0; index <	this.vmoneyinlist.length; index++){
										Recordset.Append();
										fcollege.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
										fitem0.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
										maohao0.AsString ="：" ;
										fprice0.AsFloat = this.vstudentyubaoming.totalmoney;
										yuan0.AsString ="元" ;
										yuan.AsString ="元" ;
										maohao.AsString ="：" ;
										fitem.AsString =	this.vmoneyinlist[index].itemmoneyinitemname;
										fprice.AsFloat = this.vmoneyinlist[index].amount;
										 Recordset.Post();
										
									} 
								} else if(this.vstudentmoneyrecv.feetypekey == '03' || this.vstudentmoneyrecv.feetypekey == '08'){
									//购买教材 
									for (var index = 0; index <	this.studentbookdetaillist.length; index++){
										Recordset.Append();
										fcollege.AsString = this.studentbookdetaillist[index].bookname2;
										fitem0.AsString = this.studentbookdetaillist[index].price +'*' + this.studentbookdetaillist[index].quality + '*' + this.studentbookdetaillist[index].discount + '=';
										fprice0.AsFloat = this.studentbookdetaillist[index].price * this.studentbookdetaillist[index].quality * this.studentbookdetaillist[index].discount;//应收
										if(index == 0) {
											fitem.AsString ="合计";
											maohao.AsString ="：";
											fprice.AsFloat =	this.vmoneyinlist[0].amount; //实收
											yuan.AsString ="元";
											
										}
											
										yuan0.AsString ="元";
										
										Recordset.Post();
									} 
									if(0- this.vstudentbook.preferentialmoney <0){
											Recordset.Append();
											fitem0.AsString ="优惠";
											fprice0.AsFloat =	0 - this.vstudentbook.preferentialmoney;
											yuan0.AsString ="元";
											maohao0.AsString ="：";
											
											
											Recordset.Post();
									}
								} else if(this.vstudentmoneyrecv.feetypekey == '04' || this.vstudentmoneyrecv.feetypekey == '11' ){ 
									//入学注册
									Recordset.Append();
																fitem0.AsString =	this.vstudentregisterfeedetail.itemname; 
																fprice0.AsFloat =	this.vstudentregisterfeedetail.money; //应收金额
																maohao0.AsString ="：" ;
																yuan0.AsString ="元" ; 
																fcollege.AsString = this.vstudentregisterfeedetail.collegename; 
																fitem.AsString =	this.vmoneyin.itemmoneyinitemname;
																maohao.AsString ="："
																fprice.AsFloat =this.vmoneyin.amount;
																yuan.AsString ="元" ;
																Recordset.Post(); 
															
									if(this.vstudentregisterfeedetail.discountfee !==0){ 
																	Recordset.Append();
																	fitem0.AsString ="活动优惠" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat =0-this.vstudentregisterfeedetail.discountfee;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }
																
									  if(0-this.vstudentregisterfeedetail.extrasubmoney <0){ 
																	Recordset.Append();
																	fitem0.AsString ="减免" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat =0-this.vstudentregisterfeedetail.extrasubmoney;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }
									  if(0-this.vstudentregisterfeedetail.studentfee <0){ 
																	Recordset.Append();
																	fitem0.AsString ="预交学位费" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat =0-this.vstudentregisterfeedetail.studentfee;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }
									  if(0- this.vstudentregisterfeedetail.otherfee <0){ 
																	Recordset.Append();
																	fitem0.AsString ="预交其它费" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat = 0-this.vstudentregisterfeedetail.otherfee;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }	
								} else if(this.vstudentmoneyrecv.feetypekey == '09' ){ 
									//入学注册
									Recordset.Append();
																fcollege.AsString = this.vstudentregisterfeedetail.collegename;
																fitem.AsString =	this.vmoneyin.itemmoneyinitemname;
																maohao.AsString ="："
																fprice.AsFloat =this.vmoneyin.amount;
																yuan.AsString ="元" ;
																Recordset.Post(); 
															
									if(this.vstudentregisterfeedetail.discountfee !==0){ 
																	Recordset.Append();
																	fitem0.AsString ="活动优惠" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat =0-this.vstudentregisterfeedetail.discountfee;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }
																
									  if(0-this.vstudentregisterfeedetail.extrasubmoney <0){ 
																	Recordset.Append();
																	fitem0.AsString ="减免" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat =0-this.vstudentregisterfeedetail.extrasubmoney;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }
									  if(0-this.vstudentregisterfeedetail.studentfee <0){ 
																	Recordset.Append();
																	fitem0.AsString ="预交学位费" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat =0-this.vstudentregisterfeedetail.studentfee;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }
									  if(0- this.vstudentregisterfeedetail.otherfee <0){ 
																	Recordset.Append();
																	fitem0.AsString ="预交其它费" ;
																	maohao0.AsString ="："
																	fprice0.AsFloat = 0-this.vstudentregisterfeedetail.otherfee;
																	yuan0.AsString ="元" ;
																	Recordset.Post();
									  }	
								}else if(this.vstudentmoneyrecv.feetypekey == '05'){ 
									//学历继续教育
									for (var index = 0; index <	this.vmoneyinlist.length; index++) {
											Recordset.Append();
											fcollege.AsString = this.vstudent.collegename1;
											maohao0.AsString ="：" ;
											yuan0.AsString ="元" ; 
											fprice.AsFloat = this.vmoneyinlist[index].amount;
											fitem0.AsString =this.vmoneyinlist[index].itemmoneyinitemname;
											fitem.AsString =this.vmoneyinlist[index].itemmoneyinitemname;
											maohao.AsString ="：" ;
											fprice0.AsFloat =this.vstudent.shouldrecvmoney;
											yuan.AsString ="元" ;
											Recordset.Post();
									}
									if(this.vstudent.discountfee !==0){ 
											Recordset.Append();
											fitem0.AsString ="优惠" ;
											maohao0.AsString ="："
											fprice0.AsFloat =0-this.vstudent.discountfee;
											yuan0.AsString ="元" ;
											Recordset.Post();
									  }
								}else if(this.vstudentmoneyrecv.feetypekey == '20'){ 
									//学历继续教育
									for (var index = 0; index <	this.vmoneyinlist.length; index++) {
											Recordset.Append();
											fcollege.AsString = this.vstudent.collegename1;
											maohao0.AsString ="：" ;
											yuan0.AsString ="元" ; 
											fprice.AsFloat = this.vmoneyinlist[index].amount;
											fitem0.AsString =this.vmoneyinlist[index].itemmoneyinitemname;
											fitem.AsString =this.vmoneyinlist[index].itemmoneyinitemname;
											maohao.AsString ="：" ;
											fprice0.AsFloat =this.vstudent.shouldrecvmoney;
											yuan.AsString ="元" ;
											Recordset.Post();
									}
									if(this.vstudent.discountfee !==0){ 
											Recordset.Append();
											fitem0.AsString ="优惠" ;
											maohao0.AsString ="："
											fprice0.AsFloat =0-this.vstudent.discountfee;
											yuan0.AsString ="元" ;
											Recordset.Post();
									  }
								}else if(this.vstudentmoneyrecv.feetypekey == '10'){ 
									//学历继续教育
									for (var index = 0; index <	this.vmoneyinlist.length; index++) {
											Recordset.Append();
											fcollege.AsString = this.vstudent.collegename1;
											fprice.AsFloat = this.vmoneyinlist[index].amount;
											fitem.AsString =this.vstudentmoneyrecv.feetypeval;
											maohao.AsString ="：" ;
											yuan.AsString ="元" ;
											Recordset.Post();
									}
									if(this.vstudent.discountfee !==0){ 
											Recordset.Append();
											fitem0.AsString ="优惠" ;
											maohao0.AsString ="："
											fprice0.AsFloat =0-this.vstudent.discountfee;
											yuan0.AsString ="元" ;
											Recordset.Post();
									  }
								}else if(this.vstudentmoneyrecv.feetypekey == '13' || this.vstudentmoneyrecv.feetypekey == '14' ){  
									//技能培训
									for (var index = 0; index <	this.vmoneyinlist.length; index++){
										Recordset.Append();
										if(index == 0) {
											fcollege.AsString = this.vtechfudao.fudaoclasstypename;
										}
										fitem0.AsString = this.vmoneyinlist[index].itemmoneyinitemname;//应收
										maohao0.AsString ="：";
										if(this.vmoneyinlist[index].itemmoneyinitemname === '培训费') {
											fprice0.AsFloat = this.vtechfudao.fudaofee;//应收
										}
										if(this.vmoneyinlist[index].itemmoneyinitemname === '预收教材费') {
											fprice0.AsFloat =  this.vtechfudao.bookfee;
										}
										yuan0.AsString ="元" ;
										fitem.AsString = this.vmoneyinlist[index].itemmoneyinitemname; //实收
										maohao.AsString ="：" ;
										fprice.AsFloat = this.vmoneyinlist[index].amount;
										yuan.AsString ="元" ;
										
										
										Recordset.Post();
									} 
									if(0 - this.vtechfudao.preferentialmoney <0){
													Recordset.Append();
													fitem0.AsString ="优惠" ;
													fprice0.AsFloat =0-this.vtechfudao.preferentialmoney;
													yuan0.AsString ="元" ;
													maohao0.AsString ="：" ;
												    
													Recordset.Post();
												}
									if(0 - this.vtechfudao.extrasubmoney <0){
										Recordset.Append();
										fitem0.AsString ="额外减免金额" ;
										fprice0.AsFloat =0-this.vtechfudao.extrasubmoney;
										yuan0.AsString ="元" ;
										maohao0.AsString ="：" ;
										
										Recordset.Post();
									}
	
								}else if( this.vstudentmoneyrecv.feetypekey == '15'|| this.vstudentmoneyrecv.feetypekey == '16'){
									//技能培训
									for (var index = 0; index <	this.vmoneyinlist.length; index++){
										Recordset.Append();
										 
										fitem.AsString = this.vmoneyinlist[index].itemmoneyinitemname; //实收
										maohao.AsString ="：" ;
										fprice.AsFloat = this.vmoneyinlist[index].amount;
										yuan.AsString ="元" ;
										
										
										Recordset.Post();
									} 
									
								}	
			          ReportViewer.Start();
			       }
			  }); 
		},
    //打印收据 click
    btnPrint() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.printReceipt(this.multipleSelection[0]);
      });
    },
    //打印收据 api
    printReceipt(row) {
      myRequest({
        url: studentmoneyrecv_printshouju,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          window.open(
            this.$url.upload +
              "/shouju/" +
              row.id +
              res.data.data.tshouju.code +
              ".pdf"
          );
        }
      });
    },

    //冲账 click
    btnBalance() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.inputbalance(this.multipleSelection[0].id);
      });
    },
    //冲账路由 api
    inputbalance(id) {
      myRequest({
        url: studentmoneyrecv_invert,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = {
            shoujucode: res.data.data.tstudentmoneyrecv.shoujucode,
            newshoujucode: res.data.data.tshouju.code,
            studentname: res.data.data.tstudentmoneyrecv.studentname,
            recvmoney: res.data.data.tstudentmoneyrecv.recvmoney,
            zhiyao: res.data.data.vmoneyin.zhaiyao,
            invertreason: res.data.data.tstudentmoneyrecv.invertreason,
            id: id,
          };

          this.showBalanceModal = true;
        }
      });
    },
    //保存冲账 api
    savebalance(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentmoneyrecv_invertsave,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showBalanceModal",
            }
          );
        }
      });
    },

    //刷新 click
    btnRefresh() {
      this.getDataList();
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    //选项表格 api(德立学生)
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },

};
</script>

<style  lang="scss">
</style>