<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="流水号:">
            <el-input 
							v-model.trim="searchForm.id" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="角色编号:">
            <el-input 
							v-model.trim="searchForm.code" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="角色名称:">
            <el-input 
							v-model.trim="searchForm.name" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="是否可用">
            <el-select
              v-model="searchForm.enableflag"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
              placeholder="请选择是否可用"
              clearable
            >
              <el-option value="1" label="是"></el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="角色编号" prop="code" align="center" />
      <el-table-column label="角色名称" prop="name" align="center" />
      <el-table-column label="是否超级角色" align="center">
        <template slot-scope="scope">
          {{ supperflag[scope.row.supperflag] }}
        </template>
      </el-table-column>
      <el-table-column label="是否可用" prop="enableflag" align="center">
        <template slot-scope="scope">
          {{ available[scope.row.enableflag] }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="code" label="角色编号">
          <el-input v-model.trim="dynamicValidateForm.code" clearable/>
        </el-form-item>
        <el-form-item prop="name" label="角色名称">
          <el-input v-model.trim="dynamicValidateForm.name" clearable/>
        </el-form-item>
        <el-form-item prop="supperflag" label="超级角色">
          <el-select v-model="dynamicValidateForm.supperflag" clearable>
            <el-option value="1" label="是"></el-option>
            <el-option value="0" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="enableflag" label="是否可用" >
          <el-select v-model="dynamicValidateForm.enableflag" clearable>
            <el-option value="1" label="是"></el-option>
            <el-option value="0" label="否"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <Export
      :exportTitle="'角色管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>

    <!-- 分配角色 -->
    <el-dialog
      class="btn-2b5a95"
      title="分配权限"
			v-dialogDrag
      :visible.sync="showAuth"
			:close-on-click-modal='false'
      v-if="showAuth"
      width="40%"
      center
      append-to-body
      @close="onCloseTree"
    >
      <el-tree
        ref="tree"
        :data="authData.item"
        :props="defaultProps"
        show-checkbox
        node-key="id"
        :default-checked-keys="defaultCheckMenu"
        @check="onCheckAuth"
        :check-strictly="isCheckStrictly"
      >
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="submitAuth">保 存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  role_list,
  role_input,
  role_save,
  role_delete,
  role_enable,
  rolemodule_list,
  rolemodule_save,
  role_export,
  role_exportexcel,
  role_exportsave,
} from "@/request/api/allChildrenProject";
import {
  _moneyintype_dialogresult,
  _module_treerole,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _available } from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "role",
  components: { Export },
  props: {
    exportUrl: {
      default: () => role_export,
    },
    exportexcelUrl: {
      default: () => role_exportexcel,
    },
    exportsaveUrl: {
      default: () => role_exportsave,
    },
  },
  data() {
    return {
      supperflag: {
        0: "否",
        1: "是",
      }, // 是否为超级角色
      treeData: [], // 树形结构
      listID: [], // 存储已选择的id
      initCkeckList: [], // 初始化已选择的数组

      transferDialog: false, //导出框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        code: [{ trigger: "blur", message: "必填字段", required: true }],
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        supperflag: [{ trigger: "blur", message: "必填字段", required: true }],
        enableflag: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      selectTable: {},
      selectTableUrl: "",
      available: _available,

      //分配权限：
      showAuth: false,
      authData: [],
      defaultProps: {
        label: "text",
        children: "item",
      },
      defaultCheckMenu: [],
      currentCheckMenu: [],
      isCheckStrictly: false, //是否严格遵循父节点
    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
		reset() {
			this.searchForm = {
				id: null,
				code: null,
				name: null,
				enableflag: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
    cancel() {
      this.showAuth = false;
    },
    //关闭分配权限框 click
    onCloseTree() {
      this.isCheckStrictly = true;
    },
    //点击权限tree复选框 click
    onCheckAuth(current, data) {
      if (this.isCheckStrictly) {
        this.isCheckStrictly = false;
      }
      this.currentCheckMenu = data.checkedKeys;
    },
    //分配权限 click
    btnRole() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.isCheckStrictly = true;
        this.rolemodule(this.multipleSelection[0].id);
      });
    },
    //获取角色rolecode api
    rolemodule(id) {
      myRequest({
        url: rolemodule_list,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.auth(res.data.data?.trole?.code);
        }
      });
    },
    //分配权限 api
    auth(rolecode) {
      myRequest({
        url: _module_treerole,
        data: {
          rolecode: rolecode,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.isCheckStrictly = true;
          this.defaultCheckMenu = [];
          this.showAuth = true;
          this.authData = JSON.parse(JSON.stringify(res.data.data.dhtmlxtree));
          this.filterValidAuth(this.authData.item);
        }
      });
    },
    //保存分配权限 api
    submitAuth() {
      myRequest(
        {
          method: "post",
          url: rolemodule_save,
          data: this.$qs.stringify(
            {
              roleid: this.multipleSelection[0].id,
              moduleIds: this.currentCheckMenu.concat(
                this.$refs.tree.getHalfCheckedKeys()
              ), //非严格关联父节点时，需拼接半选节点,
            },
            { arrayFormat: "repeat" }
          ),
        },
        {
          that: this,
          isRefTableList: true,
          modal: "showAuth",
        }
      );
    },
    //过滤已有的角色权限 event
    filterValidAuth(auths) {
      for (let i in auths) {
        if (auths[i].checked === 1) {
          //已有权限
          this.defaultCheckMenu.push(auths[i].id);
        }
        if (auths[i].item && auths[i].item.length) {
          this.filterValidAuth(auths[i].item);
        }
      }
    },
    ...mapActions("global", ["onExtendClick"]),
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: role_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        enableflag: "1",
        supperflag: "0",
      };
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //启用 click
    btnEnable() {
      selectCheck(this.multipleSelection, "启用", true, () => {
        confirmCallBack({
          title: "提示",
          content: "该数据是否启用！",
          success: () => {
            this.isenabled(
              this.multipleSelection.map((i) => i.id),
              1
            );
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //禁用 click
    btnDisable() {
      selectCheck(this.multipleSelection, "禁用", true, () => {
        confirmCallBack({
          title: "提示",
          content: "该数据是否禁用！",
          success: () => {
            this.isenabled(
              this.multipleSelection.map((i) => i.id),
              0
            );
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnRefresh() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: role_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.trole;
          this.dynamicValidateForm.enableflag =
            this.dynamicValidateForm.enableflag + "";
          this.dynamicValidateForm.supperflag =
            this.dynamicValidateForm.supperflag + "";
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: role_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: role_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //启用，禁用 api
    isenabled(ids, isenabled) {
      myRequest(
        {
          method: "post",
          url: role_enable,
          data: this.$qs.stringify(
            {
              ids: ids,
              enable: isenabled,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>