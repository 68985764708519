<template>
  <!-- 打印页 -->
  <div class="print-page">
    <div class="btn-list">
      <el-button @click="switchPrint">返回</el-button>
      <el-button v-print="'#print'">打印</el-button>
    </div>
    <div class="print-w">
      <div class="print-c-w" id="print">
        <div class="print-c-t">
          <h3>{{ data.vpurchase ? data.vpurchase.title : "" }}</h3>
          <div class="userinfo-w level-w">
            <div>
              流水号：{{ data.vpurchase ? data.vpurchase.id : "" }}
            </div>
            <div>
              教学点名称：{{
                data.vpurchase ? data.vpurchase.schoolorgname : ""
              }}
            </div>
            <div>
              申请时间：{{
                data.vpurchase
                  ? data.vpurchase.inserttime
                  : "" | formatDate('YYYY-MM-DD HH:mm:ss')
              }}
            </div>
            <div>
              申请人：{{
                data.vpurchase ? data.vpurchase.insertusername : ""
              }}
            </div>
            <div>负责人：</div>
          </div>
          <div class="print-table-w">
            <table border="2" width="100%" cellpadding="0" cellspacing="0">
              <tr>
                <td class="td-style">物品类型：</td>
                <td class="td-style">物品名称：</td>
                <td class="td-style">单位：</td>
                <td class="td-style">数量：</td>
              </tr>
              <tr v-for="(item, i) in data.list" :key="i">
                <td class="td-style">{{ item.type }}</td>
                <td class="td-style">{{ item.name }}</td>
                <td class="td-style">{{ item.unit }}</td>
                <td class="td-style">{{ item.num }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="print-c-b level-w"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { myRequest } from "@/request/index";
export default {
  data() {
    return {
    };
  },
  props: {
    data:{
      default:Object
    }
  },
  created() {
  },
  methods: {
    switchPrint() {
      this.$emit("switchPrint", false);
    },
  },
};
</script>


<style scoped lang="scss">
.Btn {
  padding: 10px;
  & > button {
    margin: 5px;
  }
}
.print-page {
  overflow-y: auto;
  height: calc(100vh);
  background-color: rgb(185, 200, 177);
}
.print-w {
  padding: 5% 30%;
}
.print-c-w {
  position: relative;
  width: 100%;
  padding: 0 5% 2% 5%;
  background-color: #fff;
}

.print-c-t > h3 {
  text-align: center;
}

.level-w > div {
  width: 50%;
  float: left;
  margin: 0 0 10px 0;
}
.print-c-b {
  margin: 5% 0 0 0;
}
.print-c-b,
.print-c-t {
  overflow: auto;
}

.td-style {
  padding: 0 !important;
  border: 0;
  border-bottom: 2px solid;
}
.print-table-w .border-0 {
  border: 0;
}

.print-table-w {
  & > table {
    & > tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
</style>