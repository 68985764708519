<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="学生姓名:">
          <el-input 
						v-model.trim="searchForm.studentname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="院校名称:">
          <el-input 
						v-model.trim="searchForm.collegename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="专业名称:">
          <el-input 
						v-model.trim="searchForm.majorname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :data="tableRes.list"
      stripe
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生姓名" prop="studentname" align="center" />
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column label="学习形式" prop="studytypeval" align="center" />
      <el-table-column label="考试科目" prop="coursename" align="center" />
      <el-table-column label="考试时间始" align="center" width="180px">
        <template slot-scope="scope">
          {{ scope.row.starttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="考试时间止" align="center" width="180px">
        <template slot-scope="scope">
          {{ scope.row.endtime | formatDate("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="考试地点" prop="address" align="center" />
      <el-table-column
        label="院校名称"
        prop="collegename"
        align="center"
        width="200px"
      />
      <el-table-column label="专业名称" prop="majorname" align="center" />
      <el-table-column label="层次" prop="levelval" align="center" />
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="120px"
      />
      <el-table-column
        label="函授站"
        prop="guakaoschoolorgname"
        align="center"
        width="120px"
      />
      <el-table-column label="班主任" prop="followusername" align="center" />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
  </div>
</template>


<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";

import {
  studentexamplan_list,
  studentexamplan_export,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import { confirmCallBack, messageTip, createObjParams } from "@/utils/util";
export default {
  mixins: [part],
  name: "studentexamplan",
  data() {
    return {
      searchForm: {}, // 搜索值
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      multipleSelection: [], //选中的表格项
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
		reset() {
			this.searchForm = {
				studentname: null,
				collegename: null,
				majorname: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentexamplan_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //导出总表 click api
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "导出总表会根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            studentexamplan_export +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>