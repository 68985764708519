<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				@submit.native.prevent
				:model="searchForm"
      >
        <el-form-item label="姓名:">
          <el-input
						v-model.trim="searchForm.studentname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="身份证:">
          <el-input
						v-model.trim="searchForm.studentidcard"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="院校:">
          <el-input
						v-model.trim="searchForm.collegename"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="专业:">
          <el-input
						v-model.trim="searchForm.majorname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="年级:">
          <el-input
						v-model.trim="searchForm.grade"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="层次:">
          <el-input
						v-model.trim="searchForm.levelval"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="班主任:">
          <el-input
						v-model.trim="searchForm.followusername"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input
						v-model.trim="searchForm.schoolorgname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="项目类型:">
          <el-select
						v-model="searchForm.projecttype"
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row)">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="身份证"
        prop="studentidcard"
        align="center"
        width="200"
      />
      <el-table-column
        label="院校"
        prop="collegename"
        align="center"
        width="150"
      />
      <el-table-column
        label="专业"
        prop="majorname"
        align="center"
        width="150"
      />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column label="层次" prop="levelval" align="center" />
      <el-table-column label="班主任" prop="followusername" align="center" />
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column label="第一年实收" prop="recvmoney1" align="center" width="100px"/>
      <el-table-column label="第二年实收" prop="recvmoney2" align="center" width="100px"/>
      <el-table-column label="第三年实收" prop="recvmoney3" align="center" width="100px"/>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="150"
      />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看个人 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 60%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form label-width="50%" class="form-item-w-33">
        <el-form-item label="流水号:">{{
          dynamicValidateForm.id
        }}</el-form-item>
        <el-form-item label="潜在学生流水号:">{{
          dynamicValidateForm.qianzaiid
        }}</el-form-item>
        <el-form-item label="学生姓名:">{{
          dynamicValidateForm.studentname
        }}</el-form-item>
        <el-form-item label="性别:">{{
          dynamicValidateForm.sexval
        }}</el-form-item>
        <el-form-item label="证件类型名称:">{{
          dynamicValidateForm.idcardtypeval
        }}</el-form-item>
        <el-form-item label="证件号码:">{{
          dynamicValidateForm.studentidcard
        }}</el-form-item>
        <el-form-item label="学生邮箱:">{{
          dynamicValidateForm.email
        }}</el-form-item>
        <el-form-item label="学生qq:">{{
          dynamicValidateForm.qq
        }}</el-form-item>
        <el-form-item label="第一年实收:">{{
          dynamicValidateForm.recvmoney1
        }}</el-form-item>
        <el-form-item label="第二年实收:">{{
          dynamicValidateForm.recvmoney2
        }}</el-form-item>
        <el-form-item label="第三年实收:">{{
          dynamicValidateForm.recvmoney3
        }}</el-form-item>
        <el-form-item label="跟进人:">{{
          dynamicValidateForm.followusername
        }}</el-form-item>
        <el-form-item label="生源来源类别:">{{
          dynamicValidateForm.studentfromtypeval
        }}</el-form-item>
        <el-form-item label="异动状态:">{{
          dynamicValidateForm.changeflag
        }}</el-form-item>
        <el-form-item label="准考证号:">{{
          dynamicValidateForm.ticketnumber
        }}</el-form-item>
        <el-form-item label="手机:">{{
          dynamicValidateForm.mobile
        }}</el-form-item>
        <el-form-item label="手机2:">{{
          dynamicValidateForm.mobile2
        }}</el-form-item>
        <el-form-item label="紧急联系人电话:">{{
          dynamicValidateForm.emergentphone
        }}</el-form-item>
        <el-form-item label="大一寸照片数量:">{{
          dynamicValidateForm.bigphoto
        }}</el-form-item>
        <el-form-item label="小一寸照片数量:">{{
          dynamicValidateForm.smallphoto
        }}</el-form-item>
        <el-form-item label="身份证复印件数量:">{{
          dynamicValidateForm.idcardcopy
        }}</el-form-item>
        <el-form-item label="毕业证复印件数量:">{{
          dynamicValidateForm.certificate
        }}</el-form-item>
        <el-form-item label="总部给予:">{{
          available[dynamicValidateForm.hqsend]
        }}</el-form-item>
        <el-form-item label="总部客服姓名:">{{
          dynamicValidateForm.kefuusername
        }}</el-form-item>
        <el-form-item label="院校编码:">{{
          dynamicValidateForm.collegecode
        }}</el-form-item>
        <el-form-item label="挂靠分公司:">{{
          dynamicValidateForm.guakaocompanyorgname
        }}</el-form-item>
        <el-form-item label="合作机构:">{{
          dynamicValidateForm.organization
        }}</el-form-item>
        <el-form-item label="院校名称:">{{
          dynamicValidateForm.collegename
        }}</el-form-item>
        <el-form-item label="专业名称:">{{
          dynamicValidateForm.majorname
        }}</el-form-item>
        <el-form-item label="考生号:">{{
          dynamicValidateForm.examineenumber
        }}</el-form-item>
        <el-form-item label="学号:">{{
          dynamicValidateForm.code
        }}</el-form-item>
        <el-form-item label="入学年级:">{{
          dynamicValidateForm.grade
        }}</el-form-item>
        <el-form-item label="学年:">{{
          dynamicValidateForm.iyear
        }}</el-form-item>
        <el-form-item label="学籍状态:">{{
          dynamicValidateForm.xuejistatusval
        }}</el-form-item>
        <el-form-item label="学制年限:">{{
          dynamicValidateForm.xuezhival
        }}</el-form-item>
        <el-form-item label="注册状态:">{{
          dynamicValidateForm.regflag == 1 ? "已注册" : "未注册"
        }}</el-form-item>
        <el-form-item label="层次名称:">{{
          dynamicValidateForm.levelval
        }}</el-form-item>
        <el-form-item label="类别名称:">{{
          dynamicValidateForm.keleival
        }}</el-form-item>
        <el-form-item label="所属教学点:">{{
          dynamicValidateForm.schoolorgname
        }}</el-form-item>
        <el-form-item label="省内/省外:">{{
          dynamicValidateForm.areaval
        }}</el-form-item>
        <el-form-item label="所属分公司:">{{
          dynamicValidateForm.companyorgname
        }}</el-form-item>
        <el-form-item label="校外函授站:">{{
          dynamicValidateForm.guakaoschoolorgname
        }}</el-form-item>
        <el-form-item label="上课地点:">{{
          dynamicValidateForm.classaddress
        }}</el-form-item>
        <el-form-item label="学习方式:">{{
          dynamicValidateForm.studytypeval
        }}</el-form-item>
        <el-form-item label="第一学年注册情况:">{{
          dynamicValidateForm.register1
        }}</el-form-item>
        <el-form-item label="第二学年注册情况:">{{
          dynamicValidateForm.register2
        }}</el-form-item>
        <el-form-item label="第三学年注册情况:">{{
          dynamicValidateForm.register3
        }}</el-form-item>
        <el-form-item label="项目类型:">{{
          dynamicValidateForm.projecttype
        }}</el-form-item>
        <el-form-item label="财务审核状态:">{{
          dynamicValidateForm.financeapproval
        }}</el-form-item>
        <el-form-item label="财务审核意见:">{{
          dynamicValidateForm.financeopinion
        }}</el-form-item>
        <el-form-item label="教务审核状态:">{{
          dynamicValidateForm.educationalapproval
        }}</el-form-item>
        <el-form-item label="教务审核意见:">{{
          dynamicValidateForm.educationalopinion
        }}</el-form-item>
        <el-form-item label="挂靠管理备注:">{{
          dynamicValidateForm.guakaoremark
        }}</el-form-item>
        <el-form-item label="备注:">{{
          dynamicValidateForm.remark
        }}</el-form-item>
      </el-form>
    </el-dialog>

    <Export
      :exportTitle="'已毕业管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>


<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";

import {
  studentregister_list2,
  studentregister_export,
  studentregister_exportexcel,
  studentregister_exportsave,
  studentregister_inputedit,
  studentregister_export2,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _available } from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "studentregister2",
  components: { Export },
  props: {
    exportUrl: {
      default: () => studentregister_export,
    },
    exportexcelUrl: {
      default: () => studentregister_exportexcel,
    },
    exportsaveUrl: {
      default: () => studentregister_exportsave + "?xuejistatuskey=06",
    },
  },
  data() {
    return {
      importSaveUrl: "",
      transferDialog: false, //导出框
      importDialog: false, //导入框
      rowItem: null, //首表格项
      searchForm: {},
      dynamicValidateForm: {},
      tableRes: {}, //列表接口
      projectTypes: [], // 项目类型
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      available: _available,
      showDetailModal: false, // 查看详情
    };
  },
  created() {
    this.getDataList();
    // this.getProjectTypesList();

    this.dic_combox({
      list: "projectTypes",
      typecode: "education",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "请根据搜索条件进行导出。",
        success: () => {
          window.location.href =
                  this.$url.Freemarker +
                  studentregister_export2 +
                  `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
		reset() {
			this.searchForm = {
				studentname: null,
				studentidcard: null,
				collegename: null,
				majorname: null,
				grade: null,
				levelval: null,
				followusername: null,
				schoolorgname: null,
				projecttype: null,
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },

    //列表查询 api
    getDataList() {
      myRequest({
        url: studentregister_list2,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },

    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },

    //切换选项表格页
    handleSelectChange(val) {
      this.pageinfo.pageindex = val;
      this.onSelectData();
    },

    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看个人详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: studentregister_inputedit,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vstudentregister;
        }
      });
    },

    //刷新 click
    btnReload() {
      this.getDataList();
      this.searchForm = {};
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>
