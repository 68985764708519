<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="对方单位名称:">
            <el-input 
							v-model.trim="searchForm.unitname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>

          <el-form-item label="冲账标识:">
            <el-select 
							v-model="searchForm.chongzhanflag" 
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectRef"
							clearable>
              <el-option value="1" label="已冲账"></el-option>
              <el-option value="0" label="未冲账"></el-option>
              <el-option value="2" label="被冲账"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="确认收费:">
            <el-select 
							v-model="searchForm.payflag" 
							@keyup.enter.native="seach"
							@visible-change="visiblepayflag"
							ref="payflagref"
							clearable>
              <el-option value="1" label="是"></el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否本部:">
            <el-select 
							v-model="searchForm.notselfflag"
								@keyup.enter.native="seach"
								@visible-change="visiblenotselfflag"
								ref="notselfflagref"
							 clearable>
              <el-option value="1" label="本部"></el-option>
              <el-option value="0" label="非本部"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="现金账户名称:">
            <el-input 
							v-model.trim="searchForm.cashaccountname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="所属教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
					<el-form-item label="收据号:">
					  <el-input 
							v-model.trim="searchForm.shoujucode"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="应收金额:">
					  <el-input 
							v-model.trim="searchForm.totalmoney"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="操作日期起:">
						 <el-date-picker
						      v-model="searchForm.t3"
						      type="date"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd hh:mm:ss"
						      placeholder="选择日期">
						    </el-date-picker>
					</el-form-item>
					<el-form-item label="操作日期止:">
						 <el-date-picker
						      v-model="searchForm.t4"
						      type="date"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd hh:mm:ss"
						      placeholder="选择日期">
						    </el-date-picker>
					</el-form-item>
					
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button @click="resetQuery" type="primary">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      border
			stripe
			:header-cell-style=" { background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="对方单位名称" prop="unitname" align="center" :show-overflow-tooltip="true" width="130px">
        <template slot-scope="scope" >
          <el-button @click="showTableItem(scope.row.id)" type="text">{{
            scope.row.unitname
          }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="应收金额" prop="totalmoney" align="center" />
      <el-table-column label="实收金额" prop="amount" align="center" />
      <el-table-column label="收据号" prop="shoujucode" align="center" width="140"/>

      <el-table-column label="冲账标识" prop="chongzhanflag" align="center">
        <template slot-scope="scope">
          {{ chongzhanflag[scope.row.chongzhanflag] }}
        </template>
      </el-table-column>
      <el-table-column label="账户/卡号" prop="accountname" align="center" width="200"/>
      <el-table-column label="收款时间" align="center" width="120">
        <template slot-scope="scope">
          {{ scope.row.intime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
			<el-table-column label="操作时间" align="center" width="120">
			  <template slot-scope="scope">
			    {{ scope.row.optime | formatDate("YYYY-MM-DD") }}
			  </template>
			</el-table-column>
      <el-table-column label="操作人姓名" prop="opusername" align="center" width="110" />
      <el-table-column label="教学点" prop="schoolorgname" align="center"  width="110"/>
			<!-- <el-table-column label="操作" align="center">
				<template slot-scope="scope">
					<el-button
					  type="primary"
					  size="small"
					  @click="downloadFile(0, scope.row)"
					  >查看附件</el-button
					>
				</template>
			</el-table-column> -->
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--修改-->
    <el-dialog
      class="btn-2b5a95"
      width="88%"
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			v-dialogDrag
      append-to-body
    >
			<el-form
			  :model="dynamicValidateForm"
			  ref="dynamicValidateForm"
			  label-width="150px"
			  class="demo-dynamic form-item-w-50"
			  status-icon
			  :rules="rules"
			>
			  <el-form-item prop="companyorgname" label="所属分公司:">
			    <el-select
			      v-model="dynamicValidateForm.companyorgname"
			      :disabled="!$store.state.login.isMaxAuth"
			      clearable
			      @change="onChangeCompany($event, 'dynamicValidateForm')"
			    >
			      <el-option
			        v-for="item in $store.state.selectData.companyList"
			        :key="item.key"
			        :label="item.val"
			        :value="item.key"
			      >
			      </el-option>
			    </el-select>
			  </el-form-item>
			  <el-form-item prop="schoolorgname" label="所属教学点:">
			    <el-select
			      v-model="dynamicValidateForm.schoolorgname"
			      clearable
			      @change="onChangeSchool($event, 'dynamicValidateForm')"
			    >
			      <el-option
			        v-for="item in $store.state.selectData.schoolList"
			        :key="item.key"
			        :label="item.val"
			        :value="item.key"
			      >
			      </el-option>
			    </el-select>
			  </el-form-item>
			  <el-form-item label="对方单位名称:" prop="unitname">
			    <el-input v-model.trim="dynamicValidateForm.unitname" clearable/>
			  </el-form-item>
			  <el-form-item label="年级:">
			    <el-date-picker
			      v-model="dynamicValidateForm.grade"
			      type="year"
			      format="yyyy"
			      value-format="yyyy"
			      placeholder="请选择"
			    />
			  </el-form-item>
			  <el-form-item label="层次:">
			    <el-select v-model="dynamicValidateForm.levelkey" clearable>
			      <el-option
			        v-for="item in leveltypeList"
			        :key="item.key"
			        :value="item.key"
			        :label="item.val"
			      />
			    </el-select>
			  </el-form-item>
			  <el-form-item label="现金/银行:" prop="bankorcash">
			    <el-input
			      v-model="bankorcash[dynamicValidateForm.bankorcash]"
			      disabled
			    />
			    <el-button
			      type="primary"
			      class="select-btn"
			      @click="
			        onSelectData(account_dialogresult, 'showBankorcashModal', {
			          schoolorgid: dynamicValidateForm.schoolorgid,
			        })
			      "
			      >选择账户</el-button
			    >
			  </el-form-item>
			  <el-form-item label="现金账户/卡号:" prop="accountname">
			    <el-input v-model.trim="dynamicValidateForm.accountname" disabled />
			  </el-form-item>
			  <el-form-item label="经手人:" prop="inusername">
			    <el-input v-model.trim="dynamicValidateForm.inusername" disabled />
			    <el-button
			      type="primary"
			      class="select-btn"
			      @click="
			        onSelectData(
			          user_dialogresult,
			          'showInusernameModal',
			          Object.assign(
			            { schoolorgid: dynamicValidateForm.schoolorgid },
			            selectPageInfo
			          )
			        )
			      "
			      >选择</el-button
			    >
			  </el-form-item>
			  <el-form-item label="收款时间:" prop="intime" clearable>
			    <el-date-picker
			      v-model="dynamicValidateForm.intime"
			      type="datetime"
			      format="yyyy-MM-dd HH:mm:ss"
			      value-format="yyyy-MM-dd HH:mm:ss"
			      placeholder="请选择"
			    />
			  </el-form-item>
				<el-form-item label="上传附件:" prop="url">
				  <el-input v-model.trim="dynamicValidateForm.url" disabled></el-input>
				  <el-upload
				    class="upload-demo"
				    :action="$store.state.global.baseUrl"
				    multiple
				    :file-list="fileList"
				    :show-file-list="false"
				    :before-upload="beforeUpload"
				    :http-request="
				      (url) => {
				        uploadFile('url');
				      }
				    "
				  >
				    <el-button size="small" type="primary" class="select-btn"
				      >点击上传</el-button
				    >
				    <div slot="tip" class="el-upload__tip"></div>
				  </el-upload>
				</el-form-item>
				<el-form-item
				  label="开票说明:"
				  prop="shoukuanremark"
				  class="shoukuanremark hoveFrom"
				>
				  <el-input 
						v-model.trim="dynamicValidateForm.shoukuanremark" 
						type="textarea"
						:rows="5"
						/>
				</el-form-item>
			  <p style="margin:0 0 10px 0;">收入详情：</p>
				<ol>
				<li
				  v-for="(item, i) in dynamicValidateForm.moreItems2"
				  :key="i"
					style="background: aliceblue;"
				>
				<div style="display: flex;">
					<span style="line-height: 43px;">{{i+1}}.</span>
				  <el-form-item
				    label="收入二级类别："
						style="width: 60%;"
						:prop="'moreItems2.' + i + '.type2moneyintypename'"
						:rules="{
						  required: true,
						  message: '必填字段',
						  trigger: 'blur',
						}"
				  >
				    <el-input v-model.trim="item.type2moneyintypename" disabled></el-input>
				    <el-button
				      type="primary"
				      class="select-btn"
				      @click="
				        onSelectData(_moneyintype2_dialogresult, 'editmoneyModal', {
				          index: i,
				        })
				      "
				      >选择</el-button
				    >
				  </el-form-item>
				  <el-form-item
				    label="收入明细："
						style="width: 60%;"
						:prop="'moreItems2.' + i + '.itemmoneyinitemname'"
						:rules="{
						  required: true,
						  message: '必填字段',
						  trigger: 'blur',
						}"
				  >
				    <el-input v-model.trim="item.itemmoneyinitemname" disabled></el-input>
				    <el-button
				      type="primary"
				      class="select-btn"
				      @click="
				        onSelectData(_moneyinitem_dialogresult, 'editModal', {
				          index: i,
				        })
				      "
				      >选择</el-button
				    >
				  </el-form-item>
				  <el-form-item
				    label="金额："
						:prop="'moreItems2.' + i + '.amount'"
						:rules="{
						  required: true,
						  message: '必填字段',
						  trigger: 'blur',
						}"
				  >
				    <el-input v-model.trim="item.amount" clearable @input="change($event)"></el-input>
				  </el-form-item>
				  <el-form-item
						class="zhaiyao"
				    label="摘要："
						style="width: 70%;"
						:prop="'moreItems2.' + i + '.zhaiyao'"
						:rules="{
						  required: true,
						  message: '必填字段',
						  trigger: 'blur',
						}"
				  >
				    <el-input v-model.trim="item.zhaiyao" @input="change($event)"></el-input>
				  </el-form-item>
				  <el-button
				    v-if="i !== 0"
				    @click="onRemoveList(i)"
				    style="margin: 0 10px; height: 40px;"
				    >删除</el-button
				  >
					<el-button
					  v-if="i == 0"
					  @click="onRemoveList(i)"
					  style="margin: 0 10px; height: 40px; visibility: hidden;"
					  >删除</el-button
					>
				</div>
				</li>
				</ol>
			  <el-form-item>
			    <el-button @click="editFormItem">添加一行</el-button>
			  </el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button
					type="primary"
					@click="submitAddOrEditForm('dynamicValidateForm')"
					:loading="!$store.state.global.isEndRequest"
					>提交
				</el-button>
			</span>
    </el-dialog>

    <!-- 银行账户 -->
    <el-dialog
      class="btn-2b5a95"
      width="58%"
			v-dialogDrag
      title="银行账户"
      :visible.sync="showBankorcashModal"
      v-if="showBankorcashModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-button
        type="primary"
        @click="onConfirmBankorcash('showBankorcashModal')"
        >确定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="开户名称:" prop="accountname">
              <el-input 
								v-model.trim="selectSearchForm.accountname"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
         <el-table-column
          label="余额"
          prop="curmoney"
          align="center"
          width="200px"
        />
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>

    <!-- 选择职员，经手人 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
      @close="onCloseSelect"
      title="选择职员"
      :visible.sync="showInusernameModal"
      v-if="showInusernameModal"
			:close-on-click-modal='false'
			v-dialogDrag
      append-to-body
    >
      <el-button
        type="primary"
        @click="onConfirmInusername('showInusernameModal')"
        >确定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="真实姓名" prop="realname" align="center" />
        <el-table-column label="所属部门" prop="departorgname" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
         (val, data) =>
             handleSelectChange(val, Object.assign({}, selectSearchForm))
         "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 查看 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
      title="查看"
			v-dialogDrag
      :visible.sync="showShowModal"
      v-if="showShowModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <h3>主表</h3>
      <el-form
        :model="dynamicValidateForm"
        ref="multipleTable"
        label-width="45%"
        class="demo-dynamic form-item-w-33"
        status-icon
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}</el-form-item
        >
        <el-form-item label="对方单位名称:">
          {{ dynamicValidateForm.unitname }}</el-form-item
        >
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}</el-form-item
        >
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}</el-form-item
        >
        <el-form-item label="应收金额:">
          {{ dynamicValidateForm.totalmoney }}</el-form-item
        >
        <el-form-item label="实收金额:">
          {{ dynamicValidateForm.amount }}</el-form-item
        >
        <el-form-item label="收据类型:">
          {{ dynamicValidateForm.shoujutype }}</el-form-item
        >
        <el-form-item label="是否确认付款:">
          {{ payflag[dynamicValidateForm.payflag] }}</el-form-item
        >
        <el-form-item label="现金/银行:">
          {{ bankorcash[dynamicValidateForm.bankorcash] }}</el-form-item
        >
        <el-form-item label="现金账户/银行账户:">
          {{ dynamicValidateForm.accountname }}</el-form-item
        >
        <el-form-item label="是否本部收入:">
          {{ notselfflag[dynamicValidateForm.notselfflag] }}</el-form-item
        >
        <el-form-item label="收款时间:">
          {{
            dynamicValidateForm.intime | formatDate("YYYY-MM-DD")
          }}</el-form-item
        >
        <el-form-item label="经手人姓名:">
          {{ dynamicValidateForm.inusername }}</el-form-item
        >
        <el-form-item label="操作时间:">
          {{
            dynamicValidateForm.optime | formatDate("YYYY-MM-DD")
          }}</el-form-item
        >
        <el-form-item label="操作人姓名:">
          {{ dynamicValidateForm.opusername }}</el-form-item
        >
        <el-form-item label="收据序号:">
          {{ dynamicValidateForm.shoujucode }}</el-form-item
        >
        <el-form-item label="所属分公司名称:">
          {{ dynamicValidateForm.companyorgname }}</el-form-item
        >
        <el-form-item label="所属教学点名称:">
          {{ dynamicValidateForm.schoolorgname }}</el-form-item
        >
        <el-form-item label="冲账金额:">
          {{ dynamicValidateForm.chongzhanamount }}</el-form-item
        >
        <el-form-item label="冲账时间:">
          {{
            dynamicValidateForm.chongzhangtime
              | formatDate("YYYY-MM-DD HH:mm:ss")
          }}</el-form-item
        >
        <el-form-item label="冲账标志:">
          {{ chongzhanflag[dynamicValidateForm.chongzhanflag] }}</el-form-item
        >
        <el-form-item label="冲账收据号:">
          {{ dynamicValidateForm.chongzhangshoujuid }}</el-form-item
        >
        <el-form-item label="冲账备注:">
          {{ dynamicValidateForm.chongzhanremark }}</el-form-item
        >
        <el-form-item label="冲账人:">
          {{ dynamicValidateForm.chongzhanusername }}</el-form-item
        >
        <el-form-item label="收款说明:">
          {{ dynamicValidateForm.shoukuanremark }}</el-form-item
        >
      </el-form>
      <h3>明细</h3>
      <el-table
        :data="selectTable.detaillist"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="流水号" prop="id" align="center" width="100"/>
        <el-table-column
          label="收入明细"
          prop="itemmoneyinitemname"
          align="center"
        />
        <el-table-column
          label="收入一级类别"
          prop="type1moneyintypename"
          align="center"
					width="130"
					:show-overflow-tooltip="true"
        />
        <el-table-column
          label="收入二级类别"
          prop="type2moneyintypename"
          align="center"
					width="130"
        />
        <el-table-column
          label="收入类别"
          prop="typemoneyintypename"
          align="center"
					width="130"
        />
        <el-table-column label="金额" prop="amount" align="center" />
        <el-table-column label="摘要" prop="zhaiyao" align="center"/>
      </el-table>
    </el-dialog>

    <!-- 查看明细 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="80%"
			title="查看明细"
			:close-on-click-modal='false'
      :visible.sync="showDetail"
      v-if="showDetail"
      append-to-body
    >
      <div class="select-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="btnEdit0">修改</el-button>
        <!-- <el-button type="primary" @click="btnDel0">删除</el-button> -->
      </div>
      <el-table
        :data="showDetailTable.list"
        style="width: 100%"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        ref="multipleTable"
        @selection-change="selectHandleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="100"/>
        <el-table-column
          label="收入明细"
          prop="itemmoneyinitemname"
          align="center"
        />
        <el-table-column
          label="收入一级类别"
          prop="type1moneyintypename"
          align="center"
					width="160"
					:show-overflow-tooltip="true"
        />
        <el-table-column
          label="收入二级类别"
          prop="type2moneyintypename"
          align="center"
					width="160"
        />
        <el-table-column
          label="收入类别"
          prop="typemoneyintypename"
          align="center"
					width="160"
        />
        <el-table-column label="金额" prop="amount" align="center" />
        <el-table-column label="摘要" prop="zhaiyao" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="handleShowDetailChange"
        :current-page="showDetailPage.pageindex"
        :page-size="showDetailPage.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          showDetailTable.paginationutil
            ? showDetailTable.paginationutil.totalcount
            : showDetailTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
    <!-- 查看明细-修改 -->
    <el-dialog
      class="btn-2b5a95"
      width="800px"
			v-dialogDrag
      title="修改："
			:close-on-click-modal='false'
      :visible.sync="showAddDetailModal"
      v-if="showAddDetailModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="140px"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="收入二级类别名称:" prop="type2moneyintypename">
          <el-input
            v-model.trim="dynamicValidateForm.type2moneyintypename"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收入一级类别名称:" prop="type1moneyintypename">
          <el-input
            v-model.trim="dynamicValidateForm.type1moneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入类别名称:" prop="typemoneyintypename">
          <el-input
            v-model.trim="dynamicValidateForm.typemoneyintypename"
            disabled
          />
        </el-form-item>
        <el-form-item label="收入明细名称:" prop="itemmoneyinitemname">
          <el-input
            v-model.trim="dynamicValidateForm.itemmoneyinitemname"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyinitem_dialogresult, 'itemmoneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="金额:" prop="amount">
          <el-input v-model.trim="dynamicValidateForm.amount" clearable/>
        </el-form-item>
        <el-form-item label="摘要:" prop="zhaiyao">
          <el-input v-model.trim="dynamicValidateForm.zhaiyao" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddDetailForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 查看明细-修改-选择收入二级类别 -->
    <el-dialog
      class="btn-2b5a95"
      width="77%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入二级类型"
      :visible.sync="type2moneyModal"
      v-if="type2moneyModal"
      append-to-body
    >
			
      <el-button type="primary" @click="onConfirmType2moneyModal"
        >确定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="收入类别:" prop="typename">
              <el-input 
								v-model.trim="selectSearchForm.typename"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="收入一级项目:" prop="type1name">
              <el-input 
								v-model.trim="selectSearchForm.type1name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="收入二级项目:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
            <el-form-item label="关联院校:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="80"
        />
        <el-table-column
          label="收入类别"
          align="center"
          prop="typename"
          width="170px"
        />
        <el-table-column
          label="收入一级项目"
          prop="type1name"
          align="center"
          width="250px"
        />
        <el-table-column
          label="收入二级项目"
          prop="name"
          align="center"
          width="250px"
        />
        <el-table-column
          label="关联院校流水号"
          prop="collegeid"
          align="center"
          width="100px"
        />
        <el-table-column
          label="关联院校"
          prop="collegename"
          align="center"
          width="100px"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
    <!-- 查看明细-修改-选择收入明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入明细"
      :visible.sync="itemmoneyModal"
      v-if="itemmoneyModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmItemmoney">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="名称" prop="name" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>

    <!-- 红字冲账 -->
    <el-dialog
      class="btn-2b5a95"
      width="45%"
      title="红字冲账"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showBalanceModal"
      v-if="showBalanceModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="对方单位名称:">
          {{ dynamicValidateForm.unitname }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
        <el-form-item label="收据类型:">
          {{ dynamicValidateForm.shoujutype }}
        </el-form-item>
        <el-form-item label="现金/银行:">
          {{ bankorcash[dynamicValidateForm.bankorcash] }}
        </el-form-item>
        <el-form-item label="本部标识:">
          {{ notselfflag[dynamicValidateForm.notselfflag] }}
        </el-form-item>
        <el-form-item label="现金账户/银行账户:">
          {{ dynamicValidateForm.accountname }}
        </el-form-item>
        <el-form-item label="经手人:">
          {{ dynamicValidateForm.inusername }}
        </el-form-item>
        <el-form-item label="原金额:">
          {{ dynamicValidateForm.amount }}
        </el-form-item>
        <el-form-item label="冲帐金额:">
          {{ -dynamicValidateForm.amount }}
        </el-form-item>
        <el-form-item label="所属分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="所属教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="收款说明:">
          {{ dynamicValidateForm.shoukuanremark }}
        </el-form-item>
        <el-form-item prop="chongzhanremark" label="冲帐备注:">
          <el-input 
						v-model.trim="dynamicValidateForm.chongzhanremark" 
						clearable
						type="textarea"
						:rows="6"
						placeholder="请输入内容"
						/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitBalanceForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 快速登记 -->
    <el-dialog
      class="btn-2b5a95"
      width="86%"
      title="快速登记："
			v-dialogDrag
      :visible.sync="showAddallModal"
      v-if="showAddallModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
				@submit.native.prevent
				:label-position="labelPosition" 
      >
        <el-form-item prop="companyorgname" label="所属分公司:">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点:">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对方单位名称:" prop="unitname">
          <el-input v-model.trim="dynamicValidateForm.unitname" clearable/>
        </el-form-item>
        <el-form-item label="年级:">
          <el-date-picker
            v-model="dynamicValidateForm.grade"
            type="year"
            format="yyyy"
            value-format="yyyy"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="层次:">
          <el-select v-model="dynamicValidateForm.levelkey" clearable>
            <el-option
              v-for="item in leveltypeList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="现金/银行:" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showBankorcashModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="现金账户/卡号:" prop="accountname">
          <el-input v-model.trim="dynamicValidateForm.accountname" disabled />
        </el-form-item>
        <el-form-item label="经手人:" prop="inusername">
          <el-input v-model="dynamicValidateForm.inusername" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                user_dialogresult,
                'showInusernameModal',
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectPageInfo
                )
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收款时间:" prop="intime" clearable>
          <el-date-picker
            v-model="dynamicValidateForm.intime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择"
          />
        </el-form-item>
				<el-form-item label="上传附件:" prop="url">
				  <el-input v-model="dynamicValidateForm.url" disabled></el-input>
				  <el-upload
				    class="upload-demo"
				    :action="$store.state.global.baseUrl"
				    multiple
				    :file-list="fileList"
				    :show-file-list="false"
				    :before-upload="beforeUpload"
				    :http-request="
				      (url) => {
				        uploadFile('url');
				      }
				    "
				  >
				    <el-button size="small" type="primary" class="select-btn"
				      >点击上传</el-button
				    >
				    <div slot="tip" class="el-upload__tip"></div>
				  </el-upload>
				</el-form-item>
				<el-form-item
				  label="开票说明:"
				  prop="shoukuanremark"
				  class="shoukuanremark hoveFrom"
				>
				  <el-input 
						v-model.trim="dynamicValidateForm.shoukuanremark" 
						type="textarea"
						:rows="5"
						/>
				</el-form-item>
        <p style="margin:0 0 10px 0;">收入详情：</p>
				<ol>
				<li
				  v-for="(item, i) in dynamicValidateForm.moreItems"
				  :key="i"
					style="background: aliceblue;"
				>
				<div style="display: flex;">
					<span style="line-height: 43px; width: 66px;">{{i+1}}.</span>
          <el-form-item
            label="收入二级："
            :prop="'moreItems.' + i + '.type2name'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.type2name" disabled></el-input>
            <el-button
              type="primary"
              class="select-btn"
              @click="
                onSelectData(_moneyintype2_dialogresult, 'alltype2moneyModal', {
                  index: i,
                })
              "
              >选择</el-button
            >
          </el-form-item>
          <el-form-item
            label="收入明细："
            :prop="'moreItems.' + i + '.itemname'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.itemname" disabled></el-input>
            <el-button
              type="primary"
              class="select-btn"
              @click="
                onSelectData(_moneyinitem_dialogresult, 'intomoneyModal', {
                  index: i,
                })
              "
              >选择</el-button
            >
          </el-form-item>
          <el-form-item
            label="金额："
            :prop="'moreItems.' + i + '.amount'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.amount" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="摘要："
						style="width: 70%;"
            :prop="'moreItems.' + i + '.zhaiyao'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.zhaiyao"></el-input>
          </el-form-item>
          <el-button
            v-if="i !== 0"
            @click="onRemoveMoreForm(i)"
            style="margin: 0 10px; height: 40px"
            >删除</el-button
          >
					<el-button
					  v-if="i == 0"
					  style="margin: 0 10px; height: 40px; visibility: hidden;"
					  >删除</el-button
					>
        </div>
				</li>
				</ol>
        <el-form-item>
          <el-button @click="addFormItem">添加一行</el-button>
        </el-form-item>
      </el-form>
			<span slot="footer" class="dialog-footer" style="padding-right: 30px;">
			  <el-checkbox v-model="dynamicValidateForm.checked">连续操作</el-checkbox>
			</span>
			<span slot="footer" class="dialog-footer" style="padding-right: 30px;">
			  <el-button type="primary" @click="onSubPrint('dynamicValidateForm')"
			    >确认并打收据
			  </el-button>
			</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onPaysave('dynamicValidateForm')"
          >保存
        </el-button>
      </span>
    </el-dialog>

    <!-- 快速登记--选择收入二级类别 -->
    <el-dialog
      class="btn-2b5a95"
      width="77%"
			:close-on-click-modal='false'
      @close="onCloseSelect"
			v-dialogDrag
      title="选择收入二级类型"
      :visible.sync="alltype2moneyModal"
      v-if="alltype2moneyModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAllType2moneyModal"
        >确定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
						<el-form-item label="收入二级项目:" prop="name">
						  <el-input v-model.trim="selectSearchForm.name" 
							clearable 
							@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
								{index:	dynamicValidateForm.index },
							  selectSearchForm
							))"
							/>
						</el-form-item>
						<el-form-item label="关联院校:" prop="collegename">
						  <el-input v-model.trim="selectSearchForm.collegename" 
							clearable 
							@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
								{index:	dynamicValidateForm.index },
							  selectSearchForm
							))"
							/>
						</el-form-item>
            <el-form-item label="收入类别:" prop="typename">
              <el-input v-model.trim="selectSearchForm.typename"
							 clearable 
							 @keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
							 	{index:	dynamicValidateForm.index },
							   selectSearchForm
							 ))"
							 />
            </el-form-item>
            <el-form-item label="收入一级项目:" prop="type1name">
              <el-input v-model.trim="selectSearchForm.type1name" 
							clearable 
							@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
								{index:	dynamicValidateForm.index },
							  selectSearchForm
							))"
							/>
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, Object.assign(
					 		{index:	dynamicValidateForm.index },
					 	  selectSearchForm
					 	))"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100px"
        />
        <el-table-column
          label="收入类别"
          align="center"
          prop="typename"
          width="150px"
        />
        <el-table-column
          label="收入一级项目"
          prop="type1name"
          align="center"
          width="240px"
        />
        <el-table-column
          label="收入二级项目"
          prop="name"
          align="center"
          width="250px"
        />
        <el-table-column
          label="关联院校"
          prop="collegename"
          align="center"
          width="200px"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
         (val, data) =>
             handleSelectChange(val, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))
         "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
		<!-- 快速登记--修改--选择收入二级类别 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="77%"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择收入二级类型"
		  :visible.sync="editmoneyModal"
		  v-if="editmoneyModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onConfirmedit"
		    >确定</el-button
		  >
		
		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
						<el-form-item label="收入二级项目:" prop="name">
						  <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))"
								clearable />
						</el-form-item>
						<el-form-item label="关联院校:" prop="collegename">
						  <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))"
								clearable />
						</el-form-item>
		        <el-form-item label="收入类别:" prop="typename">
		          <el-input 
								v-model.trim="selectSearchForm.typename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))"
								clearable />
		        </el-form-item>
		        <el-form-item label="收入一级项目:" prop="type1name">
		          <el-input 
								v-model.trim="selectSearchForm.type1name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))"
								clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="onSelectSearchClick(selectTableUrl, null, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		
		  <el-table
		    :data="selectTable.list"
		    ref="moneyTable"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column
		      label="流水号"
		      prop="id"
		      align="center"
		      width="100px"
		    />
		    <el-table-column
		      label="收入类别"
		      align="center"
		      prop="typename"
		      width="150px"
		    />
		    <el-table-column
		      label="收入一级项目"
		      prop="type1name"
		      align="center"
		      width="240px"
		    />
		    <el-table-column
		      label="收入二级项目"
		      prop="name"
		      align="center"
		      width="250px"
		    />
		    <el-table-column
		      label="关联院校"
		      prop="collegename"
		      align="center"
		      width="200px"
		    />
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		     (val, data) =>
		         handleSelectChange(val, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))
		     "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="
		      selectTable.paginationutil
		        ? selectTable.paginationutil.totalcount
		        : selectTable.totalcount
		    "
		  ></el-pagination>
		</el-dialog>

    <!-- 快速登记--选择收入明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入明细"
      :visible.sync="intomoneyModal"
      v-if="intomoneyModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmintomoney">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								clearable
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign(
									{index:	dynamicValidateForm.index },
								  selectSearchForm
								))"
							/>
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, Object.assign(
							{index:	dynamicValidateForm.index },
						  selectSearchForm
						))"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="名称" prop="name" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
         (val, data) =>
             handleSelectChange(val, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))
         "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
		  <!-- 快速登记--选择收入明细-- 修改 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="50%"
			:close-on-click-modal='false'
		  @close="onCloseSelect"
			v-dialogDrag
		  title="选择收入明细"
		  :visible.sync="editModal"
		  v-if="editModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onEditModal">确定</el-button>
		
		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
						@submit.native.prevent
		      >
		        <el-form-item label="名称:" prop="name">
		          <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl, null, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))"
								clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="onSelectSearchClick(selectTableUrl, null, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))"
		        >搜索</el-button
		      >
					<el-button type="primary" @click="handleQuery">重置</el-button>
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				border
				stripe
				:key="key"	
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="名称" prop="name" align="center" />
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		     (val, data) =>
		         handleSelectChange(val, Object.assign({index:	dynamicValidateForm.index }, selectSearchForm))
		     "
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="
		      selectTable.paginationutil
		        ? selectTable.paginationutil.totalcount
		        : selectTable.totalcount
		    "
		  ></el-pagination>
		</el-dialog>
		<!-- 打印收据 -->
		<el-dialog
			class="btn-2b5a95"
			width="1000px"
			v-dialogDrag
			:close-on-click-modal='false'
			@close="onCloseSelect"
			title="打印收据:"
			:visible.sync="shoujuPaper"
			v-if="shoujuPaper"
			append-to-body
		>
			<object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer" width="962px" height="550px" style="margin: auto;">
			    <param name="ReportURL" :value="this.$url.upload + '/report/shouju.grf'">
			    <param name="DataURL" value="" >
			    <param name="AutoRun" value=true >
			    <param name="SerialNo" value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
			    <param name="UserName" value="锐浪报表插件本机开发测试注册" >
			</object>
		</el-dialog>
		
    <!-- 导出 -->
    <Export
      :exportTitle="'收入登记'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import { _fileupload_save } from "@/request/api/public";
import {
  moneyiecv_list,
  moneyiecv_checkmoneyiecv,
  moneyiecv_input,
  moneyiecv_save,
  moneyiecv_delete,
  moneyiecvdetail_save,
  moneyiecvdetail_list,
  moneyiecvdetail_input,
  moneyiecvdetail_delete,
  moneyiecv_charges,
  moneyiecv_checkbalance,
  moneyiecv_inputbalance,
  moneyiecv_savebalance,
  moneyiecv_checkprint,
	moneyiecv_chargesandprintcheck,
  moneyiecv_print,
	moneyiecv_printshoujuPaper,
  moneyiecv_export,
  moneyiecv_exportexcel,
  moneyiecv_exportsave,
  moneyiecv_saveall,
  moneyiecv_show,
	moneyiecv_entry,
	moneyiecv_saveAndprint,
	moneyiecv_chargesandprint,
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
  _account_dialogresult,
  _user_dialogresult,
  dic_combox,
  _moneyinitem_dialogresult,
  _moneyintype2_dialogresult,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _bankorcash,
} from "@/assets/js/filedValueFlag";

export default {
  mixins: [part],
  name: "moneyiecv",
  components: { Export },
  props: {
    user_dialogresult: {
      default: () => _user_dialogresult,
    },
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    _moneyinitem_dialogresult: {
      default: () => _moneyinitem_dialogresult,
    },
    _moneyintype2_dialogresult: {
      default: () => _moneyintype2_dialogresult,
    },

    exportUrl: {
      default: () => moneyiecv_export,
    },
    exportexcelUrl: {
      default: () => moneyiecv_exportexcel,
    },
    exportsaveUrl: {
      default: () => moneyiecv_exportsave,
    },
    bankorcash: {
      default: () => _bankorcash,
    },
  },

  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
			typename: null,
			labelPosition: 'right',
			checked: false,
			shoujuPaper: false,
			fds: new FormData(),
			fileList: [], // 存储图像的数组
      transferDialog: false, //导出框
      itemRow: null, //首表格项
      searchForm: {}, // 搜索值
      tableRes: {}, //列表接口
      leveltypeList: [], // 层次
      payflag: {
        0: "已确认",
        1: "未确认",
      },
      notselfflag: {
        0: "非本部收入",
        1: "本部收入",
      },
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 页数查询
      showAddModal: false, // 显示表单框
      multipleSelection: [], // 选中的表格项
      selectMultipleSelection: [], // 弹框表格选中的表格项

      //冲账标志
      chongzhanflag: {
        0: "未冲账",
        1: "已冲账",
        2: "被冲账",
      },
      rules: {
        chongzhanremark: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        unitname: [{ trigger: "blur", message: "必填字段", required: true }],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        intime: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        type2name: [{ trigger: "blur", message: "必填字段", required: true }],
        itemname: [{ trigger: "blur", message: "必填字段", required: true }],
        amount: [{ trigger: "blur", message: "必填字段", required: true }],
        zhaiyao: [{ trigger: "blur", message: "必填字段", required: true }],
        type2moneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        itemmoneyinitemname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        notselfflag: [{ trigger: "blur", message: "必填字段", required: true }],
        inusername: [{ trigger: "blur", message: "必填字段", required: true }],
        shoujutype: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      // 弹框
      selectTable: {},
      selectTableUrl: null,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectIndex: 0,
      selectSearchForm: {},
			key: '',
      selectSchoolorgid: null,
      paginationutil: {},

      showBankorcashModal: false, // 选择账户
      showInusernameModal: false, // 选择经手人

      // 查看明细
      showDetail: false,

      // 增加明细
      showAddDetailModal: false,
      type2moneyModal: false,
      itemmoneyModal: false,

      // 红字冲账
      showBalanceModal: false,

      // 快速增加
      showAddallModal: false,
      moreFormIndex: null,
      alltype2moneyModal: false,
      intomoneyModal: false,
			editModal: false,
			editmoneyModal: false,
      // 查看
      showShowModal: false,

      //查看明细
      showDetailTable: {},
      showDetailPage: {
        pageindex: 1,
        pagesize: 10,
      },
			multipleSelection: [],
      //快速登记表单
      dynamicValidateForm: {
        moreItems: [
          {
            itemid: "",
            amount: "",
            zhaiyao: "",
            type2id: "",
            type1id: "",
            typeid: "",
          },
        ],
      },
			//快速登记表单--修改
			list: [],
			  list: [
			    {
			      itemid: "",
			      amount: "",
			      zhaiyao: "",
			      type2id: "",
			      type1id: "",
			      typeid: "",
			    },
			  ],
    };
  },
  created() {
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.leveltype();
    this.getDataList();
		this.getNowTime();
		window.addEventListener("keyup", this.enterSelect);
	},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]), //层次 api
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		leveltype() {
		  myRequest({
		    url: dic_combox,
		    data: {
		      typecode: "leveltype",
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.leveltypeList = res.data.data.list;
		    }
		  });
		},
		enterSelect(e) {
			if(e.keyCode === 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectRef.blur();
			}
		},
		visiblepayflag(e) {
			if(!e) {
				this.$refs.payflagref.blur();
			}
		},
		visiblenotselfflag(e) {
			if(!e) {
				this.$refs.notselfflagref.blur();
			}
		},
		selectQuery() {
			this.selectSearchForm = {
				realname: null,
				accountname: null,
				name: null,
				collegename: null,
				typename: null,
				type1name: null,
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
		reset(){
			this.searchForm ={
				unitname: null,
				chongzhanflag: null,
				payflag: null,
				notselfflag: null,
				cashaccountname: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: moneyiecv_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
		//确认并打印 
		btnChargesandprint() {
			selectCheck(this.multipleSelection, "操作", false, ()=> {
				this.chargesandMoneyiecv(this.multipleSelection[0].id)
			})
		},
		//检查可否打印收据 api 纸质版本
		chargesandMoneyiecv() {
			myRequest({
				url: moneyiecv_chargesandprintcheck,
				data: {
					id: this.multipleSelection[0].id,
				}
			}).then((res) => {
				if(res.data.code === "200") {
					this.btnprint();
					this.shoujuPaper = true;
				}
			})
		},
		btnprint() {
			  myRequest(
			    {
			      url: moneyiecv_chargesandprint,
			      data: {
							id: this.multipleSelection[0].id,
						}
			    }).then((res) => {
						if(res.data.code === "200") {
							this.getDataList();
							this.tshouju = res.data.data.tshouju;
							this.vmoneyiecv = res.data.data.vmoneyiecv;
							res.data.data.vmoneyiecv.intime = formatDare(
								this.vmoneyiecv.intime,
							  "YYYY-MM-DD HH:mm:ss"
							);
							this.vmoneyiecvdetaillist = res.data.data.vmoneyiecvdetaillist;
							    var ReportViewer = document.getElementById("ReportViewer");
							    ReportViewer.Stop();    
							    var Report = ReportViewer.Report;
							    var Recordset = Report.DetailGrid.Recordset;
					
							    //获得参数
							var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
							var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
							var ppzh= ReportViewer.Report.ParameterByName("pzh");
							var pgrade= ReportViewer.Report.ParameterByName("grade");
							var pname= ReportViewer.Report.ParameterByName("name");
							var plevel= ReportViewer.Report.ParameterByName("level");
							var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");	
							var premark= ReportViewer.Report.ParameterByName("remark");
							//填充参数
							ppzh.AsString= this.vmoneyiecv.shoujucode;
							pname.AsString=this.vmoneyiecv.unitname;			
							plevel.AsString=this.vmoneyiecv.levelval == null ? '' : this.vmoneyiecv.levelval;
							premark.AsString=this.vmoneyiecv.shoukuanremark == null ? '' : this.vmoneyiecv.shoukuanremark;	
							pinserttime.AsString=this.vmoneyiecv.intime;
							pshoukuairen.AsString=this.vmoneyiecv.opusername;	
							pkaipiaoren.AsString=this.vmoneyiecv.opusername;
							pgrade.AsString = this.vmoneyiecv.grade == null ? '' : this.vmoneyiecv.grade;	
									
							//获得字段
							var fcollege = ReportViewer.Report.FieldByName("college");
							var fitem = ReportViewer.Report.FieldByName("item");
							var fprice = ReportViewer.Report.FieldByName("price");
							var fitem0 = ReportViewer.Report.FieldByName("item0");
							var fprice0 = ReportViewer.Report.FieldByName("price0");
							var yuan0 = ReportViewer.Report.FieldByName("yuan0");
							var yuan = ReportViewer.Report.FieldByName("yuan");
							var maohao0 = ReportViewer.Report.FieldByName("maohao0");
							var maohao = ReportViewer.Report.FieldByName("maohao");
							var hengxian = ReportViewer.Report.FieldByName("hengxian");
							var type2moneyintypename = ReportViewer.Report.FieldByName("type2moneyintypename"); 
							//填充字段
							Report.PrepareRecordset();
							for (var index = 0; index <	this.vmoneyiecvdetaillist.length; index++) {
								Recordset.Append();
								fcollege.AsString =	this.vmoneyiecvdetaillist[index].type1moneyintypename;
								hengxian.AsString ="--" ;
								fitem.AsString =this.vmoneyiecvdetaillist[index].itemmoneyinitemname;
								maohao.AsString ="：" ;
								fprice.AsFloat =	this.vmoneyiecvdetaillist[index].amount;
								yuan.AsString ="元" ;
								type2moneyintypename.AsString = this.vmoneyiecvdetaillist[index].type2moneyintypename;
								fitem0.AsString =this.vmoneyiecvdetaillist[index].itemmoneyinitemname;
								maohao0.AsString ="：" ;
								fprice0.AsFloat =this.vmoneyiecvdetaillist[index].amount;
								yuan0.AsString ="元" ;
								Recordset.Post();
							}
							    	ReportViewer.Start();
						}
					})
		},
		//打印收据纸质
		btnPrint2() {
			selectCheck(this.multipleSelection, "操作", false, ()=> {
				this.selectShouju(this.multipleSelection[0].id)
			})
		},
		//检查可否打印收据 api 纸质版本
		selectShouju() {
			myRequest({
				url: moneyiecv_checkprint,
				data: {
					id: this.multipleSelection[0].id,
				}
			}).then((res) => {
				if(res.data.code === "200") {
					this.printshoujuPaper();
					this.shoujuPaper = true;
				}
			})
		},
		printshoujuPaper(){
			myRequest({
			       url: moneyiecv_printshoujuPaper,
			       data:{
			         id: this.multipleSelection[0].id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
								this.tshouju = res.data.data.tshouju;
			       		this.vmoneyiecv = res.data.data.vmoneyiecv;
								res.data.data.vmoneyiecv.intime = formatDare(
									this.vmoneyiecv.intime,
								  "YYYY-MM-DD HH:mm:ss"
								);
								this.vmoneyiecvdetaillist = res.data.data.vmoneyiecvdetaillist;
			          var ReportViewer = document.getElementById("ReportViewer");
			          ReportViewer.Stop();    
			          var Report = ReportViewer.Report;
			          var Recordset = Report.DetailGrid.Recordset;

			          //获得参数
								var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
								var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
								var ppzh= ReportViewer.Report.ParameterByName("pzh");
								var pgrade= ReportViewer.Report.ParameterByName("grade");
								var pname= ReportViewer.Report.ParameterByName("name");
								var plevel= ReportViewer.Report.ParameterByName("level");
								var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");	
								var premark= ReportViewer.Report.ParameterByName("remark");
								//填充参数
								ppzh.AsString= this.vmoneyiecv.shoujucode;
								pname.AsString=this.vmoneyiecv.unitname;			
								plevel.AsString=this.vmoneyiecv.levelval == null ? '' : this.vmoneyiecv.levelval;
								premark.AsString=this.vmoneyiecv.shoukuanremark == null ? '' : this.vmoneyiecv.shoukuanremark;	
								pinserttime.AsString=this.vmoneyiecv.intime;
								pshoukuairen.AsString=this.vmoneyiecv.opusername;	
								pkaipiaoren.AsString=this.vmoneyiecv.opusername;
								pgrade.AsString = this.vmoneyiecv.grade == null ? '' : this.vmoneyiecv.grade;	
										
								//获得字段
								var fcollege = ReportViewer.Report.FieldByName("college");
								var fitem = ReportViewer.Report.FieldByName("item");
								var fprice = ReportViewer.Report.FieldByName("price");
								var fitem0 = ReportViewer.Report.FieldByName("item0");
								var fprice0 = ReportViewer.Report.FieldByName("price0");
								var yuan0 = ReportViewer.Report.FieldByName("yuan0");
								var yuan = ReportViewer.Report.FieldByName("yuan");
								var maohao0 = ReportViewer.Report.FieldByName("maohao0");
								var maohao = ReportViewer.Report.FieldByName("maohao");
								var hengxian = ReportViewer.Report.FieldByName("hengxian");
								var type2moneyintypename = ReportViewer.Report.FieldByName("type2moneyintypename"); 
								//填充字段
								Report.PrepareRecordset();
								for (var index = 0; index <	this.vmoneyiecvdetaillist.length; index++) {
									Recordset.Append();
									fcollege.AsString =	this.vmoneyiecvdetaillist[index].type1moneyintypename;
									hengxian.AsString ="--" ;
									fitem.AsString =this.vmoneyiecvdetaillist[index].itemmoneyinitemname;
									maohao.AsString ="：" ;
									fprice.AsFloat =	this.vmoneyiecvdetaillist[index].amount;
									yuan.AsString ="元" ;
									type2moneyintypename.AsString = this.vmoneyiecvdetaillist[index].type2moneyintypename;
									fitem0.AsString =this.vmoneyiecvdetaillist[index].itemmoneyinitemname;
									maohao0.AsString ="：" ;
									fprice0.AsFloat =this.vmoneyiecvdetaillist[index].amount;
									yuan0.AsString ="元" ;
									Recordset.Post();
								}
			          	ReportViewer.Start();
									
							
			       }
			  }); 
		},
    //打印收据 click
    btnPrint() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.checkprintshouju(this.multipleSelection[0]);
      });
    },
    //检查可否打印收据 api
    checkprintshouju(row) {
      myRequest({
        url: moneyiecv_checkprint,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.printReceipt(row);
        }
      });
    },
    //打印收据 api
    printReceipt(row) {
      myRequest({
        url: moneyiecv_print,
        data: {
          id: row.id,
          shoujuid: row.shoujuid,
          shoujucode: row.shoujucode,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          window.open(
            this.$url.upload +
              "/shouju/" +
              row.id +
              res.data.data.tshouju.code +
              ".pdf"
          );
        }
      });
    },

    //确认银行账户 click
    onConfirmBankorcash() {
      selectCheck(this.multipleSelection, "选择账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showBankorcashModal = false;
      });
    },
		//确定并打印收据
		onSubPrint(formName) {
			  const initMorefield = [
			    "typeid",
			    "type1id",
			    "type2id",
			    "type2name",
			    "itemid",
			    "itemname",
			    "amount",
			    "zhaiyao",
			  ];
			  initMorefield.map((i) => (this.dynamicValidateForm[i] = []));
			  for (let i in this.dynamicValidateForm.moreItems) {
			    for (let key in this.dynamicValidateForm.moreItems[i]) {
			      if (this.dynamicValidateForm.moreItems[i][key]) {
			        //有值才push，否则是空字符串
			        this.dynamicValidateForm[key].push(
			          this.dynamicValidateForm.moreItems[i][key]
			        );
			      }
			    }
			  }
			  this.$refs[formName].validate((valid) => {
			    if (valid) {
						this.shoujuPaper = true;
			      this.saveAndprint();
			    }
			  });
			},
			//快速新增 api
			saveAndprint() {
			  myRequest(
			    {
			      method: "post",
			      url: moneyiecv_saveAndprint,
			      data: this.$qs.stringify(this.dynamicValidateForm, {
			        arrayFormat: "repeat",
			      }),
			    },
			    {
			      that: this,
			      modal: "showAddallModal",
			    }).then((res) => {
						if(res.data.code === "200") {
							this.getDataList();
							this.tshouju = res.data.data.tshouju;
							this.vmoneyiecv = res.data.data.vmoneyiecv;
							res.data.data.vmoneyiecv.intime = formatDare(
								this.vmoneyiecv.intime,
							  "YYYY-MM-DD HH:mm:ss"
							);
							this.vmoneyiecvdetaillist = res.data.data.vmoneyiecvdetaillist;
							    var ReportViewer = document.getElementById("ReportViewer");
							    ReportViewer.Stop();    
							    var Report = ReportViewer.Report;
							    var Recordset = Report.DetailGrid.Recordset;
					
							    //获得参数
							var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
							var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
							var ppzh= ReportViewer.Report.ParameterByName("pzh");
							var pgrade= ReportViewer.Report.ParameterByName("grade");
							var pname= ReportViewer.Report.ParameterByName("name");
							var plevel= ReportViewer.Report.ParameterByName("level");
							var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");	
							var premark= ReportViewer.Report.ParameterByName("remark");
							//填充参数
							ppzh.AsString= this.vmoneyiecv.shoujucode;
							pname.AsString=this.vmoneyiecv.unitname;			
							plevel.AsString=this.vmoneyiecv.levelval == null ? '' : this.vmoneyiecv.levelval;
							premark.AsString=this.vmoneyiecv.shoukuanremark == null ? '' : this.vmoneyiecv.shoukuanremark;	
							pinserttime.AsString=this.vmoneyiecv.intime;
							pshoukuairen.AsString=this.vmoneyiecv.opusername;	
							pkaipiaoren.AsString=this.vmoneyiecv.opusername;
							pgrade.AsString = this.vmoneyiecv.grade == null ? '' : this.vmoneyiecv.grade;	
									
							//获得字段
							var fcollege = ReportViewer.Report.FieldByName("college");
							var fitem = ReportViewer.Report.FieldByName("item");
							var fprice = ReportViewer.Report.FieldByName("price");
							var fitem0 = ReportViewer.Report.FieldByName("item0");
							var fprice0 = ReportViewer.Report.FieldByName("price0");
							var yuan0 = ReportViewer.Report.FieldByName("yuan0");
							var yuan = ReportViewer.Report.FieldByName("yuan");
							var maohao0 = ReportViewer.Report.FieldByName("maohao0");
							var maohao = ReportViewer.Report.FieldByName("maohao");
							var hengxian = ReportViewer.Report.FieldByName("hengxian");
							var type2moneyintypename = ReportViewer.Report.FieldByName("type2moneyintypename"); 
							//填充字段
							Report.PrepareRecordset();
							for (var index = 0; index <	this.vmoneyiecvdetaillist.length; index++) {
								Recordset.Append();
								fcollege.AsString =	this.vmoneyiecvdetaillist[index].type1moneyintypename;
								hengxian.AsString ="--" ;
								fitem.AsString =this.vmoneyiecvdetaillist[index].itemmoneyinitemname;
								maohao.AsString ="：" ;
								fprice.AsFloat =	this.vmoneyiecvdetaillist[index].amount;
								yuan.AsString ="元" ;
								type2moneyintypename.AsString = this.vmoneyiecvdetaillist[index].type2moneyintypename;
								fitem0.AsString =this.vmoneyiecvdetaillist[index].itemmoneyinitemname;
								maohao0.AsString ="：" ;
								fprice0.AsFloat =this.vmoneyiecvdetaillist[index].amount;
								yuan0.AsString ="元" ;
								Recordset.Post();
							}
							    	ReportViewer.Start();
						}
					})
			},
    //确认选择职员 click
    onConfirmInusername(modal) {
      selectCheck(this.multipleSelection, "选择职员", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "inusername",
          this.multipleSelection[0].realname
        );
        this.dynamicValidateForm.inuserid = this.multipleSelection[0].id;
        this.showInusernameModal = false;
				this.multipleSelection ='';
				this.handleQuery();
      });
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		//确定入账
		btnEntry(){
			selectCheck(this.multipleSelection, "确定入账", false, () => {
				confirmCallBack({
					title:"提示",
					content:"确定要入账吗？",
					success: () => {
						this.okEntry(this.multipleSelection[0].id);
					},
					fail: () => {
						messageTip({
							message: "取消操作",
							type: "info",
						});
					}
				});
			});
		},
		okEntry(id){
			myRequest(
			{
				url: moneyiecv_entry,
				data:{
					id:id
				}
			},
			{
			  isRefTableList: true,
			  that: this,
			}
			)
		},
		//输入款不可输入数据
		change() {
			this.$forceUpdate()
		},
    //刷新 click
    btnReload() {
      this.getDataList();
    },
		//下载文件
		downloadFile(index, row) {
			if (row.url != undefined) {
			  location.href =
			    this.$url.Freemarker +
			    "/filedownload/save.do?fileurl=" +
			    row.url;
			}
		},
		//上传文件前 before
		beforeUpload(file) {
			this.fds = new FormData();
			this.fds.append("upfile", file); // 传文件
		},
		// 上传文件 upload
		uploadFile(name) {
		  myRequest({
		    method: "post",
		    url: _fileupload_save,
		    data: this.fds,
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.dynamicValidateForm[name] = res.data.url;
		    }
		  });
		},
    // 修改
    btnEdit() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.submitFormname = "dynamicValidateForm";
        this.dialogTitle = "修改";
        myRequest({
          url: moneyiecv_checkmoneyiecv,
        }).then((res) => {
          if (res.data.code === "200") {
            this.editRoutePage(this.multipleSelection[0].id);
          }
        });
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: moneyiecv_input,
        data: {
          id: id,
        },
      }).then((res) => {
        console.log(res, '这个是=====')
        if (res.data.code === "200") {
					this.dynamicValidateForm = Object.assign(
						res.data.data.tmoneyiecv
					);
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.dynamicValidateForm.intime = formatDare(
            this.dynamicValidateForm.intime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.grade = this.dynamicValidateForm.grade || "";
					this.initDetail(res.data.data);
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.optime; //optime 该字段不用传
					
        }
      });
    },
		//修改数据 -- 收入详情
		initDetail(resData) {
			this.$set(this.dynamicValidateForm, "moreItems2", []);
			for(let i in resData.list) {
				this.dynamicValidateForm.moreItems2.push(resData.list[i]);
			}
			this.showAddModal = true;
      console.log(this.dynamicValidateForm, '这个是收入请款')
		},
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: moneyiecv_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    // 查看 click
    showTableItem(id) {
      myRequest({
        url: moneyiecv_show,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.selectTable = res.data.data;
          this.dynamicValidateForm = res.data.data.vmoneyiecv;
          this.dynamicValidateForm.intime = formatDare(
            this.dynamicValidateForm.intime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.optime = formatDare(
            this.dynamicValidateForm.optime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.showShowModal = true;
        }
      });
    },

    // 增加明细 click
    btnAddDetail() {
      selectCheck(this.multipleSelection, "增加明细", false, () => {
        this.dynamicValidateForm = {};
        this.dynamicValidateForm.moneyiecvid = this.multipleSelection[0].id;
        this.dynamicValidateForm.id = 0;
        this.showAddDetailModal = true;
      });
    },

    // 选择收入二级类型确定 click
    onConfirmType2moneyModal() {
			selectCheck(this.multipleSelection, "选择收入二级类型", false, () => {
				this.$set(
				  this.dynamicValidateForm,
				  "type2moneyintypename",
				  this.multipleSelection[0].name
				);
				this.$set(
				  this.dynamicValidateForm,
				  "type1moneyintypename",
				  this.multipleSelection[0].type1name
				);
				this.$set(
				  this.dynamicValidateForm,
				  "typemoneyintypename",
				  this.multipleSelection[0].typename
				);
				this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
				this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
				this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
				this.type2moneyModal = false;
				this.multipleSelection = '';
				this.handleQuery();
			})
    },

    // 确认收入明细 click
    onConfirmItemmoney() {
			selectCheck(this.multipleSelection, "收入明细", false, ()=> {
				this.dynamicValidateForm.itemid = this.multipleSelection[0].id;
				this.$set(
				  this.dynamicValidateForm,
				  "itemmoneyinitemname",
				  this.multipleSelection[0].name
				);
				this.itemmoneyModal = false;
				this.multipleSelection = '';
				this.handleQuery();
			})
    },

    // 查看明细 click
    btnShowDetail() {
      selectCheck(this.multipleSelection, "查看明细", false, () => {
        this.itemRow = this.multipleSelection[0];
        this.showDetailItem(this.multipleSelection[0].id);
      });
    },
    // 查看明细 api
    showDetailItem(id) {
      myRequest({
        url: moneyiecvdetail_list,
        data: {
          moneyiecvid: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetail = true;
          this.showDetailTable = res.data.data;
        }
      });
    },
    // 查看明细 - 切换表格分页 click
    handleShowDetailChange(val) {
      this.showDetailPage.pageindex = val;
      this.showDetailItem(this.itemRow.id);
    },
    // 查看明细 - 刷新
    btnReload1() {
      this.showDetailItem();
    },
    // 查看明细 - 修改 click
    btnEdit0() {
      selectCheck(this.selectMultipleSelection, "修改", false, () => {
        this.showDetailRoutePage(this.selectMultipleSelection[0].id);
      });
    },
    // 查看明细 - 修改路由页 api
    showDetailRoutePage(id) {
      myRequest({
        url: moneyiecvdetail_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddDetailModal = true;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.dynamicValidateForm = res.data.data.vmoneyiecvdetail;
        }
      });
    },
    // 查看明细 - 提交修改 api
    submitAddDetailForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest({
            url: moneyiecvdetail_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.showDetailItem(this.itemRow.id);
              this.showAddDetailModal = false;
            }
          });
        }
      });
    },

    // 确认收费 click
    btnOk() {
      selectCheck(this.multipleSelection, "确认收费", false, () => {
        confirmCallBack({
          title: "提示",
          content: "确定要收款吗？",
          success: () => {
            this.okTableItem(this.multipleSelection[0].id);
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //确认收费 api
    okTableItem(id) {
      myRequest(
        {
          url: moneyiecv_charges,
          data: {
            id: id,
          },
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    // 红字冲账 click
    btnBalance() {
      selectCheck(this.multipleSelection, "红字冲账", false, () => {
        this.balanceCheckItem(this.multipleSelection[0].id);
      });
    },

    // 红字冲账验证 api
    balanceCheckItem(id) {
      myRequest({
        url: moneyiecv_checkbalance,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.balanceTbaleItem(id);
        }
      });
    },
    // 红字冲账路由 api
    balanceTbaleItem(id) {
      myRequest({
        url: moneyiecv_inputbalance,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showBalanceModal = true;
          this.dynamicValidateForm = res.data.data.tmoneyiecv;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          delete this.dynamicValidateForm.optime; //isoktime 该字段不用传
          delete this.dynamicValidateForm.intime; //isoktime 该字段不用传
        }
      });
    },

    // 红字冲账确定 api
    submitBalanceForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: moneyiecv_savebalance,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showBalanceModal",
            }
          );
        }
      });
    },
		getNowTime() {
			var date = new Date();  
			var year = date.getFullYear();  
			var month = (date.getMonth() + 1) < 10?'0'+(date.getMonth() + 1):(date.getMonth() + 1); 
			var day = date.getDate()<10?'0'+date.getDate():date.getDate();
			var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();  
			var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); 
			var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); 
			this.dynamicValidateForm.intime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
		},
    // 快速登记 click
    btnAddall() {
      this.dynamicValidateForm = {
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
				intime: this.dynamicValidateForm.intime == '' ? this.dynamicValidateForm.intime : '',
				moreItems: [
          {
            itemid: "",
            amount: "",
            zhaiyao: "",
            type2id: "",
            type1id: "",
            typeid: "",
          },
        ],
      };
			this.$set(
			  this.dynamicValidateForm,
			  "companyorgname",
			  this.$store.state.login.userinfo.companyorgname
			);
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
			this.getNowTime();
			this.dynamicValidateForm.inusername = this.$set(
			  this.dynamicValidateForm,
			  "realname",
			  this.$store.state.login.userinfo.realname
			);
			this.dynamicValidateForm.outuserid = this.$set(
			  this.dynamicValidateForm,
			  "id",
			  this.$store.state.login.userinfo.id
			);
      this.showAddallModal = true;
    },

    //添加一行 click
    addFormItem() {
				this.dynamicValidateForm.moreItems.push({
					itemid:this.$set(
						this.dynamicValidateForm.moreItems[this.selectIndex],
						"itemid",
						this.dynamicValidateForm.moreItems[this.selectIndex].itemid
					),
					typeid:this.$set(
							this.dynamicValidateForm.moreItems[this.selectIndex],
							"typeid",
							this.dynamicValidateForm.moreItems[this.selectIndex].typeid
						),
					type1id:this.$set(
							this.dynamicValidateForm.moreItems[this.selectIndex],
							"type1id",
							this.dynamicValidateForm.moreItems[this.selectIndex].type1id 
						),
				type2id:this.$set(
							this.dynamicValidateForm.moreItems[this.selectIndex],
							"type2id",
							this.dynamicValidateForm.moreItems[this.selectIndex].type2id 
        ),
       type2name:this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "type2name",
          this.dynamicValidateForm.moreItems[this.selectIndex].type2name
        ) , 
        type1name: this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "type1name",
            this.dynamicValidateForm.moreItems[this.selectIndex].type1name
        ),
				typename: this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "typename",
            this.dynamicValidateForm.moreItems[this.selectIndex].typename
        ),
        amount:	'',
        zhaiyao:''
      });
    },
		//添加一行 click
		editFormItem() {
			this.dynamicValidateForm.moreItems2.push({
			   type2id: "",
			   itemid: "",
			   amount: "",
			   zhaiyao: "",
			});
		},
    //删除快速新增项 click
    onRemoveMoreForm(index) {
      this.dynamicValidateForm.moreItems.splice(index, 1);
    },
		//删除快速新增项 -- 修改
		onRemoveList(index) {
		  this.dynamicValidateForm.moreItems2.splice(index, 1);
		},
		//提交修改
		submitAddOrEditForm(formName) {
			const moreDataKey = ["moreItems2"];
		  const moreItemKey = [
		    "typeid",
		    "type1id",
		    "type2id",
		    "type2moneyintypename",
		    "itemid",
		    "itemmoneyinitemname",
		    "amount",
		    "zhaiyao",
		  ];
		  moreItemKey.map((i) => (this.dynamicValidateForm[i] = []));
			for (let moreKey in moreDataKey){
			  for(let j in moreItemKey){
			    for(let i in this.dynamicValidateForm[moreDataKey[moreKey]]){
			      for (let key in this.dynamicValidateForm[moreDataKey[moreKey]][i]){
			        if (this.dynamicValidateForm[moreDataKey[moreKey]][i][key] && key === moreItemKey[j]) {
			          //有值才push，否则是空字符串
			          this.dynamicValidateForm[key].push(this.dynamicValidateForm[moreDataKey[moreKey]][i][key]);
			        }
			      }
			    }
			  }
			}
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		     this.handleSave();
		    }
		  });
		},
		handleSave() {
		  myRequest(
		    {
					method: "post",
		      url: moneyiecv_save,
		      data: this.$qs.stringify(this.dynamicValidateForm, {
		        arrayFormat: "repeat",
		      }),
		    },
		    {
		      that: this,
		      modal: "showAddModal",
									 
		      isRefTableList: true,
		    }
		  );
		},
		onPaysave(formName) {
		  const initMorefield = [
		    "typeid",
		    "type1id",
		    "type2id",
		    "type2name",
		    "itemid",
		    "itemname",
		    "amount",
		    "zhaiyao",
		  ];
		  initMorefield.map((i) => (this.dynamicValidateForm[i] = []));
		  for (let i in this.dynamicValidateForm.moreItems) {
		    for (let key in this.dynamicValidateForm.moreItems[i]) {
		      if (this.dynamicValidateForm.moreItems[i][key]) {
		        //有值才push，否则是空字符串
		       this.dynamicValidateForm[key].push(
		          this.dynamicValidateForm.moreItems[i][key]
		        );
		      }
		    }
		  }
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
					if(this.dynamicValidateForm.checked == true) {
						this.saveallValidate();
					} else {
						this.saveall();
					}
		    
		    }
		  });
		},
    //快速新增 api
    saveallValidate() {
      myRequest(
        {
          method: "post",
          url: moneyiecv_saveall,
          data: this.$qs.stringify(this.dynamicValidateForm, {
            arrayFormat: "repeat",
          }),
        },
        {
          that: this,

          isRefTableList: true,
        }
      );
    },
    //快速新增 api
    saveall() {
      myRequest(
        {
          method: "post",
          url: moneyiecv_saveall,
          data: this.$qs.stringify(this.dynamicValidateForm, {
            arrayFormat: "repeat",
          }),
        },
        {
          that: this,
          modal: "showAddallModal",
          isRefTableList: true,
        }
      );
    },
	
    //快速登记的确认收入明细 click
    onConfirmintomoney() {
      selectCheck(this.multipleSelection, "确认收入明细", false, () => {
			 this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "itemid",
          this.multipleSelection[0].id
        );
				this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "itemname",
          this.multipleSelection[0].name
        );
        this.intomoneyModal = false;
      });
			this.selectQuery();
			this.multipleSelection= [];
    },
		//快速登记的确认收入明细 click-- 修改
		onEditModal() {
		  selectCheck(this.multipleSelection, "确认收入明细", false, () => {
		    this.$set(
		      this.dynamicValidateForm.moreItems2[this.selectIndex],
		      "itemid",
		      this.multipleSelection[0].id
		    );
		    this.$set(
		      this.dynamicValidateForm.moreItems2[this.selectIndex],
		      "itemmoneyinitemname",
		      this.multipleSelection[0].name
		    );
		    this.editModal = false;
		  });
			this.multipleSelection = [];
			this.selectQuery();
		},

    //确定收入二级类型 click
    onConfirmAllType2moneyModal() {
      selectCheck(this.multipleSelection, "收入二级类别", false, () => {
        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "type2id",
          this.multipleSelection[0].id
        );
        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "type2name",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "type1id",
          this.multipleSelection[0].type1id
        );
        this.$set(
          this.dynamicValidateForm.moreItems[this.selectIndex],
          "typeid",
          this.multipleSelection[0].typeid
        );
        this.alltype2moneyModal = false;
				
      });
			this.selectQuery();
			this.multipleSelection = [];
    },
		//确定收入二级类型 click -- 修改
		onConfirmedit() {
			selectCheck(this.multipleSelection, "收入二级类别", false, () => {
				this.$set(
				  this.dynamicValidateForm.moreItems2[this.selectIndex],
				  "type2moneyintypename",
				  this.multipleSelection[0].name
				);
				this.$set(
			    this.dynamicValidateForm.moreItems2[this.selectIndex],
			    "type2id",
			    this.multipleSelection[0].id
			  );
				
			  this.$set(
			    this.dynamicValidateForm.moreItems2[this.selectIndex],
			    "type1id",
			    this.multipleSelection[0].type1id
			  );
			  this.$set(
			    this.dynamicValidateForm.moreItems2[this.selectIndex],
			    "typeid",
			    this.multipleSelection[0].typeid
			  );
			  this.editmoneyModal = false;
			});
			this.multipleSelection = '';
			this.selectQuery();
		},

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    // 选项表格 api(德立学生)
    onSelectData(url, modal, data) {
			this.selectIndex = data?.index;
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
					this.dynamicValidateForm.index = res.data.data.index;
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 选择表单选中表格数据改变时
    selectHandleSelectionChange(val) {
      this.selectMultipleSelection = val;
    },
  },
};
</script>

<style lang="scss">
	.more-form-www {
		display: flex;
		flex-wrap: nowrap;
		.el-form-item {
			width: 24%;
			vertical-align: top;
		}
	}
</style>