<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
					<el-form-item label="流水号">
						<el-input
							v-model.trim="searchForm.id"
							@keyup.enter.native="search"
							clearable/>
					</el-form-item>
          <el-form-item label="学生">
          	<el-input
							v-model.trim="searchForm.qianzainame"
							@keyup.enter.native="search"
							clearable></el-input>
          </el-form-item>
			<el-form-item label="年级">
				<el-input
						v-model.trim="searchForm.grade"
						@keyup.enter.native="search"
						clearable></el-input>
			</el-form-item>
          <el-form-item label="优惠类型:">
          	<el-select
							v-model="searchForm.discounttype"
							@keyup.enter.native="search"
							placeholder="请选择"
							@visible-change="visibleType"
							ref="discounttype"
							clearable>
          		<el-option
          			v-for="item in discountTypeList"
          			:key="item.key"
          			:value="item.key"
          			:label="item.val"
          		></el-option>
          	</el-select>
          </el-form-item>
          <el-form-item label="优惠金额:">
            <el-input
							v-model.trim="searchForm.discountamount"
							@keyup.enter.native="search"
							clearable />
          </el-form-item>
          <el-form-item label="审核状态:">
          	<el-select
							v-model="searchForm.approvestate"
							@keyup.enter.native="search"
							@visible-change="visibleAppro"
							ref="approvestate"
							placeholder="请选择"
							clearable>
          		<el-option
          			v-for="item in approvestatetype"
          			:key="item.key"
          			:value="item.value"
          			:label="item.label"
          		></el-option>
          	</el-select>
          </el-form-item>
          <el-form-item label="所属教学点名称:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="search"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="search"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    	<el-table
    		:data="tableRes.list"
				border
				stripe
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
    		ref="multipleTable"
    		style="width: 100%"
    		@selection-change="handleSelectionChange"
    	>
    		<el-table-column type="selection" width="55" align="center" />
				<el-table-column align="center" label="流水号" prop="id"/>
    		<el-table-column align="center" label="学生" prop="qianzainame"/>
    		<el-table-column align="center" label="年级" prop="grade"/>
    		<el-table-column align="center" label="学年" prop="iyear"/>
    		<el-table-column align="center" label="优惠类型">
					<template slot-scope="scope">
						{{discountype[scope.row.discounttype]}}
					</template>
				</el-table-column>
    		<el-table-column align="center" label="优惠金额" prop="discountamount"/>
    		<el-table-column align="center" label="剩余额" prop="surplus"/>
    		<el-table-column align="center" label="备注" prop="remarks" :show-overflow-tooltip="true"/>
    		<el-table-column align="center" label="申请人" prop="applicantname"/>
    		<el-table-column align="center" label="审核状态">
					<template slot-scope="scope">
						{{approvestate[scope.row.approvestate]}}
					</template>
				</el-table-column>
    		<el-table-column align="center" label="审核人" prop="approvename"/>
    		<el-table-column align="center" label="教学点" prop="schoolorgname"/>
    	</el-table>
    <!-- 	分页 -->
    	<el-pagination
    		background
    		@current-change="handleCurrentChange"
    		:current-page="pageinfo.pageindex"
    		:page-size="pageinfo.pagesize"
    		:page-sizes="[10, 20, 30, 40, 50]"
    		layout="total, prev, pager, next, jumper"
    		:total="tableRes.totalcount"
    	>
    	</el-pagination>
		<!-- 	教学点审批 -->
			<el-dialog
			  class="btn-2b5a95"
			  title="教学点审批："
				v-dialogDrag
			  :visible.sync="showApprovalModal"
			  v-if="showApprovalModal"
				:close-on-click-modal='false'
			  append-to-body
			  width="35%"
			>
				<el-form
					:model="dynamicValidateForm"
					ref="dynamicValidateForm"
					class="demo-dynamic"
					status-icon
					:rules="rules"
				>
					<el-form-item
						prop="passflag"
						label="审核是否通过:"
						>
						<el-select
							v-model="dynamicValidateForm.passflag"
							placeholder="请选择"
							clearable
						>
							<el-option
								v-for="flag in passflags"
								:key="flag.value"
								:label="flag.label"
								:value="flag.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item
					  label="审批意见："
					  prop="approvedesc"
					>
					  <el-input
					    type="textarea"
					    v-model.trim="dynamicValidateForm.approvedesc"
							clearable
					  ></el-input>
					</el-form-item>
				</el-form>
			  <span slot="footer" class="dialog-footer">
			    <el-button
			      type="primary"
			      @click="onApprovalClick('dynamicValidateForm')"
			      >提交
			    </el-button>
			  </span>
			</el-dialog>


	  <Import
			  :importDialog="importDialog"
			  :importSaveUrl="importSaveUrl"
			  @onCloseImport="onCloseImport"
			  @getDataList="getDataList"
			  downloadUrl="download/财务管理/优惠劵导入模板.xls"
	  ></Import>

  </div>
</template>

<script>
	import { part } from "@/utils/mixins";
	import { mapActions } from "vuex";
	import Import from "@/components/Import/index.vue";
	import {
		discount_list,
		discount_save,
		discount_delete,
		discount_approvesave,//审核保存接口
		discount_checkapprove,//修改，删除校验
		studentregister_dialogresult4,
		discount_export,
		discount_importsave,
	} from "@/request/api/allChildrenProject";
	import {myRequest} from "@/request/index";
	import {
		selectCheck,
		confirmCallBack,
		messageTip,
		createObjParams,
		formatDare
	} from "@/utils/util";
	import {
		_discount,
		_approveflags,
		_passflags,
		_discountYear
	} from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  components: {Import},
	props:{
		studentregister_dialogresult4: {
		  default: () => studentregister_dialogresult4,
		},
		importSaveUrl: {
			default: () => discount_importsave,
		},
	},
  data() {
    return {
		importDialog: false, //导入框
			discountYear: _discountYear,
			approvestate: {
				'-2':'未提请审核',
				'-1': '审核中 ',
				'0':'不通过',
				'1':'通过',
			},
			discountype :{
				'01':'直接优惠',
				'02':'转介绍',
				'03':'3人同报名'
			},
      pageinfo: {
      	pageindex: 1,
      	pagesize: 10,
      },//页面查询
      tableRes: {},//列表接口
      searchForm: {id: ''},
      selectTable: {},
      selectTableUrl: "",
      selectPageInfo: {
      	pageindex:1,
      	pagesize: 10,
      },
      selectSearchForm: {},
			multipleSelection: [], //选中的表格项
			dynamicValidateForm: {}, //表单
			rules: {
			  passflag: [
			    { trigger: "blur", message: "必填字段", required: true },
			  ],

			}, //表单规则
      discountTypeList: [],
      approvestatetype: _approveflags,
			passflags: _passflags,
			showApprovalModal: false, //审批框
    };
  },
  created() {
		this.searchForm.id = this.$route.params.id;
  	this.org_combox();
		this.getDataList();
		this.dic_combox({
			that: this,
			typecode: "discountType",
			list: "discountTypeList",
		});
		window.addEventListener("keyup", this.enterSelect);
	},
  methods: {
  	...mapActions("global", ["onExtendClick", "isExpand"]),
  	...mapActions("selectData", ["org_combox", "org_comboxchild", "dic_combox"]),

	  //关闭导入框
	  onCloseImport(bool) {
		  this.importDialog = bool;
	  },
	  //导入 click
	  btnImport2() {
		  this.importDialog = true;
	  },

  	//触发所有btn方法
  	onClickBtn(methodsName) {
  		this.$options.methods[methodsName].call(this);//btnAdd ... （防止修改this指向）
  	},
		//改变分公司 change
		onChangeCompany(val, form) {
		  this[form].schoolorgname = "";
		  this[form].companyorgid = val;
		  this.org_comboxchild(val);
		},
		onChangeSchool(val, form) {
		  this[form].schoolorgid = val;
		},
		enterSelect(e){
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e){
			if(!e) {
				this.$refs.discounttype.blur();
			}
		},
		visibleAppro(e) {
			if(!e) {
				this.$refs.approvestate.blur();
			}
		},
		reset() {
			this.searchForm = {
				id: null,
				qianzainame: null,
				discountType: null,
				discountamount: null,
				approvestate: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
  	//搜索 click
  	search() {
  		this.pageinfo.pageindex = 1;
  		this.getDataList();
  	},
  	//列表查询 api
  	getDataList() {
			if(this.searchForm.id == undefined) {
				delete this.searchForm.id
			} else {
				this.searchForm.id
			}
  		myRequest({
  			url: discount_list,
  			data: Object.assign(this.searchForm, this.pageinfo),
  		}).then((res) => {
  			if(res.data.code === "200") {
  				this.tableRes = res.data.data;
  			}
  		})
  	},
  	//切换当前页
  	handleCurrentChange(val) {
  		this.pageinfo.pageindex = val;
  		this.getDataList();
  	},
  	//切换选项表格页
  	handleSelectChange(val,data,prop,page) {
  		this.selectPageInfo.pageindex = val;
  		this.onSelectData(
  			this.selectTableUrl,
  			null,
  			prop,
  			page
  		)
  	},
  	//刷新 click
  	btnRefresh() {
  		this.getDataList();
  	},
  	//选项表格 api
  	onSelectData(url, modal, data, prop, page) {
  		this.selectTableUrl = url;
  		myRequest({
  			url: this.selectTableUrl,
  			data: Object.assign(data || {}, this.selectPageInfo),
  		}).then((res) => {
  			if(res.data.code === "200") {
  				this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
  				this.selectTable = res.data.data;
  				this[modal] = true;
  			}
  		})
  	},
  	//选项表格 搜索click
  	onSelectSearchClick(url, modal, data){
  		this.selectPageInfo.pageindex = 1
  		this.onSelectData(url, modal, data)
  	},
  	//选中表格数据改变时
  	handleSelectionChange(val) {
  		this.multipleSelection = val;
  	},
		//关闭选项框 click
		onCloseSelect() {
			this.selectPageInfo.pageindex = 1;
		},
		//教学点审批
		aabtnVeryfy1() {
			selectCheck(this.multipleSelection, "教学点审批", false, ()=> {
				this.dynamicValidateForm.id =  this.multipleSelection[0].id;
				this.headVeryfy(this.multipleSelection.map((i) => i.id));
			})
		},

	  //负责人审批 click
	  btnVeryfy1() {
		  selectCheck(this.multipleSelection, "负责人审批", true, () => {
			  this.dynamicValidateForm = {};
			  this.headVeryfy();
		  });
	  },
	  headVeryfy() {
		  myRequest(
				  {
					  method: "post",
					  url: discount_checkapprove,
					  data: this.$qs.stringify(
							  {
								  ids: this.multipleSelection.map((i) => i.id),
							  },
							  {
								  arrayFormat: "repeat"
							  }
					  )
				  },
				  {
					  that: this,
					  methodName: "approval",
				  }
		  )
	  },

		//检查审批 api
		aaheadVeryfy(idArr) {
			myRequest(
				{
					method: "post",
					url: discount_checkapprove,
					data: this.$qs.stringify(
						{
							ids: idArr,
						},
						{
							arrayFormat: "repeat"
						}
					)
				},
				{
					that: this,
					methodName: "approval",
				}
				)
		},
		//审批弹框
		approval() {
			this.showApprovalModal = true;
		},
		//审批保存  api
		onApprovalClick(dynamicValidateForm) {
		  this.$refs[dynamicValidateForm].validate((valid) => {
		    if (valid) {
					myRequest(
					  {
					    method: "post",
					    url: discount_approvesave,
					    data: this.$qs.stringify(
					      {
					        ids: this.multipleSelection.map((i) => i.id),
					        passflag: this.dynamicValidateForm.passflag,
					        approvedesc: this.dynamicValidateForm.approvedesc,
					      },
					      {
					        arrayFormat: "repeat",
					      }
					    ),
					  },
					  {
					    modal: "showApprovalModal",
					    isRefTableList: true,
					    that: this,
					  },
					)
		    }
		  });
		},
		//检查删除 api
		headApproval(idArr) {
			myRequest(
				{
					method: "post",
					url: discount_checkapprove,
					data: this.$qs.stringify(
						{
							ids: idArr,
						},
						{
							arrayFormat: "repeat"
						}
					)
				},
				{
					that: this,
					methodName: "delTableItem",
				}
				)
		},
		// 导出
		btnImport() {
			confirmCallBack({
			  title: "提示",
			  content: "导出总表会根据搜索条件进行导出。",
			  success: () => {
			    window.location.href =
			      this.$url.Freemarker +
			      discount_export +
			      `?${createObjParams(this.searchForm)}`;
			  },
			  fail: () => {
			    messageTip({
			      message: "取消操作",
			      type: "info",
			    });
			  },
			});
		},

		//删除
		btnDel() {
			selectCheck(this.multipleSelection, "删除", true, ()=> {
				this.headApproval(this.multipleSelection.map((i) => i.id));
			});
		},
		//删除 api
		delTableItem() {
			myRequest(
			{
				method: "post",
				url: discount_delete,
				data: this.$qs.stringify(
					{
						ids: this.multipleSelection.map((i) => i.id),
					},
					{
						arrayFormat: "repeat"
					}
				),
			},
			{
				isRefTableList: true,
				that: this,
			}
			)
		},

  }
};
</script>

<style  lang="scss">
</style>
