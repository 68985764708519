<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="姓名:">
          <el-input 
						v-model.trim="searchForm.qianzainame"
						@submit.native.prevent="seach"
						clearable />
        </el-form-item>
				<el-form-item label="跟进人:">
          <el-input 
						v-model.trim="searchForm.username" 
						@submit.native.prevent="seach"
						clearable />
        </el-form-item>
				<el-form-item label="跟进内容:">
          <el-input 
						v-model.trim="searchForm.followcontent" 
						@submit.native.prevent="seach"
						clearable />
        </el-form-item>
				<el-form-item label="跟进时间起:">
					  <el-date-picker
					    v-model="searchForm.t1"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd"
					  >
					  </el-date-picker>
					</el-form-item>
					<el-form-item label="跟进时间起止:">
					  <el-date-picker
					    v-model="searchForm.t2"
					    type="date"
					    placeholder="选择日期"
					    value-format="yyyy-MM-dd"
					  >
					  </el-date-picker>
					</el-form-item>
					<el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname"
						@submit.native.prevent="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>
    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
			<el-table-column label="流水号" prop="id" align="center" />
      <el-table-column
        label="姓名"
        prop="qianzainame"
        align="center"
				width="200px"
      >
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row.qianzaiid)" type="text">{{
            scope.row.qianzainame
          }}</el-button>
        </template>
      </el-table-column>
			<el-table-column label="跟进时间" align="center" width="190">
          <template slot-scope="scope">
            {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column>
      <el-table-column label="跟进人" prop="username" align="center" />
      <el-table-column label="跟进内容" prop="followcontent" align="center"  width="410"/>
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>


		<div id="RightOffside" class="RightOffside" v-show="showDetailModal">
			<!-- 按钮组 -->
			<div class="Offside_btnBox">
				<div class="Offside_Btn Offside_CloseBtn" @click="handleShow"></div>
				<div class="Offside_Btn" v-for="(item,index) in tabList" :key="index"
					:class="{active:currentClass === index}" @click="toggleTab(index)">
					{{item}}
				</div>
			</div>
			<div class="OrderStatusTips">
				<span class="pull-left text-blue">人员信息</span>
			</div>
			<!-- 切换栏 -->
			<div>
				<div class="OrderContentBox" v-show="currentTab === 0">
					<div class="order-card x-basic abase-card"
						style="width:800px;background-color: #D1EEEE; margin-bottom: 30px;">
						<div class="top_el">
							基本信息
						</div>
						<el-form>
							<table class="tlist_el" style="width: 100%;" bgcolor="#D1EEEE" cellpadding="5px">
								<tbody>
									<tr>
										<td class="ltd_el">流水号：</td>
										<td class="ltd_tag">{{ vstudentpotential.id }}</td>
										<td class="ltd_el">姓名：</td>
										<td class="ltd_tag">{{ vstudentpotential.name }}</td>
										<td class="ltd_el">性别：</td>
										<td class="ltd_tag">{{ vstudentpotential.sexval }}</td>
										<td class="ltd_el">固定电话：</td>
										<td class="ltd_tag">{{ vstudentpotential.phone }}</td>
									</tr>
									<tr>
										<td class="ltd_el">手机：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.mobile | phoneFilter()}}
										</td>
										<td class="ltd_el">手机2：</td>
										<td class="ltd_tag">{{ vstudentpotential.mobile2 }}</td>
										<td class="ltd_el"> 微信：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.weixin }}
										</td>
										<td class="ltd_el">QQ：</td>
										<td class="ltd_tag">{{ vstudentpotential.qq }}</td>
									</tr>
									<tr>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">户口所在地：</td>
										<td class="ltd_tag">{{ vstudentpotential.hukouaddr }}</td>
										<td class="ltd_el">政治面貌：</td>
										<td class="ltd_tag">{{ vstudentpotential.polival }}</td>
										<td class="ltd_el">民族：</td>
										<td class="ltd_tag">{{ vstudentpotential.nationval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">出生日期：</td>
										<td class="ltd_tag">{{ vstudentpotential.birthday | formatDate("YYYY-MM-DD")}}
										</td>
										<td class="ltd_el">籍贯：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">证件号码：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcard }}</td>
										<td class="ltd_el">婚否：</td>
										<td class="ltd_tag">{{ vstudentpotential.marryval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.opusername }}</td>
										<td class="ltd_el"> 录入时间：</td>
										<td class="ltd_tag">
											{{
										  vstudentpotential.inserttime
										    | formatDate("YYYY-MM-DD HH:mm:ss")
										}}
										</td>
										<td class="ltd_el">意向程度：</td>
										<td class="ltd_tag">{{ vstudentpotential.intentionval }}</td>
										<td class="ltd_el">意向专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.wantcollegemajor }}</td>
									</tr>
									<tr>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">住址：</td>
										<td class="ltd_tag">{{ vstudentpotential.homeaddr }}</td>
										<td class="ltd_el">正式学生：</td>
										<td class="ltd_tag">{{ available[vstudentpotential.studentflag] }}</td>
										<td class="ltd_el"> 最后操作时间：</td>
										<td class="ltd_tag">
											{{
										    vstudentpotential.lastinserttime
										      | formatDate("YYYY-MM-DD HH:mm:ss")
										  }}
										</td>
									</tr>
									<tr>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">毕业时间：</td>
										<td class="ltd_tag">
											{{
									      vstudentpotential.graduatetime
									        | formatDate("YYYY-MM-DD")
									    }}
										</td>
										<td class="ltd_el">所属分公司：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyorgname }}</td>
										<td class="ltd_el">所属教学点：</td>
										<td class="ltd_tag">{{ vstudentpotential.schoolorgname }}</td>
									</tr>
									<tr>
										<td colspan="9" class="top_el">报读意向</td>
									</tr>
									<tr>
										<td class="ltd_el">邮编：</td>
										<td class="ltd_tag">{{ vstudentpotential.homezipcode }}</td>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">生源来源：</td>
										<td class="ltd_tag">{{ vstudentpotential.fromtypeval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进人：</td>
										<td class="ltd_tag">{{ vstudentpotential.followusername }}</td>
										<td class="ltd_el"> 转介绍人：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.linkman }}
										</td>
										<td class="ltd_el">合作企业人：</td>
										<td class="ltd_tag">{{ vstudentpotential.enterprisename }}</td>
										<td class="ltd_el">客服：</td>
										<td class="ltd_tag">{{ vstudentpotential.kefuusername }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进次数：</td>
										<td class="ltd_tag">{{ vstudentpotential.followcount }}</td>
										<td class="ltd_el"></td>
										<td class="ltd_tag"></td>
									</tr>
									<tr>
										<td colspan="9" class="top_el">个人简历</td>
									</tr>
									<tr>
										<td class="ltd_el">学历：</td>
										<td class="ltd_tag">{{ vstudentpotential.eduval }}</td>
										<td class="ltd_el">职业：</td>
										<td class="ltd_tag">{{ vstudentpotential.job }}</td>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">专科毕业专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankemajor }}</td>
									</tr>
									<tr>
										<td class="ltd_el">专科毕业证书号：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankeno }}</td>
										<td class="ltd_el">毕业时间</td>
										<td class="ltd_tag">
											{{
											vstudentpotential.graduatetime | formatDate("YYYY-MM-DD")
											}}
										</td>
										<td class="ltd_el">工作单位：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyname }}</td>
										<td class="ltd_el">工作类型：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.jobtype }}
										</td>
									</tr>
									<tr>
										<td class="ltd_el"> 参加工作时间：</td>
										<td class="ltd-tag">
											{{
												vstudentpotential.jobtime | formatDate("YYYY-MM-DD")
											}}
										</td>
										<td class="ltd_el"> 住址：</td>
										<td class="ltd-tag" colspan="3">
											{{ vstudentpotential.homeaddr }}
										</td>
										<td class="ltd_el">邮编：</td>
										<td class="ltd-tag">
											{{ vstudentpotential.homezipcode }}
										</td>
									</tr>
								</tbody>
							</table>
						</el-form>
					</div>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 1">
					<!-- 学历报名记录 -->
					<el-table 
						:data="showDetailList.studentlist" 
						border 
						:key="key"	
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="qianzainame" align="center" />
						<el-table-column label="考生号" prop="studentcode" align="center" />
						</el-table-column>
						<el-table-column label="年级" prop="grade" align="center"></el-table-column>
						<el-table-column label="院校" prop="collegename1" align="center"></el-table-column>
						<el-table-column label="专业" prop="majorname1" align="center"></el-table-column>
						<el-table-column label="层次" prop="levelval" align="center"></el-table-column>
						<el-table-column label="应收" prop="shouldrecvmoney" align="center">
						</el-table-column>
						<el-table-column label="实收" prop="realrecvmoney" align="center">
						</el-table-column>
						<el-table-column label="退费" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="项目类型" prop="projecttype" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 2">
					<!-- '收费记录' -->
					<el-table 
						:data="showDetailList.studentmoneyrecvlist"
						border 
						stripe
						:key="key"	
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center" />
						<el-table-column label="姓名" prop="studentname" align="center" />
						<el-table-column label="身份证" prop="idcard" align="center" />
						<el-table-column label="金额" prop="recvmoney" align="center" />
						<el-table-column label="缴费时间" align="center" width="160">
							<template slot-scope="scope">
								{{ scope.row.recvtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
							</template>
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center" />
						<el-table-column label="经手人" prop="insertusername" align="center" />
						<el-table-column label="冲账" align="center">
							<template slot-scope="scope">
								{{ chongzhanflag[scope.row.invertflag] }}
							</template>
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center" />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 3">
					<!-- '培训记录' -->
					<el-table 
						:data="showDetailList.techfudaolist" 
						border 
						:key="key"	
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="studentname" align="center">
						</el-table-column>
						<el-table-column label="班别" prop="fudaoclasstypename" align="center"></el-table-column>

						<el-table-column label="报读时间" align="center" width="160">
							<template slot-scope="scope">
								{{ scope.row.regdate | formatDate("YYYY-MM-DD") }}
							</template>
						</el-table-column>

						<el-table-column label="应收" prop="totalmoney" align="center">
						</el-table-column>
						<el-table-column label="减免金额" prop="extrasubmoney" align="center">
						</el-table-column>

						<el-table-column label="实收" prop="recvmoney" align="center">
						</el-table-column>
						<el-table-column label="退费金额" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 4">
					<!-- ,'学籍记录 ' -->
					<el-table 
						:data="showDetailList.studentregisterlist" 
						border 
						:key="key"	
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="studentname" align="center">
						</el-table-column>
						<el-table-column label="年级" prop="grade" align="center">
						</el-table-column>
						<el-table-column label="院校" prop="collegename" align="center">
						</el-table-column>
						<el-table-column label="专业" prop="majorname" align="center">
						</el-table-column>
						<el-table-column label="层次" prop="levelval" align="center">
						</el-table-column>
						<el-table-column label="学年" prop="iyear" align="center">
						</el-table-column>
						<el-table-column label="项目类型" prop="projecttype" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 5">
					<!-- ,'跟进记录' -->
					<el-table 
						:data="showDetailList.studentgenzonglist" 
						border 
						stripe
						:key="key"	
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center" />
						<el-table-column label="学生" prop="qianzainame" align="center" />
						<el-table-column label="跟进内容" prop="followcontent" align="center" />
						<el-table-column label="跟进时间" align="center" width="160">
							<template slot-scope="scope">
								{{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
							</template>
						</el-table-column>
						<el-table-column label="跟进人" prop="username" align="center" />
						<el-table-column label="教学点" prop="schoolorgname" align="center" />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 6">
					<el-table :data="showDetailList.studentfudaolist" border stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="studentname" align="center">
						</el-table-column>
						<el-table-column label="年级" prop="grade" align="center"></el-table-column>

						<el-table-column label="辅导班" prop="fudaoclasstypename" align="center">
						</el-table-column>

						<el-table-column label="应收" prop="totalmoney" align="center">
						</el-table-column>

						<el-table-column label="实收" prop="recvmoney" align="center">
						</el-table-column>

						<el-table-column label="优惠" prop="preferentialmoney" align="center">
						</el-table-column>
						<el-table-column label="额外减免" prop="extrasubmoney" align="center">
						</el-table-column>

						<el-table-column label="退费" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 7">
					<el-table :data="showDetailList.studentyubaominglist" border stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="qianzainame" align="center">
						</el-table-column>
						<el-table-column label="年级" prop="year" align="center"></el-table-column>
						<el-table-column label="应收" prop="totalmoney" align="center">
						</el-table-column>
						<el-table-column label="实收" prop="recvmoney" align="center">
						</el-table-column>
						<el-table-column label="退费" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 8">
					<div class="demo-image__preview demo-image__error">
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证正面</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src="vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : ''"
									@click="vbs(vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证反面</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src="vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : ''"
									@click="vbs(vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">照片</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.photo ? $url.upload + vstudentpotential.photo : ''"
									@click="vbs(vstudentpotential.photo ? $url.upload + vstudentpotential.photo : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本首页</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : ''"
									@click="vbs(vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本人页</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : ''"
									@click="vbs(vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">毕业证</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src="vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : ''"
									@click="vbs(vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">注册备案表</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : ''"
									@click="vbs(vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">居住证明</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.residence ? $url.upload + vstudentpotential.residence : ''"
									@click="vbs(vstudentpotential.residence ? $url.upload + vstudentpotential.residence : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import { _approveflag, _typeStudent, _techtypeflag, _available, } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  studentgenzong_list2,
	studentpotential_show,
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "stufudaofeestandard",
  components: {},
  props: {
    
  },
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
			showTable: {},
			available: _available,
      showAddModal: false, // 添加
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        
      }, //表单规则
      dialogTitle: "", //对话框标题
			currentTab: 0,
			currentClass: 0,
      selectTable: {},
      selectTableUrl: "",
      selectSearchForm: {}, // 选择搜索
			showDetailModal: false,
			vstudentpotential: {},
			srcList: [],
			showDetailList: {},
			activeName: "vstudentpotential",
			tabId: null,
			tabList: ['档案', '学历报名', '收费', '培训', '学籍 ', '跟进', '成考辅导班', '代预报名', '证件信息'],
			key: '',
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick","isExpand"]),
    ...mapActions("selectData", [
      "org_combox",
      "org_comboxchild",
      "dic_combox",
    ]),
		reset() {
			this.searchForm = {
				id: null,
				qianzainame: null,
				inserttime: null,
				username: null,
				followcontent: null,
				schoolorgname: null,
				t1: null,
				t2: null
			}
		},
		//重置
		resetQuery() {
			this.pageinfo.pageindex = 1;
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				name: null,
				fudaotypeval: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null)
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentgenzong_list2,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
		vbs(val) {
			this.srcList = []
			this.srcList.push(val)
		},
		//隐藏和显示
		handleShow() {
			this.showDetailModal = false;
		},
		//  查看详情 tab切换 change
		handleClick() {
			this.btnShowDetail(this.tabId);
		},
		toggleTab(current) {
			this.currentTab = current;
			this.currentClass = current;
		},
		// 查看详情 clik
		btnShowDetail(qianzaiid) {
				this.tabId = qianzaiid;
				myRequest({
					url: studentpotential_show,
					data: {
						id: qianzaiid,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.showDetailList = res.data.data;
						this.vstudentpotential = this.showDetailList.vstudentpotential;
						this.showDetailModal = true;
					}
				});
			},
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选项表格 api（专业）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style  lang="scss">
	@import "../../../assets/css/pulid.scss";
	.form-item-w-18 {
		.flex-free{
			.form-item-w-13{
				width: 13%;
			}
				
		}
	}
	
	@media only screen and (max-width: 1536px) {
	  .form-item-w-18 {
	  	.flex-free{
	  		.form-item-w-13{
	  			width: 16%;
	  		}
	  			
	  	}
	  }
		
		.form-item-w-14 .el-form-item {
		    width: 16%;
		}
	}
	 
</style>