<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="员工姓名:">
          <el-input 
						v-model.trim="searchForm.username" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="员工姓名" prop="username" align="center" />
      <el-table-column label="性别" prop="sex" align="center" />
      <el-table-column label="岗位" prop="positionname" align="center" />
      <el-table-column label="基本工资" prop="basic" align="center" />
      <el-table-column
        label="学历或资格证补贴"
        prop="subsidy1"
        align="center"
      />
      <el-table-column label="伙食补贴" prop="subsidy2" align="center" />
      <el-table-column label="电话补贴" prop="subsidy3" align="center" />
      <el-table-column label="其他补贴" prop="subsidy4" align="center" />
      <el-table-column label="合计" prop="total" align="center" />
      <el-table-column label="社保基数" prop="base" align="center" />
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			width="800px"
			:title="this.dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="150px"
        :rules="rules"
        class="form-item-w-50"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工:" prop="username">
          <el-input v-model="dynamicValidateForm.username" disabled/>
          <el-button
            class="select-btn"
            type="primary"
            @click="
              onSelectData(user_dialogresult4, 'showUser4', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="基本工资:" prop="basic">
          <el-input v-model.trim="dynamicValidateForm.basic" clearable/>
        </el-form-item>
        <el-form-item label="学历或资格证补贴:" prop="subsidy1">
          <el-input v-model.trim="dynamicValidateForm.subsidy1" clearable/>
        </el-form-item>
        <el-form-item label="伙食补贴:" prop="subsidy2">
          <el-input v-model.trim="dynamicValidateForm.subsidy2" clearable/>
        </el-form-item>
        <el-form-item label="电话补贴:" prop="subsidy3">
          <el-input v-model.trim="dynamicValidateForm.subsidy3" clearable/>
        </el-form-item>
        <el-form-item label="其他补贴:" prop="subsidy4">
          <el-input v-model.trim="dynamicValidateForm.subsidy4" clearable/>
        </el-form-item>
        <el-form-item label="合计:" prop="total">
          <el-input v-model.trim="dynamicValidateForm.total" clearable/>
        </el-form-item>
        <el-form-item label="社保基数:" prop="base">
          <el-input v-model.trim="dynamicValidateForm.base" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 选择用户 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择用户："
      :visible.sync="showUser4"
			v-if="showUser4"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser4">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({
                schoolorgid: dynamicValidateForm.schoolorgid,
              }, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({
                schoolorgid: dynamicValidateForm.schoolorgid,
              }, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>


      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				:key="key"	
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="真实姓名" prop="realname" align="center" />
        <el-table-column label="性别" prop="sexval" align="center" />
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({
                schoolorgid: dynamicValidateForm.schoolorgid,
              }, selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!--薪资调整-->
    <el-dialog
      class="btn-2b5a95"
      width="500px"
			v-dialogDrag
      title="薪资调整："
      :visible.sync="showAdjust"
			:close-on-click-modal='false'
			v-if="showAdjust"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        :rules="rules"
        class="form-item-w-100"
      >
        <el-form-item label="员工姓名:" prop="username">
          <el-input v-model.trim="dynamicValidateForm.username" disabled />
        </el-form-item>
        <el-form-item label="原薪资:" prop="salary1">
          <el-input v-model.trim="dynamicValidateForm.salary1" disabled/>
        </el-form-item>
        <el-form-item label="调后薪资:" prop="salary2">
          <el-input v-model.trim="dynamicValidateForm.salary2" clearable/>
        </el-form-item>
        <el-form-item label="调整日期:" prop="dates">
          <el-date-picker
            v-model="dynamicValidateForm.dates"
            type="date"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAdjust('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 调整记录-列表 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      title="调整记录："
      :visible.sync="showRecord"
			:close-on-click-modal='false'
			v-if="showRecord"
      append-to-body
    >
		<section style="margin-bottom: 20px;">
			<el-button type="primary" @click="onRecordAdd">增加</el-button>
			<el-button type="primary" @click="onRecordEdit">修改</el-button>
			<el-button type="primary" @click="onRecordDel">删除</el-button>
		</section>
      <el-table
        :data="recordData.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				:key="key"	
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="员工姓名" prop="username" align="center" />
        <el-table-column label="原薪资" prop="salary1" align="center" />
        <el-table-column label="调后薪资" prop="salary2" align="center" />
        <el-table-column label="调整日期" prop="dates" align="center" >
            <template slot-scope="scope">
              {{scope.row.dates | formatDate('YYYY-MM-DD HH:mm:ss')}}
            </template>
         </el-table-column >
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, prop, method) => handlePublichange(val, 'recordPage', 'record')
        "
        :current-page="recordPage.pageindex"
        :page-size="recordPage.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="recordData.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 调整记录-增加,修改 -->
    <el-dialog
      class="btn-2b5a95"
      width="500px"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showRecordSave"
			:close-on-click-modal='false'
			v-if="showRecordSave"
      append-to-body
    >
       <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        :rules="rules"
        class="form-item-w-100"
      >
        <el-form-item label="员工姓名:" prop="username">
          <el-input v-model.trim="dynamicValidateForm.username" disabled />
        </el-form-item>
        <el-form-item label="原薪资:" prop="salary1">
          <el-input v-model.trim="dynamicValidateForm.salary1" disabled/>
        </el-form-item>
        <el-form-item label="调后薪资:" prop="salary2">
          <el-input v-model.trim="dynamicValidateForm.salary2" clearable/>
        </el-form-item>
        <el-form-item label="调整日期:" prop="dates">
          <el-date-picker
            v-model="dynamicValidateForm.dates"
            type="date"
            value-format="yyyy-MM-dd 00:00:00"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitRecordSave('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 导入 -->
    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
      downloadUrl="download/财务管理/薪资导入模板.xls"
    ></Import>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import Import from "@/components/Import/index.vue";

import {
  salary_list,
  salary_input,
  salary_save,
  salary_delete,
  salary_importsave,
  salary_export2,
  adjustment_save,
  adjustment_list,
  adjustment_delete,
} from "@/request/api/allChildrenProject";
import { _user_dialogresult4 } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "special",
  components: { Import },
  props: {
    importSaveUrl: {
      default: () => salary_importsave,
    },
    user_dialogresult4: {
      default: () => _user_dialogresult4,
    },

    _adjustment_list: {
      default: () => adjustment_list,
    },
  },
  data() {
    return {
      // pulic
      itemRow: null, //首表格项
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      dialogTitle:'',
      multipleSelection: [], //选中的表格项
      searchForm: {},

      dynamicValidateForm: {}, // 对象
      showAddModal: false, //显示表单框
      importDialog: false, //导入框

      // 规则校验
      rules: {
        companyorgid: [
          { required: true, message: "必填字段", trigger: "change" },
        ],
        schoolorgid: [
          { required: true, message: "必填字段", trigger: "change" },
        ],
        username: [{ required: true, message: "必填字段", trigger: "change" }],
        basic: [{ required: true, message: "必填字段", trigger: "change" }],
        subsidy1: [{ required: true, message: "必填字段", trigger: "change" }],
        subsidy2: [{ required: true, message: "必填字段", trigger: "change" }],
        subsidy3: [{ required: true, message: "必填字段", trigger: "change" }],
        subsidy4: [{ required: true, message: "必填字段", trigger: "change" }],
        total: [{ required: true, message: "必填字段", trigger: "change" }],
        base: [{ required: true, message: "必填字段", trigger: "change" }],
        salary1: [{ required: true, message: "必填字段", trigger: "change" }],
        salary2: [{ required: true, message: "必填字段", trigger: "change" }],
        dates: [{ required: true, message: "必填字段", trigger: "change" }],

      },

      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
			key: '',
      selectTable: {},
      selectTableUrl: "",
      selectSearchForm: {},
      showUser4: false, //选择用户
      showAdjust: false, //薪资调整
      // 调整记录
      showRecord: false, //列表
      showRecordSave: false, //增加,修改
      recordData: {}, //数据
      recordPage: {
        pageindex: 1,
        pagesize: 10,
      },
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]), //关闭导入框
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    onCloseImport(bool) {
      this.importDialog = bool;
    },
		reset() {
			this.searchForm = {
				username: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				realname: null,
				
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null,  {
        schoolorgid: this.dynamicValidateForm.schoolorgid,
      });
		},
    //导入 click
    btnImport() {
      this.importDialog = true;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: salary_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },

    //切换公共分页
    handlePublichange(val, prop, method) {
      this[prop].pageindex = val;
      this[method]();
    },
    //切换选项表格页
    handleSelectChange(val) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, {
        schoolorgid: this.dynamicValidateForm.schoolorgid,
      });
			this.onCloseSelect();
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = '增加:'
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = '修改:'
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //导出 click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            salary_export2 +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
    //薪资调整 click
    btnAdjust() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.dynamicValidateForm = {
          username: this.multipleSelection[0].username,
          userid: this.multipleSelection[0].userid,
          salary1:this.multipleSelection[0].total
        };
        this.showAdjust = true;
      });
    },
    //调整记录 click
    btnRecord() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.itemRow = this.multipleSelection[0]
        this.record();
      });
    },
    //调整记录-列表 api
    record() {
      myRequest({
        url: adjustment_list,
        data: Object.assign({userid:this.itemRow.userid},this.recordPage),
      }).then((res) => {
        if (res.data.code === "200") {
          this.showRecord = true;
          this.recordData = res.data.data;
          this.multipleSelection = []
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
        }
      });
    },
    //调整记录-增加 click
    onRecordAdd() {
      this.dialogTitle = '增加:'
      this.dynamicValidateForm = {
           username: this.itemRow.username,
          userid: this.itemRow.userid,
          salary1:this.itemRow.total,
      }
      this.showRecordSave = true
    },
    //调整记录-修改 click
    onRecordEdit() {
      this.dialogTitle = '修改:'
      selectCheck(this.multipleSelection, "修改", false, () => {
        console.log(this.multipleSelection[0])
        this.dynamicValidateForm = {
          username: this.multipleSelection[0].username,
          userid: this.multipleSelection[0].userid,
          salary1:this.multipleSelection[0].salary1,
          id:this.multipleSelection[0].id
        };
        this.showRecordSave = true
      });
    },
    //调整记录-删除 click
    onRecordDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delRecord(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //调整记录-删除 api
    delRecord(idArr) {
      myRequest(
        {
          method: "post",
          url: adjustment_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
      ).then(res=>{
        if(res.data.code==='200'){
          this.record()
        }
      })
    },


    //调整记录-保存 click api
    submitRecordSave(formName){
       this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: adjustment_save,
              data: this.dynamicValidateForm,
            },
          ).then(res=>{
            if(res.data.code==='200'){
              this.record()
              this.showRecordSave = false
            }
          })
        }
      });
    },


    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: salary_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vsalary;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
          this.showAddModal = true;
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: salary_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: salary_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //提交薪资调整 api click
    submitAdjust(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: adjustment_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAdjust",
            }
          );
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //确认用户 click
    onConfirmUser4() {
      selectCheck(this.multipleSelection, "确认用户", false, () => {
        this.dynamicValidateForm.userid = this.multipleSelection[0].id;
        this.$set(
          this.dynamicValidateForm,
          "username",
          this.multipleSelection[0].realname
        );
        this.showUser4 = false;
        this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //选项表格 api（用户）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
  },
};
</script>

<style  lang="scss">
</style>