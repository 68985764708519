<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				@submit.native.prevent
      >
        <el-form-item label="统计月份:">
          <el-input 
						v-model.trim="searchForm.countmonth" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="账户名称:">
          <el-input 
						v-model.trim="searchForm.accountname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname"
						 @keyup.enter.native="seach"
						clearable />
        </el-form-item>
				<el-form-item label="卡号:">
				  <el-input 
						v-model.trim="searchForm.cardno"
						 @keyup.enter.native="seach"
						clearable />
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      stripe
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="统计月份" prop="countmonth" align="center" />
			<el-table-column label="账户名称" prop="accountname" align="center">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.accountname
          }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="卡号" prop="cardno" align="center" />
      <el-table-column label="金额" prop="money" align="center" />
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
      />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
			v-dialogDrag
      class="btn-2b5a95"
			:close-on-click-modal='false'
      width="60%"
			title="详情"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
    >
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
        <tr>
          <td>金额：{{ dynamicValidateForm.money }}</td>
          <td>统计月份：{{ dynamicValidateForm.countmonth }}</td>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
        </tr>
        <tr>
          <td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
          <td>账户名称：{{ dynamicValidateForm.accountname }}</td>
          <td>卡号：{{ dynamicValidateForm.cardno }}</td>
        </tr>
        <tr>
          <td>账户所属教学点名称：{{ dynamicValidateForm.accschoolorgname }}</td>
          <td>账户所属分公司名称：{{ dynamicValidateForm.acccompanyorgname }}</td>
					<td>流水号: {{dynamicValidateForm.id}}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import { prebalances_list } from "@/request/api/allChildrenProject";
import { _moneyintype_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "prebalances",
  components: {},
  props: {},
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      showDetailModal: false,

    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		reset() {
			this.searchForm = {
				countmonth: null,
				accountname: null,
				schoolorgname: null,
				cardno: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: prebalances_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>