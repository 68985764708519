<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
					<el-form-item label="流水号:">
					  <el-input 
							v-model.trim="searchForm.id"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="名称:">
            <el-input 
							v-model.trim="searchForm.name" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="类型:">
            <el-select 
							v-model="searchForm.type" 
							@keyup.enter.native="seach"
							@visible-change="typeVisible"
							ref="selectRef"
							placeholder="请选择"
							clearable>
              <el-option value="公章"></el-option>
              <el-option value="发票专用章"></el-option>
              <el-option value="财务章"></el-option>
              <el-option value="法人章"></el-option>
              <el-option value="收款章"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
			<el-table-column width="100" align="center" label="流水号" prop="id"/>
      <el-table-column label="名称" prop="name" align="center" />
      <el-table-column label="类型" prop="type" align="center" />
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          {{ useStatus[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column label="保管人" prop="custodianusername" align="center" />
      <el-table-column label="申请人" prop="insertusername" align="center" />
      <el-table-column label="申请时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>

      <el-table-column label="归还时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.returntime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
      />
      <el-table-column
        label="所属分公司"
        prop="companyorgname"
        align="center"
      />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="名称:" prop="sealName">
          <el-input
            v-model="dynamicValidateForm.sealName"
            placeholder="请选择"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(seal_list, 'showSealNameModal')"
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择印章名称 -->
    <el-dialog
      class="btn-2b5a95"
      width="76%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择印章："
			:close-on-click-modal='false'
      :visible.sync="showSealNameModal"
      v-if="showSealNameModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmSealName">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="保管人:" prop="custodianusername">
              <el-input
                v-model.trim="selectSearchForm.custodianusername"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
                clearable
              />
            </el-form-item>
            <el-form-item label="借用人:" prop="borrowerusername">
              <el-input 
								v-model.trim="selectSearchForm.borrowerusername" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
            <el-form-item label="所属教学点:" prop="schoolorgname">
              <el-input 
								v-model.trim="selectSearchForm.schoolorgname"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				border
				:key="key"	
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="名称" prop="name" align="center" />
        <el-table-column label="类型" prop="type" align="center" />
        <el-table-column label="状态" prop="status" align="center" />
        <el-table-column
          label="保管人"
          prop="custodianusername"
          align="center"
        />
        <el-table-column
          label="借用人"
          prop="borrowerusername"
          align="center"
        />
        <el-table-column
          label="所属教学点名称"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 保管人审批 -->
    <el-dialog
      class="btn-2b5a95"
      title="保管人审批："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showApprovalModal"
      v-if="showApprovalModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="审核是否通过：" prop="passflag">
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 2, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批意见：" prop="approvedesc">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval('dynamicValidateForm')">提交 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  sealrecord_list,
  sealrecord_save,
  sealrecord_requestapprove,
  sealrecord_approvesave,
  sealrecord_returns,
  sealrecord_delete,
  seal_list,
} from "@/request/api/allChildrenProject";
import { _vuser_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "sealrecord",
  components: {},
  props: {
    seal_list: {
      default: () => seal_list,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
      searchForm: { id: null },
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
				sealName: [{ trigger: "blur", message: "必填字段", required: true }],
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
				approvedesc: [{ trigger: "blur", message: "必填字段", required: true }],
				passflag: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
			key: '',
      selectTable: {},
      selectTableUrl: "",
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectSearchForm: {},

      selectSchoolorgid: null,
      showSealNameModal: false,
      showApprovalModal: false, //审批框

      useStatus: {
        "-2": "未提请审批",
        "-1": "审核中",
        0: "审核不通过",
        1: "同意并借出",
        2: "已归还",
      },

      useStatuss: [
        {
          label: "未提请审批",
          value: "-2",
        },
        {
          label: "审核中",
          value: "-1",
        },
        {
          label: "审核不通过",
          value: "0",
        },
        {
          label: "同意并借出",
          value: "1",
        },
        {
          label: "已归还",
          value: "2",
        },
      ],
    };
  },
  created() {
    this.searchForm.id = this.$route.params.id;
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		typeVisible(e) {
			if(!e) {
				this.$refs.selectRef.blur();
			}
		},
		reset() {
			this.searchForm = {
				id: null,
				name: null,
				type: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		querySelect() {
			this.selectSearchForm = {
				name: null,
				custodianusername: null,
				borrowerusername: null,
				schoolorgname: null
			}
		},		
		handleQuery() {
			this.querySelect();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: sealrecord_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "是否提请审批？",
          success: () => {
            this.requestapprove(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //归还 click
    btnReturn() {
      selectCheck(this.multipleSelection, "归还", true, () => {
        confirmCallBack({
          title: "提示",
          content: "是否归还？",
          success: () => {
            this.returns(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //保管人审批 click
    btnApprovalSave() {
      selectCheck(this.multipleSelection, "保管人审批", true, () => {
        this.dynamicValidateForm = {};
        this.showApprovalModal = true;
      });
    },
    //确认印章 click
    onConfirmSealName() {
      selectCheck(this.multipleSelection, "确认印章", false, () => {
        this.dynamicValidateForm.sealid = this.multipleSelection[0].id;
        this.$set(
          this.dynamicValidateForm,
          "sealName",
          this.multipleSelection[0].name
        );
        this.showSealNameModal = false;
				this.multipleSelection = [];
				this.querySelect();
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: sealrecord_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: sealrecord_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //提请审批 api
    requestapprove(ids) {
      myRequest(
        {
          method: "post",
          url: sealrecord_requestapprove,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          that: this,
          isRefTableList: true,
        }
      );
    },
    //保管人审批 api
		headApproval(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		        myRequest(
		          {
		            method: "post",
		            url: sealrecord_approvesave,
		            data: this.$qs.stringify(
		              {
		                ids: this.multipleSelection.map((i) => i.id),
		                passflag: this.dynamicValidateForm.passflag,
		                approvedesc: this.dynamicValidateForm.approvedesc,
		              },
		              {
		                arrayFormat: "repeat",
		              }
		            ),
		          },
		          {
		            isRefTableList: true,
		            that: this,
		      
		            modal: "showApprovalModal",
		          }
		        );
		    }
		  });
		},
    
    //归还 api
    returns(idArr) {
      myRequest(
        {
          method: "post",
          url: sealrecord_returns,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          that: this,
          isRefTableList: true,
        }
      );
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },

    //选项表格 api(德立学生)
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>