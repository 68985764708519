<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
      :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }"
    >
      <el-form
        label-position="right"
        ref="searchForm"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
        :rules="searchRules"
        :model="searchForm"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="searchForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'searchForm')"
						@visible-change="visibleRef"
						ref="selectref"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="searchForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'searchForm')"
						@keyup.enter.native="getDataList"
						@visible-change="visibleName"
						ref="nameref"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报读月份:" prop="regdate">
          <el-date-picker
            v-model="searchForm.regdate"
            type="month"
            placeholder="请选择"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="招生员:" prop="followusername">
          <el-input 
						v-model.trim="searchForm.followusername" 
						@keyup.enter.native="getDataList"
						clearable />
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="getDataList"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格（财会培训） -->
    <el-table
      :data="listkuaiji_table.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column
        label="招生员"
        prop="followusername"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生人数"
        prop="num"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="实收总金额"
        prop="recvmoney"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="生源来源类别"
        prop="studentfromtypeval"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="奖励百分比"
        prop="percentage"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="总提成"
        prop="reward"
        align="center"
        width="100"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleListkuaijiChange"
      :current-page="listkuaiji_page.pageindex"
      :page-size="listkuaiji_page.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="listkuaiji_table.totalcount"
    ></el-pagination>

    <!-- 表格（教师资格） -->
    <el-table
      :data="listjiaoshi_table.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="招生员"
        prop="followusername"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生人数"
        prop="num"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="实收总金额"
        prop="recvmoney"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="生源来源类别"
        prop="studentfromtypeval"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="奖励百分比"
        prop="percentage"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="总提成"
        prop="reward"
        align="center"
        width="100"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleListjiaoshiChange"
      :current-page="listjiaoshi_page.pageindex"
      :page-size="listjiaoshi_page.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="listjiaoshi_table.totalcount"
    ></el-pagination>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  educationreward_listkuaiji,
  educationreward_listjiaoshi,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _org_combox, _allSchool } from "@/request/api/public";

export default {
  mixins: [part],
  name: "accountantteacher",
  components: {},
  props: {},
  data() {
    return {
      itemId: null, //首表格项id
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      searchForm: {
        regdate: "",
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
      },
      searchRules: {
        regdate: [{ trigger: "blur", message: "必填字段", required: true }],
        searchUrl: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
      },
      companyList: [],
      schoolList: [],

      listkuaiji_table: {},
      listjiaoshi_table: {},
      listkuaiji_page: {
        pageindex: 1,
        pagesize: 10,
      },
      listjiaoshi_page: {
        pageindex: 1,
        pagesize: 10,
      },
    };
  },
  created() {
    this.org_combox();
    this.org_comboxchild(this.searchForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    ...mapActions("global", ["onExtendClick"]),

    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleRef(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		visibleName(e) {
			if(!e) {
				this.$refs.nameref.blur();
			}
		},
		reset() {
			this.searchForm = {
				companyorgname: null,
				schoolorgname: null,
				regdate: null,
				followusername: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //列表查询 api
    getDataList() {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          this.listkuaiji();
          this.listjiaoshi();
        }
      });
    },

    //财会培训 api
    listkuaiji() {
      myRequest({
        url: educationreward_listkuaiji,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.listkuaiji_table = res.data.data;
        }
      });
    },

    //教师资格 api
    listjiaoshi() {
      myRequest({
        url: educationreward_listjiaoshi,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.listjiaoshi_table = res.data.data;
        }
      });
    },

    handleListkuaijiChange(val) {
      this.listkuaiji_table.pageindex = val;
      this.listkuaiji();
    },
    handleListjiaoshiChange(val) {
      this.listjiaoshi_page.pageindex = val;
      this.listjiaoshi();
    },

    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>