<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-form-item label="流水号:">
          <el-input v-model="searchForm.id" clearable />
        </el-form-item>
        <el-form-item label="类型:">
          <el-input v-model="searchForm.type" clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
      v-show="tableRes.list && tableRes.list.length"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="流水号"
        prop="id"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="目标奖励档次始"
        prop="begin"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="目标奖励档次止"
        prop="end"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="类型"
        prop="type"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="招生人数始"
        prop="beginnum"
        align="center"
        width="150"
      ></el-table-column>
      <el-table-column
        label="招生人数止"
        prop="endnum"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="总部奖励"
        prop="headquarters"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="教学点奖励"
        prop="school"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="合计"
        prop="total"
        align="center"
        width="100"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="begin" label="目标奖励档次始：">
          <el-input v-model.trim="dynamicValidateForm.begin"></el-input>
        </el-form-item>
        <el-form-item prop="end" label="目标奖励档次止：">
          <el-input v-model.trim="dynamicValidateForm.end"></el-input>
        </el-form-item>
        <el-form-item prop="type" label="类型：">
          <el-input v-model.trim="dynamicValidateForm.type"></el-input>
        </el-form-item>
        <el-form-item prop="beginnum" label="招生人数始：">
          <el-input v-model.trim="dynamicValidateForm.beginnum"></el-input>
        </el-form-item>
        <el-form-item prop="endnum" label="招生人数止：">
          <el-input v-model.trim="dynamicValidateForm.endnum"></el-input>
        </el-form-item>
        <el-form-item prop="headquarters" label="总部奖励：">
          <el-input v-model.trim="dynamicValidateForm.headquarters"></el-input>
        </el-form-item>
        <el-form-item prop="school" label="教学点奖励：">
          <el-input v-model.trim="dynamicValidateForm.school"></el-input>
        </el-form-item>
        <el-form-item prop="total" label="合计：">
          <el-input v-model.trim="dynamicValidateForm.total"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAddOrEditForm">提交 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  personal_list,
  personal_input,
  personal_save,
  personal_delete,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "personal",
  components: {},
  props: {},
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      searchForm: {},
      rules: {},
      dynamicValidateForm: {},
      dialogTitle: "", //对话框标题
    };
  },
  created() {
    this.getDataList();

    myRequest({
      url: "/performance/chengkao.do",
    }).then((res) => {});
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    //搜索 click
    seach(formName) {
      myRequest({
        url: personal_list,
        data: this.searchForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: personal_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: personal_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vpersonal;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm() {
      myRequest(
        {
          url: personal_save,
          data: this.dynamicValidateForm,
        },
        {
          isRefTableList: true,
          that: this,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = false;
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: personal_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>