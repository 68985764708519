<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				@submit.native.prevent
				:model="searchForm"
      >
        <el-form-item label="用户名:">
          <el-input 
						v-model.trim="searchForm.username" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="招生年份:">
          <el-date-picker
            v-model="searchForm.year"
            type="year"
            placeholder="选择年"
            value-format="yyyy"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="招生员" align="center">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.username
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="年份" prop="year" align="center" />
      <el-table-column label="月份" align="center">
        <el-table-column label="1" prop="january" align="center" width="60" />
        <el-table-column label="2" prop="february" align="center" width="60" />
        <el-table-column label="3" prop="march" align="center" width="60" />
        <el-table-column label="4" prop="april" align="center" width="60" />
        <el-table-column label="5" prop="may" align="center" width="60" />
        <el-table-column label="6" prop="june" align="center" width="60" />
        <el-table-column label="7" prop="july" align="center" width="60" />
        <el-table-column label="8" prop="august" align="center" width="60" />
        <el-table-column label="9" prop="september" align="center" width="60" />
        <el-table-column label="10" prop="october" align="center" width="60" />
        <el-table-column label="11" prop="november" align="center" width="60" />
        <el-table-column label="12" prop="december" align="center" width="60" />
      </el-table-column>
      <el-table-column label="合计" prop="total" align="center" />
      <el-table-column label="保底目标" prop="bdtarget" align="center" />
      <el-table-column label="计划目标" prop="jhtarget" align="center" />
      <el-table-column label="冲刺目标" prop="cctarget" align="center" />
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="招生年份:">
          {{ dynamicValidateForm.year }}
        </el-form-item>
        <el-form-item label="1月:">
          {{ dynamicValidateForm.january }}个
        </el-form-item>
        <el-form-item label="2月:">
          {{ dynamicValidateForm.february }}个
        </el-form-item>
        <el-form-item label="3月:">
          {{ dynamicValidateForm.march }}个
        </el-form-item>
        <el-form-item label="4月:">
          {{ dynamicValidateForm.april }}个
        </el-form-item>
        <el-form-item label="5月:">
          {{ dynamicValidateForm.may }}个
        </el-form-item>
        <el-form-item label="6月:">
          {{ dynamicValidateForm.june }}个
        </el-form-item>
        <el-form-item label="7月:">
          {{ dynamicValidateForm.july }}个
        </el-form-item>
        <el-form-item label="8月:">
          {{ dynamicValidateForm.august }}个
        </el-form-item>
        <el-form-item label="9月:">
          {{ dynamicValidateForm.september }}个
        </el-form-item>
        <el-form-item label="10月:">
          {{ dynamicValidateForm.october }}个
        </el-form-item>
        <el-form-item label="11月:">
          {{ dynamicValidateForm.november }}个
        </el-form-item>
        <el-form-item label="12月:">
          {{ dynamicValidateForm.december }}个
        </el-form-item>
        <el-form-item label="合计:">
          {{ dynamicValidateForm.total }}个
        </el-form-item>
        <el-form-item label="保底目标:">
          {{ dynamicValidateForm.bdtarget }}个
        </el-form-item>
        <el-form-item label="计划目标:">
          {{ dynamicValidateForm.jhtarget }}个
        </el-form-item>
        <el-form-item label="冲刺目标:">
          {{ dynamicValidateForm.cctarget }}个
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.username }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="45%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="username" label="用户:">
          <el-input v-model="dynamicValidateForm.username" disabled />
          <el-button
          class="select-btn"
            type="primary"
            @click="onSelectData(user_dialogresult, 'showUserModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="年份:" prop="year">
          <el-date-picker
            type="year"
            format="yyyy"
            v-model="dynamicValidateForm.year"
            value-format="yyyy"
          />
        </el-form-item>
        <el-form-item label="1月:" prop="january">
          <el-input v-model.trim="dynamicValidateForm.january" clearable/>
        </el-form-item>
        <el-form-item label="2月:" prop="february">
          <el-input v-model.trim="dynamicValidateForm.february" clearable/>
        </el-form-item>
        <el-form-item label="3月:" prop="march">
          <el-input v-model.trim="dynamicValidateForm.march" clearable/>
        </el-form-item>
        <el-form-item label="4月:" prop="april">
          <el-input v-model.trim="dynamicValidateForm.april" clearable/>
        </el-form-item>
        <el-form-item label="5月:" prop="may">
          <el-input v-model.trim="dynamicValidateForm.may" clearable/>
        </el-form-item>
        <el-form-item label="6月:" prop="june">
          <el-input v-model.trim="dynamicValidateForm.june" clearable/>
        </el-form-item>
        <el-form-item label="7月:" prop="july">
          <el-input v-model.trim="dynamicValidateForm.july" clearable/>
        </el-form-item>
        <el-form-item label="8月:" prop="august">
          <el-input v-model.trim="dynamicValidateForm.august" clearable></el-input>
        </el-form-item>
        <el-form-item label="9月:" prop="september">
          <el-input v-model.trim="dynamicValidateForm.september" clearable></el-input>
        </el-form-item>
        <el-form-item label="10月:" prop="october">
          <el-input v-model.trim="dynamicValidateForm.october" clearable></el-input>
        </el-form-item>
        <el-form-item label="11月:" prop="november">
          <el-input v-model.trim="dynamicValidateForm.november" clearable></el-input>
        </el-form-item>
        <el-form-item label="12月:" prop="december">
          <el-input v-model.trim="dynamicValidateForm.december" clearable></el-input>
        </el-form-item>
        <el-form-item label="保底目标:" prop="bdtarget">
          <el-input v-model.trim="dynamicValidateForm.bdtarget" clearable></el-input>
        </el-form-item>
        <el-form-item label="计划目标:" prop="jhtarget">
          <el-input v-model.trim="dynamicValidateForm.jhtarget" clearable></el-input>
        </el-form-item>
        <el-form-item label="冲刺目标:" prop="cctarget">
          <el-input v-model.trim="dynamicValidateForm.cctarget" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择跟进人 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择跟进人："
			:close-on-click-modal='false'
      :visible.sync="showUserModal"
v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>


<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>


      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column
          label="真实姓名"
          prop="realname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="员工编号"
          prop="code"
          align="center"
        ></el-table-column>
        <el-table-column
          label="登录账号"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属部门"
          prop="departorgname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="所属分公司"
          prop="companyorgname"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="(val,data,prop,page)=>handleSelectChange(val,'selectTable','selectPageInfo')"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>

    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      downloadUrl="download/业务管理/互动美语招生计划.xls"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
    ></Import>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import Import from "@/components/Import/index.vue";
import { mapActions } from "vuex";

import {
  englishplan_list,
  englishplan_input,
  englishplan_save,
  englishplan_delete,
  englishplan_importsave,
} from "@/request/api/allChildrenProject";
import { _user_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

import {_initenglishplan} from '@/assets/js/initTableData'

export default {
  mixins: [part],
  name: "englishplan",
  components: { Import },
  props: {
    importSaveUrl: {
      default: () => englishplan_importsave,
    },
    user_dialogresult: {
      default: () => _user_dialogresult,
    },
  },
  data() {
    return {
      importDialog: false, //导入框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: _initenglishplan, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
        username: [{ trigger: "blur", message: "必填字段", required: true }],
        year: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgname: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],

        january: [{ trigger: "blur", message: "必填字段", required: true }],
        february: [{ trigger: "blur", message: "必填字段", required: true }],
        march: [{ trigger: "blur", message: "必填字段", required: true }],
        april: [{ trigger: "blur", message: "必填字段", required: true }],
        may: [{ trigger: "blur", message: "必填字段", required: true }],
        june: [{ trigger: "blur", message: "必填字段", required: true }],
        july: [{ trigger: "blur", message: "必填字段", required: true }],
        august: [{ trigger: "blur", message: "必填字段", required: true }],
        september: [{ trigger: "blur", message: "必填字段", required: true }],
        october: [{ trigger: "blur", message: "必填字段", required: true }],
        november: [{ trigger: "blur", message: "必填字段", required: true }],
        december: [{ trigger: "blur", message: "必填字段", required: true }],
        bdtarget: [{ trigger: "blur", message: "必填字段", required: true }],
        jhtarget: [{ trigger: "blur", message: "必填字段", required: true }],
        cctarget: [{ trigger: "blur", message: "必填字段", required: true }],
        
      }, //表单规则
      dialogTitle: "", //对话框标题
			key: '',
      selectTable: {},
      selectTableUrl: "",
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectSearchForm: {},
      showUserModal: false,
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid)
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
    //导入 click
    btnImport() {
      this.importDialog = true;
    },
		reset() {
			this.searchForm = {
				username: null,
				schoolorgname: null,
				
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				realname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
		
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: englishplan_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换选项表格页
    handleSelectChange(val,data,prop,page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,
        data,
        this.itemRow,
        prop,
        page);
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: englishplan_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          console.log(res);
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.venglishplan;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = _initenglishplan
      this.dynamicValidateForm.schoolorgid = this.$store.state.login.userinfo.schoolorgid
      this.dynamicValidateForm.companyorgid = this.$store.state.login.userinfo.companyorgid
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //确认跟进人 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认跟进人", false, () => {
        this.$set(this.dynamicValidateForm,'username',this.multipleSelection[0].realname)
        this.dynamicValidateForm.userid = this.multipleSelection[0].id;
        this.showUserModal = false;
				this.multipleSelection = [];
				this.selectQuery();
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: englishplan_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.venglishplan;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: englishplan_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: englishplan_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选项表格 api（跟进人）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>