<template>
  <!-- 打印页 -->
  <div class="print-page">
    <div class="btn-list">
      <el-button @click="switchPrint">返回</el-button>
      <el-button v-print="'#print'">打印</el-button>
    </div>
    <div class="print-w">
      <div class="print-c-w" id="print">
        <div class="print-c-t">
          <h3>成考教材</h3>
          <div class="userinfo-w level-w">
            <div>流水号：{{ detailForm.id }}</div>
            <div>客户：{{ detailForm.client }}</div>
            <div>申请人：{{ detailForm.insertusername }}</div>
            <div>申请人电话：{{ detailForm.phone }}</div>
            <div>收货人：{{ detailForm.consignee }}</div>
            <div>收货人电话：{{ detailForm.consigneephone }}</div>
            <div>收货人地址：{{ detailForm.address }}</div>
          </div>
          <div class="print-table-w">
            <table border="2" width="100%" cellpadding="0" cellspacing="0">
              <tr>
                <td class="td-style">书名</td>
                <td class="td-style">单价（元）</td>
                <td class="td-style">数量</td>
                <td class="td-style">码洋</td>
              </tr>

              <tr v-if="detailForm.art > 0">
                <td class="td-style">艺术概论</td>
                <td class="td-style">{{ detailForm.artprice }}</td>
                <td class="td-style">{{ detailForm.art }}</td>
                <td class="td-style">
                  {{ detailForm.art * detailForm.artprice }}
                </td>
              </tr>

              <tr v-if="detailForm.artworkbook > 0">
                <td class="td-style">艺术概论练习册</td>
                <td class="td-style">{{ detailForm.artworkbookprice }}</td>
                <td class="td-style">{{ detailForm.artworkbook }}</td>
                <td class="td-style">
                  {{ detailForm.artworkbook * detailForm.artworkbookprice }}
                </td>
              </tr>

              <tr v-if="detailForm.chinese > 0">
                <td class="td-style">语文</td>
                <td class="td-style">{{ detailForm.chineseprice }}</td>
                <td class="td-style">{{ detailForm.chinese }}</td>
                <td class="td-style">
                  {{ detailForm.chinese * detailForm.chineseprice }}
                </td>
              </tr>

              <tr v-if="detailForm.chineseworkbook > 0">
                <td class="td-style">语文练习册</td>
                <td class="td-style">{{ detailForm.chineseworkbookprice }}</td>
                <td class="td-style">{{ detailForm.chineseworkbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.chineseworkbook * detailForm.chineseworkbookprice
                  }}
                </td>
              </tr>

              <tr v-if="detailForm.chinese2 > 0">
                <td class="td-style">大学语文</td>
                <td class="td-style">{{ detailForm.chinese2price }}</td>
                <td class="td-style">{{ detailForm.chinese2 }}</td>
                <td class="td-style">
                  {{ detailForm.chinese2 * detailForm.chinese2price }}
                </td>
              </tr>

              <tr v-if="detailForm.chinese2workbook > 0">
                <td class="td-style">大学语文练习册</td>
                <td class="td-style">{{ detailForm.chinese2workbookprice }}</td>
                <td class="td-style">{{ detailForm.chinese2workbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.chinese2workbook *
                    detailForm.chinese2workbookprice
                  }}
                </td>
              </tr>

              <tr v-if="detailForm.education > 0">
                <td class="td-style">教育理论</td>
                <td class="td-style">{{ detailForm.educationprice }}</td>
                <td class="td-style">{{ detailForm.education }}</td>
                <td class="td-style">
                  {{ detailForm.education * detailForm.educationprice }}
                </td>
              </tr>

              <tr v-if="detailForm.educationworkbook > 0">
                <td class="td-style">教育理论练习册</td>
                <td class="td-style">
                  {{ detailForm.educationworkbookprice }}
                </td>
                <td class="td-style">{{ detailForm.educationworkbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.educationworkbook *
                    detailForm.educationworkbookprice
                  }}
                </td>
              </tr>

              <tr v-if="detailForm.english > 0">
                <td class="td-style">英语</td>
                <td class="td-style">{{ detailForm.englishprice }}</td>
                <td class="td-style">{{ detailForm.english }}</td>
                <td class="td-style">
                  {{ detailForm.english * detailForm.englishprice }}
                </td>
              </tr>

              <tr v-if="detailForm.englishworkbook > 0">
                <td class="td-style">英语练习册</td>
                <td class="td-style">{{ detailForm.englishworkbookprice }}</td>
                <td class="td-style">{{ detailForm.englishworkbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.englishworkbook * detailForm.englishworkbookprice
                  }}
                </td>
              </tr>
              <tr v-if="detailForm.english2 > 0">
                <td class="td-style">英语（专升本）</td>
                <td class="td-style">{{ detailForm.english2price }}</td>
                <td class="td-style">{{ detailForm.english2 }}</td>
                <td class="td-style">
                  {{ detailForm.english2 * detailForm.english2price }}
                </td>
              </tr>
              <tr v-if="detailForm.english2workbook > 0">
                <td class="td-style">英语2练习册</td>
                <td class="td-style">{{ detailForm.english2workbookprice }}</td>
                <td class="td-style">{{ detailForm.english2workbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.english2workbook *
                    detailForm.english2workbookprice
                  }}
                </td>
              </tr>
              <tr v-if="detailForm.history > 0">
                <td class="td-style">文史</td>
                <td class="td-style">{{ detailForm.historyprice }}</td>
                <td class="td-style">{{ detailForm.history }}</td>
                <td class="td-style">
                  {{ detailForm.history * detailForm.historyprice }}
                </td>
              </tr>
              <tr v-if="detailForm.historyworkbook > 0">
                <td class="td-style">文史练习册</td>
                <td class="td-style">{{ detailForm.historyworkbookprice }}</td>
                <td class="td-style">{{ detailForm.historyworkbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.historyworkbook * detailForm.historyworkbookprice
                  }}
                </td>
              </tr>
              <tr v-if="detailForm.law > 0">
                <td class="td-style">法学</td>
                <td class="td-style">{{ detailForm.lawprice }}</td>
                <td class="td-style">{{ detailForm.law }}</td>
                <td class="td-style">
                  {{ detailForm.law * detailForm.lawprice }}
                </td>
              </tr>
              <tr v-if="detailForm.lawworkbook > 0">
                <td class="td-style">法学练习册</td>
                <td class="td-style">{{ detailForm.lawworkbookprice }}</td>
                <td class="td-style">{{ detailForm.lawworkbook }}</td>
                <td class="td-style">
                  {{ detailForm.lawworkbook * detailForm.lawworkbookprice }}
                </td>
              </tr>

              <tr v-if="detailForm.math > 0">
                <td class="td-style">数学</td>
                <td class="td-style">{{ detailForm.mathprice }}</td>
                <td class="td-style">{{ detailForm.math }}</td>
                <td class="td-style">
                  {{ detailForm.math * detailForm.mathprice }}
                </td>
              </tr>

              <tr v-if="detailForm.mathworkbook > 0">
                <td class="td-style">数学练习册</td>
                <td class="td-style">{{ detailForm.mathworkbookprice }}</td>
                <td class="td-style">{{ detailForm.mathworkbook }}</td>
                <td class="td-style">
                  {{ detailForm.mathworkbook * detailForm.mathworkbookprice }}
                </td>
              </tr>

              <tr v-if="detailForm.math2 > 0">
                <td class="td-style">高数二</td>
                <td class="td-style">{{ detailForm.math2price }}</td>
                <td class="td-style">{{ detailForm.math2 }}</td>
                <td class="td-style">
                  {{ detailForm.math2 * detailForm.math2price }}
                </td>
              </tr>
              <tr v-if="detailForm.math2workbook > 0">
                <td class="td-style">高数二练习册</td>
                <td class="td-style">{{ detailForm.math2workbookprice }}</td>
                <td class="td-style">{{ detailForm.math2workbook }}</td>
                <td class="td-style">
                  {{ detailForm.math2workbook * detailForm.math2workbookprice }}
                </td>
              </tr>
              <tr v-if="detailForm.medicine > 0">
                <td class="td-style">医学综合</td>
                <td class="td-style">{{ detailForm.medicineprice }}</td>
                <td class="td-style">{{ detailForm.medicine }}</td>
                <td class="td-style">
                  {{ detailForm.medicine * detailForm.medicineprice }}
                </td>
              </tr>
              <tr v-if="detailForm.medicineworkbook > 0">
                <td class="td-style">医学综合练习册</td>
                <td class="td-style">{{ detailForm.medicineworkbookprice }}</td>
                <td class="td-style">{{ detailForm.medicineworkbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.medicineworkbook *
                    detailForm.medicineworkbookprice
                  }}
                </td>
              </tr>
              <tr v-if="detailForm.politics > 0">
                <td class="td-style">政治</td>
                <td class="td-style">{{ detailForm.politicsprice }}</td>
                <td class="td-style">{{ detailForm.politics }}</td>
                <td class="td-style">
                  {{ detailForm.politics * detailForm.politicsprice }}
                </td>
              </tr>
              <tr v-if="detailForm.politicsworkbook > 0">
                <td class="td-style">政治练习册</td>
                <td class="td-style">{{ detailForm.politicsworkbookprice }}</td>
                <td class="td-style">{{ detailForm.politicsworkbook }}</td>
                <td class="td-style">
                  {{
                    detailForm.politicsworkbook *
                    detailForm.politicsworkbookprice
                  }}
                </td>
              </tr>
              <tr v-if="detailForm.rkc > 0">
                <td class="td-style">理化</td>
                <td class="td-style">{{ detailForm.rkcprice }}</td>
                <td class="td-style">{{ detailForm.rkc }}</td>
                <td class="td-style">
                  {{ detailForm.rkc * detailForm.rkcprice }}
                </td>
              </tr>
              <tr v-if="detailForm.rkcworkbook > 0">
                <td class="td-style">理化练习册</td>
                <td class="td-style">
                  {{ detailForm.rkcworkbookprice }}
                </td>
                <td class="td-style">{{ detailForm.rkcworkbook }}</td>
                <td class="td-style">
                  {{ detailForm.rkcworkbook * detailForm.rkcworkbookprice }}
                </td>
              </tr>

              <tr v-if="detailForm.ecology > 0">
                <td class="td-style">生态学</td>
                <td class="td-style">
                  {{ detailForm.ecologyprice }}
                </td>
                <td class="td-style">{{ detailForm.ecology }}</td>
                <td class="td-style">
                  {{ detailForm.ecology * detailForm.ecologyprice }}
                </td>
              </tr>
              <tr v-if="detailForm.ecologybook > 0">
                <td class="td-style">生态学练习册</td>
                <td class="td-style">
                  {{ detailForm.ecologybookprice }}
                </td>
                <td class="td-style">{{ detailForm.ecologybook }}</td>
                <td class="td-style">
                  {{ detailForm.ecologybook * detailForm.ecologybookprice }}
                </td>
              </tr>


            </table>
          </div>
        </div>
        <div class="print-c-b level-w">
          <div>制单人：{{ detailForm.isokusername }}</div>
          <div>制单时间：{{ detailForm.isoktime | formatDate('YYYY-MM-DD HH:mm:ss') }}</div>
          <div>发货员：</div>
          <div>提书人：</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { myRequest } from "@/request/index";

export default {
  data() {
    return {
      detailForm: {}, //表单

      books: [], //所有书本
    };
  },

  props: {
    id: {
      default: Number,
    },
    printUrl: String,
    resKey: String,
  },

  created() {
    this.getDataDetail();
  },
  methods: {
    switchPrint() {
      this.$emit("switchPrint", false);
    },
    //数据详情 api
    getDataDetail() {
      myRequest({
        url: this.printUrl,
        data: {
          id: this.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.detailForm = res.data.data[this.resKey];
          this.filterFiled(this.detailForm);
        }
      });
    },

    //筛选大于0的字段
    filterFiled(obj) {
      for (var key in obj) {
        if (typeof obj[key] === "number" && obj[key] > 0) {
          this.books.push(obj[key]);
        }
      }
    },
  },
};
</script>


<style scoped lang="scss">
.Btn {
  padding: 10px;
  & > button {
    margin: 5px;
  }
}
.print-page {
  overflow-y: auto;
  height: calc(100vh);
  background-color: rgb(185, 200, 177);
}
.print-w {
  padding: 5% 30%;
}
.print-c-w {
  position: relative;
  width: 100%;
  padding: 0 5% 2% 5%;
  background-color: #fff;
}

.print-c-t > h3 {
  text-align: center;
}

.level-w > div {
  width: 50%;
  float: left;
  margin: 0 0 10px 0;
}
.print-c-b {
  margin: 5% 0 0 0;
}
.print-c-b,
.print-c-t {
  overflow: auto;
}

.td-style {
  padding: 0 !important;
  border: 0;
  border-bottom: 2px solid;
}

.print-table-w {
  & > table {
    & > tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
</style>
