<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="学生名称:">
            <el-input
							v-model.trim="searchForm.studentname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="手机号码:">
            <el-input
							v-model.trim="searchForm.mobile"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="转出教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname1"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="转入教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname2"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生名称" align="center" width="100px">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" prop="mobile" align="center" width="150px">
				<template slot-scope="scope" v-if="scope.row.mobile !== null">
				     {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
				 </template>
			</el-table-column>
      <el-table-column label="院校" prop="collegename" align="center" width="150px"/>
      <el-table-column label="专业" prop="majorname" align="center" width="160px"/>
      <el-table-column label="层次" prop="levelval" align="center" />
      <el-table-column
        label="转出教学点"
        prop="schoolorgname1"
        align="center"
				width="150px"
      />
      <el-table-column
        label="转入教学点"
        prop="schoolorgname2"
        align="center"
				width="150px"
      />

      <el-table-column label="转入时间" align="center" width="120">
        <template slot-scope="scope">
          {{ scope.row.movetime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        label="是否接收"
        prop="recvflag"
        align="center"
      >
        <template slot-scope="scope">
          {{ available[scope.row.recvflag] }}
        </template>
      </el-table-column>
      <el-table-column label="接收时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.recvtime | formatDate("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column label="接收人" prop="recvusername" align="center" width="120px"/>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      width="60%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学生名称:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="手机号码:">
          {{ dynamicValidateForm.mobile }}
        </el-form-item>
        <el-form-item label="转学时间:">
          {{ dynamicValidateForm.movetime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="接收标志:">
          {{ available[dynamicValidateForm.recvflag] }}
        </el-form-item>
        <el-form-item label="接收时间:">
          {{ dynamicValidateForm.recvtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
        <el-form-item label="接收人名称:">
          {{ dynamicValidateForm.recvusername }}
        </el-form-item>
        <el-form-item label="原教学点名称:">
          {{ dynamicValidateForm.schoolorgname1 }}
        </el-form-item>
        <el-form-item label="现教学点名称:">
          {{ dynamicValidateForm.schoolorgname2 }}
        </el-form-item>
        <el-form-item label="原分公司名称:">
          {{ dynamicValidateForm.companyorgname1 }}
        </el-form-item>
        <el-form-item label="现分公司名称:">
          {{ dynamicValidateForm.companyorgname2 }}
        </el-form-item>
        <el-form-item label="现教学点名称:">
          {{ dynamicValidateForm.schoolorgname2 }}
        </el-form-item>
        <el-form-item label="备注:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
        <el-form-item label="转学原因:">
          {{ dynamicValidateForm.reason }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <Export
      :exportTitle="'教学点学生转入'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>


<script>
import { _available } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  studentmove_recvlist,
  studentmove_input,
  studentmove_export,
  studentmove_exportexcel,
  studentmove_exportsave,
  studentmove_batchsaverecv,
  studentmove_export2,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
export default {
  mixins: [part],
  name: "studentmove1",
  components: { Export },
  props: {
    exportUrl: {
      default: () => studentmove_export,
    },
    exportexcelUrl: {
      default: () => studentmove_exportexcel,
    },
    exportsaveUrl: {
      default: () => studentmove_exportsave,
    },
  },
  data() {
    return {
      transferDialog: false, //导出框
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        name: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      schoolorg: {}, //院校列表
      showSchoolorgModal: false,

      deliTable: {},
      showDeliModal: false, //德立学生

      available: _available,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "请根据搜索条件进行导出。",
        success: () => {
          window.location.href =
                  this.$url.Freemarker +
                  studentmove_export2 +
                  `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
		//重置
		reset(){
			this.searchForm = {
				studentname: null,
				mobile: null,
				schoolorgname1: null,
				schoolorgname2: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentmove_recvlist,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换德立学生表格页
    handleDeliChange(val) {
      this.pageinfo.pageindex = val;
      this.onSelectDeli();
    },
    //切换转入教学点表格页
    handleAcademyChange(val) {
      this.pageinfo.pageindex = val;
      this.collegeDialogresult();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      console.log(row.id);
      myRequest({
        url: studentmove_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vstudentmove;
        }
      });
    },

    //批量接收 click
    btnBatch() {
      selectCheck(this.multipleSelection, "批量接收", true, () => {
        confirmCallBack({
          title: "提示",
          content: "确认要接收吗？",
          success: () => {
            this.batch(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //批量接收 api
    batch(ids) {
      myRequest(
        {
          method: "post",
          url: studentmove_batchsaverecv,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>
