<template>
  <div class="goodsregister-w">
    <div>
      <!-- 搜索 -->
      <el-form class="inputBox" label-width="110px" :model="searchForm">
        <el-form-item label="流水号:">
          <el-input v-model="searchForm.id" clearable />
        </el-form-item>
        <el-form-item label="招生年份:">
          <el-input v-model="searchForm.enrollyearkey" clearable />
        </el-form-item>
        <el-form-item label="计划主题:">
          <el-input v-model="searchForm.title" clearable />
        </el-form-item>
        <el-form-item label="审核标识:">
          <el-select v-model="searchForm.approveflag" clearable>
            <el-option value="-2" label="未提起审批" />
            <el-option value="-1" label="审核中" />
            <el-option value="0" label="审核不通过" />
            <el-option value="1" label="通过" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属教学点名称:">
          <el-input v-model="searchForm.schoolorgname" clearable />
        </el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="getDataList"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
      </el-form>
      <!-- 操作按钮 -->
      <div class="select-btn">
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <!-- 表格 -->
      <el-table
        :data="tableRes.list"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="招生年份" prop="enrollyearval" align="center" />
        <el-table-column label="计划主题" prop="title" align="center" />
        <el-table-column label="招生总人数" prop="total" align="center" />
        <el-table-column
          label="校外教学点名称"
          prop="schoolorgname"
          align="center"
        />
        <el-table-column
          label="所属分公司名称"
          prop="companyorgname"
          align="center"
        />
        <el-table-column label="审核标识" prop="approveflag" align="center" />
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="tableRes.totalcount"
      ></el-pagination>
    </div>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			:close-on-click-modal='false'
      title="添加："
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招生年份:">
          <el-date-picker
            v-model="dynamicValidateForm.enrollyearkey"
            type="year"
            placeholder="选择招生年份"
            value-format="yyyy"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="计划主题:">
          <el-input v-model="dynamicValidateForm.title" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAddOrEditForm">提交 </el-button>
      </span>
    </el-dialog>

    <!--增加明细-->
    <el-dialog
      class="btn-2b5a95"
      width="40%"
      title="增加明细："
			:close-on-click-modal='false'
      :visible.sync="showAddDetailModal"
      v-if="showAddDetailModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic"
        status-icon
        :rules="rules"
      >
        <el-form-item label="计划主题:">
          <el-input v-model="dynamicValidateForm.title" disabled />
        </el-form-item>
        <el-form-item label="院校名称:">
          <el-input v-model="dynamicValidateForm.collegename" disabled />
          <el-button
            type="primary"
            @click="
              onSelectData(
                collegemajorenroll_dialogresult,
                'showCollegenameModal'
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="招生年级:">
          <el-input v-model="dynamicValidateForm.enrollyearval" disabled />
        </el-form-item>
        <el-form-item label="专业名称:">
          <el-input v-model="dynamicValidateForm.majorname" disabled />
        </el-form-item>
        <el-form-item label="层次:">
          <el-input v-model="dynamicValidateForm.majorlevelval" disabled />
        </el-form-item>
        <el-form-item label="校外教学点:">
          <el-input v-model="dynamicValidateForm.schoolorgname" disabled />
        </el-form-item>
        <el-form-item label="招生人数:">
          <el-input v-model="dynamicValidateForm.enrollcount" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveDetail">提交 </el-button>
      </span>
    </el-dialog>

    <!--查看明细-->
    <el-dialog
      class="btn-2b5a95"
      width="40%"
			:close-on-click-modal='false'
      title="查看明细："
      :visible.sync="showListDetailModal"
      v-if="showListDetailModal"
      append-to-body
    >
      <el-button type="primary" @click="btnDetailDel">删除</el-button>
      <el-button type="primary" @click="detaillist">刷新</el-button>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="招生人数" prop="enrollcount" align="center" />
        <el-table-column label="院校名称" prop="collegename" align="center" />
        <el-table-column label="专业名称" prop="majorname" align="center" />
        <el-table-column
          label="招生年级名称"
          prop="enrollyearval"
          align="center"
        />
        <el-table-column
          label="校外教学点名称"
          prop="schoolcorpname"
          align="center"
        />
        <el-table-column
          label="校外分公司名称"
          prop="companycorpname"
          align="center"
        />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column label="招生计划主题" prop="title" align="center" />
        <el-table-column
          label="所属分公司名称"
          prop="companyorgname"
          align="center"
        />
        <el-table-column
          label="所属教学点名称"
          prop="schoolorgname"
          align="center"
        />
        <el-table-column label="年级" prop="iyear" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择院校 -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择院校："
      :visible.sync="showCollegenameModal"
      v-if="showCollegenameModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCollegename">确定</el-button>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="申报年级" prop="enrollyearkey" align="center" />
        <el-table-column label="学校名称" prop="collegename" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column label="专业名称" prop="majorname" align="center" />
        <el-table-column label="申报时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.inserttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="申报教学点名称"
          prop="schoolorgname"
          align="center"
        />
        <el-table-column
          label="审核标识"
          prop="approveflag"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            {{ approveflag[scope.row.approveflag] }}
          </template>
        </el-table-column>
        <el-table-column label="申报人" prop="username" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 查看审批 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      title="审批记录："
      :visible.sync="showModal"
      v-if="showModal"
      append-to-body
    >
      <el-table :data="showTable.list" style="width: 100%">
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="员工名称" prop="objectname" align="center" />
        <el-table-column
          label="审核是否通过标志"
          prop="passflag"
          align="center"
        />
        <el-table-column label="审批意见" prop="approvedesc" align="center" />
        <el-table-column label="审批时间" prop="approvetime" align="center">
          <template slot-scope="scope">
            {{ scope.row.approvetime | formatDate("YYYY-MM-DD hh:mm:ss") }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <!-- 总裁审批表单 -->
    <el-dialog
      class="btn-2b5a95"
      title="总裁审批："
			:close-on-click-modal='false'
      :visible.sync="showApprovalModal"
      v-if="showApprovalModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic"
        status-icon
        label-width="15%"
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 2, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审批意见："
          prop="approvedesc"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onApprovalClick('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  enrollplan_list,
  enrollplan_input,
  enrollplan_save,
  enrollplan_delete,
  enrollplandetail_save,
  enrollplandetail_list,
  enrollplan_requestapprove,
  enrollplan_approvecheck,
  enrollplan_approvesave,
  collegemajorenroll_getcollegemajorenroll2,
  enrollplandetail_savebatch,
  enrollplan_export2,
  enrollplandetail_delete,
} from "@/request/api/allChildrenProject";
import { _collegemajorenroll_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _approveflag,} from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "enrollplan",
  components: { Export },
  props: {
    collegemajorenroll_dialogresult: {
      default: () => _collegemajorenroll_dialogresult,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      // 审批状态
      approvestateVal: {
        1: "通过",
        "-2": "提请审核",
        "-1": "审核中",
        0: "审核不通过",
      },
			approveflag: _approveflag,
      itemRow: null,
      searchForm: {},
      itemId: null, //首表格项id
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则

      showApprovalModal: false, //审批框

      showTable: {}, //查看审批 data
      showModal: false, //查看审批框

      selectTable: {},
      selectTableUrl: "",
      showAddDetailModal: false, //增加明细
      showListDetailModal: false, //查看明细
      showCollegenameModal: false, //选择院校
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },

    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: enrollplan_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换选项表格页
    handleSelectChange(val, data) {
      this.pageinfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "提请审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "确定提请审批吗？",
          success: () => {
            this.reqApproval(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //总裁审批 click
    btnHeaderApproval() {
      selectCheck(this.multipleSelection, "总裁审批", false, () => {
        this.headApproval();
      });
    },
    //查看审批情况 click
    btnViewApprove() {
      selectCheck(this.multipleSelection, "查看审批", false, () => {
        this.approvelist();
      });
    },
    //导出 click
    btnExport() {
      selectCheck(this.multipleSelection, "导出", false, () => {
        window.location.href =
          this.$url.Freemarker +
          enrollplan_export2 +
          "?id=" +
          this.multipleSelection[0].id;
      });
    },
    //增加明细 click
    btnAddDetail() {
      selectCheck(this.multipleSelection, "增加明细", false, () => {
        this.dynamicValidateForm = {
          title: this.multipleSelection[0].title,
          planid: this.multipleSelection[0].id,
        };
        this.showAddDetailModal = true;
      });
    },
    //查看明细 click
    btnShowDetail() {
      selectCheck(this.multipleSelection, "查看明细", false, () => {
        this.itemRow = this.multipleSelection[0];
        this.detaillist();
      });
    },
    //查看明细里的删除 click
    btnDetailDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.detailDel(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //确认院校 click
    onConfirmCollegename() {
      selectCheck(this.multipleSelection, "确认院校", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "collegename",
          this.multipleSelection[0].collegename
        );
        this.$set(
          this.dynamicValidateForm,
          "enrollyearval",
          this.multipleSelection[0].enrollyearkey
        );
        this.$set(
          this.dynamicValidateForm,
          "majorname",
          this.multipleSelection[0].majorname
        );
        this.$set(
          this.dynamicValidateForm,
          "majorlevelval",
          this.multipleSelection[0].majorlevelval
        );
        this.$set(
          this.dynamicValidateForm,
          "schoolorgname",
          this.multipleSelection[0].schoolorgname
        );
        this.dynamicValidateForm.collegeid =
          this.multipleSelection[0].collegeid;
        this.dynamicValidateForm.majorid = this.multipleSelection[0].majorid;
        this.showCollegenameModal = false;
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: enrollplan_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.tenrollplan;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm() {
      myRequest(
        {
          url: enrollplan_save,
          data: this.dynamicValidateForm,
        },
        {
          isRefTableList: true,
          that: this,

          modal: "showAddModal",
        }
      );
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: enrollplan_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //提请审批 api
    reqApproval(ids) {
      myRequest(
        {
          method: "post",
          url: enrollplan_requestapprove,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //审批检查 api
    headApproval() {
      myRequest(
        {
          method: "post",
          url: enrollplan_approvecheck,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
              leadertype: "05",
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          that: this,
          showModal: "showApprovalModal",
        }
      );
    },
    //审批保存  api
    onApprovalClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.approvesave();
        }
      });
    },
    //查看审批 api
    approvelist() {
      myRequest({
        url: approve_list,
        data: {
          objectname: "T_ACCOUNT",
          objectid: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showTable = res.data.data;
          this.showModal = true;
        }
      });
    },
    //审批保存  api
    approvesave() {
      myRequest(
        {
          method: "post",
          url: enrollplan_approvesave,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
              leadertype: "05",
              passflag: this.dynamicValidateForm.passflag,
              approvedesc: this.dynamicValidateForm.approvedesc,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          modal: "showApprovalModal",
          isRefTableList: true,
          that: this,
        }
      );
    },
    //增加明细提交 api
    saveDetail() {
      myRequest(
        {
          url: enrollplandetail_save,
          data: this.dynamicValidateForm,
        },
        {
          isRefTableList: true,
          that: this,
          modal: "showAddDetailModal",
        }
      );
    },
    //查看明细 api
    detaillist() {
      myRequest({
        url: enrollplandetail_list,
        data: {
          planid: this.itemRow.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this.showListDetailModal = true;
        }
      });
    },

    //查看明细的删除 api
    detailDel(idArr) {
      myRequest(
        {
          method: "post",
          url: enrollplandetail_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          methodName: "detaillist",
          that: this,
        }
      );
    },
    //选项表格 api（院校）
    onSelectData(url, modal) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss">
.el-form .el-form-item__content {
  display: flex;
}

.more-form-item-w {
  display: flex;
}
</style>
