<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				@submit.native.prevent
				:model="searchForm"
      >
        <el-form-item label="名称:">
          <el-input 
						v-model.trim="searchForm.name" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="类型:">
          <el-select 
						v-model="searchForm.type" 
						@keyup.enter.native="seach"
						@visible-change="visibleType"
						ref="selectRef"
						clearable>
            <el-option value="公章"></el-option>
            <el-option value="发票专用章"></el-option>
            <el-option value="财务章"></el-option>
            <el-option value="法人章"></el-option>
            <el-option value="收款章"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="名称" prop="name" align="center" />
      <el-table-column label="类型" prop="type" align="center" />

      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          {{ scope.row.status === 1 ? "正常" : "借出" }}
        </template>
      </el-table-column>

      <el-table-column label="保管人" prop="custodianusername" align="center" />
      <el-table-column label="借用人" prop="borrowerusername" align="center" />
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
      />
    
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="名称:" prop="name">
          <el-input 
						v-model.trim="dynamicValidateForm.name" 
						clearable/>
        </el-form-item>
        <el-form-item label="类型:" prop="type">
          <el-select v-model="dynamicValidateForm.type">
            <el-option value="公章"></el-option>
            <el-option value="发票专用章"></el-option>
            <el-option value="财务章"></el-option>
            <el-option value="法人章"></el-option>
            <el-option value="收款章"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="保管人:" prop="custodianusername">
          <el-input
            v-model="dynamicValidateForm.custodianusername"
            placeholder="请选择"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                vuser_dialogresult,
                'showCustodianModal',
              )
            "
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择保管人 -->
    <el-dialog
      class="btn-2b5a95"
      width="70%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择保管人："
      :visible.sync="showCustodianModal"
			v-if="showCustodianModal"
			:close-on-click-modal='false'
      append-to-body
      
    >
      <el-button type="primary" @click="onConfirmCustodian">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="所属教学点:" prop="schoolorgname">
              <el-input 
								v-model.trim="selectSearchForm.schoolorgname"
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
           
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				:key="key"	
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="姓名" prop="realname" align="center" />
        <el-table-column label="岗位" prop="positionname" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  seal_list,
  seal_input,
  seal_save,
  seal_delete,
} from "@/request/api/allChildrenProject";
import { _vuser_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "seal",
  components: {},
  props: {
    vuser_dialogresult: {
      default: () => _vuser_dialogresult,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
				name:[{ trigger: "blur", message: "必填字段", required: true }],
				type:[{ trigger: "blur", message: "必填字段", required: true }],
				custodianusername:[{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
			key: '',
      selectTable: {},
      selectTableUrl: null,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectSearchForm: {},
      
      selectSchoolorgid: null,
      showCustodianModal: false,
    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectRef.blur();
			}
		},
		reset(){
			this.searchForm = {
				name: null,
				type: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		querySelect() {
			this.selectSearchForm = {
				realname: null,
				schoolorgname: null,
			}
		},
		handleQuery() {
			this.querySelect();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: seal_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //确认保管人 click
    onConfirmCustodian() {
      selectCheck(this.multipleSelection, "确认保管人", false, () => {
        this.$set(this.dynamicValidateForm,'custodianusername',this.multipleSelection[0].realname)
        this.dynamicValidateForm.custodianuserid = this.multipleSelection[0].id;
        this.dynamicValidateForm.schoolorgid = this.multipleSelection[0].schoolorgid;
        this.dynamicValidateForm.companyorgid = this.multipleSelection[0].companyorgid;
        this.showCustodianModal = false;
				this.querySelect();
				this.multipleSelection = [];
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: seal_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vseal;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: seal_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: seal_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },
    //选项表格 api(德立学生)
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>