<template>
  <div class="goodsregister-w">
    <div class="page-c-w">
      <!-- 日历 -->
      <el-calendar>
        <template slot="dateCell" slot-scope="{ date, data }">
          {{ date.getDate() }}
          <!-- 日程内容 -->
          <div
            class="schedule-content"
            v-for="(item, i) in daysData"
            :key="i"
            v-show="data.day === item.dates && data.type === 'current-month'"
          >
            <div v-for="(itemC, k) in item.detaillist" :key="k">
              {{ itemC.content + `（${itemC.type === 2 ? "管理" : "个人"}）` }}
            </div>
          </div>
          <!-- 显示编辑阴影框 -->
          <div
            class="edit-day-i-c"
            v-if="
              dayCurrentIndex === date.getDate() &&
              data.type === 'current-month'
            "
          >
            <!-- 这里的点击事件通过DOM动态绑定 -->
            <i class="el-icon-edit"></i>
            <i class="el-icon-plus"></i>
          </div>
          <!-- 编辑日程框 -->
          <div
            v-if="
              clickCurrentIndex === date.getDate() &&
              showSchedulePopover &&
              data.type === 'current-month'
            "
          >
            <el-popover
              placement="right"
              :title="date.getMonth() + 1 + '月' + date.getDate() + '号日程'"
              width="600"
              trigger="manual"
              v-model="showSchedulePopover"
            >
              <!-- 表格 -->
              <div v-if="showEditPopover">
                <!-- 操作按钮 -->
                <div
                  class="select-btn"
                  style="display: flex; justify-content: space-between"
                >
                  <div>
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="btnEditDetialDay"
                      >修改
                    </el-button>
                    <el-button
                      type="info"
                      icon="el-icon-delete"
                      @click="btnDelDetialDay"
                      >删除
                    </el-button>
                  </div>

                  <el-button
                    icon="el-icon-close"
                    circle
                    @click="onCloseEditPopover"
                  ></el-button>
                </div>

                <el-table
                  :data="currentDayTableData"
                  style="width: 100%"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column type="selection" width="55">
                  </el-table-column>
                  <el-table-column
                    label="流水号"
                    prop="id"
                    align="center"
                    width="100"
                  ></el-table-column>
                  <el-table-column
                    label="当天日程"
                    prop="content"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!-- 添加 -->
              <el-form
                v-if="showAddPopover"
                :model="currentScheduleForm"
                ref="dynamicValidateForm"
                class="demo-dynamic"
                status-icon
              >
                <div
                  v-for="(formItemCol, i) in moreFormItem"
                  :key="i"
                  class="more-form-item-w"
                >
                  <div v-for="(formItem, k) in formItemCol" :key="k">
                    <el-form-item prop="content" :label="formItem.label">
                      <el-input type="textarea" v-model="formItem.value">
                      </el-input>
                    </el-form-item>
                  </div>
                  <el-button
                    type="danger"
                    @click="onRemoveMoreForm(i)"
                    style="margin: 0 0 0 10px; height: 40px"
                    >删除</el-button
                  >
                </div>
                <el-form-item>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                    "
                  >
                    <div>
                      <el-button @click="addFormItem">添加日程</el-button>
                      <el-button
                        type="primary"
                        @click="onSubmitSchedule"
                        v-if="moreFormItem.length"
                        >提交</el-button
                      >
                    </div>
                    <el-button @click="onCloseEditPopover">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </el-popover>
          </div>
        </template>
      </el-calendar>
    </div>
    <!-- 修改每一项日程 -->
    <el-dialog
      class="btn-2b5a95"
      title="修改："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showEditModal"
			v-if="showEditModal"
      append-to-body
      width="40%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic"
        status-icon
        label-width="30%"
      >
        <el-form-item prop="content" label="当天日程：">
          <el-input
            type="textarea"
            v-model="dynamicValidateForm.content"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitEditDay">提交 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import {
  schedulemanage_list2,
  schedulemanage_save,
  schedulemanage_delete,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "schedulemanage",
  components: {},
  props: {},
  data() {
    return {
      daysData: [], //所有日程数据
      dayCurrentIndex: null, //当天索引
      currentScheduleForm: {
        content: [],
      }, //当前日程表单
      moreFormItem: [], //多个日程内容
      showSchedulePopover: false, //显示日程气泡框
      clickCurrentIndex: null,
      currentYear: "", //当前年份
      currentMonth: "", //当前月份
      hasPerContentDays: [], //包含内容的日期 -- 个人的
      hasComContentDays: [], //包含内容的日期 -- 总部的
      showEditPopover: false, //编辑时
      currentDayTableData: [], //当天日程表格数据
      showEditModal: false,
      showAddPopover: false,
      dynamicValidateForm: {},
      multipleSelection: [],
      currentDayId: null,
      currentDayDate: "",

      noCurrentMonthDays: [], //非当前月所有天
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      // 切换月份时 执行
      let monthBtns = document.querySelectorAll(
        ".el-calendar__button-group .el-button-group>button"
      );
      this.initScheduleEvent();
      this.initNoCurrentScheduleEvent();
      monthBtns.forEach((i) => {
        i.addEventListener("click", () => {
          this.initScheduleEvent();
        });
      });
    });
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]), //获取当前月份
    getCurrentMonth() {
      this.currentMonth = document.querySelector(
        ".el-calendar__title"
      ).innerText;

      let calendar__title = this.currentMonth
        .replace(/ 月/g, "")
        .replace(/年/g, "-")
        .replace(/ /g, "")
        .split("-");
      this.currentYear = calendar__title[0];
      this.currentMonth = calendar__title[1].padStart(2, "0");
    },
    //初始化当前月日程事件
    initScheduleEvent() {
      let that = this;
      this.getCurrentMonth();
      this.dayCurrentIndex = null;
      this.clickCurrentIndex = null;
      let $dayDoms = []; //当前月所有天
      let table__row = null;
      table__row = document.getElementsByClassName("el-calendar-table__row");
      for (let i = 0; i < table__row.length; i++) {
        for (
          let j = 0;
          j < table__row[i].getElementsByClassName("current").length;
          j++
        ) {
          $dayDoms.push(table__row[i].getElementsByClassName("current")[j]);
        }
      }
      for (let k = 0; k < $dayDoms.length; k++) {
        //每次移入或移出只执行一次
        $dayDoms[k].onmouseenter = function () {
          that.enter(k);
        };
        $dayDoms[k].onmouseleave = function () {
          that.leave();
        };
      }
      this.getDataList();
    },
    //初始化非当前月日程事件
    initNoCurrentScheduleEvent() {
      let table__row = null;
      table__row = document.getElementsByClassName("el-calendar-table__row");
      if (this.noCurrentMonthDays.length) {
        this.noCurrentMonthDays.forEach((element) => {
          element.removeEventListener("click", this.noCurrentMonthClick, false);
        });
        this.noCurrentMonthDays = []; //非当前月所有天
      }
      for (let i = 0; i < table__row.length; i++) {
        for (let j = 0; j < table__row[i].children.length; j++) {
          if (table__row[i].children[j].className === "prev") {
            this.noCurrentMonthDays.push(table__row[i].children[j]);
          }
          if (table__row[i].children[j].className === "next") {
            this.noCurrentMonthDays.push(table__row[i].children[j]);
          }
        }
      }
      this.noCurrentMonthDays.forEach((i) => {
        i.addEventListener("click", this.noCurrentMonthClick, false);
      });
    },
    //初始化隐藏框按钮事件
    initShadowModalBtnEvent(day) {
      this.$nextTick(() => {
        let editDayBtn = null,
          addDayBtn = null;
        editDayBtn = document.querySelector(".el-icon-edit");
        addDayBtn = document.querySelector(".el-icon-plus");
        if (editDayBtn && addDayBtn) {
          editDayBtn.addEventListener(
            "click",
            this.editIconClick.bind(null, day),
            false
          );
          addDayBtn.addEventListener(
            "click",
            this.addDayContentClick.bind(null, day),
            false
          );
        }
      });
    },
    //添加一行日程 click
    addFormItem() {
      this.moreFormItem.push([
        {
          prop: "content",
          value: "",
          label: "内容：",
        },
      ]);
    },
    //删除一行日程 click
    onRemoveMoreForm(index) {
      if (!this.moreFormItem[index][0].id) {
        return this.moreFormItem.splice(index, 1);
      }
      selectCheck(this.moreFormItem, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "该日程内容将永久删除！",
          success: () => {
            this.delDayDetail(this.moreFormItem[index][0].id);
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //编辑日程icon click
    editIconClick(day) {
      let currentDayItem = null;
      this.currentDayTableData = [];
      this.clickCurrentIndex = day;
      this.showEditPopover = true;
      this.showSchedulePopover = true;
      this.showAddPopover = false;
      currentDayItem = this.daysData.find(
        (i) => Number(formatDare(i.dates, "DD")) === day
      );
      if (currentDayItem) {
        this.currentDayTableData = currentDayItem.detaillist;
        this.currentDayId = currentDayItem.id;
      }
    },
    //添加日程icon click
    addDayContentClick(day) {
      this.clickCurrentIndex = day;
      this.showSchedulePopover = true;
      this.showAddPopover = true;
      this.showEditPopover = false;
      this.moreFormItem = [];
      this.currentDayDate =
        this.currentYear +
        "-" +
        this.currentMonth +
        "-" +
        String(day).padStart(2, "0");
    },
    //编辑日程项 click
    btnEditDetialDay() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.dynamicValidateForm = this.multipleSelection[0];
        if (this.dynamicValidateForm.type === 2) {
          //要编辑此数据可前往年度日程编辑
          return messageTip({
            type: "warning",
            message: "此项数据不可编辑！！！",
          });
        }
        this.showEditModal = true;
      });
    },
    //删除日程项 click
    btnDelDetialDay() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delDayDetail(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //修改日程项提交 click
    submitEditDay() {
      this.editDayDetial(this.multipleSelection[0].id);
    },
    //关闭所有气泡框
    onCloseEditPopover() {
      this.showSchedulePopover = false;
      this.showAddPopover = false;
      this.showEditPopover = false;
    },
    //获取当月日历的内容 api
    getDataList() {
      myRequest({
        url: schedulemanage_list2,
        data: {
          month:
            this.currentYear + "-" + this.currentMonth ||
            formatDare(new Date(), "YYYY-MM"), //当前月份（2020-12）
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.daysData = res.data.data.returnmanagedtolist2;
        }
      });
    },
    //添加日程提交 click
    onSubmitSchedule() {
      this.currentScheduleForm.content = [];
      this.moreFormItem.forEach((i) => {
        i.forEach((i) => this.currentScheduleForm.content.push(i.value));
      });
      this.submitSchedule();
    },
    enter(index) {
      this.dayCurrentIndex = index + 1;
      this.initShadowModalBtnEvent(this.dayCurrentIndex);
    },
    leave() {
      this.dayCurrentIndex = null;
    },
    //非当前月日期 click
    noCurrentMonthClick() {
      this.initNoCurrentScheduleEvent();
      this.initScheduleEvent();
    },
    //表格选中改变
    handleSelectionChange(vals) {
      this.multipleSelection = vals;
    },
    //提交日程 api
    submitSchedule() {
      myRequest({
        url: schedulemanage_save,
        method: "post",
        data: this.$qs.stringify(
          {
            dates1: this.currentDayDate,
            content: this.currentScheduleForm.content,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.getDataList();
          this.showSchedulePopover = false;
        }
      });
    },
    //删除日程项 api
    delDayDetail(id) {
      myRequest(
        {
          method: "post",
          url: schedulemanage_delete,
          data: this.$qs.stringify(
            {
              detailid: id,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.showSchedulePopover = false;
          this.multipleSelection = [];
        }
      });
    },
    //添加日程项 api
    editDayDetial() {
      myRequest({
        url: schedulemanage_save,
        method: "post",
        data: this.$qs.stringify(
          {
            content: [this.dynamicValidateForm.content],
            detailid: this.dynamicValidateForm.id,
            id: this.currentDayId,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.getDataList();
          this.multipleSelection = [];
          this.showEditModal = false;
        }
      });
    },
  },
};
</script>


<style  lang="scss" >
.is-today {
  border: 2px solid #409eff !important;
  .el-calendar-day {
    background-color: #409eff;
    color: #fff;
    margin: 5px;
  }
}

.el-calendar__body {
  .current {
    overflow: hidden;
  }
}
.ellips {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-popover {
  overflow: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 400px;
  z-index: 100;
}
.edit-day-i-c {
  .el-icon-edit {
    font-size: 30px;
    color: #fff;
  }
}
.el-popover__title {
  text-align: center;
  font-size: 22px;
}
.schedule-content {
  font-size: 16px;
  display: -webkit-box; /*值必须为-webkit-box或者-webkit-inline-box*/
  -webkit-box-orient: vertical; /*值必须为vertical*/
  -webkit-line-clamp: 2; /*值为数字，表示一共显示几行*/
  overflow: hidden;
}
.el-form .el-form-item__content {
  display: flex;
}

.el-calendar-day {
  position: relative;
}
.edit-day-i-c {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 10%;
  background-color: rgba(0, 0, 0, 0.5);
  & > i {
    font-size: 30px;
    color: #fff;
  }
}

.more-form-item-w {
  display: flex;
  & > div {
    width: 100%;
  }
}
</style>