<template>
  <div class="important-page-c display--flex fd--column">
    <div v-if="!goPrint">
      <!-- 搜索 -->
      <div
        class="search-form btn-2b5a95"
      >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
        <el-form
          label-position="right"
          :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="订购时间起:">
            <el-date-picker
              v-model="searchForm.t1"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd 00:00:00"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="订购时间止:">
            <el-date-picker
              v-model="searchForm.t2"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd 00:00:00"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否确认:">
            <el-select
							v-model="searchForm.isok"
							placeholder="请选择"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
							clearable>
              <el-option
                v-for="item in [
                  { value: 1, label: '是' },
                  { value: 0, label: '否' },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属教学点:">
            <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >展开</el-button
        >
      </div>

      </div>

      <!-- 按钮操作 -->
      <div
        class="Btn-w eaf4ff-btn"
        :style="{
          height: $store.state.global.isBtnExtend_1
            ? ''
            : $store.state.global.BtnHeight_1 + 10 + 'px',
        }"
      >
        <div
          class="btn-l"
        v-if="tableRes.buttonlist"
          :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
        >
          <el-button
            v-for="(item, index) in tableRes.buttonlist"
            :key="index"
            type="primary"
            @click="onClickBtn(item.buttonname)"
            >{{ item.name }}
          </el-button>
        </div>

        <div class="Btn-extend">
          <el-button
            type="primary"
            v-if="$store.state.global.btnExtend_1"
            class="btn-2b5a95"
            :icon="
              $store.state.global.isBtnExtend_1
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            @click="onExtendClick('isBtnExtend_1')"
            >展开</el-button
          >
        </div>
      </div>

      <!-- 表格 -->
      <el-table
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        :data="tableRes.list"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="录入时间" align="center" width="160">
          <template slot-scope="scope">
            {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="确认" prop="isok" align="center">
          <template slot-scope="scope">
            {{ scope.row.isok === 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          width="130"
          align="center"
        ></el-table-column>
        <el-table-column
          label="语文"
          prop="chinese"
          align="center"
        >
					<template slot-scope="scope">
						<el-button @click="btnShowDetail(scope.row)" type="text">
							{{scope.row.chinese}}
						</el-button>
					</template>

				</el-table-column>
        <el-table-column
          label="语文模拟"
          prop="chineseworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="数学"
          prop="math"
          align="center"
        ></el-table-column>
        <el-table-column
          label="数学模拟"
          prop="mathworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="英语"
          prop="english"
          align="center"
        ></el-table-column>
        <el-table-column
          label="英语模拟"
          prop="englishworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="理化"
          prop="rkc"
          align="center"
        ></el-table-column>
        <el-table-column
          label="理化模拟"
          prop="rkcworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="史地"
          prop="history"
          align="center"
        ></el-table-column>
        <el-table-column
          label="史地模拟"
          prop="historyworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="政治"
          prop="politics"
          align="center"
        ></el-table-column>
        <el-table-column
          label="政治模拟"
          prop="politicsworkbook"
          align="center"
        ></el-table-column>

        <el-table-column
          label="英语"
          prop="english2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="英语模拟"
          prop="english2workbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="高数"
          prop="math2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="高数模拟"
          prop="math2workbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="大语"
          prop="chinese2"
          align="center"
        ></el-table-column>
        <el-table-column
          label="大语模拟"
          prop="chinese2workbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="教理"
          prop="education"
          align="center"
        ></el-table-column>
        <el-table-column
          label="教理模拟"
          prop="educationworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="法学"
          prop="law"
          align="center"
        ></el-table-column>
        <el-table-column
          label="法学模拟"
          prop="lawworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="艺概"
          prop="art"
          align="center"
        ></el-table-column>
        <el-table-column
          label="艺概模拟"
          prop="artworkbook"
          align="center"
        ></el-table-column>
        <el-table-column
          label="医综"
          prop="medicine"
          align="center"
        ></el-table-column>
        <el-table-column
          label="医综模拟"
          prop="medicineworkbook"
          align="center"
        ></el-table-column>

        <el-table-column
                label="生态学"
                prop="ecology"
                align="center"
        ></el-table-column>
        <el-table-column
                label="生态模拟"
                prop="ecologybook"
                align="center"
        ></el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="tableRes.totalcount"
      ></el-pagination>
    </div>
    <!-- 打印页 -->
    <Print
      @switchPrint="switchPrint"
      :id="itemId"
      :printUrl="printUrl"
      :resKey="'vgoodsregister'"
      v-else
    ></Print>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="60%"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="33%"
        class="demo-dynamic form-item-w-50"
        :rules="rules"
        status-icon
      >
        <p>高起点专/本：</p>
        <el-form-item prop="chinese" label="语文：">
          <el-input v-model.trim="dynamicValidateForm.chinese" clearable></el-input>
        </el-form-item>
        <el-form-item prop="chineseworkbook" label="语文练习册：">
          <el-input v-model.trim="dynamicValidateForm.chineseworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="math" label="数学：">
          <el-input v-model.trim="dynamicValidateForm.math" clearable></el-input>
        </el-form-item>
        <el-form-item prop="mathworkbook" label="数学练习册：">
          <el-input v-model.trim="dynamicValidateForm.mathworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="english" label="英语：">
          <el-input v-model.trim="dynamicValidateForm.english" clearable></el-input>
        </el-form-item>
        <el-form-item prop="englishworkbook" label="英语练习册：">
          <el-input v-model.trim="dynamicValidateForm.englishworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="rkc" label="理化综合：">
          <el-input v-model.trim="dynamicValidateForm.rkc" clearable></el-input>
        </el-form-item>
        <el-form-item prop="rkcworkbook" label="理化练习册：">
          <el-input v-model.trim="dynamicValidateForm.rkcworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="history" label="史地综合：">
          <el-input v-model.trim="dynamicValidateForm.history" clearable></el-input>
        </el-form-item>
        <el-form-item prop="historyworkbook" label="史地练习册：">
          <el-input v-model.trim="dynamicValidateForm.historyworkbook" clearable></el-input>
        </el-form-item>
        <p>专升本：</p>
        <el-form-item prop="politics" label="政治：">
          <el-input v-model.trim="dynamicValidateForm.politics" clearable></el-input>
        </el-form-item>
        <el-form-item prop="politicsworkbook" label="政治练习册：">
          <el-input v-model.trim="dynamicValidateForm.politicsworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="english2" label="英语：">
          <el-input v-model.trim="dynamicValidateForm.english2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="english2workbook" label="英语练习册：">
          <el-input v-model.trim="dynamicValidateForm.english2workbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="math2" label="高数二：">
          <el-input v-model.trim="dynamicValidateForm.math2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="math2workbook" label="高数练习册：">
          <el-input v-model.trim="dynamicValidateForm.math2workbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="chinese2" label="大学语文：">
          <el-input v-model.trim="dynamicValidateForm.chinese2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="chinese2workbook" label="大学语文练习册：">
          <el-input v-model.trim="dynamicValidateForm.chinese2workbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="law" label="法学：">
          <el-input v-model.trim="dynamicValidateForm.law" clearable></el-input>
        </el-form-item>
        <el-form-item prop="lawworkbook" label="法学练习册：">
          <el-input v-model.trim="dynamicValidateForm.lawworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="education" label="教育理论：">
          <el-input v-model.trim="dynamicValidateForm.education" clearable></el-input>
        </el-form-item>
        <el-form-item prop="educationworkbook" label="教育理论练习册：">
          <el-input v-model.trim="dynamicValidateForm.educationworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="art" label="艺术概论：">
          <el-input v-model.trim="dynamicValidateForm.art" clearable></el-input>
        </el-form-item>
        <el-form-item prop="artworkbook" label="艺术概论练习册：">
          <el-input v-model.trim="dynamicValidateForm.artworkbook" clearable></el-input>
        </el-form-item>
        <el-form-item prop="medicine" label="医学综合：">
          <el-input v-model.trim="dynamicValidateForm.medicine" clearable></el-input>
        </el-form-item>
        <el-form-item prop="medicineworkbook" label="医综练习册：">
          <el-input v-model.trim="dynamicValidateForm.medicineworkbook" clearable></el-input>
        </el-form-item>

        <el-form-item prop="ecology" label="生态学：">
          <el-input v-model.trim="dynamicValidateForm.ecology" clearable></el-input>
        </el-form-item>
        <el-form-item prop="ecologybook" label="生态学练习册：">
          <el-input v-model.trim="dynamicValidateForm.ecologybook" clearable></el-input>
        </el-form-item>



        <el-form-item prop="client" label="单位名单：">
          <el-input v-model.trim="dynamicValidateForm.client" clearable></el-input>
        </el-form-item>
        <el-form-item prop="consignee" label="收货人：">
          <el-input v-model.trim="dynamicValidateForm.consignee" clearable></el-input>
        </el-form-item>
        <el-form-item prop="consigneephone" label="收货人联系电话：">
          <el-input v-model.trim="dynamicValidateForm.consigneephone" clearable></el-input>
        </el-form-item>
        <el-form-item prop="address" label="收货地址：">
          <el-input v-model.trim="dynamicValidateForm.address" clearable></el-input>
        </el-form-item>
        <el-form-item prop="remark" label="备注：">
          <el-input v-model.trim="dynamicValidateForm.remark" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

 <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table border="1" cellpadding="10" cellspacing="0" class="base_table" >
        <tr>
          <td>语文：{{ dynamicValidateForm.chinese }}</td>
          <td>语文单价：{{ dynamicValidateForm.chineseprice }}</td>
          <td>数学：{{ dynamicValidateForm.math }}</td>
        </tr>
        <tr>
          <td>数学单价：{{ dynamicValidateForm.mathprice }}</td>
          <td>英语：{{ dynamicValidateForm.english }}</td>
          <td>英语单价：{{ dynamicValidateForm.englishprice }}</td>
        </tr>
        <tr>
          <td>理化：{{ dynamicValidateForm.rkc }}</td>
          <td>理化单价：{{ dynamicValidateForm.rkcprice }}</td>
          <td>理化练习册：{{ dynamicValidateForm.rkcworkbook }}</td>
        </tr>
        <tr>
          <td>理化练习册单价：{{ dynamicValidateForm.rkcworkbookprice }}</td>
          <td>文史：{{ dynamicValidateForm.history }}</td>
          <td>文史单价：{{ dynamicValidateForm.historyprice }}</td>
        </tr>
        <tr>
          <td>文史练习册：{{ dynamicValidateForm.historyworkbook }}</td>
          <td>文史练习册单价：{{ dynamicValidateForm.historyworkbookprice }}</td>
          <td>政治：{{ dynamicValidateForm.politics }}</td>
        </tr>
        <tr>
          <td>政治单价：{{ dynamicValidateForm.politicsprice }}</td>
          <td>英语（专升本）：{{ dynamicValidateForm.english2 }}</td>
          <td>英语（专升本）单价：{{ dynamicValidateForm.english2price }}</td>
        </tr>
        <tr>
          <td>高数二：{{ dynamicValidateForm.math2 }}</td>
          <td>高数二单价：{{ dynamicValidateForm.math2price }}</td>
          <td>大学语文：{{ dynamicValidateForm.chinese2 }}</td>
        </tr>
        <tr>
          <td>大学语文单价：{{ dynamicValidateForm.chinese2price }}</td>
          <td>大学语文练习册：{{ dynamicValidateForm.chinese2workbook }}</td>
          <td>大学语文练习册单价：{{ dynamicValidateForm.chinese2workbookprice }}</td>
        </tr>
        <tr>
          <td>教育理论：{{ dynamicValidateForm.education }}</td>
          <td>教育理论单价：{{ dynamicValidateForm.educationprice }}</td>
          <td>教育理论练习册：{{ dynamicValidateForm.educationworkbook }}</td>
        </tr>
        <tr>
          <td>教育理论练习册单价：{{ dynamicValidateForm.educationworkbookprice }}</td>
          <td>法学：{{ dynamicValidateForm.law }}</td>
          <td>法学单价：{{ dynamicValidateForm.lawprice }}</td>
        </tr>
        <tr>
          <td>法学练习册：{{ dynamicValidateForm.lawworkbook }}</td>
          <td>法学练习册单价：{{ dynamicValidateForm.lawworkbookprice }}</td>
          <td>艺术概论：{{ dynamicValidateForm.art }}</td>
        </tr>
        <tr>
          <td>艺术概论单价：{{ dynamicValidateForm.artprice }}</td>
          <td>艺术概论练习册：{{ dynamicValidateForm.artworkbook }}</td>
          <td>艺术概论练习册单价：{{ dynamicValidateForm.artworkbookprice }}</td>
        </tr>
        <tr>
          <td>医学综合：{{ dynamicValidateForm.medicine }}</td>
          <td>医学综合单价：{{ dynamicValidateForm.medicineprice }}</td>
          <td>医学综合练习册：{{ dynamicValidateForm.medicineworkbook }}</td>
        </tr>
        <tr>
          <td>医学综合练习册单价：{{ dynamicValidateForm.medicineworkbookprice }}</td>
          <td>客户：{{ dynamicValidateForm.client }}</td>
          <td>录入时间：{{ dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD") }}</td>
        </tr>
        <tr>
          <td v-if="dynamicValidateForm.isok == 0">是否确认：否</td>
					<td v-if="dynamicValidateForm.isok == 1">是否确认：是</td>
          <td>制单时间：{{ dynamicValidateForm.isoktime | formatDate("YYYY-MM-DD") }}</td>
          <td>备注：{{ dynamicValidateForm.remark }}</td>
        </tr>
        <tr>
          <td>收货人：{{ dynamicValidateForm.consignee }}</td>
          <td>收货地址：{{ dynamicValidateForm.address }}</td>
          <td>收货人联系电话：{{ dynamicValidateForm.consigneephone }}</td>
        </tr>
        <tr>
          <td>申请人姓名：{{ dynamicValidateForm.insertusername }}</td>
          <td>制单员姓名：{{ dynamicValidateForm.isokusername }}</td>
          <td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
        </tr>
        <tr>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
          <td>申请人电话：{{ dynamicValidateForm.phone }}</td>
          <td>语文练习册：{{ dynamicValidateForm.chineseworkbook }}</td>
        </tr>
        <tr>
          <td>语文练习册单价：{{ dynamicValidateForm.chineseworkbookprice }}</td>
          <td>数学练习册：{{ dynamicValidateForm.mathworkbook }}</td>
          <td>数学练习册单价：{{ dynamicValidateForm.mathworkbookprice }}</td>
        </tr>
        <tr>
          <td>英语练习册：{{ dynamicValidateForm.englishworkbook }}</td>
          <td>英语练习册单价：{{ dynamicValidateForm.englishworkbookprice }}</td>
          <td>政治练习册：{{ dynamicValidateForm.politicsworkbook }}</td>
        </tr>
        <tr>
          <td>政治练习册单价：{{ dynamicValidateForm.politicsworkbookprice }}</td>
          <td>英语2练习册：{{ dynamicValidateForm.english2workbook }}</td>
          <td>英语2练习册单价：{{ dynamicValidateForm.english2workbookprice }}</td>
        </tr>
        <tr>
          <td>高数二练习册：{{ dynamicValidateForm.math2workbook }}</td>
          <td>高数二练习册单价：{{ dynamicValidateForm.math2workbookprice }}</td>
					<td>流水号：{{dynamicValidateForm.id}}</td>
        </tr>
        <tr>
          <td>生态学基础：{{ dynamicValidateForm.ecology }}</td>
          <td>生态学基础单价：{{ dynamicValidateForm.ecologyprice }}</td>
          <td>生态学基础练习册：{{dynamicValidateForm.ecologybook}}</td>
        </tr>
        <tr>
          <td colspan="3">生态学基础练习册单价：{{ dynamicValidateForm.ecologybookprice }}</td>
          <td></td>
          <td></td>
        </tr>

      </table>
    </el-dialog>

  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  goodsregister_list,
  goodsregister_save,
  goodsregister_input2,
  goodsregister_delete,
  goodsregister_checkedit,
  goodsregister_isok,
  goodsregister_cancelisok,
  goodsregister_exportbyid,
  goodsregister_exports,
  goodsregister_checkprint,
  goodsregister_print,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import { _initGoodsregister } from "@/assets/js/initTableData";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
} from "@/utils/util";
import Print from "@/components/public/print.vue";
export default {
  mixins: [part],
  name: "goodsregister",
  components: {
    Print,
  },
  props: {
    printUrl: {
      default: () => goodsregister_print,
    },
  },
  data() {
    //表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };

    return {
      itemId: null, //每一项id
      searchForm: {}, //搜索表单
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      dynamicValidateForm: {}, //表单
      rules: {
        chinese: [{ trigger: "blur", validator: checkfield, required: true }],
        chineseworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        math: [{ trigger: "blur", validator: checkfield, required: true }],
        mathworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        english: [{ trigger: "blur", validator: checkfield, required: true }],
        englishworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        rkc: [{ trigger: "blur", validator: checkfield, required: true }],
        rkcworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        history: [{ trigger: "blur", validator: checkfield, required: true }],
        historyworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        politics: [{ trigger: "blur", validator: checkfield, required: true }],
        politicsworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        english2: [{ trigger: "blur", validator: checkfield, required: true }],
        english2workbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        math2: [{ trigger: "blur", validator: checkfield, required: true }],
        math2workbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        chinese2: [{ trigger: "blur", validator: checkfield, required: true }],
        chinese2workbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        law: [{ trigger: "blur", validator: checkfield, required: true }],
        lawworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        education: [{ trigger: "blur", validator: checkfield, required: true }],
        educationworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        art: [{ trigger: "blur", validator: checkfield, required: true }],
        artworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        medicine: [{ trigger: "blur", validator: checkfield, required: true }],
        medicineworkbook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        ecology: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        ecologybook: [
          { trigger: "blur", validator: checkfield, required: true },
        ],

        client: [{ trigger: "blur", message: "必填字段", required: true }],
        consignee: [{ trigger: "blur", message: "必填字段", required: true }],
        consigneephone: [
          { trigger: "blur", validator: checkfield, required: true },
        ],
        address: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      multipleSelection: [], //选中的表格项
      dialogTitle: "", //对话框标题

      goPrint: false, //前往打印页
      showDetailModal:false,
    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick",'isExpand']),
    //切换到打印页
    switchPrint(data) {
      this.goPrint = data;
      this.multipleSelection = [];
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				t1: null,
				t2: null,
				isok: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: goodsregister_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //添加click
    btnAdd() {
			this.dynamicValidateForm = {
				chinese: 0,
				chineseworkbook: 0,
				math: 0,
				mathworkbook: 0,
				english: 0,
				englishworkbook: 0,
				rkc: 0,
				rkcworkbook: 0,
				history: 0,
				historyworkbook: 0,
				politics: 0,
				politicsworkbook: 0,
				english2: 0,
				english2workbook: 0,
				math2: 0,
				math2workbook: 0,
				chinese2: 0,
				chinese2workbook: 0,
				law: 0,
				lawworkbook: 0,
				education: 0,
				educationworkbook: 0,
				art: 0,
				artworkbook: 0,
				medicine: 0,
				medicineworkbook: 0,
              ecology:0,
              ecologybook:0,
				client: '',
				consignee: '',
				consigneephone: '',
				address: '',
				remark: '',
			};
			this.dialogTitle = "添加";
			this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.checkedit(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //确认制单 click
    btnVoucher() {
      selectCheck(this.multipleSelection, "制单", true, () => {
        confirmCallBack({
          title: "提示",
          content: "确定要制单吗！",
          success: () => {
            this.isok(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //取消制单 click
    btnCancelVoucher() {
      selectCheck(this.multipleSelection, "取消制单", false, () => {
        confirmCallBack({
          title: "提示",
          content: "确定要取消制单吗！",
          success: () => {
            this.cancelisok(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //导出订单 click
    exportOrder() {
      selectCheck(this.multipleSelection, "导出", false, () => {
        this.exportbyid(this.multipleSelection[0].id);
      });
    },
    //导出总表 click api
    exportAllTable() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            goodsregister_exports +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },

    //打印 click
    btnPrint() {
      selectCheck(this.multipleSelection, "打印", false, () => {
        this.printCheck(this.multipleSelection[0].id);
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: goodsregister_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
							isRefTableList: true,
							modal: "showAddModal",
            }
          ).then((res) => {
            if (res.data.code === "200") {
              this.showAddModal = false;
            }
          });
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: goodsregister_input2,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vgoodsregister;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //删除表格项 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: goodsregister_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //修改校验 api
    checkedit(id) {
      myRequest({
        url: goodsregister_checkedit,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.editRoutePage(this.multipleSelection[0].id);
        }
      });
    },
    //确认制单 api
    isok(idArr) {
      myRequest(
        {
          method: "post",
          url: goodsregister_isok,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //取消制单 api
    cancelisok(idArr) {
      myRequest(
        {
          method: "post",
          url: goodsregister_cancelisok,
          data: this.$qs.stringify(
            {
              id: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //导出订单 api
    exportbyid(id) {
      myRequest({
        //  method: "post",
        url: goodsregister_exportbyid,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.status === 200) {
          window.open(this.$url.Freemarker + goodsregister_exportbyid + "?id=" + id)
        }
      });
    },
    //打印校验 api
    printCheck(id) {
      myRequest({
        url: goodsregister_checkprint,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.goPrint = true;
          this.itemId = id;
        }
      });
    },
  },
};
</script>


<style  lang="scss">
.Btn {
  padding: 10px;
  button {
    margin: 5px;
  }
}
.print-page {
  height: calc(100vh);
}
.print-w {
  height: 100%;
  padding: 5% 30%;
  background-color: rgb(185, 200, 177);
}
.print-c-w {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5% 5% 2% 5%;
  background-color: #fff;
}

.print-c-t > h3 {
  text-align: center;
}

.level-w > div {
  width: 50%;
  float: left;
  margin: 0 0 20px 0;
}
.print-c-b {
  position: absolute; //sticky
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 5%;
}
</style>
