<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="标题:">
          <el-input 
						v-model.trim="searchForm.title" 
						@keyup.enter.native="seach"
						clearable></el-input>
        </el-form-item>
        <el-form-item label="分享人:">
          <el-input 
						v-model.trim="searchForm.username" 
						@keyup.enter.native="seach"
						clearable></el-input>
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						clearable></el-input>
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="标题"
        prop="title"
        align="center"
      >
      
      <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text"
            >{{scope.row.title}}</el-button
          >
        </template>
      
      </el-table-column>
      <el-table-column
        label="分享人"
        prop="username"
        align="center"
      ></el-table-column>
      <el-table-column label="分享时间" align="center"
        ><template slot-scope="scope">
          {{ scope.row.sharetime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template></el-table-column
      >
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="downloadFile(scope.row)"
            >下载附件</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
      :title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="标题:">
          <el-input v-model.trim="dynamicValidateForm.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="文件路径：" prop="fileurl">
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
          >
            <el-button size="small" type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
            <tr>
              <td>标题：{{ dynamicValidateForm.title }}</td>
              <td>文件路径：{{ dynamicValidateForm.fileurl }}</td>
              <td>文件大小：{{ dynamicValidateForm.filesize }}</td>
            </tr>
            <tr>
              <td>分享时间：{{ dynamicValidateForm.sharetime  | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
              <td>点击次数：{{ dynamicValidateForm.clickcount }}次</td>
              <td>下载次数：{{ dynamicValidateForm.downcount }}次</td>
            </tr>
						<tr>
							<td>流水号: {{dynamicValidateForm.id}}</td>
							<td></td>
							<td></td>
						</tr>
          </table>
    </el-dialog>

    <!-- 选择发送范围 -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { part } from "@/utils/mixins";
import {
  fileshare_list,
  fileshare_input,
  fileshare_save,
  fileshare_delete,
} from "@/request/api/allChildrenProject";
import { _fileupload_save, _filedownload_save } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "fileshare",
  components: {},
  props: {},
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        title: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurl: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),
      showDetailModal:false,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick",'isExpand']), //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: fileshare_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row))
      this.showDetailModal = true;
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.fileurl = res.data.url;
        }
      });
    },
    //下载 click
    downloadFile(row) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.fileurl;
    },
		reset() {
			this.searchForm = {
				title: null,
				username: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //刷新 click
    btnRefresh() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: fileshare_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.tfileshare;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.sharetime; //sharetime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: fileshare_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: fileshare_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss">
	 .base_table{
	  margin: auto;
	 }
</style>