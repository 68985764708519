<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="学校名称:">
          <el-input 
						v-model.trim="searchForm.collegename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="专业:">
          <el-input 
						v-model.trim="searchForm.majorname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="层次:">
          <el-select 
						v-model="searchForm.majorlevelval" 
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in leveltype"
              :value="item.val"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程:">
          <el-input 
						v-model.trim="searchForm.coursename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="院校" align="center" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="btnShowDetail(scope.row)">{{
            scope.row.collegename
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="专业"
        prop="majorname"
        align="center"
        width="200"
      />
      <el-table-column
        label="层次"
        prop="majorlevelval"
        align="center"
        width="80"
      />
      <el-table-column
        label="课程名称"
        prop="coursename"
        align="center"
        width="350"
      />
      <el-table-column
        label="上传人"
        prop="uploadusername"
        align="center"
        width="100"
      />
      <el-table-column label="审核状态" align="center" width="100px">
        <template slot-scope="scope">
          {{ approveflag[scope.row.approveflag] }}
        </template>
      </el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="150"
      />
      <el-table-column label="操作" align="center" width="400">
        <template slot-scope="scope">
          <el-button type="text" @click="downloadFile(scope.row.fileurla)"
            >下载A卷</el-button
          >
          <el-button type="text" @click="downloadFile(scope.row.fileurlb)"
            >下载B卷</el-button
          >
					<el-button type="text" @click="downloadFile(scope.row.fileurlb)"
					  >下载c卷</el-button
					>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      width="60%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle "
    >
   <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="35%"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="是否审核:">
          {{ approveflag[dynamicValidateForm.approveflag] }}
        </el-form-item>
        <el-form-item label="审核人:">
          {{ dynamicValidateForm.approvefusername }}
        </el-form-item>
        <el-form-item label="审核时间:">
          {{
            dynamicValidateForm.approveftime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="课程:">
          {{ dynamicValidateForm.coursename }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.majorlevelval }}
        </el-form-item>
        <el-form-item label="上传人:">
          {{ dynamicValidateForm.uploadusername }}
        </el-form-item>
        <el-form-item label="上传时间:">
          {{
            dynamicValidateForm.uploadtime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="上传教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="上传分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="文件路径A:">
          {{ dynamicValidateForm.fileurla }}
        </el-form-item>
        <el-form-item label="文件路径B:">
          {{ dynamicValidateForm.fileurlb }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="课程名称:" prop="coursename">
          <el-input v-model="dynamicValidateForm.coursename" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                coursemanagement_dialogresult,
                'showcourseModal',
              )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="A卷" prop="fileurla">
          <el-input v-model="dynamicValidateForm.fileurla" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(fileUrl) => uploadFile('fileurla')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="B卷" prop="fileurlb">
          <el-input v-model="dynamicValidateForm.fileurlb" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(fileUrl) => uploadFile('fileurlb')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
				<el-form-item label="c卷" prop="fileurlc">
				  <el-input v-model="dynamicValidateForm.fileurlc" disabled />
				  <el-upload
				    class="upload-demo"
				    :action="$store.state.global.baseUrl"
				    multiple
				    :show-file-list="false"
				    :before-upload="beforeUpload"
				    :http-request="(fileUrl) => uploadFile('fileurlc')"
				  >
				    <el-button type="primary" class="select-btn">点击上传</el-button>
				    <div slot="tip" class="el-upload__tip"></div>
				  </el-upload>
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择课程 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="78%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择课程："
      :close-on-click-modal='false'
      :visible.sync="showcourseModal"
			v-if="showcourseModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCourse">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="课程:" prop="coursename">
              <el-input 
								v-model.trim="selectSearchForm.coursename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="院校:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="层次:" prop="majorlevelval">
              <el-input 
								v-model.trim="selectSearchForm.majorlevelval" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
						<el-form-item label="专业:" prop="majorname">
              <el-input 
								v-model.trim="selectSearchForm.majorname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="课程名称" prop="coursename" align="center" />
        <el-table-column label="院校名称" prop="collegename" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column label="专业" prop="majorname" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
  </div>
</template>


<script>
import { _approveflag } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  paperstock_list,
  paperstock_input,
  paperstock_save,
  paperstock_delete,
} from "@/request/api/allChildrenProject";
import {
  _fileupload_save,
  _filedownload_save,
  _coursemanagement_dialogresult,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "paperstock",
  components: {},
  props: {
    coursemanagement_dialogresult: {
      default: () => _coursemanagement_dialogresult,
    },
  },
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        coursename: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurla: [{ trigger: "blur", message: "必填字段", required: true }],
        fileurlb: [{ trigger: "blur", message: "必填字段", required: true }],
				fileurlc: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      courseTable: {}, //课程表格
      showcourseModal: false,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      leveltype: [],
      selectSearchForm: {},
      selectTable: {}, // 选择的对象
      selectTableUrl: null, // 选择的路径
      
      selectSchoolorgid: null, // modal框
      approveflag: _approveflag,
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      list: "leveltype",
      typecode: "leveltype",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		reset() {
			this.searchForm = {
				collegename: null,
				majorname: null,
				majorlevelval: null,
				coursename: null,
				schoolorgname: null,
				
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				coursename: null,
				collegename: null,
				majorlevelval: null,
				majorname: null,
				
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: paperstock_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: paperstock_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vpaperstock;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        fileurla: null,
        fileurlb: null,
				fileurlc: null,
        coursename: null,
      };
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds = new FormData();
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(fileUrl) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm[fileUrl] = res.data.url;
        }
      });
    },
    //下载 click
    downloadFile(fileurlName) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + fileurlName;
    },
    //确认课程 click
    onConfirmCourse() {
      selectCheck(this.multipleSelection, "确认课程", false, () => {
        this.dynamicValidateForm.courseid = this.multipleSelection[0].id;
        this.$set(this.dynamicValidateForm,'coursename',this.multipleSelection[0].coursename)
        this.showcourseModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选择课程  api
    onSelectCourse() {
      myRequest({
        url: _coursemanagement_dialogresult,
        data: this.selectPageInfo,
      }).then((res) => {
        if (res.data.code === "200") {
          this.multipleSelection = [];
          this.courseTable = res.data.data;
          this.showcourseModal = true;
        }
      });
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onCloseSelect();
    },

    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },


    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: paperstock_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vpaperstock;
          delete this.dynamicValidateForm.uploadtime; //inserttime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: paperstock_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: paperstock_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>