<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					@submit.native.prevent
        >
					<el-form-item label="标题:">
					  <el-input 
							v-model.trim="searchForm.title" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="付款方:">
					  <el-input 
							v-model.trim="searchForm.unitname" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="应收总额:">
					  <el-input 
								v-model.trim="searchForm.total" 
								@keyup.enter.native="seach"
								clearable />
					</el-form-item>
					<el-form-item label="收款标识:">
						<el-select 
							v-model="searchForm.isokflag" 
							@keyup.enter.native="seach"
							@visible-change="isokflagvisible"
							ref="selectref"
							clearable>
							<el-option value="0" label="未收"></el-option>
							<el-option value="1" label="已收"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="收据号:">
					  <el-input 
							v-model.trim="searchForm.shoujucodes" 
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button typr="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="标题" prop="title" align="center" width="300px"></el-table-column>
			<el-table-column label="收款方" prop="schoolorgname" align="center" width="120px"></el-table-column>
			<el-table-column label="付款方" prop="unitname" align="center" width="130px"></el-table-column>
			<el-table-column label="应收总额" align="center" prop="total"></el-table-column>
			<el-table-column label="实收总额" align="center" prop="total2"></el-table-column>
			<el-table-column label="对方已付" align="center" prop="paytotal"/>
			<el-table-column label="标识" align="center" prop="isokflag">
				<template slot-scope="scope">
				  {{ isokflag[scope.row.isokflag] }}
				</template>
			</el-table-column>
			<el-table-column label="收据号" align="center" prop="shoujucodes"/>
			<el-table-column label="录入人" align="center" prop="insertusername"></el-table-column>
			<el-table-column label="教学点" align="center" prop="schoolorgname"></el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope" align="center">
          <el-button type="primary" size="mini" @click="btnShow(scope.row)"
            >查看名单</el-button
          >
          <el-button type="primary" size="mini" @click="btnExport(scope.row)"
            >导出</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 导入应收学费 -->
    <el-dialog
      class="btn-2b5a95"
      title="导入应收学费："
      :visible.sync="showImport"
      v-if="showImport"
			:close-on-click-modal='false'
      append-to-body
			v-dialogDrag
      width="500px"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
				<el-form-item label="标题" prop="title">
					<el-input v-model.trim="dynamicValidateForm.title" clearable/>
				</el-form-item>
        <el-form-item label="函授站:" prop="station">
          <el-select
            v-model="dynamicValidateForm.station"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in stations"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-upload
            class="upload-demo"
            name="file"
            ref="upload"
            action=""
            accept=".xls, .xlsx"
            :on-change="handleChange"
            :fileList="fileList"
          >
            <el-button slot="trigger" type="primary">选择文件</el-button>

            <el-button
              class="select-btn"
              type="success"
              @click="
                downloadTemp('download/财务管理/院校往来学生名单导入模板.xls')
              "
              >下载模板</el-button
            >
            <div slot="tip" class="el-upload__tip" v-if="!resTipC">
              只能上传以.xls为后缀的文件
            </div>
            <!-- 响应提示 -->
            <div v-else>
              <h2>提示：</h2>
              <div v-html="resTipC" class="resTipc-w" style="color: red;"></div>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitImport('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>
		<!-- 	导入应收总部管理费 -->
			<el-dialog
			  class="btn-2b5a95"
			  title="导入应收总部管理费："
			  :visible.sync="showImport3"
				:close-on-click-modal='false'
			  v-if="showImport3"
			  append-to-body
				v-dialogDrag
			  width="500px"
			>
			  <el-form
			    :model="dynamicValidateForm"
			    ref="dynamicValidateForm"
			    label-width="100px"
			    class="demo-dynamic form-item-w-100"
			    status-icon
			    :rules="rules"
			  >
					<el-form-item label="标题" prop="title">
						<el-input v-model.trim="dynamicValidateForm.title" clearable/>
					</el-form-item>
			    <el-form-item label="函授站:" prop="station">
			      <el-select
			        v-model="dynamicValidateForm.station"
			        placeholder="请选择"
			        clearable
			      >
			        <el-option
			          v-for="item in stations"
			          :key="item.key"
			          :label="item.val"
			          :value="item.val"
			        >
			        </el-option>
			      </el-select>
			    </el-form-item>
			
			    <el-form-item>
			      <el-upload
			        class="upload-demo"
			        name="file"
			        ref="upload"
			        action=""
			        accept=".xls, .xlsx"
			        :on-change="handleChange"
			        :fileList="fileList"
			      >
			        <el-button slot="trigger" type="primary">选择文件</el-button>
			
			        <el-button
			          class="select-btn"
			          type="success"
			          @click="
			            downloadTemp('download/财务管理/院校往来学生名单导入模板.xls')
			          "
			          >下载模板</el-button
			        >
			        <div slot="tip" class="el-upload__tip" v-if="!resTipM">
			          只能上传以.xls为后缀的文件
			        </div>
			        <!-- 响应提示 -->
			        <div v-else>
			          <h2>提示：</h2>
			          <div v-html="resTipM" class="resTipc-w" style="color: red;"></div>
			        </div>
			      </el-upload>
			    </el-form-item>
			  </el-form>
			  <span slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="onSubmitImport3('dynamicValidateForm')"
			      >提交</el-button
			    >
			  </span>
			</el-dialog>
<!-- 导入应收预留收费项 -->
		<el-dialog
		  class="btn-2b5a95"
		  title="导入应收预留收费项："
		  :visible.sync="showImport4"
		  v-if="showImport4"
			:close-on-click-modal='false'
		  append-to-body
			v-dialogDrag
		  width="500px"
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="100px"
		    class="demo-dynamic form-item-w-100"
		    status-icon
		    :rules="rules"
		  >
				<el-form-item label="标题" prop="title">
					<el-input v-model.trim="dynamicValidateForm.title" clearable/>
				</el-form-item>
		    <el-form-item label="函授站:" prop="station">
		      <el-select
		        v-model="dynamicValidateForm.station"
		        placeholder="请选择"
		        clearable
		      >
		        <el-option
		          v-for="item in stations"
		          :key="item.key"
		          :label="item.val"
		          :value="item.val"
		        >
		        </el-option>
		      </el-select>
		    </el-form-item>
		
		    <el-form-item>
		      <el-upload
		        class="upload-demo"
		        name="file"
		        ref="upload"
		        action=""
		        accept=".xls, .xlsx"
		        :on-change="handleChange"
		        :fileList="fileList"
		      >
		        <el-button slot="trigger" type="primary">选择文件</el-button>
		
		        <el-button
		          class="select-btn"
		          type="success"
		          @click="
		            downloadTemp('download/财务管理/院校往来学生名单导入模板.xls')
		          "
		          >下载模板</el-button
		        >
		        <div slot="tip" class="el-upload__tip" v-if="!resTip">
		          只能上传以.xls为后缀的文件
		        </div>
		        <!-- 响应提示 -->
		        <div v-else>
		          <h2>提示：</h2>
		          <div v-html="resTip" class="resTipc-w" style="color: red;"></div>
		        </div>
		      </el-upload>
		    </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="onSubmitImport4('dynamicValidateForm')"
		      >提交</el-button
		    >
		  </span>
		</el-dialog>
    <!-- 查看名单列表 -->
    <el-dialog
      class="btn-2b5a95"
      title="名单列表："
			:close-on-click-modal='false'
      :visible.sync="showDetailList"
      v-if="showDetailList"
      append-to-body
			v-dialogDrag
      width="80%"
      :fullscreen="$store.state.global.isFullscreen"
    >
      <span slot="title" class="el-dialog__title">
        <span>名单列表：</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <!-- 搜索 -->
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="index_2_searchFrom"
						@submit.native.prevent
          >
            <el-form-item label="流水号:" prop="id">
              <el-input 
								v-model.trim="index_2_searchFrom.id"
								@keyup.enter.native="detailList"
								clearable />
            </el-form-item>
            <el-form-item label="姓名:" prop="studentname">
              <el-input 
								v-model.trim="index_2_searchFrom.studentname" 
								@keyup.enter.native="detailList"
								clearable />
            </el-form-item>
            <el-form-item label="身份证:" prop="idcard">
              <el-input 
								v-model.trim="index_2_searchFrom.idcard" 
								@keyup.enter.native="detailList"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="major">
              <el-input 
								v-model.trim="index_2_searchFrom.major" 
								@keyup.enter.native="detailList"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="detailList"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="detailRes.list"
        border
        stripe
        :header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        ref="multipleTable"
				:key="key"	
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="流水号" prop="id" align="center" width="90" />
        <el-table-column label="学生姓名" prop="studentname" align="center" />
        <el-table-column label="性别" prop="sex" align="center" />
        <el-table-column label="身份证" prop="idcard" align="center" />

        <el-table-column label="专业" prop="major" align="center" width="100">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.major"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="层次" prop="levelval" align="center" />
        <el-table-column label="专业类别" prop="typeval" align="center" />
        <el-table-column label="学习形式" prop="studyval" align="center" />

        <el-table-column label="院校学费" prop="tuition" align="center">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.tuition"
							:placholder = "itemRow.flag === 2 ||
                itemRow.flag === 5 || itemRow.flag === 8"
              :disabled="
                itemRow.flag === 2 ||
                itemRow.flag === 5 || itemRow.flag === 8
              "
            >
						</el-input>
          </template>
        </el-table-column>

        <el-table-column label="总部管理费" prop="managementfee" align="center">
          <template slot-scope="scope">
           <el-input
              v-model.trim="scope.row.managementfee"
							:placholder="itemRow.flag === 1 || itemRow.flag === 2 || itemRow.flag === 5"
              :disabled="
                 itemRow.flag === 1 || itemRow.flag === 2 || itemRow.flag === 5 "
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column label="函授站返还款" prop="refund" align="center">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.refund"
							:placholder ="itemRow.flag === 1 ||
                itemRow.flag === 5 || itemRow.flag === 8"
              :disabled="
                itemRow.flag === 1 ||
                itemRow.flag === 5 || itemRow.flag === 8
              "
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column label="税费" prop="taxation" align="center">
          <template slot-scope="scope">
            <el-input
              v-model.trim="scope.row.taxation"
							:placholder ="
								itemRow.flag === 1 ||
								itemRow.flag === 5 || itemRow.flag === 8
							"
              :disabled="
                itemRow.flag === 1 ||
                itemRow.flag === 5 || itemRow.flag === 8
              "
            ></el-input>
          </template>
        </el-table-column>
				<el-table-column :label="otherfeename1" prop="otherfee1" align="center">
					<template slot-scope="scope">
					  <el-input
					    v-model.trim="scope.row.otherfee1"
								:placholder="
									itemRow.flag === 2 || itemRow.flag === 8
								"
							 :disabled="
							   itemRow.flag === 2 || itemRow.flag === 8
							 "
					  ></el-input>
					</template>
				</el-table-column>
				<el-table-column :label="otherfeename2" prop="otherfee2" align="center">
					<template slot-scope="scope">
					  <el-input
					    v-model.trim="scope.row.otherfee2"
							:placholder="itemRow.flag === 2 || itemRow.flag === 8"
							:disabled="
							 itemRow.flag === 2 || itemRow.flag === 8
							"
					  ></el-input>
					</template>
				</el-table-column>
				<el-table-column :label="otherfeename3" prop="otherfee3" align="center">
					<template slot-scope="scope">
					  <el-input
					    v-model.trim="scope.row.otherfee3"
							:placholder="itemRow.flag === 2 || itemRow.flag === 8"
							:disabled="
							  itemRow.flag === 2 || itemRow.flag === 8
							"
					  ></el-input>
					</template>
				</el-table-column>
        <el-table-column label="备注" prop="remark" align="center" width="100">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.remark"
              placeholder="请选择"
              clearable
            >
              <el-option label="不注册" value="不注册"></el-option>
              <el-option label="欠费" value="欠费"></el-option>
              <el-option label="退学" value="退学"></el-option>
              <el-option label="休学" value="休学"></el-option>
              <el-option label="转专业" value="转专业"></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="教学点" prop="schoolorgname" align="center" />

        <el-table-column label="操作" align="center" width="120" fixed="right">
          <template slot-scope="scope" align="center">
            <el-button
              type="primary"
              size="mini"
              @click="onDetailSave(scope.row)"
              >保存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看名单 - 收款 -->
    <el-dialog
      class="btn-2b5a95"
      width="900px"
			v-dialogDrag
      :title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showDetailCharge"
      v-if="showDetailCharge"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="150px"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
           <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
           <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="total" label="应收总金额：">
          <el-input v-model.trim="dynamicValidateForm.total" clearable disabled></el-input>
        </el-form-item>
        <el-form-item prop="total2" label="实收总金额：">
          <el-input 
						v-model.trim="dynamicValidateForm.total2"
						:placeholder="dynamicValidateForm.total"
						clearable
					></el-input>
        </el-form-item>

        <el-form-item prop="shoujucode" label="收据编号：">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn" @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item prop="accountname" label="账户名称：">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
          <el-button
            type="primary"
            @click="
              onSelectData(account_dialogresult, 'showBankorcashModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            class="select-btn"
            >选择账户</el-button
          >
        </el-form-item>

        <el-form-item prop="type2moneyintypename" label="收入二级类别：">
          <el-input
            v-model="dynamicValidateForm.type2moneyintypename"
            disabled
          ></el-input>
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(_moneyintype2_dialogresult, 'type2moneyModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="itemmoneyinitemname" label="收入明细：">
          <el-input
            v-model="dynamicValidateForm.itemmoneyinitemname"
            disabled
          ></el-input>
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showMoneyinitemModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="shoukuantime" label="收款时间：">
          <el-date-picker
            v-model="dynamicValidateForm.shoukuantime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="shoukuanrenark" label="收款备注：">
          <el-input 
						v-model.trim="dynamicValidateForm.shoukuanrenark"
						clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitDetailCharge('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看基本信息 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
      :visible.sync="showBase"
			:close-on-click-modal='false'
      v-if="showBase"
			v-dialogDrag
      append-to-body
			title="基本信息"
    >
      <table border="1" cellpadding="10" cellspacing="0">
        <tr>
          <td>现金/项目类型：{{ dynamicValidateForm.projecttype }}</td>
          <td>年级：{{ dynamicValidateForm.grade }}</td>
          <td>院校：{{ dynamicValidateForm.collegename }}</td>
        </tr>
        <tr>
          <td>学年：{{ dynamicValidateForm.iyear }}</td>
          <td>函授站：{{ dynamicValidateForm.station }}</td>
          <td>对方单位：{{ dynamicValidateForm.unitname }}</td>
        </tr>
        <tr>
          <td>教学点：{{ dynamicValidateForm.schoolorgname }}</td>
          <td>挂靠人数：{{ dynamicValidateForm.num }}</td>
          <td>应收院校学费：{{ dynamicValidateForm.tuition }}</td>
        </tr>
        <tr>
          <td>应收总部管理费：{{ dynamicValidateForm.managementfee }}</td>
          <td>应收返还款：{{ dynamicValidateForm.refund }}</td>
          <td>应收税费：{{ dynamicValidateForm.taxation }}</td>
        </tr>
        <tr>
          <td>应收网课费：{{ dynamicValidateForm.course }}</td>
          <td>应收作业费：{{ dynamicValidateForm.task }}</td>
          <td>应收平台费：{{ dynamicValidateForm.platform }}</td>
        </tr>
        <tr>
          <td>应收总额：{{ dynamicValidateForm.total }}</td>
          <td>
            录入时间：{{
              dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
            }}
          </td>
          <td>款项结清标识 ：{{ dynamicValidateForm.isokflag }}</td>
        </tr>
        <tr>
          <td>现金1：{{ dynamicValidateForm.bankorcash }}</td>
          <td>收据编号：{{ dynamicValidateForm.shoujucodes }}</td>
          <td>收款时间：{{ dynamicValidateForm.shoukuantime }}</td>
        </tr>
        <tr>
          <td>收款备注：{{ dynamicValidateForm.shoukuanrenark }}</td>
          <td>实收院校学费：{{ dynamicValidateForm.tuition2 }}</td>
          <td>实收总部管理费：{{ dynamicValidateForm.managementfee2 }}</td>
        </tr>
        <tr>
          <td>实收返还款：{{ dynamicValidateForm.refund2 }}</td>
          <td>实收税费：{{ dynamicValidateForm.taxation2 }}</td>
          <td>实收网课费：{{ dynamicValidateForm.course2 }}</td>
        </tr>
        <tr>
          <td>实收作业费：{{ dynamicValidateForm.task2 }}</td>
          <td>实收平台费：{{ dynamicValidateForm.platform2 }}</td>
          <td>实收总额：{{ dynamicValidateForm.total2 }}</td>
        </tr>
        <tr>
          <td>坏账状态：{{ dynamicValidateForm.badstatus }}</td>
          <td>录入人：{{ dynamicValidateForm.insertusername }}</td>
          <td>银行账户名称：{{ dynamicValidateForm.accountname }}</td>
        </tr>
        <tr>
          <td>收款人：{{ dynamicValidateForm.shoukuanusername }}</td>
          <td>收入明细项：{{ dynamicValidateForm.itemname }}</td>
        </tr>
      </table>
    </el-dialog>
    <!-- 选择账户 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
      title="银行账户"
      @close="onCloseSelect"
			:close-on-click-modal='false'
      :visible.sync="showBankorcashModal"
      v-if="showBankorcashModal"
			v-dialogDrag
      append-to-body
    >
      <el-button
        type="primary"
        @click="onConfirmBankorcash('showBankorcashModal')"
        >确定</el-button
      >

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
          >
            <el-form-item label="开户名称:" prop="accountname">
              <el-input v-model="selectSearchForm.accountname" clearable />
            </el-form-item>
            <el-form-item label="银行名称:" prop="bankname">
              <el-input v-model="selectSearchForm.bankname" clearable />
            </el-form-item>
            <el-form-item label="存折账号:" prop="bookno">
              <el-input v-model="selectSearchForm.bookno" clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
        <el-table-column
          label="余额"
          prop="curmoney"
          align="center"
          width="200px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>

        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />

        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
    <!-- 选择收入明细 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
      @close="onCloseSelect"
      title="选择收入明细："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showMoneyinitemModal"
      v-if="showMoneyinitemModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
          >
            <el-form-item label="名称:" prop="name">
              <el-input v-model="selectSearchForm.name" clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          align="center"
          width="100"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 选择收入二级类别 -->
    <el-dialog
      class="btn-2b5a95"
      width="1200px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择收入二级类别"
      :visible.sync="type2moneyModal"
      v-if="type2moneyModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmType2moneyModal"
        >确定</el-button
      >
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
          >
            <el-form-item label="收入类别:" prop="typename">
              <el-input v-model="selectSearchForm.typename" clearable />
            </el-form-item>
            <el-form-item label="收入一级项目:" prop="type1name">
              <el-input v-model="selectSearchForm.type1name" clearable />
            </el-form-item>
            <el-form-item label="收入二级项目:" prop="collegename">
              <el-input v-model="selectSearchForm.collegename" clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
            >搜索</el-button
          >
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="收入类别" align="center" prop="typename" />
        <el-table-column label="收入一级项目" prop="type1name" align="center" />
        <el-table-column label="收入二级项目" prop="collegename" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil
            ? selectTable.paginationutil.totalcount
            : selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>
		<el-dialog
		  class="btn-2b5a95"
		  width="860px"
			v-dialogDrag
			:close-on-click-modal='false'
		  :title="dialogTitle"
		  :visible.sync="showAddModal"
		  v-if="showAddModal"
		  append-to-body
		>
		  <el-form
		    :model="dynamicValidateForm"
		    ref="dynamicValidateForm"
		    label-width="140px"
		    class="demo-dynamic form-item-w-100"
		    status-icon
		    :rules="rules"
		  >
				<el-form-item label="函授站:" prop="station">
				  <el-select
				    v-model="dynamicValidateForm.station"
				    placeholder="请选择"
				    clearable
				  >
				    <el-option
				      v-for="item in stations"
				      :key="item.key"
				      :label="item.val"
				      :value="item.val"
				    >
				    </el-option>
				  </el-select>
				</el-form-item>
		    <el-form-item label="学生:" prop="studentname">
		      <el-input v-model="dynamicValidateForm.studentname" disabled />
		      <el-button
		        type="primary"
		        class="select-btn"
		        @click="
		          onSelectData(studentregister_dialogresultselect, 'showDeliModal')
		        "
		        >选择</el-button
		      >
		    </el-form-item>
		    <el-form-item label="应收院校学费:" prop="tuition">
					<el-input clearable v-model="dynamicValidateForm.tuition"/>
		    </el-form-item>
				<p style="margin:0 0 10px 0;">收入详情：</p>
				<div
				  v-for="(item, index) in dynamicValidateForm.moreItems"
				  :key="index"
				  class="more-form-item-w"
				>
				  <el-form-item
				    label="收费名称："
				    :prop="'moreItems.' + index + '.otherfeename'"
				    :rules="{
				      required: true,
				      message: '必填字段',
				      trigger: 'blur',
				    }"
						style="width: 400px;"
				  >
				    <el-input v-model="item.otherfeename" clearable></el-input>
				  </el-form-item>
				  <el-form-item
				    label="费用："
				    :prop="'moreItems.' + index + '.otherfee'"
				    :rules="{
				      required: true,
				      message: '必填字段',
				      trigger: 'blur',
				    }"
						style="width: 400px;"
				  >
				    <el-input v-model="item.otherfee" clearable></el-input>
				  </el-form-item>
				  <el-button
				    v-if="index !== 0"
				    @click="onRemoveMoreForm(index)"
				    style="margin: 0 10px; height: 40px"
				    >删除</el-button
				  >
				</div>
				<el-form-item>
				  <el-button @click="addFormItem">添加一行</el-button>
				</el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button
		      type="primary"
		      @click="submitAddOrEditForm('dynamicValidateForm')"
		      :loading="!$store.state.global.isEndRequest"
		      >提交
		    </el-button>
		  </span>
		</el-dialog>
		<!-- 学生选择 -->
		<el-dialog
		  class="btn-2b5a95"
		  width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
		  @close="onCloseSelect"
		  title="选择"
		  :visible.sync="showDeliModal"
		  v-if="showDeliModal"
		  append-to-body
		>
		  <el-button type="primary" @click="onConfirm">确定</el-button>
		
		  <div class="index_2 btn-2b5a95">
		    <div
		      class="l-w"
		      :style="{
		        height: $store.state.global.isSearchExtend_2
		          ? ''
		          : $store.state.global.searchFormHeight_2 + 10 + 'px',
		      }"
		    >
		      <el-form
		        label-position="right"
		        :class="
		          $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
		        "
		        :model="selectSearchForm"
		      >
		        <el-form-item label="学生姓名:" prop="studentname">
		          <el-input v-model="selectSearchForm.studentname" clearable />
		        </el-form-item>
		        <el-form-item label="手机号码:" prop="mobile">
		          <el-input v-model="selectSearchForm.mobile" clearable />
		        </el-form-item>
		      </el-form>
		    </div>
		    <div class="r-w">
		      <el-button
		        type="primary"
		        icon="el-icon-search"
		        :loading="!$store.state.global.isEndRequest"
		        class="btn-2b5a95"
		        @click="onSelectSearchClick(selectTableUrl, null, selectSearchForm)"
		        >搜索</el-button
		      >
		      <el-button
		        type="primary"
		        :icon="
		          $store.state.global.isSearchExtend_2
		            ? 'el-icon-arrow-up'
		            : 'el-icon-arrow-down'
		        "
		        v-if="$store.state.global.searchExtend_2"
		        class="btn-2b5a95"
		        @click="onExtendClick('isSearchExtend_2')"
		        >{{ $store.state.global.searchExtendText_2 }}</el-button
		      >
		    </div>
		  </div>
		
		  <el-table
		    :data="selectTable.list"
		    style="width: 100%"
		    ref="moneyTable"
				border
				:key="key"	
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
		    @selection-change="handleSelectionChange"
		  >
		    <el-table-column type="selection" width="55" />
		    <el-table-column label="流水号" prop="id" align="center" />
		    <el-table-column label="学生姓名" prop="studentname" align="center" />
		    <el-table-column label="手机号码" prop="mobile" align="center" />
				<el-table-column label="年级" prop="grade" align="center"></el-table-column>
				<el-table-column label="院校" prop="collegename" align="center"></el-table-column>
				<el-table-column label="专业" prop="majorname" align="center"></el-table-column>
		    <el-table-column label="层次" prop="levelval" align="center" />
				<el-table-column label="教学点" prop="schoolorgname" align="center"></el-table-column>
		  </el-table>
		  <el-pagination
		    background
		    @current-change="
		      (val, data, prop, page) =>
		        handleSelectChange(val)"
		    :current-page="selectPageInfo.pageindex"
		    :page-size="selectPageInfo.pagesize"
		    :page-sizes="[10, 20, 30, 40, 50]"
		    layout="total, prev, pager, next, jumper"
		    :total="selectTable.totalcount"
		  ></el-pagination>
		</el-dialog>
		<!-- 打印收据 -->
		<el-dialog
			class="btn-2b5a95"
			width="56%"
			v-dialogDrag
			:close-on-click-modal='false'
			@close="onCloseSelect"
			title="打印收据:"
			:visible.sync="shoujuPaper"
			v-if="shoujuPaper"
			append-to-body
		>
			<object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer" width="900px" height="500px" style="margin: auto;">
			    <param name="ReportURL" :value="this.$url.upload + '/report/shouju.grf'">
			    <param name="DataURL" value="" >
			    <param name="AutoRun" value=true >
			    <param name="SerialNo" value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
			    <param name="UserName" value="锐浪报表插件本机开发测试注册" >
			</object>
		</el-dialog>
  </div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
	collegesreceivable_printrsceipt,
	collegesrceivable_checkprintshouju,
	studentregister_dialogresultselect,
	collegesreceivable_save,
  collegesreceivable_list,
  collegesreceivable_input,
	collegesreceivable_printshoujuPaper,
  collegesreceivable_importsave,
	collegesreceivable_improtdo,
	collegesreceivabel_import,
  collegesreceivabledetail_list,
  collegesreceivable_exportdetail,
  collegesreceivabledetail_save2,
  collegesreceivable_income,
  collegesreceivabledetail_income,
  collegesreceivabledetail_incomesave,
  collegesreceivablefee_list,
	collegesreceivabel_income_save
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _projectTypes,
  _available,
  _availables,
  _bankorcash,
  _paidType,
  _paidTypes
} from "@/assets/js/filedValueFlag";
import {
  _filedownload_save,
  _account_dialogresult,
  _shouju_getshoujuinfo,
  _moneyinitem_dialogresult,
  _moneyintype2_dialogresult,
} from "@/request/api/public";

export default {
  mixins: [part],
  name: "collegesreceivable",
  components: {},
  props: {
    projectTypes: {
      default: () => _projectTypes,
    },
    available: {
      default: () => _available,
    },
    availables: {
      default: () => _availables,
    },
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    bankorcash: {
      default: () => _bankorcash,
    },
    moneyinitem_dialogresult: {
      default: () => _moneyinitem_dialogresult,
    },
    _moneyintype2_dialogresult: {
      default: () => _moneyintype2_dialogresult,
    },
    paidType: {
      default: () => _paidType,
    },
    paidTypes: {
      default: () => _paidTypes,
    },
		studentregister_dialogresultselect: {
			default: () => studentregister_dialogresultselect,
		}
  },
  inject: ["reload"],
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
			vmoneyinlist:[],
			vcollegesreceivable: {},
			showPrint: false,//打印报销单
			isokflag: {
				0 :'未收',
				1 : '已收'
			},
			otherfeename1:'',
			//增加一行
			dynamicValidateForm: {
			  moreItems: [
			    {
					otherfee:'',
					otherfeename:''
			    },
			  ],
			},
			showDeliModal:false,//学生选择
			showAddModal:false, //添加
      itemRow: null, //表格项
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单

      rules: {
				otherfeename:[
					{ trigger: "blur", message: "必填字段", required: true },
				],
				otherfee:[
					{ trigger: "blur", message: "必填字段", required: true },
				],
				title:[
					{ trigger: "blur", message: "必填字段", required: true },
				],
        station: [{ trigger: "blur", message: "必填字段", required: true }],
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        projecttype: [{ trigger: "blur", message: "必填字段", required: true }],
        grade: [{ trigger: "blur", message: "必填字段", required: true }],
        collegename: [{ trigger: "blur", message: "必填字段", required: true }],
        iyear: [{ trigger: "blur", message: "必填字段", required: true }],
        unitname: [{ trigger: "blur", message: "必填字段", required: true }],
        shoujucodes: [{ trigger: "blur", message: "必填字段", required: true }],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        accountname: [{ trigger: "blur", message: "必填字段", required: true }],
        tuition: [{ trigger: "blur", message: "必填字段", required: true }],
        tuition2: [{ trigger: "blur", message: "必填字段", required: true }],
        managementfee: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        managementfee2: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        refund: [{ trigger: "blur", message: "必填字段", required: true }],
        refund2: [{ trigger: "blur", message: "必填字段", required: true }],
        taxation: [{ trigger: "blur", message: "必填字段", required: true }],
        taxation2: [{ trigger: "blur", message: "必填字段", required: true }],
        course: [{ trigger: "blur", message: "必填字段", required: true }],
        course2: [{ trigger: "blur", message: "必填字段", required: true }],
        task: [{ trigger: "blur", message: "必填字段", required: true }],
        task2: [{ trigger: "blur", message: "必填字段", required: true }],
        platform: [{ trigger: "blur", message: "必填字段", required: true }],
        platform2: [{ trigger: "blur", message: "必填字段", required: true }],

        tuition3: [{ trigger: "blur", message: "必填字段", required: true }],
        managementfee3: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        refund3: [{ trigger: "blur", message: "必填字段", required: true }],
        taxation3: [{ trigger: "blur", message: "必填字段", required: true }],
        course3: [{ trigger: "blur", message: "必填字段", required: true }],
        task3: [{ trigger: "blur", message: "必填字段", required: true }],
        platform3: [{ trigger: "blur", message: "必填字段", required: true }],
        crefund3: [{ trigger: "blur", message: "必填字段", required: true }],
        total: [{ trigger: "blur", message: "必填字段", required: true }],
        total2: [{ trigger: "blur", message: "必填字段", required: true }],

        shoukuantime: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        itemmoneyinitemname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        shoujucode: [{ trigger: "blur", message: "必填字段", required: true }],
        type2moneyintypename: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
      }, //表单规则
			shoujuPaper:false,//纸质打印
      showDetailModal: false,
      showDetailCharge: false, //查看名单 - 收款
      showImport: false,
			showImport3: false,
			showImport4: false,
      fileList: [], //上传的文件列表，仅作展示
      file: new FormData(),
      resTipC: "", //响应提示 object
			resTipM: "",//响应提示
			resTip:"",//响应提示
      stations: [], //函授站
      showDetailList: false,
      detailRes: {},
			key: '',
      showBase: false, //基本信息
      index_2_searchFrom: {},
      showBankorcashModal: false,

      selectTable: {},
      selectTableUrl: null,
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectSearchForm: {},
      showMoneyinitemModal: false,
      type2moneyModal: false,
      //收费记录
      chargeLogRes: {},
      showChargeLog: false, 
      //收费记录 - 明细
      chargeLogDetailRes:{},
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      that: this,
      list: "stations",
      typecode: "station",
    });
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
	mounted() {
	},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", [
      "dic_combox",
      "org_comboxchild",
      "org_combox",
    ]),
		enterSelect(e) {
			if(e.keyCode == 13){
				this.getDataList();
			}
		},
		isokflagvisible(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				title: null,
				unitname: null,
				total: null,
				isokflag: null,
				shoujucodes: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.index_2_searchFrom = {
				id: null,
				studentname: null,
				idcard: null,
				major: null
			}
			this.onSelectData(this.selectTableUrl, null, null);
		},
		handleQuery() {
			this.selectQuery();
			this.detailList();
		},
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
      this.getshoujuinfo();
    },
    //上传文件改变时
    handleChange(file, fileList) {
      this.file = new FormData();
      this.file.append("scorefile", file.raw);
      this.fileList = [{ name: file.name, url: file.name }];
    },
    //下载模板 click
    downloadTemp(url) {
      myRequest({
        url: _filedownload_save,
        data: {
          fileurl: url,
        },
      }).then((res) => {
        if (res.status === 200) {
          location.href =
            this.$url.Freemarker + _filedownload_save + "?fileurl=" + url;
        }
      });
    },
		//检查可否打印收据 api 纸质版本
		selectShouju() {
			myRequest({
				url: collegesrceivable_checkprintshouju,
				data: {
					id: this.multipleSelection[0].id,
				}
			}).then((res) => {
				if(res.data.code === "200") {
					this.shoujuPaper = true;
					this.printshoujuPaper();
				}
			})
		},
		printshoujuPaper() {
			myRequest({
			       url: collegesreceivable_printshoujuPaper,
			       data:{
			         id: this.multipleSelection[0].id
			         	}
			     }).then((res) => {
			       if (res.data.code === "200") {
			        	this.vcollegesreceivable = res.data.data.vcollegesreceivable;
								this.vmoneyinlist = res.data.data.vmoneyinlist;
								res.data.data.vcollegesreceivable.shoukuantime = 
								formatDare(
								  this.vcollegesreceivable.shoukuantime,
								  "YYYY-MM-DD HH:mm:ss"
								);
			           var ReportViewer = document.getElementById("ReportViewer");
			           ReportViewer.Stop();    
			           var Report = ReportViewer.Report;
			           var Recordset = Report.DetailGrid.Recordset;
			           //获得参数
			           	var ppzh = ReportViewer.Report.ParameterByName("pzh");
									var pname = ReportViewer.Report.ParameterByName("name");
									var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
									var pgrade = ReportViewer.Report.ParameterByName("grade");
									var premark= ReportViewer.Report.ParameterByName("remark");
									var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
									var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");	
									
			           	ppzh.AsString = this.vcollegesreceivable.shoujucodes;
									pname.AsString = this.vcollegesreceivable.unitname;			
									pinserttime.AsString = this.vcollegesreceivable.shoukuantime;	
									pgrade.AsString = this.vcollegesreceivable.grade;	
									premark.AsString = this.vcollegesreceivable.shoukuanrenark;
									pshoukuairen.AsString = this.vcollegesreceivable.shoukuanusername;
									pkaipiaoren.AsString = this.vcollegesreceivable.shoukuanusername;
									
										//获得字段
									var fcollege = ReportViewer.Report.FieldByName("college");
									var fprice0 = ReportViewer.Report.FieldByName("price0");
									var yuan0 = ReportViewer.Report.FieldByName("yuan0");
									var fprice = ReportViewer.Report.FieldByName("price");
									var yuan = ReportViewer.Report.FieldByName("yuan");
									
								 //填充字段
									Report.PrepareRecordset();
									for (var index = 0; index <	this.vmoneyinlist.length; index++) {
										Recordset.Append();
										fcollege.AsString = this.vcollegesreceivable.collegename;
										fprice0.AsFloat = this.vmoneyinlist[index].money;
										yuan0.AsString ="元" ; 
										fprice.AsFloat = this.vmoneyinlist[index].amount;
										yuan.AsString ="元" ;
										Recordset.Post();
									}
									
			          	ReportViewer.Start();
			       }
			  }); 
		},
		//纸质模板
	   btnPrint2() {
			selectCheck(this.multipleSelection, "操作", false, ()=> {
				this.selectShouju(this.multipleSelection[0].id)
			})
	   }, 	
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: collegesreceivable_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },

    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		//添加click
		btnAdd(){
			this.dialogTitle = "添加"
			this.dynamicValidateForm = {
				moreItems: [
				    { 
							otherfee: "",
							otherfeename: "",
				    },
				  ],
			};
			this.showAddModal = true;
		},
		//onConfirm学生选择确定
		onConfirm() {
		  selectCheck(this.multipleSelection, "选择", false, () => {
		    this.dynamicValidateForm.registerid = this.multipleSelection[0].id;
		    this.$set(
		      this.dynamicValidateForm,
		      "studentname",
		      this.multipleSelection[0].studentname
		    );
		    this.showDeliModal = false;
		  });
		},
		//添加一行 click
		addFormItem() {
		  this.dynamicValidateForm.moreItems.push({
		   otherfee: "",
		   otherfeename: "",
		  });
		},
		//删除快速新增项 click
		onRemoveMoreForm(index) {
		  this.dynamicValidateForm.moreItems.splice(index, 1);
		},
		//提交-（添加） 
		submitAddOrEditForm(formName) {
			const initMorefield = [
				"otherfee",
				"otherfeename",
			];
			initMorefield.map((i) => (this.dynamicValidateForm[i] = []));
			for (let i in this.dynamicValidateForm.moreItems) {
			  for (let key in this.dynamicValidateForm.moreItems[i]) {
			    if (this.dynamicValidateForm.moreItems[i][key]) {
			      //有值才push，否则是空字符串
			      this.dynamicValidateForm[key].push(
			        this.dynamicValidateForm.moreItems[i][key]
			      );
			    }
			  }
			}
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		      this.saveall();
		    }
		  });
		},
	//提交api 
		saveall(){
			myRequest(
			  {
					method: "post",
			    url: collegesreceivable_save,
					data: this.$qs.stringify(this.dynamicValidateForm, {
					  arrayFormat: "repeat",
					}),
			  },
			  {
			    isRefTableList: true,
			    that: this,
					
			    modal: "showAddModal",
			  }
			);
		},
		
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //导入 click
    btnImport() {
      this.dynamicValidateForm = {
        station: "",
				title: ""
      };
      this.file.delete("scorefile");
      this.fileList = [];
      this.showImport = true;
    },
		//导入 click
		btnImport3() {
		  this.dynamicValidateForm = {
		    station: "",
				title: ""
		  };
		  this.file.delete("scorefile");
		  this.fileList = [];
		  this.showImport3 = true;
		},
		//导入 click
		btnImport4() {
		  this.dynamicValidateForm = {
		    station: "",
				title: ""
		  };
		  this.file.delete("scorefile");
		  this.fileList = [];
		  this.showImport4 = true;
		},
    //导入 - 提交 api
    onSubmitImport(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.file.append("station", this.dynamicValidateForm.station);
					this.file.append("title", this.dynamicValidateForm.title);
          if (!this.file.get("scorefile")) {
            return messageTip({
              message: "请选择需要导入的文件",
              type: "warning",
            });
          }
          myRequest({
              method: "post",
              url: collegesreceivable_importsave,
              data:this.file,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then((res) => {
							this.resTipC = res.data.data;
							if(res.data.code === "200" && res.data.status === null) {
								this.getDataList();
								this.showImport = false;
							}
						})
        }
      });
    },
		onSubmitImport3(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		      this.file.append("station", this.dynamicValidateForm.station);
					this.file.append("title", this.dynamicValidateForm.title);
		      if (!this.file.get("scorefile")) {
		        return messageTip({
		          message: "请选择需要导入的文件",
		          type: "warning",
		        });
		      }
		      myRequest({
		          method: "post",
		          url: collegesreceivable_improtdo,
		          data:this.file,
		          headers: {
		            "Content-Type": "multipart/form-data",
		          },
		        }).then((res) =>{
							this.resTipM = res.data.data;
							if(res.data.code === "200") {
								this.getDataList();
								this.showImport3 = false;
							}
						})
		    }
		  });
		},
		onSubmitImport4(formName) {
		  this.$refs[formName].validate((valid) => {
		    if (valid) {
		      this.file.append("station", this.dynamicValidateForm.station);
					this.file.append("title", this.dynamicValidateForm.title);
		      if (!this.file.get("scorefile")) {
		        return messageTip({
		          message: "请选择需要导入的文件",
		          type: "warning",
		        });
		      }
		      myRequest({
		          method: "post",
		          url: collegesreceivabel_import,
		          data:this.file,
		          headers: {
		            "Content-Type": "multipart/form-data",
		          },
		        }).then((res) => {
							this.resTip = res.data.data;
							if(res.data.code === "200") {
								this.getDataList();
								this.showImport4 = false;
							}
						})		      
		    }
		  });
		},
    //导出 click
    btnExport(row) {
      window.location.href =
        this.$url.Freemarker +
        collegesreceivable_exportdetail +
        "?id=" +
        row.id;
    },

    //查看名单 click
    btnShow(row) {
      this.itemRow = row;
      this.detailList();
    },
    //查看名单 api
    detailList() {
      myRequest({
        url: collegesreceivabledetail_list,
        data: Object.assign(
          {
            collegesreceivableid: this.itemRow.id,
          },
          this.index_2_searchFrom
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.detailRes = res.data.data;
          this.multipleSelection = []
          this.showDetailList = true;
					this.otherfeename1 = res.data.data.list[0].otherfeename1;
					this.otherfeename2 = res.data.data.list[0].otherfeename2;
					this.otherfeename3 = res.data.data.list[0].otherfeename3;
        }
      });
    },
    //查看名单 - 保存 click
    onDetailSave(row) {
      if (!row.remark)
        return messageTip({ message: "请选择备注类型", type: "warning" });
				row.flag = this.itemRow.flag;
      myRequest({
        url: collegesreceivabledetail_save2,
        data: row,
      }).then((res) => {
        if (res.data.code === "200") {
          this.detailList();
        }
      });
    },
		//收款
		btnReceivable(){
			this.dialogTitle = "收款";
			selectCheck(this.multipleSelection, "操作", false, () => {
				this.detailChargeRoute(this.multipleSelection[0].id);
				this.dynamicValidateForm.id = this.multipleSelection[0].id;
				this.dynamicValidateForm.companyorgname= this.multipleSelection[0].companyorgname;
				this.dynamicValidateForm.schoolorgname = this.multipleSelection[0].schoolorgname;
				this.dynamicValidateForm.total = this.multipleSelection[0].total;
			})
		},
    //收款路由 - api
   detailChargeRoute(id) {
      myRequest({
        url: collegesreceivable_income,
        data:{
					id:id
				}
      }).then((res) => {
        if (res.data.code === "200") {
         this.showDetailCharge = true;
				 this.dynamicValidateForm.shoujucode = res.data.data.tshouju.code;
				 this.dynamicValidateForm.shoujuid = res.data.data.tshouju.id;
        }
      });
    },
    //查看名单 - 收款保存 - api
    onSubmitDetailCharge(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest({
            method: "post",
            url: collegesreceivabel_income_save,
            data: this.$qs.stringify(this.dynamicValidateForm, {
              arrayFormat: "repeat",
            }),
          }).then((res) => {
            if (res.data.code === "200") {
              this.dailyPrint();
							this.showDetailCharge = false;
            }
          });
        }
      });
    },
		//打印收据 click
		btnPrint() {
			selectCheck(this.multipleSelection, "操作", false, ()=> {
				this.checkprintshouju(this.multipleSelection[0])
			})
		},
		//检查可否打印收据 api
		checkprintshouju(row) {
			myRequest({
				url: collegesrceivable_checkprintshouju,
				data: {
					id: row.id,
				}
			}).then((res) => {
				if(res.data.code === "200") {
					this.printReceipt(row);
				}
			})
		},
		//打印收据 api
		printReceipt(row) {
		  myRequest({
		    url: collegesreceivable_printrsceipt,
		    data: {
		      id: row.id,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
					window.open(
						this.$url.upload + 
						"/shouju/" +
						row.id +
						res.data.data.tshouju.code +
						".pdf"
					)
		    }
		  });
		},
		//打印收据
		dailyPrint(){
			myRequest({
				url: collegesreceivable_printrsceipt,
				data:{
					id: this.dynamicValidateForm.id
				},
			}).then((res) => {
				if(res.data.code === '200') {
					window.open(
						this.$url.upload +
						"/shouju/" +
						this.dynamicValidateForm.id +
						res.data.data.tshouju.code +
						".pdf"
					)
				}
			})
		},
    //确认银行账户 click
    onConfirmBankorcash() {
      selectCheck(this.multipleSelection, "选择账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "cardno",
          this.multipleSelection[0].cardno
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showBankorcashModal = false;
      });
    },
    //确认收入明细 click
    onConfirmMoneyinitem() {
      selectCheck(this.multipleSelection, "确认收入明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "itemmoneyinitemname",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.itemid= this.multipleSelection[0].id;
        this.showMoneyinitemModal = false;
      });
    },
    //选择收入二级类型确定 click
    onConfirmType2moneyModal() {
     this.$set(
        this.dynamicValidateForm,
        "type2moneyintypename",
        this.multipleSelection[0].collegename
      );
      this.$set(
        this.dynamicValidateForm,
        "type1moneyintypename",
        this.multipleSelection[0].type1name
      );
      this.$set(
        this.dynamicValidateForm,
        "typemoneyintypename",
        this.multipleSelection[0].typename
      );
      this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
      this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
      this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
      this.type2moneyModal = false;
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectIndex = data?.index || 0;
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },
    //获取收据编号  api
    getshoujuinfo() {
      myRequest({
        url: _shouju_getshoujuinfo,
        data: {
          schoolorgid: this.multipleSelection[0].schoolorgid
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.shoujucodes = res.data.data.tshouju.code;
          this.dynamicValidateForm.shoujucode = res.data.data.tshouju.code;
          this.dynamicValidateForm.shoujuid = res.data.data.tshouju.id;
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss">
	@import "../../../assets/css/kard.scss";
</style>