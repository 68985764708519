<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="姓名:">
          <el-input 
						v-model.trim="searchForm.studentname" 
						@keyup.enter.native="searchForm"
						clearable />
        </el-form-item>
        <el-form-item label="手机:">
          <el-input 
						v-model.trim="searchForm.mobile" 
						@keyup.enter.native="searchForm"
						clearable />
        </el-form-item>
        <el-form-item label="项目类型:">
          <el-select 
						v-model="searchForm.projecttype" 
						@keyup.enter.native="searchForm"
						@visible-change="visibleType"
						ref="selectref"
						clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="searchForm"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生姓名" align="center">
        <template slot-scope="scope"
          ><el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="手机号码"
        prop="mobile"
        align="center"
        width="130"
      >
				<template slot-scope="scope">
				    {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
				</template>
			</el-table-column>
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column label="院校" prop="collegename" align="center" />
      <el-table-column label="专业" prop="majorname" align="center" />
      <el-table-column label="层次" prop="levelval" align="center" />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column
        label="录入人姓名"
        prop="insertusername"
        align="center"
      />
      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
        width="150"
      />
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      width="35%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学生姓名:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="手机号码:">
          {{ dynamicValidateForm.mobile | phoneFilter()}}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="所属教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="所属分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="备注:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="姓名:"
          prop="studentname"
          :rules="[{ trigger: 'blur', message: '必填字段', required: true }]"
        >
          <el-input v-model="dynamicValidateForm.studentname" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                studentregister_dialogresult5,
                'showDeliModal',
                {schoolorgid:dynamicValidateForm.schoolorgid,}
              )
            "
            :disabled="dialogTitle !== '添加'"
            >选择学生</el-button
          >
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model.trim="dynamicValidateForm.remark" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择德立学生-->
    <el-dialog
      class="btn-2b5a95"
      width="90%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择学生："
      :visible.sync="showDeliModal"
			v-if="showDeliModal"
			:close-on-click-modal='false'
      append-to-body
      
    >
      <el-button type="primary" @click="onConfirmDeli">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="学生姓名:" prop="studentname">
              <el-input 
								v-model.trim="selectSearchForm.studentname" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
								clearable />
            </el-form-item>
            <el-form-item label="手机号码:" prop="mobile">
              <el-input 
								v-model.trim="selectSearchForm.mobile" 
									@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        height="250"
        :cell-style="{padding:0}"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="学生姓名" prop="studentname" align="center" />
        <el-table-column
          label="手机号码"
          prop="mobile"
          align="center"
          width="180"
        />
        <el-table-column label="学号" prop="code" align="center" />
        <el-table-column
          label="院校"
          prop="collegename"
          align="center"
          width="150"
        />
        <el-table-column label="学年" prop="iyear" align="center" />
        <el-table-column label="省内/省外" prop="areaval" align="center" />
        <el-table-column label="入学年级" prop="grade" align="center" />
        <el-table-column label="类别" prop="keleival" align="center" />
        <el-table-column label="层次" prop="levelval" align="center" />
        <el-table-column
          label="专业"
          prop="majorname"
          align="center"
          width="180"
        />
        <el-table-column label="学习方式" prop="studytypeval" align="center" />
        <el-table-column
          label="学籍状态"
          prop="xuejistatusval"
          align="center"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="120"
        />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
      downloadUrl="download/教学管理/学历/符合学位学生名单导入模板.xls"
    ></Import>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Import from "@/components/Import/index.vue";
import {
  conformacademic_list,
  conformacademic_input,
  conformacademic_save,
  conformacademic_delete,
  conformacademic_importsave,
  conformacademic_exportscore,
  studentregister_dialogresult5,
  studentregister_getstudentregister,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _projectTypes } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "conformacademic",
  components: { Import },
  props: {
    importSaveUrl: {
      default: () => conformacademic_importsave,
    },
    studentregister_dialogresult5: {
      default: () => studentregister_dialogresult5,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      importDialog: false, //导入框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
				companyorgname: [{ trigger: "blur", message: "必填字段", required: true }],
				schoolorgname: [{ trigger: "blur", message: "必填字段", required: true }],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      showDeliModal: false, //德立学生
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTableUrl: null,
      selectTable: {},
      
      selectSchoolorgid: null,
      selectSearchForm: {},
      projectTypes: _projectTypes,
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
    //导入 click
    btnImport() {
      this.importDialog = true;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				mobile: null,
				projecttype: null,
				schoolorgname: null
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				studentname: null,
				mobile: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, {schoolorgid: this.dynamicValidateForm.schoolorgid});
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: conformacademic_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: conformacademic_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          console.log(res);
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vconformacademic;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        studentname: null,
      };
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //确认学生 click
    onConfirmDeli() {
      selectCheck(this.multipleSelection, "确认学生", false, () => {
      this.$set(this.dynamicValidateForm,'studentname',this.multipleSelection[0].studentname)
        this.dynamicValidateForm.studentregisterid = this.multipleSelection[0].id;
        this.showDeliModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //导出click
    btnExport() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            conformacademic_exportscore +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: conformacademic_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vconformacademic;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          this.showAddModal = true;
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: conformacademic_save,
              data: this.$qs.stringify(this.dynamicValidateForm, {
                arrayFormat: "repeat",
              }),
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: conformacademic_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
			this.onSelectData();
    },
    //选项表格 api(德立学生)
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>