


<template>
  <div>
    <div id="cadmissionsummary" class="cadmission-w"></div>

    <!-- 该标签用来触发 updated 函数 -->
    <div class="loading-end">{{isLoading}}</div>
  </div>
</template>



<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import { performance_chengkao } from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
export default {
  mixins: [part],
  name: "cadmissionsummary",
  created() {

    this.chengkao();
  },

  mounted() {
  },

  data() {
    return {
      performancelist: [], //效绩列表

      schoolNameList: [], //教学点
      schoolsData: [
        {
          name: "年度德立学生报名",
          type: "bar",
          data: [],
        },
        {
          name: "上月德立学生报名",
          data: [],
          type: "bar",
        },
        {
          name: "本月德立学生报名",
          type: "bar",
          data: [],
        },
        {
          name: "本月辅导班报名",
          type: "bar",
          data: [],
        },
        {
          name: "昨日德立学生报名",
          type: "bar",
          data: [],
        },
        {
          name: "年度合作报名",
          type: "bar",
          data: [],
        },
      ], //所有教学点不同时间报名人数 总数据
      valFlag: ["c1", "c2", "c3", "c5", "c6", "c7"], //不同时段

      isLoading:true, //是否结束加载
    };
  },

  methods: {
    ...mapActions("global", ["onExtendClick"]),
    //初始化图标
    initCadmissionE() {
      const that = this;
      // echart
      const dom = document.getElementById("cadmissionsummary");
      const myChart = this.$echarts.init(dom);
      let option = {
        title: {
          subtext: "成考招生汇总",
        },
        tooltip: {},
        legend: {
          left: "right",
          data: [
            "年度德立学生报名",
            "上月德立学生报名",
            "本月德立学生报名",
            "本月学位费报名",
            "本月辅导班报名",
            "昨日德立学生报名",
            "年度合作报名",
          ],
        },
        grid: {
          width: "1100px",
          left: 60,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                formatter: function (params) {
                  return params.value.replace("\n", "");
                },
              },
            },
          },
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              interval: 0,
              formatter: function (value, i) {
                if (value.length > 2 && i % 2 !== 0) {
                  return value.substring(0, 3) + "...";
                } else if (value.length > 2) {
                  return value.substring(0, 2) + "...";
                }
              },
            },
            data: this.schoolNameList,
            splitLine: { show: false },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "报名人数",
            min: 0,
            max: 2000,
          },
        ],
        series: this.schoolsData,
      };
      myChart.setOption(option);
    },
    //加载数据 api
    chengkao() {
      myRequest({
        url: performance_chengkao,
      }).then((res) => {
        if (res.data.code === "200") {
          this.performancelist = res.data.data.performancelist;
          this.schoolNameList = res.data.data.performancelist.map((item, i) => {
            if (i % 2 !== 0) {
              return "\n" + item.schoolorgname;
            }
            return item.schoolorgname;
          });
          this.whileSchoolData(this.performancelist);
          this.initCadmissionE();
          this.isLoading = false
        }
      });
    },
    //遍历所有教学点不同时间报名人数
    whileSchoolData(alldata) {
      for (let c in this.valFlag) {
        for (let i in alldata) {
          this.schoolsData[c].data.push(alldata[i][this.valFlag[c]]);
        }
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.cadmission-w {
  width: 100%;
  height: 400px;
}
.loading-end{
    display: none;
  }
</style>
