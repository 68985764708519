<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="日期:">
          <el-date-picker
            v-model="searchForm.dates"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd 00:00:00"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所属教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="教学点名称"
        prop="schoolorgname"
        align="center"
      ></el-table-column>
      <el-table-column
        label="分公司名称"
        prop="companyorgname"
        align="center"
      ></el-table-column>
			<el-table-column label="日期" prop="dates" align="center">
			  <template slot-scope="scope">
			    {{ scope.row.dates | formatDate("YYYY-MM-DD") }}
			  </template>
			</el-table-column>
			<el-table-column label="操作" prop="dates" align="center">
			  <template slot-scope="scope">
			    <el-button @click="btnShowDetails(scope.row.id)" type="text"
			      >查看详情</el-button
			    >
			  </template>
			</el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 添加 -->
    <el-dialog
      class="btn-2b5a95"
      title="添加："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
      width="40%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
      >
        <el-form-item prop="dates1" label="选择日期：">
          <el-date-picker
            v-model="dynamicValidateForm.dates1"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd 00:00:00"
          >
          </el-date-picker>
        </el-form-item>

        <div
          v-for="(item, i) in dynamicValidateForm.moreItems"
          :key="i"
          class="more-form-item-w"
        >
          <el-form-item
            label="内容："
            :prop="'moreItems.' + i + '.content'"
            :rules="{
              required: true,
              message: '必填字段',
              trigger: 'blur',
            }"
          >
            <el-input v-model.trim="item.content" type="textarea" clearable></el-input>
          </el-form-item>
          <el-button
            v-if="i !== 0"
            @click="onRemoveMoreForm(i)"
            style="margin: 0 10px; height: 40px"
            >删除</el-button
          >
        </div>
        <el-form-item>
          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <div>
              <el-button @click="addFormItem">添加日程</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitSchedule('dynamicValidateForm')">提交 </el-button>
      </span>
    </el-dialog>

    <!-- 表格项详情 -->
    <el-dialog
      title="详情"
			v-dialogDrag
			width="60%"
			:close-on-click-modal='false'
      :visible.sync="showTableItemModal"
			v-if="showTableItemModal"
      append-to-body
    >
      <!-- 操作按钮 -->
      <div class="Btn eaf4ff-btn">
        <el-button type="primary" @click="btnAddDetial">添加 </el-button>
        <el-button type="primary" @click="btnEditDetial">修改 </el-button>
        <el-button type="primary" @click="btnDelDetial">删除 </el-button>
      </div>

      <el-table
        :data="tableItemDetail.list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>

        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column label="内容" prop="content" align="center" width="200">
        </el-table-column>
        <el-table-column width="150"> </el-table-column>
        <el-table-column width="150"> </el-table-column>
        <el-table-column width="150"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <!-- 详情里的修改，添加 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="detailItemDialogTitle"
      :visible.sync="showDetailEditModal"
			v-if="showDetailEditModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="detailItemForm"
        ref="detailItemForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
      >
        <el-form-item prop="content" label="日程内容：">
          <el-input type="textarea" v-model.trim="detailItemForm.content" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitDetailForm">提交 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  scheduleyear_save,
  scheduleyear_list,
  schedulemanagedetail_list,
  scheduleyear_delete,
  schedulemanagedetail_save,
  schedulemanagedetail_delete,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import { _initStockmanage } from "@/assets/js/initTableData";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "scheduleyear",
  components: {},
  props: {},
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {}, //搜索表单
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      showTableItemModal: false,
      multipleSelection: [], //选中的表格项

      moreFormItem: [], //添加一行表单项
      dynamicValidateForm: {
        //快速新增表单
        dates1: "",
        moreItems: [
          {
            content: "",
          },
        ],
      },
      tableItemDetail: [],
      showDetailEditModal: false,
      detailItemForm: {},
      detailItemDialogTitle: "",
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
		reset() {
			this.searchForm = {
				dates: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: scheduleyear_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    //增加 click
    btnAdd() {
      this.dynamicValidateForm = {
        dates1: "",
        moreItems: [
          {
            content: "",
          },
        ],
      };
      this.showAddModal = true;
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //查看详情 click
    btnShowDetails(id) {
      this.itemId = id;
      this.lookDetail();
      this.showTableItemModal = true;
    },

    //查看详情 api
    lookDetail() {
      myRequest({
        method: "post",
        url: schedulemanagedetail_list,
        data: this.$qs.stringify(
          {
            pageinfo: this.pageinfo,
            schedulemanageid: this.itemId,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableItemDetail = res.data.data;
          this.multipleSelection = [];
        }
      });
    },

    //查看详情的添加 click
    btnAddDetial() {
      this.showDetailEditModal = true;
      this.detailItemDialogTitle = "添加：";
      this.detailItemForm = {};
    },

    //查看详情的修改 click
    btnEditDetial() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.detailItemForm = this.multipleSelection[0];
        this.detailItemDialogTitle = "修改：";
        this.showDetailEditModal = true;
      });
    },

    //查看详情的修改，添加提交  api
    onSubmitDetailForm() {
      myRequest(
        {
          url: schedulemanagedetail_save,
          method: "post",
          data: this.$qs.stringify(
            {
              id: this.detailItemForm.id || "",
              content: this.detailItemForm.content,
              schedulemanageid: this.itemId,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          methodName: "lookDetail",
          modal: "showDetailEditModal",
          that: this,
        }
      );
    },

    //查看详情的删除 click
    btnDelDetial() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delDetial(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //查看详情的删除 api
    delDetial(ids) {
      myRequest({
        method: "post",
        url: schedulemanagedetail_delete,
        data: this.$qs.stringify(
          {
            ids: ids,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.lookDetail();
        }
      });
    },

    //刷新 click
		btnRefresh() {
      this.getDataList();
    },
    //添加一行日程 click
    addFormItem() {
      this.dynamicValidateForm.moreItems.push({
        content: "",
      });
    },
    //删除一行日程 click
    onRemoveMoreForm(index) {
      this.dynamicValidateForm.moreItems.splice(index, 1);
    },

    //提交日程 click
    onSubmitSchedule(formName) {
      this.dynamicValidateForm.content = [];
      for (let i in this.dynamicValidateForm.moreItems) {
        for (let key in this.dynamicValidateForm.moreItems[i]) {
          if (this.dynamicValidateForm.moreItems[i][key]) {
            //有值才push，否则是空字符串
            this.dynamicValidateForm[key].push(this.dynamicValidateForm.moreItems[i][key]);
          }
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitSchedule();
        }
      });
    },

    //添加日程 api
    submitSchedule() {
      myRequest(
        {
          method: "post",
          url: scheduleyear_save,
          data: this.$qs.stringify(
            {
              content: this.dynamicValidateForm.content,
              dates1: this.dynamicValidateForm.dates1,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = false;
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: scheduleyear_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>