<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm" 
				@submit.native.prevent
      >
				<el-form-item label="流水号:">
					<el-input 
						v-model.trim="searchForm.id" 
						@keyup.enter.native="seach"
						clearable />
				</el-form-item>
        <el-form-item label="名称:">
          <el-input 
						v-model.trim="searchForm.name" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="收入类型名称:">
          <el-input 
						v-model.trim="searchForm.typename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button 
				type="primary"
				@click="resetQuery"
				>
				重置
			</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      border
			stripe
			:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="名称" prop="name" align="center" />
      <el-table-column
        label="是否启用"
        prop="isenabled"
        align="center"
        width="160"
      >
        <template slot-scope="scope">
          {{ available[scope.row.isenabled] }}
        </template>
      </el-table-column>
      <el-table-column label="收入类型名称" prop="typename" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			v-dialogDrag
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="name" label="名称">
          <el-input v-model.trim="dynamicValidateForm.name" clearable/>
        </el-form-item>
        <el-form-item prop="typename" label="收入类型:">
          <el-input
            v-model.trim="dynamicValidateForm.typename"
            placeholder="请选择"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                moneyintype_dialogresult,
                'showLevel2Modal',
              )
            "
            >选择</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择收入类型 -->
    <el-dialog
      class="btn-2b5a95"
      width="52%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择收入类型："
      :visible.sync="showLevel2Modal"
			v-if="showLevel2Modal"
			:close-on-click-modal='false'
      append-to-body
      
    >
      <el-button type="primary" @click="onConfirmLevel2">确定</el-button>

<div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))"
								clearable />
            </el-form-item>
            
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="onSelectSearchClick(selectTableUrl,null,Object.assign({},selectSearchForm))
            "
            >搜索</el-button
          >
					<el-button
						type="primary"
						@click="handleRest"
						>
						重置
					</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="名称" prop="name" align="center" />
        <el-table-column label="是否启用" align="center">
          <template slot-scope="scope">
            {{ scope.row.isenabled == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
    <Export
      :exportTitle="'收入一级项目'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>


<script>
import { _available } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  moneyintype1_list,
  moneyintype1_input,
  moneyintype1_save,
  moneyintype1_delete,
  moneyintype1_export,
  moneyintype1_exportexcel,
  moneyintype1_exportsave,
  moneyintype1_isenabled,
} from "@/request/api/allChildrenProject";
import { _moneyintype_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "moneyintype1",
  components: { Export },
  props: {
    exportUrl: {
      default: () => moneyintype1_export,
    },
    exportexcelUrl: {
      default: () => moneyintype1_exportexcel,
    },
    exportsaveUrl: {
      default: () => moneyintype1_exportsave,
    },
    moneyintype_dialogresult: {
      default: () => _moneyintype_dialogresult,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        name: [{ trigger: "blur", message: "必填字段", required: true }],
        typename: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      
      selectSchoolorgid: null,
      showLevel2Modal: false, //二级项目
			key: '',
      selectTableUrl: null,
      selectTable: {},
      selectSearchForm: {},
      available: _available,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
		rest() {
			this.searchForm = {
				id: null,
			  name: null,
				typename: null,
			}
		},
		handleRest() {
			this.selectSearchForm = {
				name: null
			};
			this.onSelectData(this.selectTableUrl,null,null);
		},
		//重置 click
		resetQuery() {
			this.rest();
			this.getDataList();
		},
    //列表查询 api
    getDataList() {
      myRequest({
        url: moneyintype1_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //启用 click
    btnEnable() {
      selectCheck(this.multipleSelection, "启用", true, () => {
        confirmCallBack({
          title: "提示",
          content: "该数据是否启用！",
          success: () => {
            this.isenabled(
              this.multipleSelection.map((i) => i.id),
              1
            );
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //禁用 click
    btnDisable() {
      selectCheck(this.multipleSelection, "禁用", true, () => {
        confirmCallBack({
          title: "提示",
          content: "该数据是否禁用！",
          success: () => {
            this.isenabled(
              this.multipleSelection.map((i) => i.id),
              0
            );
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //确认二级项目 click
    onConfirmLevel2() {
      selectCheck(this.multipleSelection, "收入类型", false, () => {
        this.$set(this.dynamicValidateForm,'typename',this.multipleSelection[0].name)
        this.dynamicValidateForm.typeid = this.multipleSelection[0].id;
        this.showLevel2Modal = false;
				this.handleRest();
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: moneyintype1_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vmoneyintype1;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: moneyintype1_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: moneyintype1_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //启用，禁用 api
    isenabled(ids, isenabled) {
      myRequest(
        {
          method: "post",
          url: moneyintype1_isenabled,
          data: this.$qs.stringify(
            {
              ids: ids,
              isenabled: isenabled,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
    },
    //选项表格 api(德立学生)
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
			this.handleRest();
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style  lang="scss">
</style>